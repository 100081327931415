var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-100 cursor-pointer rounded border position-relative shadow-sm",
      class: { "alert-success border-success": _vm.t._selected },
      staticStyle: { "min-height": "98px" }
    },
    [
      _c("div", { staticClass: "h-100 p-2 d-flex flex-column" }, [
        _c("div", { staticClass: "flex-grow-1" }, [
          _c("div", [
            _vm._v("\n        " + _vm._s(_vm.t.label) + "\n        "),
            _vm.t.type === "base"
              ? _c("span", { staticClass: "badge badge-warning" }, [
                  _c("i", { staticClass: "fas fa-lock" }),
                  _vm._v(" Base Template\n        ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "small" }, [
            _vm._v(_vm._s(_vm._f("truncate")(_vm.t.description, 120)))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex justify-content-between align-items-center" },
          [
            _c("div", { staticClass: "small text-muted" }, [
              _vm._v("Name: " + _vm._s(_vm.t.name))
            ]),
            _vm._v(" "),
            _c("div", [
              !_vm.isBaseTemplate
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-light",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.editTemplate(_vm.t.id)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-pen" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isBaseTemplate
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-light",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.deleteTemplate(_vm.t.id)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-trash" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isBaseTemplate
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-light",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.showTemplate(_vm.t.id)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-info" })]
                  )
                : _vm._e()
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }