<template>
  <b-modal
    ref="modal"
    id="show-template-modal"
    title="Template Details"
    size="lg"
  >
    <div class="p-2">
      <div class="form-label">
        Name
      </div>
      <div class="mb-2 small ">
        {{ name }}
      </div>
      <div class="form-label">
        Label
      </div>
      <div class="mb-2 small ">
        {{ label }}
      </div>
      <div class="form-label">
        Description
      </div>
      <div class="mb-2 small">
        {{ description }}
      </div>
    </div>

    <template slot="modal-footer" slot-scope="{ ok }">
      <button class="btn btn-sm btn-primary" @click="ok()">
        OK
      </button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ShowTemplateModal",
  computed: {
    selectedTemplateId() {
      return this.$store.getters.selectedTemplateId;
    },
    template() {
      return this.$store.getters.templateById(this.selectedTemplateId);
    },
    name() {
      return this.template.name;
    },
    label() {
      return this.template.label;
    },
    description() {
      return this.template.description;
    }
  }
};
</script>

<style scoped></style>
