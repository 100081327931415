var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.edit
    ? _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.initialValue,
            expression: "initialValue"
          },
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.save,
            expression: "save"
          }
        ],
        ref: "input",
        staticClass: "form-control form-control-sm",
        attrs: { id: "description", rows: "5" },
        domProps: { value: _vm.initialValue },
        on: {
          keyup: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.save($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.cancel($event)
            }
          ],
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.initialValue = $event.target.value
          }
        }
      })
    : _c(
        "div",
        {
          staticClass:
            "w-100 hoverable form-control-sm d-flex align-items-center justify-content-between",
          staticStyle: { height: "auto !important" },
          on: { click: _vm.startEditing }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.initialValue))]),
          _vm._v(" "),
          _vm.initialValue === "" ? _c("span", [_vm._v(" ")]) : _vm._e(),
          _vm._v(" "),
          _c("i", { staticClass: "edit-indicator fas fa-pen text-muted" })
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }