<template>
  <div class="p-2" :class="{'border border-danger':paramCount===0,'border':paramCount!==0}">
    <div class="d-flex">
      <div
        style="width: 35px; min-width: 35px; max-width:35px"
        @click="
          () => {
            collapsed ? expand() : hide();
          }
        "
      >
        <i
          class="fas"
          :class="{
            'fa-chevron-down': collapsed,
            'fa-chevron-up': !collapsed
          }"
        ></i>
      </div>
      <div class="w-100">
        <div class="form-label">Name</div>
        <TextInline type="text" v-model="name" :validate="validateName"></TextInline>
      </div>
      <div class="w-100">
        <div class="form-label">Function</div>
        <SelectInline :options="operations" v-model="operation"></SelectInline>
      </div>
      <div class="ml-3 mr-3">
        <div
          class="badge"
          :class="{
            'badge-danger': paramCount === 0,
            'badge-light': paramCount > 0
          }"
        >{{ paramCount }} parameter(s)</div>
      </div>
      <div>
        <button class="btn btn-sm btn-light" @click="remove">
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </div>
    <div class="ml-3 mr-3">
      <div
        v-if="referenceValueDisplay"
        class="badge badge-light"
      >Selected parameter for reference value output: {{referenceValueDisplay}}</div>
      <div v-else class="badge">No parameter selected for reference value output</div>
    </div>
    <div style="margin-left: 35px" v-if="!collapsed">
      <div class="form-label">Parameter(s)</div>
      <div class="row">
        <div v-for="(p, idx) in slot.parameters" class="col-6">
          <div class="d-flex">
            <div>
              <input
                type="checkbox"
                @click="setRefValue(p.id)"
                :disabled="!p.isRefValue && isDisabledRefValue(p.id)"
                :checked="p.isRefValue"
              />
            </div>
            <div>
              <ParameterInline
                :idx="idx"
                :slotId="slot.id"
                :parameterId="p.id"
                :measMethodId="p.measMethodId"
                :measMethodOptions="measMethodOptions"
                :key="`parameter-${p.id}`"
              ></ParameterInline>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex bg-light rounded">
            <select
              class="form-control form-control-sm"
              id="measMethodId"
              ref="input"
              v-model="newMeasMethodId"
            >
              <option v-for="o in measMethodOptions" :value="o.id">{{ o.shortName }}</option>
            </select>
            <select class="form-control form-control-sm" id="parameterId" v-model="newParameterId">
              <option v-for="o in paramOptions" :value="o.id">{{ o.shortName }}</option>
            </select>
            <button class="btn btn-sm btn-light" @click="addParameter">
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right"></div>
  </div>
</template>

<script>
import { OPERATIONS, SQL_OBJECT_REGEX } from "@/domain";
import {
  REMOVE_SLOT,
  UPDATE_SLOT_NAME,
  UPDATE_SLOT_OPERATION,
  FETCH_PARAMETERS,
  FETCH_PARAMETERS_FOR_SLOT,
  ASSIGN_PARAMETER,
  UPDATE_SLOT_REFERENCE_VALUE
} from "@/store/templates";

import ParameterInline from "@/components/ParameterInline";
import TextInline from "@/components/TextInline";
import SelectInline from "@/components/SelectInline";

export default {
  name: "TemplateSlot",
  components: { SelectInline, TextInline, ParameterInline },
  props: ["id"],
  data() {
    return {
      collapsed: true,
      newMeasMethodId: -1,
      newParameterId: -1
    };
  },
  watch: {
    newMeasMethodId(val) {
      if (val === -1) {
        return;
      }
      // reset parameterId on change
      this.newParameterId = -1;
      this.$store.dispatch(FETCH_PARAMETERS, val);
    }
  },
  computed: {
    slot() {
      return this.$store.getters.slotById(this.id);
    },
    referenceValueDisplay() {
      if (!this.slot) return "";
      const p = this.slot.parameters.find(p => p.isRefValue === true);
      if (!p) {
        return "";
      }

      const param = this.parameters[p.id];
      const mds = this.measurementDescriptions[p.measMethodId];
      return `${mds.shortName} -> ${param.shortName}`;
    },
    parameters() {
      return this.$store.getters.parameters;
    },
    measurementDescriptions() {
      return this.$store.getters.measurementDescriptions;
    },
    paramCount() {
      if (!this.slot) return 0;
      return this.slot.parameters.length;
    },

    name: {
      get() {
        if (!this.slot) return "";
        return this.slot.name;
      },
      set(val) {
        this.$store.dispatch(UPDATE_SLOT_NAME, {
          id: this.id,
          name: val
        });
      }
    },
    operations() {
      return Object.keys(OPERATIONS).map(id => {
        return {
          id,
          label: OPERATIONS[id]
        };
      });
    },
    operation: {
      get() {
        if (!this.slot) return 0;
        return this.slot.operation;
      },
      set(val) {
        this.$store.dispatch(UPDATE_SLOT_OPERATION, {
          id: this.id,
          operation: val
        });
      }
    },
    measMethodOptions() {
      const res = [];
      const mds = this.measurementDescriptions;
      Object.keys(mds).forEach(mId => {
        if (
          this.slot.parameters.findIndex(p => p.measMethodId === mId) !== -1
        ) {
          return;
        }
        res.push({
          id: mId,
          shortName: mds[mId].shortName
        });
      });
      res.sort((a, b) => a.shortName.localeCompare(b.shortName));
      return res;
    },
    paramOptions() {
      const params = this.$store.getters.parametersByMeasMethodId(
        this.newMeasMethodId
      );
      if (!params) return [];
      return params.sort((a, b) => a.shortName.localeCompare(b.shortName));
    }
  },
  methods: {
    isDisabledRefValue(id) {
      return this.$store.getters.referenceValues[id];
    },
    setRefValue(id) {
      this.$store.dispatch(UPDATE_SLOT_REFERENCE_VALUE, {
        id: this.id,
        paramId: id
      });
    },
    validateName(val) {
      if (!val) {
        return false;
      }
      return val.match(SQL_OBJECT_REGEX) !== null;
    },
    hide() {
      this.collapsed = true;
    },
    expand() {
      this.collapsed = false;
      this.$store.dispatch(FETCH_PARAMETERS_FOR_SLOT, this.id);
    },
    remove() {
      this.$store.dispatch(REMOVE_SLOT, {
        id: this.id
      });
    },
    addParameter() {
      if (this.newMeasMethodId === -1 || this.newParameterId === -1) {
        return;
      }
      this.$store.dispatch(ASSIGN_PARAMETER, {
        slotId: this.id,
        measMethodId: this.newMeasMethodId,
        parameterId: this.newParameterId
      });
      this.newMeasMethodId = -1;
      this.newParameterId = -1;
    }
  }
};
</script>

<style scoped></style>
