import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Home from "@/components/home/Home";
import Login from "@/components/login/Login";
import Configuration from "@/components/configuration/Configuration";
import About from "@/components/about/About";
import Connection from "@/components/connection/Connection";

const routes = [
  {
    path: "*",
    redirect: "home"
  },
  {
    name: "about",
    path: "/about",
    component: About
  },
  {
    name: "home",
    path: "/home",
    component: Home,
    meta: { requiresConnection: true }
  },
  {
    name: "connection",
    path: "/connection",
    component: Connection
  },
  {
    name: "configuration",
    path: "/configuration",
    component: Configuration,
    meta: { requiresAuth: true }
  },
  {
    name: "login",
    path: "/login",
    component: Login
  },
  {
    name: "about",
    path: "/about",
    component: About
  }
];
export default new VueRouter({
  routes
});
