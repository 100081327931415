import Vue from "vue";
import uuidv4 from "uuid/v4";

import Api from "@/common/api";
import { DATA_VERSION, TEMPLATE_TYPE_BASE, TEMPLATE_TYPE_USER } from "@/domain";

const state = {
  parameters: {},
  templates: {},
  selectedTemplateId: null,
  deployment: {
    sourceDBName: "SENTRYSUITE",
    cubeDBName: null,
    serverName: "localhost",
    instanceName: "SENTRYSUITE",
    loginName: null,
    password: null,
    cubeLoginName: null,
    cubePassword: null,
    templateIds: []
  },
  modified: false,
  measurementDescriptions: {},
  slots: {}
};

// file export / import functions
export const exportTemplates = (templates, slots) => {
  return {
    version: DATA_VERSION,
    data: {
      templates,
      slots
    }
  };
};
const importTemplates = ({ version, data }) => {
  if (version !== DATA_VERSION) {
    throw new Error("unsupported file version");
  }
  const templates = {};
  Object.keys(data.templates).forEach(id => {
    if (data.templates[id].type == TEMPLATE_TYPE_BASE) return;

    const newId = uuidv4();
    templates[newId] = { ...data.templates[id], id: newId };

    templates[newId].slots = templates[newId].slots.map(slotId => {
      const newSlotId = uuidv4();
      const slot = Object.assign({}, data.slots[slotId]);
      slot.id = newSlotId;
      delete data.slots[slotId];
      data.slots[newSlotId] = slot;
      return newSlotId;
    });
  });
  return {
    templates,
    slots: data.slots
  };
};

const normalizeTemplates = (data, templateType = TEMPLATE_TYPE_USER) => {
  const res = {
    templates: {},
    slots: {}
  };
  data.forEach(t => {
    const template = {
      id: uuidv4(),
      name: t.Name,
      label: t.Label,
      description: t.Description,
      slots: [],
      type: templateType
    };
    // iterate slots
    if (t.Slots) {
      t.Slots.forEach(s => {
        const slot = {
          id: uuidv4(),
          name: s.Name,
          operation: s.Function,
          referenceValue: -1,
          parameters: []
        };
        template.slots.push(slot.id);
        if (!res.slots[slot.id]) {
          res.slots[slot.id] = slot;
        }

        s.SelectedParameters.forEach(p => {
          slot.parameters.push({
            id: p.ParameterId + "",
            measMethodId: p.MeasMethodId + ""
          });
        });
      });
    }
    res.templates[template.id] = template;
  });
  return res;
};
const normalizeParameters = data => {
  const params = {};
  data.forEach(p => {
    const id = p.Id + "";
    params[id] = {
      id,
      shortName: p.ShortName,
      measMethodId: p.MeasMethodId + ""
    };
  });
  return params;
};
const normalizeMeasurementDescriptions = data => {
  const mds = {};
  data.forEach(md => {
    const mdId = md.Id + "";
    mds[mdId] = { id: mdId, shortName: md.ShortName };
    for (let i = 0; i < md.Childs.length; i++) {
      const mdc = md.Childs[i];
      const mdcId = mdc.Id + "";
      mds[mdcId] = {
        id: mdcId,
        shortName: md.ShortName + " -> " + mdc.ShortName
      };
    }
  });
  return mds;
};

// ACTIONS
export const RESET_MODIFIED = "resetModified";
export const FETCH_MEASUREMENT_DESCRIPTIONS = "fetchMeasurementDescriptions";
export const FETCH_PARAMETERS = "fetchParameters";
export const FETCH_PARAMETERS_FOR_SLOT = "fetchParametersForSlot";
export const FETCH_BASE_TEMPLATES = "fetchBaseTemplates";
export const IMPORT_TEMPLATES = "importTemplates";
export const REMOVE_TEMPLATE = "removeTemplate";
export const ADD_TEMPLATE = "addTemplate";
export const OPEN_TEMPLATE_EDITOR = "openTemplateEditor";
export const CLOSE_TEMPLATE_EDITOR = "closeTemplateEditor";
export const UPDATE_TEMPLATE_NAME = "updateTemplateName";
export const UPDATE_TEMPLATE_LABEL = "updateTemplateLabel";
export const UPDATE_TEMPLATE_DESCRIPTION = "updateTemplateDescription";
export const REMOVE_SLOT = "removeSlot";
export const ADD_SLOT = "addSlot";
export const UPDATE_SLOT_NAME = "updateSlotName";
export const UPDATE_SLOT_OPERATION = "updateSlotOperation";
export const UPDATE_SLOT_ORDER = "updateSlotOrder";
export const ASSIGN_PARAMETER = "assigneParameter";
export const REMOVE_ASSIGNED_PARAMETER = "removeAssignedParameter";
export const UPDATE_ASSIGNED_PARAMETER = "updateAssignedParameter";
export const SELECT_TEMPLATE_FOR_DEPLOYMENT = "selectTemplateForDeployment";
export const REMOVE_TEMPLATE_FROM_DEPLOYMENT = "removeTemplateFromDeployment";
export const REMOVE_TEMPLATES_FROM_DEPLOYMENT = "removeTemplatesFromDeployment";
export const UPDATE_DEPLOYMENT_SOURCE_DB_NAME = "updateDeploymentSourceDBName";
export const UPDATE_DEPLOYMENT_CUBE_DB_NAME = "updateDeploymentCubeDBName";
export const UPDATE_DEPLOYMENT_SERVER_NAME = "updateDeploymentServerName";
export const UPDATE_DEPLOYMENT_INSTANCE_NAME = "updateDeploymentInstanceName";
export const UPDATE_DEPLOYMENT_LOGIN_NAME = "updateDeploymentLoginName";
export const UPDATE_DEPLOYMENT_PASSWORD = "updateDeploymentPassword";
export const UPDATE_DEPLOYMENT_CUBE_LOGIN_NAME =
  "updateDeploymentCubeLoginName";
export const UPDATE_DEPLOYMENT_CUBE_PASSWORD = "updateDeploymentCubePassword";
export const UPDATE_SLOT_REFERENCE_VALUE = "updateSlotReferenceValue";
const actions = {
  [RESET_MODIFIED]({ commit }) {
    commit(SET_TEMPLATE_MODIFIED, false);
  },
  [FETCH_MEASUREMENT_DESCRIPTIONS]({ commit, rootState }) {
    return Api.ReadMeasurementDescription(rootState.auth.token).then(d => {
      const mds = normalizeMeasurementDescriptions(d);
      commit(SET_MEASUREMENT_DESCRIPTIONS, mds);
    });
  },
  [FETCH_PARAMETERS]({ commit, state, rootState }, measMethodId) {
    return Api.ReadParameterDescription(
      rootState.auth.token,
      measMethodId
    ).then(d => {
      const params = normalizeParameters(d);
      commit(MERGE_PARAMETERS, params);
    });
  },
  [FETCH_PARAMETERS_FOR_SLOT]({ getters, dispatch }, slotId) {
    const slot = getters.slotById(slotId);
    if (!slot) {
      return;
    }
    slot.parameters.forEach(p => {
      dispatch(FETCH_PARAMETERS, p.measMethodId);
    });
  },
  [IMPORT_TEMPLATES]({ commit, dispatch }, data) {
    const { templates, slots } = importTemplates(data);

    Object.keys(slots).forEach(id => {
      commit(SET_SLOT, slots[id]);
      dispatch(FETCH_PARAMETERS_FOR_SLOT, id);
    });

    Object.keys(templates).forEach(id => {
      commit(SET_TEMPLATE, templates[id]);
    });
  },
  [ADD_TEMPLATE]({ commit, dispatch }) {
    const template = {
      id: uuidv4(),
      name: `new_template`,
      label: `New Template`,
      description: `New Template Description`,
      slots: [],
      type: TEMPLATE_TYPE_USER
    };
    commit(SET_TEMPLATE, template);
    dispatch(ADD_SLOT, { templateId: template.id });
  },
  [OPEN_TEMPLATE_EDITOR]({ commit }, { id }) {
    commit(SET_SELECTED_TEMPLATE_ID, id);
  },
  [CLOSE_TEMPLATE_EDITOR]({ commit }) {
    commit(SET_SELECTED_TEMPLATE_ID, null);
  },
  [FETCH_BASE_TEMPLATES]({ commit, getters, rootState }) {
    return Api.ReadAvailableTemplates(rootState.auth.token).then(d => {
      const lookup = getters.templateIdsByName;
      const { templates } = normalizeTemplates(d, TEMPLATE_TYPE_BASE);
      Object.keys(templates).forEach(id => {
        const oldId = lookup[templates[id].name];
        if (oldId) {
          templates[id].id = oldId;
        }
        commit(SET_TEMPLATE, templates[id]);
      });
    });
  },
  [REMOVE_TEMPLATE]({ commit, state }, { id }) {
    commit(UNSET_TEMPLATE_FROM_DEPLOYMENT, id);
    commit(UNSET_TEMPLATE, { id });
    if (id === state.selectedTemplateId) {
      commit(SET_SELECTED_TEMPLATE_ID, null);
    }
  },
  [UPDATE_TEMPLATE_NAME]({ commit }, { id, name }) {
    commit(SET_TEMPLATE_NAME, { id, name });
    commit(SET_TEMPLATE_MODIFIED, true);
  },
  [UPDATE_TEMPLATE_LABEL]({ commit }, { id, label }) {
    commit(SET_TEMPLATE_LABEL, { id, label });
    commit(SET_TEMPLATE_MODIFIED, true);
  },
  [UPDATE_TEMPLATE_DESCRIPTION]({ commit }, { id, description }) {
    commit(SET_TEMPLATE_DESCRIPTION, { id, description });
    commit(SET_TEMPLATE_MODIFIED, true);
  },
  [UPDATE_SLOT_ORDER]({ commit }, { id, order }) {
    commit(SET_SLOT_ORDER, { id, order });
    commit(SET_TEMPLATE_MODIFIED, true);
  },
  [ADD_SLOT]({ commit }, { templateId }) {
    const s = {
      id: uuidv4(),
      name: `new_slot`,
      operation: "1",
      referenceValue: -1,
      parameters: []
    };
    commit(SET_TEMPLATE_MODIFIED, true);
    commit(NEW_SLOT, s);
    commit(ADD_SLOT_TO_TEMPLATE, { templateId, slotId: s.id });
  },
  [REMOVE_SLOT]({ commit }, { id }) {
    commit(UNSET_SLOT, { id });
    commit(SET_TEMPLATE_MODIFIED, true);
  },
  [UPDATE_SLOT_NAME]({ commit }, { id, name }) {
    commit(SET_SLOT_NAME, { id, name });
    commit(SET_TEMPLATE_MODIFIED, true);
  },
  [UPDATE_SLOT_OPERATION]({ commit }, { id, operation }) {
    commit(SET_SLOT_OPERATION, { id, operation });
    commit(SET_TEMPLATE_MODIFIED, true);
  },
  [ASSIGN_PARAMETER]({ commit }, { slotId, measMethodId, parameterId }) {
    commit(SET_ASSIGNED_PARAMETER, { slotId, measMethodId, parameterId });
    commit(SET_TEMPLATE_MODIFIED, true);
  },
  [UPDATE_ASSIGNED_PARAMETER](
    { commit },
    { slotId, idx, measMethodId, parameterId }
  ) {
    commit(REPLACE_ASSIGNED_PARAMETER, {
      slotId,
      idx,
      measMethodId,
      parameterId
    });
    commit(SET_TEMPLATE_MODIFIED, true);
  },
  [REMOVE_ASSIGNED_PARAMETER](
    { commit },
    { slotId, measMethodId, parameterId }
  ) {
    commit(UNSET_ASSIGNED_PARAMETER, { slotId, measMethodId, parameterId });
    commit(SET_TEMPLATE_MODIFIED, true);
  },
  [SELECT_TEMPLATE_FOR_DEPLOYMENT]({ commit }, id) {
    commit(SET_TEMPLATE_FOR_DEPLOYMENT, id);
  },
  [REMOVE_TEMPLATE_FROM_DEPLOYMENT]({ commit }, id) {
    commit(UNSET_TEMPLATE_FROM_DEPLOYMENT, id);
  },
  [REMOVE_TEMPLATES_FROM_DEPLOYMENT]({ commit }) {
    commit(SET_DEPLOYMENT_TEMPLATES, []);
  },
  [UPDATE_DEPLOYMENT_SOURCE_DB_NAME]({ commit }, name) {
    commit(SET_DEPLOYMENT_SOURCE_DB_NAME, name);
  },
  [UPDATE_DEPLOYMENT_CUBE_DB_NAME]({ commit }, name) {
    commit(SET_DEPLOYMENT_CUBE_DB_NAME, name);
  },
  [UPDATE_DEPLOYMENT_SERVER_NAME]({ commit }, name) {
    commit(SET_DEPLOYMENT_SERVER_NAME, name);
  },
  [UPDATE_DEPLOYMENT_INSTANCE_NAME]({ commit }, name) {
    commit(SET_DEPLOYMENT_INSTANCE_NAME, name);
  },
  [UPDATE_DEPLOYMENT_LOGIN_NAME]({ commit }, name) {
    commit(SET_DEPLOYMENT_LOGIN_NAME, name);
  },
  [UPDATE_DEPLOYMENT_PASSWORD]({ commit }, password) {
    commit(SET_DEPLOYMENT_PASSWORD, password);
  },
  [UPDATE_DEPLOYMENT_CUBE_LOGIN_NAME]({ commit }, name) {
    commit(SET_DEPLOYMENT_CUBE_LOGIN_NAME, name);
  },
  [UPDATE_DEPLOYMENT_CUBE_PASSWORD]({ commit }, password) {
    commit(SET_DEPLOYMENT_CUBE_PASSWORD, password);
  },
  [UPDATE_SLOT_REFERENCE_VALUE]({ commit }, { id, paramId }) {
    commit(SET_SLOT_REFERENCE_VALUE, { id, paramId });
  }
};

const SET_MEASUREMENT_DESCRIPTIONS = "setMeasurementTexts";
const SET_TEMPLATE = "setTemplate";
const SET_TEMPLATE_MODIFIED = "setTemplateModified";
const SET_SELECTED_TEMPLATE_ID = "setSelectedTemplateId";
const SET_BASE_TEMPLATES = "setBaseTemplates";
const MERGE_PARAMETERS = "mergeParameters";
const SET_SLOT = "setSlot";
const UNSET_TEMPLATE = "unsetTemplate";
const SET_TEMPLATE_NAME = "setTemplateName";
const SET_TEMPLATE_LABEL = "setTemplateLabel";
const SET_TEMPLATE_DESCRIPTION = "setTemplateDescription";
const UNSET_SLOT = "unsetSlot";
const NEW_SLOT = "newSlot";
const ADD_SLOT_TO_TEMPLATE = "addSlotToTemplate";
const SET_SLOT_NAME = "setSlotName";
const SET_SLOT_OPERATION = "setSlotOperation";
const SET_SLOT_ORDER = "setSlotOrder";
const UNSET_ASSIGNED_PARAMETER = "unsetAssignedParameter";
const SET_ASSIGNED_PARAMETER = "setAssignedParameter";
const REPLACE_ASSIGNED_PARAMETER = "replaceAssignedParameter";
const SET_TEMPLATE_FOR_DEPLOYMENT = "setTemplateForDeployment";
const UNSET_TEMPLATE_FROM_DEPLOYMENT = "unsetTemplateFromDeployment";
const SET_DEPLOYMENT_TEMPLATES = "setDeploymentTemplates";
const SET_DEPLOYMENT_SOURCE_DB_NAME = "setDeploymentSourceDBName";
const SET_DEPLOYMENT_CUBE_DB_NAME = "setDeploymentCubeDBName";
const SET_DEPLOYMENT_SERVER_NAME = "setDeploymentServerName";
const SET_DEPLOYMENT_INSTANCE_NAME = "setDeploymentInstanceName";
const SET_DEPLOYMENT_LOGIN_NAME = "setDeploymentLoginName";
const SET_DEPLOYMENT_PASSWORD = "setDeploymentPassword";
const SET_DEPLOYMENT_CUBE_LOGIN_NAME = "setDeploymentCubeLoginName";
const SET_DEPLOYMENT_CUBE_PASSWORD = "setDeploymentCubePassword";
const SET_SLOT_REFERENCE_VALUE = "setSlotReferenceValue";
const mutations = {
  [SET_TEMPLATE_MODIFIED](state, modified) {
    state.modified = modified;
    if (modified) {
      window.onbeforeunload = confirmExit;
      function confirmExit() {
        return "Your changes will be lost.  Are you sure you want to exit this page?";
      }
    } else {
      window.onbeforeunload = null;
    }
  },
  [SET_SELECTED_TEMPLATE_ID](state, id) {
    state.selectedTemplateId = id;
  },
  [SET_MEASUREMENT_DESCRIPTIONS](state, mds) {
    state.measurementDescriptions = mds;
  },
  [SET_TEMPLATE](state, template) {
    Vue.set(state.templates, template.id, template);
  },
  [SET_BASE_TEMPLATES](state, templates) {
    state.baseTemplates = templates;
  },
  [MERGE_PARAMETERS](state, parameters) {
    state.parameters = { ...state.parameters, ...parameters };
  },
  [UNSET_TEMPLATE](state, { id }) {
    Vue.delete(state.templates, id);
  },
  [SET_SLOT](state, slot) {
    Vue.set(state.slots, slot.id, slot);
  },
  [SET_TEMPLATE_NAME](state, { id, name }) {
    Vue.set(state.templates[id], "name", name);
  },
  [SET_TEMPLATE_LABEL](state, { id, label }) {
    Vue.set(state.templates[id], "label", label);
  },
  [SET_TEMPLATE_DESCRIPTION](state, { id, description }) {
    Vue.set(state.templates[id], "description", description);
  },
  [UNSET_SLOT](state, { id }) {
    Object.keys(state.templates).forEach(tId => {
      const t = state.templates[tId];
      const idx = t.slots.findIndex(s => s === id);
      if (idx === -1) {
        return;
      }
      t.slots.splice(idx, 1);
    });
    Vue.delete(state.slots, id);
  },
  [NEW_SLOT](state, { id, name, operation, parameters }) {
    Vue.set(state.slots, id, {
      id,
      name,
      operation,
      parameters
    });
  },
  [ADD_SLOT_TO_TEMPLATE](state, { templateId, slotId }) {
    state.templates[templateId].slots.push(slotId);
  },
  [SET_SLOT_ORDER](state, { id, order }) {
    Vue.set(state.templates[id], "slots", order);
  },
  [SET_SLOT_NAME](state, { id, name }) {
    Vue.set(state.slots[id], "name", name);
  },
  [SET_SLOT_OPERATION](state, { id, operation }) {
    Vue.set(state.slots[id], "operation", operation);
  },
  [UNSET_ASSIGNED_PARAMETER](state, { slotId, parameterId }) {
    const idx = state.slots[slotId].parameters.findIndex(
      p => p.id === parameterId
    );
    if (idx === -1) {
      return;
    }
    state.slots[slotId].parameters.splice(idx, 1);
  },
  [SET_ASSIGNED_PARAMETER](state, { slotId, parameterId, measMethodId }) {
    state.slots[slotId].parameters.push({
      id: parameterId,
      isRefValue: false,
      measMethodId
    });
  },
  [REPLACE_ASSIGNED_PARAMETER](
    state,
    { slotId, idx, parameterId, measMethodId }
  ) {
    Vue.set(state.slots[slotId].parameters, idx, {
      id: parameterId,
      isRefValue: false,
      measMethodId
    });
  },
  [SET_TEMPLATE_FOR_DEPLOYMENT](state, id) {
    state.deployment.templateIds.push(id);
  },
  [UNSET_TEMPLATE_FROM_DEPLOYMENT](state, id) {
    const idx = state.deployment.templateIds.findIndex(tId => tId === id);
    if (idx === -1) {
      return;
    }
    state.deployment.templateIds.splice(idx, 1);
  },
  [SET_DEPLOYMENT_TEMPLATES](state, ids) {
    state.deployment.templateIds = ids;
  },
  [SET_DEPLOYMENT_SOURCE_DB_NAME](state, name) {
    state.deployment.sourceDBName = name;
  },
  [SET_DEPLOYMENT_CUBE_DB_NAME](state, name) {
    state.deployment.cubeDBName = name;
  },
  [SET_DEPLOYMENT_SERVER_NAME](state, name) {
    state.deployment.serverName = name;
  },
  [SET_DEPLOYMENT_INSTANCE_NAME](state, name) {
    state.deployment.instanceName = name;
  },
  [SET_DEPLOYMENT_LOGIN_NAME](state, name) {
    state.deployment.loginName = name;
  },
  [SET_DEPLOYMENT_PASSWORD](state, password) {
    state.deployment.password = password;
  },
  [SET_DEPLOYMENT_CUBE_LOGIN_NAME](state, name) {
    state.deployment.cubeLoginName = name;
  },
  [SET_DEPLOYMENT_CUBE_PASSWORD](state, password) {
    state.deployment.cubePassword = password;
  },
  [SET_SLOT_REFERENCE_VALUE](state, { id, paramId }) {
    const slot = state.slots[id];
    if (!slot) {
      return;
    }
    slot.parameters.forEach((p, idx) => {
      if (p.id !== paramId) {
        Vue.set(slot.parameters[idx], "isRefValue", false);
        return;
      }
      Vue.set(slot.parameters[idx], "isRefValue", !p.isRefValue);
    });
  }
};
const getters = {
  selectedTemplateId: state => state.selectedTemplateId,
  selectedTemplateIds: state => state.deployment.templateIds,
  measurementDescriptions: state => state.measurementDescriptions,
  measurementDescriptionById: state => id => state.measurementDescriptions[id],
  templates: state => {
    const res = [];
    Object.keys(state.templates).forEach(id => {
      const t = state.templates[id];
      let selected = false;
      if (state.deployment.templateIds.indexOf(id) !== -1) {
        selected = true;
      }
      res.push(Object.assign({}, t, { _selected: selected }));
    });
    return res;
  },
  templateById: state => id => {
    let selected = false;
    if (state.deployment.templateIds.indexOf(id) !== -1) {
      selected = true;
    }
    return Object.assign({}, state.templates[id], { _selected: selected });
  },
  referenceValues: state => {
    const res = {};
    Object.keys(state.slots).forEach(sId => {
      const slot = state.slots[sId];
      slot.parameters.forEach(p => {
        if (p.isRefValue) {
          res[p.id] = true;
        }
      });
    });
    return res;
  },
  slots: state => state.slots,
  slotById: state => id => state.slots[id],
  parameters: state => state.parameters,
  parameterById: state => id => state.parameters[id],
  parametersByMeasMethodId: state => measMethodId => {
    const res = [];
    Object.keys(state.parameters).forEach(id => {
      if (state.parameters[id].measMethodId !== measMethodId) {
        return;
      }
      res.push(state.parameters[id]);
    });
    return res;
  },
  templateIdsByName: state => {
    const res = {};
    Object.keys(state.templates).forEach(id => {
      res[state.templates[id].name] = id;
    });
    return res;
  },
  deployment: state => state.deployment,
  templateModified: state => state.modified
};

export default {
  state,
  getters,
  actions,
  mutations
};
