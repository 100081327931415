<template>
  <div v-if="edit">
    <input
      ref="input"
      class="form-control form-control-sm"
      :class="{ 'is-invalid': !isValid }"
      :style="{ fontSize: `${size}px !important` }"
      :type="type"
      v-model="initialValue"
      @keyup.enter="save"
      @keyup.esc="cancel"
      v-click-outside="save"
    />
    <div class="invalid-feedback" v-if="!isValid">
      Please provide a valid value.
    </div>
  </div>
  <div
    class="w-100 hoverable form-control-sm d-flex align-items-center justify-content-between"
    :style="{ fontSize: `${size}px !important` }"
    v-else
    @click="startEditing"
  >
    <span v-if="secret">*****</span>
    <span v-else>{{ value }}</span>
    <span v-if="value === ''">&nbsp;</span>
    <i class="edit-indicator fas fa-pen text-muted"></i>
  </div>
</template>

<script>
export default {
  name: "TextInline",
  props: {
    size: Number,
    secret: Boolean,
    value: String,
    validate: Function
  },
  data() {
    return {
      edit: false,
      initialValue: this.value
    };
  },
  computed: {
    isValid() {
      if (!this.validate) {
        return true;
      }
      return this.validate(this.initialValue);
    },
    type() {
      return this.secret ? "password" : "text";
    }
  },
  methods: {
    startEditing() {
      this.edit = true;
      this.$nextTick().then(() => {
        this.$refs.input.focus();
        this.$refs.input.select();
      });
    },
    save() {
      if (!this.isValid) {
        this.cancel();
        return;
      }
      this.$emit("input", this.initialValue);
      this.edit = false;
    },
    cancel() {
      this.initialValue = this.value;
      this.edit = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../_custom.scss";

.edit-indicator {
  display: none;
}
.hoverable {
  &:hover {
    background-color: map-get($theme-colors, "light") !important;
    cursor: text;

    .edit-indicator {
      display: inline-block;
    }
  }
}
</style>
