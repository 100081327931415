var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.edit
    ? _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.initialValue,
              expression: "initialValue"
            },
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.save,
              expression: "save"
            }
          ],
          ref: "input",
          staticClass: "form-control form-control-sm",
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.cancel($event)
            },
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.initialValue = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        _vm._l(_vm.options, function(o) {
          return _c("option", { domProps: { value: o.id } }, [
            _vm._v("\n    " + _vm._s(o.label) + "\n  ")
          ])
        }),
        0
      )
    : _c(
        "div",
        {
          staticClass:
            "w-100 hoverable form-control-sm d-flex align-items-center justify-content-between",
          on: { click: _vm.startEditing }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.label))]),
          _vm._v(" "),
          _c("i", { staticClass: "edit-indicator fas fa-pen text-muted" })
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }