<template>
  <div v-if="edit" class="bg-light rounded" v-click-outside="save">
    <div class="d-flex">
      <select
        class="form-control form-control-sm"
        id="measMethodId"
        ref="input"
        v-model="activeMeasMethodId"
        @keyup.esc="cancel"
      >
        <option v-for="o in mdOptions" :value="o.id">{{ o.shortName }}</option>
      </select>
      <select
        class="form-control form-control-sm"
        id="parameterId"
        v-model="activeParameterId"
        @keyup.esc="cancel"
      >
        <option v-for="o in paramOptions" :value="o.id">{{ o.shortName }}</option>
      </select>
      <button class="btn btn-sm bg-light" @click.prevent.stop="remove">
        <i class="fas fa-trash"></i>
      </button>
    </div>
  </div>
  <div class="d-flex rounded hoverable" v-else @click="startEditing">
    <div class="w-100 form-control-sm d-flex align-items-center justify-content-between">
      <div v-if="parameter">
        {{ measurementDescription.shortName }} ({{ measMethodId }}):
        {{ parameter.shortName }} ({{ parameterId }})
      </div>
      <i class="edit-indicator fas fa-pen text-muted"></i>
    </div>
  </div>
</template>

<script>
import {
  FETCH_PARAMETERS,
  REMOVE_ASSIGNED_PARAMETER,
  UPDATE_ASSIGNED_PARAMETER
} from "@/store/templates";

export default {
  name: "ParameterInline",
  props: ["idx", "slotId", "parameterId", "measMethodId", "measMethodOptions"],
  data() {
    return {
      edit: false,
      activeMeasMethodId: this.measMethodId,
      activeParameterId: this.parameterId
    };
  },
  computed: {
    slot() {
      return this.$store.getters.slotById(this.slotId);
    },
    parameter() {
      return this.$store.getters.parameterById(this.activeParameterId);
    },
    measurementDescription() {
      return this.$store.getters.measurementDescriptionById(
        this.activeMeasMethodId
      );
    },
    measurementDescriptions() {
      return this.$store.getters.measurementDescriptions;
    },
    mdOptions() {
      const mds = this.measurementDescriptions;
      return [
        {
          id: this.activeMeasMethodId,
          shortName: mds[this.activeMeasMethodId].shortName
        }
      ]
        .concat(this.measMethodOptions)
        .sort((a, b) => a.shortName.localeCompare(b.shortName));
    },
    paramOptions() {
      const params = this.$store.getters.parametersByMeasMethodId(
        this.activeMeasMethodId
      );
      if (!params) return [];
      return params.sort((a, b) => a.shortName.localeCompare(b.shortName));
    }
  },
  watch: {
    activeMeasMethodId(val) {
      this.$store.dispatch(FETCH_PARAMETERS, val);
    },
    parameterId(val) {
      this.activeParameterId = val;
    },
    measMethodId(val) {
      this.activeMeasMethodId = val;
    }
  },
  methods: {
    startEditing() {
      this.edit = true;
      this.$nextTick().then(() => {
        this.$refs.input.focus();
      });
    },
    remove() {
      this.$store.dispatch(REMOVE_ASSIGNED_PARAMETER, {
        slotId: this.slotId,
        measMethodId: this.measMethodId,
        parameterId: this.parameterId
      });
      this.edit = false;
    },
    save() {
      this.$store.dispatch(UPDATE_ASSIGNED_PARAMETER, {
        slotId: this.slotId,
        idx: this.idx,
        measMethodId: this.activeMeasMethodId,
        parameterId: this.activeParameterId
      });
      this.edit = false;
    },
    cancel() {
      this.activeMeasMethodId = this.measMethodId;
      this.activeParameterId = this.parameterId;
      this.edit = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../_custom.scss";

.edit-indicator {
  display: none;
}
.hoverable {
  &:hover {
    background-color: map-get($theme-colors, "light") !important;
    cursor: pointer;

    .edit-indicator {
      display: inline-block;
    }
  }
}
</style>
