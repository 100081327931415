<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        Jump To:
        <button class="btn btn-link btn-sm" @click="ScrollTo('pd')">Parameter Descriptions</button>
        <button class="btn btn-link btn-sm" @click="ScrollTo('vd')">View Descriptions</button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2>Overview</h2>
        <h5>
          The Sentry CIS DataCube is a Data warehouse solution which provides the option to conduct big data analysis for SentrySuite database. Based on Sentry CIS Datacube templates a
          <b>customized snapshot of the central repository</b> is transferred into a Datacube database, hosted on central repository server. The DataCube engine is implemented in the new Sentry
          <b>CIS Data Analytics service.</b>
          <br />
          <br />The following diagram gives an overview about Sentry CIS DataCube installation, configuration and deployment and the principle QueryBuilder workflows:
        </h5>
        <img id="about-datacube" src="../../assets/img/aboutdatacube.jpg" />

        <h2>Calculation of Z Score</h2>
        <h5>The following diagram gives an overview how the Z Score can be recalculated:</h5>
        <img id="about-datacube" src="../../assets/img/CalculateZScore.jpg" />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 id="pd">
          Parameter Descriptions
          <button
            class="btn btn-link btn-sm"
            @click="ScrollTo('AbContScroll')"
          >
            <i class="fas fa-arrow-alt-circle-up"></i>
          </button>
        </h2>
        <h5>The following section lists all parameters including identifiers, units and a short description of the parameter content:</h5>
        <div v-for="m in mds" :key="m.Id">
          <h3 class="bg-light p-2">
            {{ m.ShortName }}
            <button class="btn btn-link btn-sm" @click="ScrollTo('AbContScroll')">
              <i class="fas fa-arrow-alt-circle-up"></i>
            </button>
          </h3>
          <Parameter
            :measName="`${m.ShortName} (${m.Id})`"
            :param="p"
            v-for="p in m.Parameters"
            :key="p.Id"
          />
          <div v-for="c in m.Childs">
            <h3 class="p-2">
              {{ m.ShortName }} -> {{ c.ShortName }}
              <button
                class="btn btn-link btn-sm"
                @click="ScrollTo('AbContScroll')"
              >
                <i class="fas fa-arrow-alt-circle-up"></i>
              </button>
            </h3>
            <Parameter
              :measName="`${m.ShortName} -> ${c.ShortName} (${c.Id})`"
              :param="cp"
              v-for="cp in c.Parameters"
              :key="cp.Id"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h2 id="vd">
          View Descriptions
          <button class="btn btn-link btn-sm" @click="ScrollTo('AbContScroll')">
            <i class="fas fa-arrow-alt-circle-up"></i>
          </button>
        </h2>
        <h5>The following section provides an overview about all preconfigured views and describes content and general usage of the views:</h5>
        <div v-for="v in vds" :key="v.Name">
          <h3 class="bg-light p-2">
            {{ v.Label }}
            <button class="btn btn-link btn-sm" @click="ScrollTo('AbContScroll')">
              <i class="fas fa-arrow-alt-circle-up"></i>
            </button>
          </h3>
          <h5>{{ v.Comment }}</h5>
          <p>
            <Column :viewName="v.Label" :col="c" v-for="c in v.Columns" :key="c.Name" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DATA_DICTIONARY_MEASUREMENT_PARAMETER_DESCRIPTION,
  DATA_DICTIONARY_VIEWS_DESCRIPTION
} from "@/domain";

/* Comment in for development*/
/*
import { LoremIpsum } from "lorem-ipsum";

const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 3,
    min: 1
  }
});
*/

import Parameter from "@/components/about/Parameter";
import Column from "@/components/about/Column";
import { isNullOrUndefined } from "util";

const mds = DATA_DICTIONARY_MEASUREMENT_PARAMETER_DESCRIPTION.MeasurementParameterDescription.sort(
  (a, b) => a.ShortName.localeCompare(b.ShortName)
);
const vds = DATA_DICTIONARY_VIEWS_DESCRIPTION.DataDictionaryViews.sort((a, b) =>
  a.Label.localeCompare(b.Label)
);

mds.forEach(md => {
  md.Childs = md.Childs.sort((a, b) => a.ShortName.localeCompare(b.ShortName));
  md.Childs.forEach(c => {
    c.Parameters = c.Parameters.sort((a, b) =>
      a.ShortName.localeCompare(b.ShortName)
    );
    /*
    c.Parameters = c.Parameters.map(p => {
      return {
        ...p,
        Comment: isNullOrUndefined(p.Comment)
          ? lorem.generateParagraphs(1)
          : p.Comment
      };
    });
    */
  });
});
mds.forEach(md => {
  md.Parameters = md.Parameters.sort((a, b) =>
    a.ShortName.localeCompare(b.ShortName)
  );
  /*
  md.Parameters = md.Parameters.map(p => {
    return {
      ...p,
      Comment: isNullOrUndefined(p.Comment)
        ? lorem.generateParagraphs(1)
        : p.Comment
    };
  });
  */
});

/*
vds.forEach(vd => {
  vd.Columns = vd.Columns.map(c => {
    return {
      ...c,
      Comment: isNullOrUndefined(c.Comment)
        ? lorem.generateParagraphs(1)
        : c.Comment
    };
  });
});
*/

export default {
  name: "About",
  components: {
    Parameter,
    Column
  },
  methods: {
    ScrollTo(name) {
      const base = document.getElementById("AbContScroll");
      const target = document.getElementById(name);
      const y = target.getBoundingClientRect().top - 50;
      base.scrollTop = y;
    }
  },
  computed: {
    desc() {
      return ""; //lorem.generateParagraphs(1);
    }
  },
  mounted() {
    console.log(this.$refs);
  },

  created() {
    this.mds = mds;
    this.vds = vds;
  }
};
</script>

<style lang="scss" scoped>
.scroll-behavior {
  scroll-behavior: smooth;
}
</style>
