var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100 d-flex justify-content-center align-items-center" },
    [
      _c("div", { staticClass: "w-25" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.connect($event)
              }
            }
          },
          [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v(_vm._s(_vm.error))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "pt-2 form-label" }, [
              _vm._v("Servername")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.serverName,
                  expression: "serverName"
                }
              ],
              staticClass: "form-control form-control-sm",
              attrs: { type: "text" },
              domProps: { value: _vm.serverName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.serverName = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "pt-2 form-label" }, [
              _vm._v("Instance name / port number")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.instanceName,
                  expression: "instanceName"
                }
              ],
              staticClass: "form-control form-control-sm",
              attrs: { type: "text" },
              domProps: { value: _vm.instanceName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.instanceName = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "pt-2 form-label" }, [_vm._v("Database")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.databaseName,
                  expression: "databaseName"
                }
              ],
              staticClass: "form-control form-control-sm",
              attrs: { type: "text" },
              domProps: { value: _vm.databaseName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.databaseName = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "pt-2 form-label" }, [_vm._v("User")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.databaseUser,
                  expression: "databaseUser"
                }
              ],
              staticClass: "form-control form-control-sm",
              attrs: { type: "text" },
              domProps: { value: _vm.databaseUser },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.databaseUser = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "pt-2 form-label" }, [_vm._v("Password")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.databasePassword,
                  expression: "databasePassword"
                }
              ],
              staticClass: "form-control form-control-sm",
              attrs: { type: "password" },
              domProps: { value: _vm.databasePassword },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.databasePassword = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "mt-2 text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  attrs: { type: "submit", disabled: _vm.isConnectDisabled },
                  on: { click: _vm.connect }
                },
                [_vm._v("Connect")]
              )
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }