<template>
  <b-modal ref="modal" id="edit-template-modal" title="Edit Template" size="lg">
    <div class="p-2">
      <div class="form-label">Name</div>
      <div class="mb-2">
        <TextInline v-model="name" :validate="validateName"></TextInline>
      </div>
      <div class="form-label">Label</div>
      <div class="mb-2">
        <TextInline v-model="label" :validate="validateLabel"></TextInline>
      </div>
      <div class="form-label">Description</div>
      <div class="mb-2">
        <TextareaInline v-model="description"></TextareaInline>
      </div>
      <div class="form-label">
        Slots
        <button class="btn btn-sm btn-light" @click="addSlot">
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <div class="m-2">
        <draggable class="drag-area" v-model="slotOrder" ghost-class="shadow">
          <TemplateSlot :id="id" v-for="id in slotOrder" class="cursor-move" :key="`slot-${id}`"></TemplateSlot>
        </draggable>
      </div>
    </div>

    <template slot="modal-footer" slot-scope="{ ok }">
      <button :disabled="isTemplateInvalid" class="btn btn-sm btn-primary" @click="ok()">OK</button>
    </template>
  </b-modal>
</template>

<script>
import draggable from "vuedraggable";

import { SQL_OBJECT_REGEX } from "@/domain";
import {
  UPDATE_TEMPLATE_NAME,
  UPDATE_TEMPLATE_DESCRIPTION,
  UPDATE_TEMPLATE_LABEL,
  ADD_SLOT,
  UPDATE_SLOT_ORDER
} from "@/store/templates";

import TextInline from "@/components/TextInline";
import TextareaInline from "@/components/TextareaInline";
import TemplateSlot from "@/components/configuration/TemplateSlot";

export default {
  name: "EditTemplateModal",
  components: { TemplateSlot, TextInline, TextareaInline, draggable },
  computed: {
    selectedTemplateId() {
      return this.$store.getters.selectedTemplateId;
    },
    template() {
      return this.$store.getters.templateById(this.selectedTemplateId);
    },
    isTemplateInvalid() {
      return this.template.slots.find(id => {
        const s = this.$store.getters.slotById(id);
        return s.parameters.length === 0;
      });
    },
    slotOrder: {
      get() {
        return this.template.slots;
      },
      set(val) {
        this.$store.dispatch(UPDATE_SLOT_ORDER, {
          id: this.selectedTemplateId,
          order: val
        });
      }
    },
    name: {
      get() {
        return this.template.name;
      },
      set(val) {
        this.$store.dispatch(UPDATE_TEMPLATE_NAME, {
          id: this.selectedTemplateId,
          name: val
        });
      }
    },
    label: {
      get() {
        return this.template.label;
      },
      set(val) {
        this.$store.dispatch(UPDATE_TEMPLATE_LABEL, {
          id: this.selectedTemplateId,
          label: val
        });
      }
    },
    description: {
      get() {
        return this.template.description;
      },
      set(val) {
        this.$store.dispatch(UPDATE_TEMPLATE_DESCRIPTION, {
          id: this.selectedTemplateId,
          description: val
        });
      }
    }
  },
  methods: {
    validateName(val) {
      if (!val) {
        return false;
      }
      return val.match(SQL_OBJECT_REGEX) !== null;
    },
    validateLabel(val) {
      return !!val;
    },
    addSlot() {
      this.$store.dispatch(ADD_SLOT, {
        templateId: this.selectedTemplateId
      });
    }
  }
};
</script>

<style scoped></style>
