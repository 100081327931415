import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from "vue";
import vClickOutside from "v-click-outside";
import { ModalPlugin } from "bootstrap-vue";
import VCalendar from "v-calendar";

Vue.use(VCalendar);
Vue.use(vClickOutside);
Vue.use(ModalPlugin);

import store from "@/store";
import router from "@/router";

import App from "@/App";

import "@/main.scss";

Vue.filter("truncate", (val, count) => {
  return val.slice(0, count) + (count < val.length ? "..." : "");
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some(record => record.meta.requiresConnection) &&
    !store.getters.isConnected
  ) {
    next({
      name: "connection"
    });
    return;
  }
  if (
    to.matched.some(record => record.meta.requiresAuth) &&
    !store.getters.isLoggedIn
  ) {
    next({
      name: "login",
      query: { redirect: to.fullPath }
    });
    return;
  }
  next(); // make sure to always call next()!
});

const app = new Vue({
  el: "#app",
  store,
  router,
  render: h => h(App)
});
