import axios from "axios";

const url = "Api";

const handleServiceResponse = response => {
  const { data } = response;
  if (!data.Success) {
    throw new Error(data.ErrorMessage);
  }
  return data.ResultValue;
};

export const convertConfigurationToBackend = (templates, slots) => {
  const res = [];
  Object.keys(templates).forEach(k => {
    const t = templates[k];
    const template = {
      Name: t.name,
      Label: t.label,
      Description: t.description,
      Slots: []
    };
    if (t.slots) {
      t.slots.forEach(sId => {
        const refValueParamId = 0;
        const s = slots[sId];
        const slot = {
          Name: s.name,
          Function: s.operation,
          AddPredictedValueOfParameter: 0,
          SelectedParameters: []
        };
        s.parameters.forEach(p => {
          slot.SelectedParameters.push({
            MeasMethodId: p.measMethodId,
            ParameterId: p.id
          });
          if (p.isRefValue) {
            slot.AddPredictedValueOfParameter = p.id;
          }
        });
        template.Slots.push(slot);
      });
    }
    res.push(template);
  });
  return res;
};

const convertRule = segment => {
  return {
    Name: segment.name,
    Operator: segment.operator,
    Value: segment.value
  };
};
const convertGroup = (segment, segments) => {
  const groups = [];
  const rules = [];
  segment.childIds.forEach(id => {
    const child = segments[id];
    switch (child.type) {
      case "group":
        groups.push(convertGroup(child, segments));
        break;
      case "rule":
        rules.push(convertRule(child));
        break;
    }
  });
  return {
    LogicalOperator: segment.logicalOperator,
    Rules: rules,
    Groups: groups
  };
};
export const convertQueryToBackend = segments => {
  return convertGroup(segments["1"], segments);
};

export const convertViewToBackend = view => {
  return {
    Name: view.name,
    Label: view.label,
    Columns: view.columns.map(c => {
      return {
        Name: c.name,
        Label: c.label,
        Type: c.type
      };
    })
  };
};

export default {
  Login(userName, password) {
    return axios
      .post(url + "/Configuration/Login", {
        Username: userName,
        Password: password
      })
      .then(handleServiceResponse);
  },
  ReadAvailableTemplates(token) {
    return axios
      .get(url + "/Configuration/ReadAvailableTemplates", {
        headers: { SecurityToken: token }
      })
      .then(handleServiceResponse);
  },
  ReadMeasurementDescription(token) {
    return axios
      .get(url + "/Configuration/GetMeasurementDescription", {
        headers: { SecurityToken: token }
      })
      .then(handleServiceResponse);
  },
  ReadParameterDescription(token, id) {
    return axios
      .post(
        url + "/Configuration/GetParameterDescriptions",
        {
          Id: id
        },
        {
          headers: { SecurityToken: token }
        }
      )
      .then(handleServiceResponse);
  },
  GetCubeVersion(
    serverName,
    instanceName,
    databaseName,
    databaseUser,
    databasePassword
  ) {
    return axios
      .post(url + "/QueryBuilder/GetCubeVersion", {
        instanceName,
        serverName,
        databaseName,
        databaseUser,
        databasePassword
      })
      .then(handleServiceResponse);
  },
  /**
   *
   * @param serverName
   * @param instanceName can either be the instance name or the port number
   * @param databaseName
   * @param databaseUser
   * @param databasePassword
   * @returns {Promise<AxiosResponse<any> | never>}
   * @constructor
   */
  GetQueryBuilderViews(
    serverName,
    instanceName,
    databaseName,
    databaseUser,
    databasePassword
  ) {
    return axios
      .post(url + "/QueryBuilder/GetQueryBuilderViews", {
        instanceName,
        serverName,
        databaseName,
        databaseUser,
        databasePassword
      })
      .then(handleServiceResponse);
  },
  GetQueryBuilderCount(
    serverName,
    instanceName,
    databaseName,
    databaseUser,
    databasePassword,
    view,
    query
  ) {
    return axios
      .post(url + "/QueryBuilder/GetQueryBuilderCount", {
        Connection: {
          serverName,
          instanceName,
          databaseName,
          databaseUser,
          databasePassword
        },
        View: view,
        Query: query
      })
      .then(handleServiceResponse);
  },
  GetQueryBuilderResult(
    serverName,
    instanceName,
    databaseName,
    databaseUser,
    databasePassword,
    view,
    query
  ) {
    return axios
      .post(url + "/QueryBuilder/GetQueryBuilderResult", {
        Connection: {
          instanceName,
          serverName,
          databaseName,
          databaseUser,
          databasePassword
        },
        View: view,
        Query: query
      })
      .then(handleServiceResponse);
  },
  /**
   * Example Body:
   * {
   *   "SourceDBName": "string",
   *   "CubeDBName": "string",
   *   "BaseTemplates": [
   *     {
   *       "Name": "string",
   *       "Label": "string",
   *       "Description": "string"
   *     }
   *   ],
   *   "UserTemplates": [
   *     {
   *       "Name": "string",
   *       "Label": "string",
   *       "Description": "string",
   *       "Slots": [
   *         {
   *           "Id": 0,
   *           "Label": "string",
   *           "Function": "Min",
   *           "AddPredictedValue": true,
   *           "SelectedParameters": [
   *             {
   *               "MeasMethodId": 0,
   *               "ParameterId": 0
   *             }
   *           ]
   *         }
   *       ]
   *     }
   *   ]
   * }
   *
   * @param token
   * @param cubeDatabaseName
   * @param sourceDatabaseName
   * @param selectedTemplates
   * @param selectedUserTemplates
   * @returns {Promise<AxiosResponse<any> | never>}
   * @constructor
   */
  CreateCubeScript(
    token,
    cubeDatabaseName,
    sourceDatabaseName,
    selectedTemplates,
    selectedUserTemplates
  ) {
    return axios
      .post(
        url + "/Configuration/CreateCubeScript",
        {
          SourceDBName: sourceDatabaseName,
          CubeDBName: cubeDatabaseName,
          BaseTemplates: selectedTemplates,
          UserTemplates: selectedUserTemplates
        },
        {
          headers: { SecurityToken: token }
        }
      )
      .then(handleServiceResponse);
  },
  /**
   *
   * @param token
   * @param serverName
   * @param instanceName
   * @param cubeDatabaseName
   * @param sourceDatabaseName
   * @param databaseUser
   * @param databasePassword
   * @param cubeDatabaseUser
   * @param cubeDatabasePassword
   * @param selectedTemplates
   * @param selectedUserTemplates
   * @returns {Promise<AxiosResponse<any> | never>}
   * @constructor
   */
  CreateCubeDB(
    token,
    serverName,
    instanceName,
    cubeDatabaseName,
    sourceDatabaseName,
    databaseUser,
    databasePassword,
    cubeDatabaseUser,
    cubeDatabasePassword,
    selectedTemplates,
    selectedUserTemplates
  ) {
    return axios
      .post(
        url + "/Configuration/CreateCubeDB",
        {
          SourceDBConnection: {
            serverName,
            instanceName: instanceName,
            databaseName: sourceDatabaseName,
            databaseUser: databaseUser,
            databasePassword: databasePassword
          },
          CubeDBConnection: {
            serverName,
            instanceName: instanceName,
            databaseName: cubeDatabaseName,
            databaseUser: cubeDatabaseUser,
            databasePassword: cubeDatabasePassword
          },
          BaseTemplates: selectedTemplates,
          UserTemplates: selectedUserTemplates
        },
        {
          headers: { SecurityToken: token }
        }
      )
      .then(handleServiceResponse);
  }
};
