<template>
  <div class="h-100 bg-white overflow-auto d-flex flex-column">
    <div class="flex-grow-1 p-2 overflow-auto">
      <div class="h6 d-flex align-items-center justify-content-between">
        <div>
          Query
          <div class="small font-weight-normal text-muted">Select the view and create a query.</div>
        </div>
        <div class="small pr-2 d-flex align-items-center justify-content-between">
          <div>
            Connected as user
            <b>{{ connection.databaseUser }}</b> to database
            <b>{{ connection.databaseName }}</b> on
            <b>{{ connection.serverName }}</b>
            - Version: {{ version }}
          </div>
          <div class="pl-2">
            <button class="btn btn-sm btn-primary" @click="disconnect">Disconnect</button>
          </div>
        </div>
      </div>
      <div class="d-flex mt-2 p-2">
        <div style="width: 300px;">
          <select
            ref="viewInput"
            class="form-control form-control-sm mb-2"
            v-model="name"
            @change="$refs.viewInput.blur()"
          >
            <option v-for="(view, idx) in views" :value="view.name" :key="idx">{{ view.label }}</option>
          </select>
          <div
            v-if="selectedView"
            class="small font-italic pl-2"
          >{{ selectedView.columns.length }} columns</div>
        </div>
        <div class="flex-grow-1 pl-2">
          <QueryBuilder v-if="name !== ''"></QueryBuilder>
        </div>
      </div>
    </div>
    <div
      class="p-2 bg-light border-top button-bar d-flex align-items-center justify-content-between"
    >
      <div>
        <input
          type="file"
          name="filter"
          ref="fileInput"
          @change="importFromFile"
          style="display: none"
        />
        <button class="btn btn-sm btn-light" @click="$refs.fileInput.click()">
          <i class="fas fa-upload"></i> Import
        </button>
        <button class="btn btn-sm btn-light" @click="exportToFile">
          <i class="fas fa-download"></i> Export
        </button>
      </div>
      <div v-if="count > -1">{{ count }} results</div>
      <div>
        <button class="btn btn-sm btn-primary" :disabled="isCountDisabled" @click="queryCount">Count</button>
        <button
          class="btn btn-sm btn-primary"
          :disabled="isDownloadDisabled"
          @click="download"
        >Download</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  FETCH_VIEWS,
  SELECT_VIEW,
  DISCONNECT,
  QUERY_COUNT,
  IMPORT_QUERY,
  exportQuery
} from "@/store/query";
import Api, { convertViewToBackend, convertQueryToBackend } from "@/common/api";

import QueryBuilder from "@/components/home/QueryBuilder";

const s2ab = s => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

export default {
  name: "Home",
  components: { QueryBuilder },
  computed: {
    count() {
      return this.$store.getters.count;
    },
    isCountDisabled() {
      return this.count !== -1;
    },
    isDownloadDisabled() {
      return this.count < 1;
    },
    connection() {
      return this.$store.getters.connection;
    },
    version() {
      return this.$store.getters.version;
    },
    selectedView() {
      return this.$store.getters.selectedView;
    },
    name: {
      get() {
        const view = this.selectedView;
        if (!view) {
          return "";
        }
        return view.name;
      },
      set(val) {
        this.$store.dispatch(SELECT_VIEW, val);
      }
    },
    views() {
      return this.$store.getters.views;
    }
  },
  mounted() {
    this.$store.dispatch(FETCH_VIEWS);
  },
  methods: {
    disconnect() {
      this.$store.dispatch(DISCONNECT).then(() => {
        this.$router.push({ name: "connection" }).catch(err => {
          console.log(err);
        });
      });
    },
    queryCount() {
      this.$store.dispatch(QUERY_COUNT);
    },
    download() {
      // convert to backend format
      return Api.GetQueryBuilderResult(
        this.connection.serverName,
        this.connection.instanceName,
        this.connection.databaseName,
        this.connection.databaseUser,
        this.connection.databasePassword,
        convertViewToBackend(this.selectedView),
        convertQueryToBackend(this.$store.getters.segments)
      ).then(d => {
        var xml = s2ab(atob(d.Data));

        const blob = new Blob([xml]);
        const fileName = "SentrySuiteQueryBuilder_Results.xlsx";
        //for microsoft IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
      });
    },

    importFromFile() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = evt => {
          try {
            const d = JSON.parse(evt.target.result);
            this.$store.dispatch(IMPORT_QUERY, d);
          } catch (e) {
            alert(e);
          }
        };
        reader.onerror = evt => {
          alert(evt);
        };
      }
    },
    exportToFile() {
      const content = JSON.stringify(
        exportQuery(this.name, this.$store.getters.segments)
      );
      const blob = new Blob([content]);
      const fileName = "ses-query-builder-query.json";
      //for microsoft IE
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      }
    }
  }
};
</script>

<style scoped></style>
