<template>
  <div class="h-100 d-flex justify-content-center align-items-center">
    <div class="w-25">
      <form v-on:submit.prevent="login">
        <div class="alert alert-danger" v-if="error">{{ error }}</div>
        <div class="pt-2 form-label">Username</div>
        <input
          type="text"
          class="mt-2 form-control form-control-sm"
          v-model="username"
          @keyup.enter="login"
        />
        <div class="pt-2 form-label">Password</div>
        <input
          type="password"
          class="mt-2 form-control form-control-sm"
          v-model="password"
          @keyup.enter="login"
        />
        <div class="mt-2 text-right">
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            @click="login"
            :disabled="isLoginDisabled"
          >Login</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { LOGIN } from "@/store/auth";

export default {
  name: "Login",
  data() {
    return {
      error: null,
      username: "Administrator",
      password: null
    };
  },
  computed: {
    isLoginDisabled() {
      return !this.username || !this.password;
    }
  },
  methods: {
    login() {
      this.error = null;
      this.$store
        .dispatch(LOGIN, {
          username: this.username,
          password: this.password
        })
        .then(() => {
          this.$router.replace({ name: "configuration" }).catch(err => {
            console.log(err);
          });
        })
        .catch(e => {
          this.error = e;
          this.password = "";
        });
    }
  }
};
</script>

<style scoped></style>
