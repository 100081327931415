<template>
  <div class="d-flex flex-column" style="height: 100vh">
    <div
      style="min-height: 50px; height: 50px; border-bottom-width: 2px !important"
      class="pl-2 bg-white border-bottom d-flex"
    >
      <div class="d-flex align-items-center mr-2">
        <img id="review-logo" src="./assets/img/logo.png" />
        DataCube (v 3.10)
      </div>
      <div>
        <router-link
          :to="{ name: 'home' }"
          :class="{
            'router-link-active': $route.name === 'connection'
          }"
          class="link pl-2 pr-2 d-inline-block"
        >Home</router-link>
        <router-link
          :to="{ name: 'configuration' }"
          class="link pl-2 pr-2 d-inline-block"
          :class="{
            'router-link-active': $route.name === 'login'
          }"
        >Configuration</router-link>
        <router-link :to="{ name: 'about' }" class="link pl-2 pr-2 d-inline-block">About</router-link>
      </div>
      <div class="ml-auto p-2 d-flex align-items-center justify-content-end" v-if="isLoggedIn">
        <div class="mr-2">
          logged in as
          <strong>{{ username }}</strong>
          <button class="btn btn-sm btn-light" @click="logout">
            <i class="fas fa-sign-out-alt"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="bg-white position-relative flex-grow-1 overflow-auto" id="AbContScroll">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { LOGOUT } from "@/store/auth";
import {
  REMOVE_TEMPLATES_FROM_DEPLOYMENT,
  RESET_MODIFIED
} from "@/store/templates";

export default {
  name: "App",
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    username() {
      return this.$store.getters.username;
    }
  },
  methods: {
    logout() {
      if (
        this.$store.getters.templateModified &&
        !confirm(
          "Your changes will be lost.  Are you sure you want to log out?"
        )
      ) {
        return;
      }
      this.$store.dispatch(RESET_MODIFIED);
      this.$store.dispatch(LOGOUT);
      this.$store.dispatch(REMOVE_TEMPLATES_FROM_DEPLOYMENT);
      this.$router.push({ name: "home" }).catch(err => {
        console.log(err);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "_custom.scss";

.link {
  cursor: pointer;
  line-height: 50px;
  &:hover {
    background-color: map-get($theme-colors, "hover") !important;
  }
}
.router-link-active {
  background-color: map-get($theme-colors, "light") !important;
}
</style>
