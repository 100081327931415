export const OPERATORS_BY_COLUMN_TYPE = {
	nvarchar: ["Equals", "Contains", "BeginsWith", "EndsWith"],
	int: ["=", "<>", "<", "<=", ">", ">="],
	float: ["=", "<>", "<", "<=", ">", ">="],
	date: ["=", "<>", "<", "<=", ">", ">="],
	datetime: ["=", "<>", "<", "<=", ">", ">="]
};

export const TEMPLATE_TYPE_BASE = "base";
export const TEMPLATE_TYPE_USER = "user";

export const OPERATIONS = {
	1: "Min",
	2: "Max",
	3: "Avg"
};

export const DATA_VERSION = 1;

export const SQL_OBJECT_REGEX = /^[a-zA-Z_][a-zA-Z0-9_]{0,50}$/;

export const DATA_DICTIONARY_MEASUREMENT_PARAMETER_DESCRIPTION = {
	"MeasurementParameterDescription": [{
			"Id": 1,
			"Childs": [{
					"Id": 199,
					"Childs": [],
					"ParentId": 1,
					"ShortName": "VMAX",
					"LongName": "VMAX",
					"Comment": null,
					"Parameters": [{
							"Id": 13041670,
							"ShortName": "DyspneaIndex",
							"LongName": "DyspneaIndex",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 13041669,
							"ShortName": "ETOV",
							"LongName": "ETOV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 13041674,
							"ShortName": "FEF10",
							"LongName": "FEF10",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 13041671,
							"ShortName": "FEF70",
							"LongName": "FEF70",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 13041672,
							"ShortName": "FEF85",
							"LongName": "FEF85",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 13041673,
							"ShortName": "FEF90",
							"LongName": "FEF90",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 13041688,
							"ShortName": "FEV05/FEV2",
							"LongName": "FEV05/FEV2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 13041677,
							"ShortName": "FEV10",
							"LongName": "FEV10",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 13041675,
							"ShortName": "FEV7",
							"LongName": "FEV7",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 13041676,
							"ShortName": "FIF2575",
							"LongName": "FIF2575",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 13041684,
							"ShortName": "FRC_PL",
							"LongName": "FRC_PL",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 13041680,
							"ShortName": "FVL_TAMB",
							"LongName": "FVL_TAMB",
							"UnitShort": "°C",
							"UnitLong": "Celsius",
							"Comment": null
						}, {
							"Id": 13041686,
							"ShortName": "fVTG",
							"LongName": "VTGFreq",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 13041665,
							"ShortName": "ISOSTDFEF",
							"LongName": "ISOSTDFEF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 13041683,
							"ShortName": "LUNG_ECODE",
							"LongName": "LUNG_ECODE",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 13041682,
							"ShortName": "MVV_ECODE",
							"LongName": "MVV_ECODE",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 13041678,
							"ShortName": "NLHEP",
							"LongName": "NLHEP",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 13041679,
							"ShortName": "P_GRADE",
							"LongName": "P_GRADE",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 13041685,
							"ShortName": "RAW_ECODE",
							"LongName": "RAW_ECODE",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 13041681,
							"ShortName": "SPIR_ECODE",
							"LongName": "SPIR_ECODE",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 13041668,
							"ShortName": "VMAX60",
							"LongName": "VMAX60",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 13041667,
							"ShortName": "VMAX70",
							"LongName": "VMAX70",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 13041666,
							"ShortName": "VMAX80",
							"LongName": "VMAX80",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 13041687,
							"ShortName": "VTG",
							"LongName": "VTG",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}
			],
			"ParentId": 0,
			"ShortName": "Spiro",
			"LongName": "Spirometry",
			"Comment": null,
			"Parameters": [{
					"Id": 65621,
					"ShortName": "......",
					"LongName": ".........................",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 65582,
					"ShortName": "75/85 ",
					"LongName": "FEF 75/85",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Mean flow between MEF85 to MEF75"
				}, {
					"Id": 65585,
					"ShortName": "AEX   ",
					"LongName": "Exspir. F/V area",
					"UnitShort": "L*L/s",
					"UnitLong": "L*L/s",
					"Comment": "Area under the Flow/Volume curve of expiration"
				}, {
					"Id": 65619,
					"ShortName": "AEX%IN",
					"LongName": "AEX % AIN",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 65618,
					"ShortName": "AIN",
					"LongName": "Inspir F/V area",
					"UnitShort": "L*L/s",
					"UnitLong": "L*L/s",
					"Comment": "Area under the Flow/ Volume curve of inspiration"
				}, {
					"Id": 65633,
					"ShortName": "Atiff",
					"LongName": "Exsp tiff area",
					"UnitShort": "L*L/s",
					"UnitLong": "L*L/s",
					"Comment": "Area under the Tiffeneau curve"
				}, {
					"Id": 65542,
					"ShortName": "BF    ",
					"LongName": "BF",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": "Average frequency calculated based on the duration of a breath"
				}, {
					"Id": 65555,
					"ShortName": "BF MVV",
					"LongName": "BF MVV",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": "Breathing frequency during MVV"
				}, {
					"Id": 65665,
					"ShortName": "BF_F",
					"LongName": "BF forced",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": "Average frequency calculated based on the duration of a breath calculated from breaths before forced maneuver"
				}, {
					"Id": 65587,
					"ShortName": "Conf1 ",
					"LongName": "Configuration 1",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Defines the application which wrote this parameter record. Spirometry, Body or PulmAssist"
				}, {
					"Id": 65588,
					"ShortName": "Conf2 ",
					"LongName": "Configuration 2",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Stored settings of calculation coded as number"
				}, {
					"Id": 65617,
					"ShortName": "Cons.",
					"LongName": "FEV 1 Consistent",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 65622,
					"ShortName": "dFEV1",
					"LongName": "Diff FEV 1",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Difference of FEV1 value of trial to best"
				}, {
					"Id": 65604,
					"ShortName": "dFEV1%",
					"LongName": "Diff FEV 1 %",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "difference in percent of FEV1 value of trial to best"
				}, {
					"Id": 65623,
					"ShortName": "dFVC",
					"LongName": "Diff FVC",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Difference of FVC value of trial to best"
				}, {
					"Id": 65605,
					"ShortName": "dFVC%",
					"LongName": "Diff FVC %",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 65598,
					"ShortName": "E code",
					"LongName": "ATS error code",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Parameter FV_ERROR_ATS 61 E-code/ATS error code error codes for ATS criteria: ERR_REPORD_LESS, ERR_REPROD_ONE_VALID value: 1 error code for reproducibility, not enough valid trials;  ERR_REPROD_FEV1 value: 2 error code for reproducibility, FEV1 unstable; ERR_REPROD_FVC value: 4 error code for reproducibility, FVC unstable; ERR_FV_ATS_SHORT_10 value: 10. expiration to short; ERR_FV_ATS_NOPLAT_10 value: 20. no end expiration plateau found; ERR_FV_ATS_BE_ALLW_10 value: 100. BE criteria not fullfilled, BE calculated;<br>ERR_FV_ATS_BE_10 value:200. BE not calculated;"
				}, {
					"Id": 65656,
					"ShortName": "E MVV",
					"LongName": "Error MVV",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Parameter MVV_ERROR_ATS 119 E-MVV / Error MVV error codes for ATS ERS combined, published in 2005, for MVV: ERR_REPORD_LESS, ERR_REPROD_ONE_VALID value: 1 error code for reproducibility, not enough valid trials; ERR_REPROD_MVV value: 4 error code for reproducibility, MVV unstable; ERR_MVV_ATS_ERS_BF_10 value: 10. breathing frequency to large;"
				}, {
					"Id": 65657,
					"ShortName": "E Spir",
					"LongName": "Error Slow Spir",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Parameter SPIR_ERROR_ATS 120 E Spir / Error Slow Spir error codes for ATS ERS combined, published in 2005, for slow spirometry: ERR_REPORD_LESS, ERR_REPROD_ONE_VALID value: 1 error code for reproducibility, not enough valid trials; ERR_REPROD_VC value: 4 error code for reproducibility, VC unstable (slow spirometry); ERR_FV_ATS_ERS_EOT_10 value: 100. no end of test criteria found;"
				}, {
					"Id": 65654,
					"ShortName": "EATS05",
					"LongName": "Error  ATS ERS 05",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Parameter FV_ERROR_ATS_ERS 117 EATS05 / Error ATS ERS 05 error codes for ATS ERS combined, published in 2005, for forced spirometry: ERR_REPORD_LESS, ERR_REPROD_ONE_VALID: value: 1 error code for reproducibility, not enough valid trials; ERR_REPROD_FEV1 value: 2 error code for reproducibility, FEV1 unstable; ERR_REPROD_FVC value: 4 error code for reproducibility, FVC unstable; ERR_FV_ATS_ERS_COUGH_10 value: 10. cough during expiration; ERR_FV_ATS_ERS_EOT_10 value: 100. no end of test criteria found; ERR_FV_ATS_ERS_SHORT_10 value: 200. expiration to short; ERR_FV_ATS_ERS_NOPLAT_10 value: 400. no end expiration plateau found; ERR_FV_ATS_ERS_BE_ALLW_10 value: 1000. BE criteria not fulfilled, BE calculated; ERR_FV_ATS_ERS_BE_10 value: 2000. BE criteria not fulfilled, BE not calculated; ERR_FV_ATS_ERS_EARLY_END_10 value: 4000. early end criteria;"
				}, {
					"Id": 65627,
					"ShortName": "E-ATSp",
					"LongName": "Error ATSplus",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Parameter FV_ERROR_ATS_P 90 E-ATSp / Error ATSplus error codes for extended ATS criteria: ERR_FV_ATS_LATE_PEF_10 value:1. PEF was to late; ERR_FV_ATS_COUGH_10 value: 10. cough during expiration; ERR_FV_ATS_EARLY_END_10 value: 100. early end criteria;"
				}, {
					"Id": 65628,
					"ShortName": "E-ERS",
					"LongName": "Error ERS",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Parameter FV_ERROR_ERS 91 E-ERS / Error ERS error codes for ERS criteria: ERR_REPORD_LESS, ERR_REPROD_ONE_VALID value: 1 error code for reproducibility, not enough valid trials; ERR_REPROD_FEV1 value: 2 error code for reproducibility, FEV1 unstable; ERR_REPROD_FVC value: 4 error code for reproducibility, FVC unstable; ERR_REPROD_PEF value: 8 error code for reproducibility, PEF unstable; ERR_FV_ERS_NOPLAT_10 value: 100. no end expiration plateau found; ERR_FV_ERS_BE_ALLW_10 value: 1000. BE criteria not fulfilled, BE calculated; ERR_FV_ERS_BE_10 value: 2000. BE not calculated;"
				}, {
					"Id": 65629,
					"ShortName": "E-Mayo",
					"LongName": "Error Mayo",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Parameter FV_ERROR_MAYO 92 E-Mayo / Error Mayo error codes for Mayo criteria: ERR_REPORD_LESS, ERR_REPROD_ONE_VALID value: 1 error code for reproducibility, not enough valid trials; ERR_REPROD_FEV1 value: 2 error code for reproducibility, FEV1 unstable; ERR_REPROD_FVC value: 4 error code for reproducibility, FVC unstable; ERR_REPROD_PEF value: 8 error code for reproducibility, PEF unstable; ERR_FV_MAYO_SHORT_10 value: 40.0 expiration to short; ERR_FV_MAYO_NOPLAT_10 value: 20.0 no end expiration plateau found; ERR_FV_MAYO_BE_ALLW_10 value: 100.0 BE criteria not fulfilled, BE calculated; ERR_FV_MAYO_BE_10 value: 200.0 BE not calculated; ERR_FV_MAYO_COUGH_10 value: 1000.0 cough during expiration; ERR_FV_MAYO_EARLY_END_10 2000.0 early end criteria"
				}, {
					"Id": 65556,
					"ShortName": "EMF   ",
					"LongName": "EMF",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Average expiratory flow during MVV measurement"
				}, {
					"Id": 65653,
					"ShortName": "E-Off",
					"LongName": "Error Office",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Parameter FV_ERROR_OFFICE 116 E-Off / Error Office error codes for office spirometry: ERR_FV_OFFICE_BE value: 1 volume of backextrapolation to large; ERR_FV_OFFICE_LATE_PEF value: 2 time to peak flow to late; ERR_FV_OFFICE_EARLY_END value: 4 early end criteria; ERR_FV_OFFICE_PEF_REPROD value: 8 reproducibility of PEF; ERR_FV_OFFICE_FEV6_REPROD value: 16 reproducibility of FEV6; ERR_FV_OFFICE_LESS2 value: 32 less than 2 valid trials"
				}, {
					"Id": 65673,
					"ShortName": "EOTT",
					"LongName": "EOTT",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 65682,
					"ShortName": "EOTV",
					"LongName": "EOTV",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Volume of the forced expiration last second"
				}, {
					"Id": 65678,
					"ShortName": "Error NIOSH",
					"LongName": "Error NIOSH",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Parameter FV_ERROR_NIOSH 141 E NIOSH / Error NIOSH error codes for NIOSH criteria: ERR_REPORD_LESS, ERR_REPROD_ONE_VALID value: 1 error code for reproducibility, not enough valid trials; ERR_REPROD_FEV1 value: 2 error code for reproducibility, FEV1 unstable; ERR_REPROD_FVC value: 4 error code for reproducibility, FVC unstable; ERR_FV_ATS_ERS_COUGH_10 value: 10. cough during expiration; ERR_FV_ATS_ERS_EOT_10 value: 100. no end of test criteria found; ERR_FV_ATS_ERS_SHORT_10 value: 200. expiration to short; ERR_FV_ATS_ERS_NOPLAT_10 value: 400. no end expiration plateau found; ERR_FV_ATS_ERS_BE_ALLW_10 value: 1000. BE criteria not fulfilled, BE calculated; ERR_FV_ATS_ERS_BE_10 value: 2000. BE criteria not fulfilled, BE not calculated; ERR_FV_ATS_ERS_EARLY_END_10 value: 4000. early end criteria;"
				}, {
					"Id": 65539,
					"ShortName": "ERV   ",
					"LongName": "ERV",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Difference of tidal breathing and deepest breath"
				}, {
					"Id": 65667,
					"ShortName": "ERV_F",
					"LongName": "ERV forced",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Difference of tidal breathing  and deepest breath calculated from breaths before forced maneuver"
				}, {
					"Id": 65634,
					"ShortName": "F CT",
					"LongName": "Error CT",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 65659,
					"ShortName": "F05FVC",
					"LongName": "FEV 0.5%FVC",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "FEV 0.5/FVC in percent"
				}, {
					"Id": 65660,
					"ShortName": "F05VCM",
					"LongName": "FEV 0.5%VCMax",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "FEV 0.5/VCmax in percent"
				}, {
					"Id": 65641,
					"ShortName": "F75/85",
					"LongName": "MFEF 75/85",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Mean flow between FEF85 to FEF75"
				}, {
					"Id": 65661,
					"ShortName": "F75FVC",
					"LongName": "FEV 0.75%FVC",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "FEV 0.75/FVC in percent"
				}, {
					"Id": 65662,
					"ShortName": "F75VCM",
					"LongName": "FEV 0.75%VCMax",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "FEV 0.75/VCmax in percent"
				}, {
					"Id": 65658,
					"ShortName": "FE Ran",
					"LongName": "Trial Ranking",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Order of the expiration of all trials"
				}, {
					"Id": 65597,
					"ShortName": "FE%FIF",
					"LongName": "FEF 50 % FIF 50",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Max expiratory flow at 50% divided by max inspiratory flow at 50%"
				}, {
					"Id": 65642,
					"ShortName": "FE%MIF",
					"LongName": "FEF 50 % MIF 50",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Max expiratory flow at 50% divided by max inspiratory flow at 50%"
				}, {
					"Id": 65669,
					"ShortName": "FEF1s",
					"LongName": "Forced expiratory flow after 1 second",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Forced expiratory flow after 1 s"
				}, {
					"Id": 65638,
					"ShortName": "FEF25",
					"LongName": "FEF 25",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Max expiratory flow at 75% of FVC"
				}, {
					"Id": 65645,
					"ShortName": "FEF40",
					"LongName": "FEF 40",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Forced Expiratory Flow: 40% of FVC"
				}, {
					"Id": 65637,
					"ShortName": "FEF50",
					"LongName": "FEF 50",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Max expiratory flow at 50% of FVC"
				}, {
					"Id": 65639,
					"ShortName": "FEF50%",
					"LongName": "FEF 50 % FVC",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Max expiratory flow at 50% divided by forced vital capacity (percent)"
				}, {
					"Id": 65685,
					"ShortName": "FEF50/75",
					"LongName": "FEF50/FEF75",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "FEF50/75"
				}, {
					"Id": 65684,
					"ShortName": "FEF50/Ht",
					"LongName": "FEF50/Ht",
					"UnitShort": "L/s/m",
					"UnitLong": "L/s/m",
					"Comment": "FEF50 devided by patient height"
				}, {
					"Id": 65646,
					"ShortName": "FEF60",
					"LongName": "FEF 60",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Forced Expiratory Flow: 60% of FVC"
				}, {
					"Id": 65636,
					"ShortName": "FEF75",
					"LongName": "FEF 75",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Max expiratory flow at 25% of FVC"
				}, {
					"Id": 65683,
					"ShortName": "FEF75/Ht",
					"LongName": "FEF75/Ht",
					"UnitShort": "L/s/m",
					"UnitLong": "L/s/m",
					"Comment": "FEF75 devided by patient height"
				}, {
					"Id": 65647,
					"ShortName": "FEF80",
					"LongName": "FEF 80",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Forced Expiratory Flow: 80% of FVC"
				}, {
					"Id": 65569,
					"ShortName": "FET   ",
					"LongName": "FET",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": "Duration of the forced expiration"
				}, {
					"Id": 65649,
					"ShortName": "FET100",
					"LongName": "FET 100",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": "Forced Expiratory Time at 100% of FVC"
				}, {
					"Id": 65681,
					"ShortName": "FET25ml",
					"LongName": "FET_25ml",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": "Duration of the forced expiration"
				}, {
					"Id": 65575,
					"ShortName": "FETPEF",
					"LongName": "FET PEF",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 65557,
					"ShortName": "FEV*30",
					"LongName": "FEV 1*30",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": "Estimation of the MVV by multiplication of FEV1 by 30"
				}, {
					"Id": 65546,
					"ShortName": "FEV.5",
					"LongName": "FEV 0.5",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Volume of air exhaled in half a second during the performance of the forced vital capacity"
				}, {
					"Id": 65624,
					"ShortName": "FEV.75",
					"LongName": "FEV 0.75",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Forced Expiratory Volume after 0.75 sec"
				}, {
					"Id": 65547,
					"ShortName": "FEV1 ",
					"LongName": "FEV 1",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "The volume of air exhaled in one second during the performance of the forced vital capacity (Tiffeneau test)."
				}, {
					"Id": 65626,
					"ShortName": "FEV1%6",
					"LongName": "FEV 1 % FEV 6",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Forced expiratory volume after 1 sec divided by FEV after 6 sec"
				}, {
					"Id": 65568,
					"ShortName": "FEV1%E",
					"LongName": "FEV 1 % VC EX",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Forced expiratory volume (first second) divided by expiratory vital capacity in percent"
				}, {
					"Id": 65551,
					"ShortName": "FEV1%F",
					"LongName": "FEV 1 % FVC",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Forced expiratory volume (first second) divided by inspiratory vital capacity in percent"
				}, {
					"Id": 65550,
					"ShortName": "FEV1%I",
					"LongName": "FEV 1 % VC IN",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Forced expiratory volume (first second) divided by inspiratory vital capacity in percent"
				}, {
					"Id": 65677,
					"ShortName": "FEV1%I",
					"LongName": "FEV1/VC IN_F",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 65552,
					"ShortName": "FEV1%M",
					"LongName": "FEV 1 % VC MAX",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Forced expiratory volume (first second) divided by max vital capacity in percent"
				}, {
					"Id": 65603,
					"ShortName": "FEV1Mp",
					"LongName": "FEV 1/VCmax % pred",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Percentage of measured ratio FEV1/ VCMax to predicted value"
				}, {
					"Id": 65602,
					"ShortName": "FEV1p",
					"LongName": "FEV 1 % pred",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Percentage of measured FEV1 to predicted value"
				}, {
					"Id": 65548,
					"ShortName": "FEV2 ",
					"LongName": "FEV 2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "The volume of air exhaled in two seconds during the performance of the forced vital capacity"
				}, {
					"Id": 65549,
					"ShortName": "FEV3 ",
					"LongName": "FEV 3",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "The volume of air exhaled in three seconds during the performance of the forced vital capacity"
				}, {
					"Id": 65589,
					"ShortName": "FEV3%E",
					"LongName": "FEV 3 % FVC",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Forced expiratory flow after 3 sec divided by forced vital capacity"
				}, {
					"Id": 65625,
					"ShortName": "FEV6",
					"LongName": "FEV 6",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Forced Expiratory Volume after 6 sec"
				}, {
					"Id": 65574,
					"ShortName": "FEVPEF",
					"LongName": "FEV PEF",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Expired volume until PEF is reached"
				}, {
					"Id": 65650,
					"ShortName": "FIF25",
					"LongName": "FIF 25",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Forced Inspiratory Flow: 25% of FIVC"
				}, {
					"Id": 65593,
					"ShortName": "FIF50",
					"LongName": "FIF 50",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Max inspiratory flow at 50% of FVC or VCmax"
				}, {
					"Id": 65651,
					"ShortName": "FIF75",
					"LongName": "FIF 75",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Forced Inspiratory Flow: 75% of FIVC"
				}, {
					"Id": 65631,
					"ShortName": "FIV0.5",
					"LongName": "FIV 0.5",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Volume of air inhaled in half a second during the performance of the forced vital capacity"
				}, {
					"Id": 65594,
					"ShortName": "FIV1  ",
					"LongName": "FIV 1",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Forced inspiratory volume after 1 sec"
				}, {
					"Id": 65595,
					"ShortName": "FIV1%F",
					"LongName": "FIV 1 % FVC ",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Forced inspiratory volume after 1 sec divided by forced vital capacity inspiratory"
				}, {
					"Id": 65596,
					"ShortName": "FIV1%M",
					"LongName": "FIV 1 % VC MAX",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Forced inspiratory volume after 1 sec divided by max vital capacity"
				}, {
					"Id": 65648,
					"ShortName": "FT2575",
					"LongName": "FET25_75",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": "(Forced Expiratory Time: 25% to 75% of FVC"
				}, {
					"Id": 65607,
					"ShortName": "FV1e%i",
					"LongName": "FEV 1 % FIV 1",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 65632,
					"ShortName": "FV5e%i",
					"LongName": "FEV 0.5 / FIV 0.5",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "FEV0.5 divided by FIV0.5"
				}, {
					"Id": 65567,
					"ShortName": "FVC   ",
					"LongName": "FVC",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "The maximal volume of air expired with a maximally forced expiratory effort from the point of maximal inspiration"
				}, {
					"Id": 65591,
					"ShortName": "FVC IN",
					"LongName": "FVC IN",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Forced vital capacity inspiratory"
				}, {
					"Id": 65601,
					"ShortName": "FVCp  ",
					"LongName": "FVC % pred",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Percentage of measu-red FVC to predicted value"
				}, {
					"Id": 65655,
					"ShortName": "Grade",
					"LongName": "Office Grade",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Grade of trials, Office Spirometry"
				}, {
					"Id": 65538,
					"ShortName": "IC    ",
					"LongName": "IC",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Difference of tidal breathing and highest breath"
				}, {
					"Id": 65670,
					"ShortName": "IC_AV",
					"LongName": "IC average",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 65668,
					"ShortName": "IC_F",
					"LongName": "IC forced",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Difference of tidal breathing and highest breath calculated from breaths before forced maneuver"
				}, {
					"Id": 65543,
					"ShortName": "IRV   ",
					"LongName": "IRV",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Inspiratory Reserve Volume"
				}, {
					"Id": 65676,
					"ShortName": "IRV_F",
					"LongName": "IRV forced",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 65643,
					"ShortName": "ISOFEF",
					"LongName": "ISOFEF",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "(Forced Expiratory Flow: 25% to 75% of Pre Rx FVC) / Iso Volume)"
				}, {
					"Id": 65612,
					"ShortName": "M50%FR",
					"LongName": "MEF 50 % FRC",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Max expiratory flow at 50% of FVC or VCmax divided by FRC"
				}, {
					"Id": 65566,
					"ShortName": "MEF   ",
					"LongName": "MEF",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Mean expiratory flow"
				}, {
					"Id": 65572,
					"ShortName": "MEF25",
					"LongName": "MEF 25",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Max expiratory flow at 25% of FVC or VCmax"
				}, {
					"Id": 65688,
					"ShortName": "MEF25/Ht",
					"LongName": "MEF25/Ht",
					"UnitShort": "L/s/m",
					"UnitLong": "L/s/m",
					"Comment": "MEF25 devided by patient height"
				}, {
					"Id": 65571,
					"ShortName": "MEF50",
					"LongName": "MEF 50",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Max expiratory flow at 50% of FVC or VCmax"
				}, {
					"Id": 65580,
					"ShortName": "MEF50%",
					"LongName": "MEF 50 % FVC",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Max expiratory flow at 50% divided by forced vital capacity (percent)"
				}, {
					"Id": 65690,
					"ShortName": "MEF50/25",
					"LongName": "MEF50/MEF25",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "MEF50 / MEF25"
				}, {
					"Id": 65689,
					"ShortName": "MEF50/Ht",
					"LongName": "MEF50/Ht",
					"UnitShort": "L/s/m",
					"UnitLong": "L/s/m",
					"Comment": "MEF50 devided by patient height"
				}, {
					"Id": 65570,
					"ShortName": "MEF75",
					"LongName": "MEF 75",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Max expiratory flow at 75% of FVC or VCmax"
				}, {
					"Id": 65613,
					"ShortName": "MEFFRC",
					"LongName": "MEF at FRC",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Max expiratory flow at FRC"
				}, {
					"Id": 65640,
					"ShortName": "MFEF",
					"LongName": "MFEF 75/25",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Mean max expiratory flow between FEF75 and FEF25"
				}, {
					"Id": 65620,
					"ShortName": "mFEV1",
					"LongName": "mean FEV 1",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 65565,
					"ShortName": "MIF   ",
					"LongName": "MIF",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Mean inspiratory flow"
				}, {
					"Id": 65581,
					"ShortName": "MMEF  ",
					"LongName": "MMEF 75/25",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Mean max expiratory flow between MEF75 and MEF25"
				}, {
					"Id": 65584,
					"ShortName": "MTT   ",
					"LongName": "Mean transit time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": "Mean Transit Time"
				}, {
					"Id": 65541,
					"ShortName": "MV    ",
					"LongName": "MV",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": "Volume ventilated during 1 minute."
				}, {
					"Id": 65666,
					"ShortName": "MV_F",
					"LongName": "MV forced",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": "Volume ventilated during 1 minute. calculated from breaths before forced maneuver"
				}, {
					"Id": 65553,
					"ShortName": "MVV   ",
					"LongName": "MVV",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": "Maximal ventilation"
				}, {
					"Id": 65672,
					"ShortName": "MVV End",
					"LongName": "MVV End",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 65671,
					"ShortName": "MVV Start",
					"LongName": "MVV Start",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 65652,
					"ShortName": "MVV6",
					"LongName": "MVV 6",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": "Maximal ventilation Calculated from 1st 6 seconds"
				}, {
					"Id": 65630,
					"ShortName": "OrigNo",
					"LongName": "Orig. trial no.",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 65599,
					"ShortName": "OSC EX",
					"LongName": "Oscillation EX",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 65600,
					"ShortName": "OSC IN",
					"LongName": "Oscillation IN",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 65573,
					"ShortName": "PEF   ",
					"LongName": "PEF",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Maximum expiratory flow: Highest forced expiratory flow during expiration."
				}, {
					"Id": 65590,
					"ShortName": "PEF*  ",
					"LongName": "FEF 200-1200",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Mean expiratory flow between volume of 200 ml to 1200 ml"
				}, {
					"Id": 65592,
					"ShortName": "PIF   ",
					"LongName": "PIF",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "Maximum inspiratory flow: Highest forced expiratory flow during inspiration."
				}, {
					"Id": 65680,
					"ShortName": "Q G FEV1",
					"LongName": "Quality Grade FEV1",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 65679,
					"ShortName": "Q G FVC",
					"LongName": "Quality Grade FVC",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 65579,
					"ShortName": "QC-ok",
					"LongName": "QC-Accepted",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "FVC curve accepted due to the chosen guidelines"
				}, {
					"Id": 65606,
					"ShortName": "SPIMOD",
					"LongName": "SPIMOD",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "data modified in offline set = 0"
				}, {
					"Id": 65644,
					"ShortName": "STDFEF",
					"LongName": "STDFEF 2575",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": "FEF25_75 Volume basis FEV6 instead of FVC"
				}, {
					"Id": 65560,
					"ShortName": "T EX  ",
					"LongName": "T EX",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": "Average time between start and end of expiration"
				}, {
					"Id": 65559,
					"ShortName": "T IN  ",
					"LongName": "T IN",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": "Average time between start and end of inspiration"
				}, {
					"Id": 65558,
					"ShortName": "T MVV ",
					"LongName": "TIME MVV",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": "Duration of MVV measurement"
				}, {
					"Id": 65561,
					"ShortName": "T TOT ",
					"LongName": "T TOT",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": "Average time of one breath"
				}, {
					"Id": 65583,
					"ShortName": "T25/50",
					"LongName": "TC 25/50",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": "Duration between MEF75 to MEF50"
				}, {
					"Id": 65576,
					"ShortName": "TBEex ",
					"LongName": "T backextrapol. ex ",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": "Time offset due to back extrapolation"
				}, {
					"Id": 65609,
					"ShortName": "TBEin ",
					"LongName": "T backextrapol. in ",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": "Time offset due to back extrapolation"
				}, {
					"Id": 65663,
					"ShortName": "Tdel",
					"LongName": "T delay",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": "Time difference between end inspiration and start expiration. Difference in volume defined as 25ml, see picture below"
				}, {
					"Id": 65563,
					"ShortName": "TE/TOT",
					"LongName": "TEX/TTOT",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Ratio of time of expiration to time of breath"
				}, {
					"Id": 65564,
					"ShortName": "TI/TE ",
					"LongName": "TIN/TEX",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Ratio of time of inspiration to time of expiration"
				}, {
					"Id": 65562,
					"ShortName": "TI/TOT",
					"LongName": "TIN/TTOT",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Ratio of time of inspiration to time of breath"
				}, {
					"Id": 65615,
					"ShortName": "Tr.ctr",
					"LongName": "Trial-Counter",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Number of trial"
				}, {
					"Id": 65608,
					"ShortName": "Trial ",
					"LongName": "Trial-Number             ",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 65616,
					"ShortName": "TTrial",
					"LongName": "Time of Trial",
					"UnitShort": "min",
					"UnitLong": "min",
					"Comment": "Difference time of trial since start of program"
				}, {
					"Id": 65578,
					"ShortName": "VBe%FV",
					"LongName": "V backextrapol. % FVC",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Volume of back extrapolation for expiration percent FVC"
				}, {
					"Id": 65577,
					"ShortName": "VBEex ",
					"LongName": "V backextrapolation ex",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Volume offset due to back extrapolation"
				}, {
					"Id": 65610,
					"ShortName": "VBEin ",
					"LongName": "V backextrapolation in",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Volume offset due to back extrapolation"
				}, {
					"Id": 65611,
					"ShortName": "VBi%FV",
					"LongName": "VBEin % FVCin",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": "Volume of the back extrapolation in percent of FVC"
				}, {
					"Id": 65544,
					"ShortName": "VC EX ",
					"LongName": "VC EX",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "The maximal volume of air slowly expired from the point of maximal inspiration"
				}, {
					"Id": 65537,
					"ShortName": "VC IN ",
					"LongName": "VC IN ",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "The maximal volume of air slowly inspired from the point of maximal expiration"
				}, {
					"Id": 65545,
					"ShortName": "VC MAX",
					"LongName": "VC MAX",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Maximum of Vital Capacity"
				}, {
					"Id": 65675,
					"ShortName": "VCEX_F",
					"LongName": "VC EX forced",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 65674,
					"ShortName": "VCIN_F",
					"LongName": "VC IN forced",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 65687,
					"ShortName": "VCm%FVC",
					"LongName": "VCmax%FVC",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 65686,
					"ShortName": "VCm%pred",
					"LongName": "VCmax%pred",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 65635,
					"ShortName": "VCmod",
					"LongName": "VCmod",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "VC value after modification of ERV or IC"
				}, {
					"Id": 65586,
					"ShortName": "Vers  ",
					"LongName": "Version",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": "Version of acquisition software"
				}, {
					"Id": 65614,
					"ShortName": "VMEF",
					"LongName": "VMEF",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Volume used to determine MEFx values"
				}, {
					"Id": 65540,
					"ShortName": "VT    ",
					"LongName": "VT",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Average volume in/expired per breath"
				}, {
					"Id": 65554,
					"ShortName": "VT MVV",
					"LongName": "VT MVV",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Tidal volume during MVV"
				}, {
					"Id": 65664,
					"ShortName": "VT_F",
					"LongName": "VT forced",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": "Average volume in/expired per breath, calculated from breaths before forced maneuver"
				}
			]
		}, {
			"Id": 3,
			"Childs": [{
					"Id": 301,
					"Childs": [],
					"ParentId": 3,
					"ShortName": "SVC",
					"LongName": "Slow Spirometry",
					"Comment": null,
					"Parameters": [{
							"Id": 19726421,
							"ShortName": "......",
							"LongName": ".........................",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19726382,
							"ShortName": "75-85 ",
							"LongName": "FEF 75-85",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726385,
							"ShortName": "AEX   ",
							"LongName": "Exspir. F/V area",
							"UnitShort": "L*L/s",
							"UnitLong": "L*L/s",
							"Comment": null
						}, {
							"Id": 19726419,
							"ShortName": "AEX%IN",
							"LongName": "AEX % AIN",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Area of expiration in percent of inspiration"
						}, {
							"Id": 19726418,
							"ShortName": "AIN",
							"LongName": "Inspir F/V area",
							"UnitShort": "L*L/s",
							"UnitLong": "L*L/s",
							"Comment": null
						}, {
							"Id": 19726433,
							"ShortName": "Atiff",
							"LongName": "Exsp tiff area",
							"UnitShort": "L*L/s",
							"UnitLong": "L*L/s",
							"Comment": "Area of tiffeneau curve"
						}, {
							"Id": 19726342,
							"ShortName": "BF    ",
							"LongName": "BF",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": "Breathing frequency "
						}, {
							"Id": 19726355,
							"ShortName": "BF MVV",
							"LongName": "BF MVV",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": "Breathing frequency during MVV measurement"
						}, {
							"Id": 19726465,
							"ShortName": "BF_F",
							"LongName": "BF forced",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 19726387,
							"ShortName": "Conf1 ",
							"LongName": "Configuration 1",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19726388,
							"ShortName": "Conf2 ",
							"LongName": "Configuration 2",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19726417,
							"ShortName": "Cons.",
							"LongName": "FEV1 Consistent",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19726422,
							"ShortName": "dFEV1",
							"LongName": "Diff FEV1",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "difference of FEV1 value of trial to value of best trial"
						}, {
							"Id": 19726404,
							"ShortName": "dFEV1%",
							"LongName": "Diff FEV1 %",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726423,
							"ShortName": "dFVC",
							"LongName": "Diff FVC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "difference of FVC1 value of trial to value of best trial"
						}, {
							"Id": 19726405,
							"ShortName": "dFVC%",
							"LongName": "Diff FVC %",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726398,
							"ShortName": "E code",
							"LongName": "ATS error code",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Parameter FV_ERROR_ATS 61 E-code/ATS error code error codes for ATS criteria: ERR_REPORD_LESS, ERR_REPROD_ONE_VALID value: 1 error code for reproducibility, not enough valid trials;  ERR_REPROD_FEV1 value: 2 error code for reproducibility, FEV1 unstable; ERR_REPROD_FVC value: 4 error code for reproducibility, FVC unstable; ERR_FV_ATS_SHORT_10 value: 10. expiration to short; ERR_FV_ATS_NOPLAT_10 value: 20. no end expiration plateau found; ERR_FV_ATS_BE_ALLW_10 value: 100. BE criteria not fullfilled, BE calculated;<br>ERR_FV_ATS_BE_10 value:200. BE not calculated;"
						}, {
							"Id": 19726456,
							"ShortName": "E MVV",
							"LongName": "Error MVV",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Parameter MVV_ERROR_ATS 119 E-MVV / Error MVV error codes for ATS ERS combined, published in 2005, for MVV: ERR_REPORD_LESS, ERR_REPROD_ONE_VALID value: 1 error code for reproducibility, not enough valid trials; ERR_REPROD_MVV value: 4 error code for reproducibility, MVV unstable; ERR_MVV_ATS_ERS_BF_10 value: 10. breathing frequency to large;"
						}, {
							"Id": 19726457,
							"ShortName": "E Spir",
							"LongName": "Error Slow Spir",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Parameter SPIR_ERROR_ATS 120 E Spir / Error Slow Spir error codes for ATS ERS combined, published in 2005, for slow spirometry: ERR_REPORD_LESS, ERR_REPROD_ONE_VALID value: 1 error code for reproducibility, not enough valid trials; ERR_REPROD_VC value: 4 error code for reproducibility, VC unstable (slow spirometry); ERR_FV_ATS_ERS_EOT_10 value: 100. no end of test criteria found;"
						}, {
							"Id": 19726454,
							"ShortName": "EATS05",
							"LongName": "Error  ATS ERS 05",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Parameter FV_ERROR_ATS_ERS 117 EATS05 / Error ATS ERS 05 error codes for ATS ERS combined, published in 2005, for forced spirometry: ERR_REPORD_LESS, ERR_REPROD_ONE_VALID: value: 1 error code for reproducibility, not enough valid trials; ERR_REPROD_FEV1 value: 2 error code for reproducibility, FEV1 unstable; ERR_REPROD_FVC value: 4 error code for reproducibility, FVC unstable; ERR_FV_ATS_ERS_COUGH_10 value: 10. cough during expiration; ERR_FV_ATS_ERS_EOT_10 value: 100. no end of test criteria found; ERR_FV_ATS_ERS_SHORT_10 value: 200. expiration to short; ERR_FV_ATS_ERS_NOPLAT_10 value: 400. no end expiration plateau found; ERR_FV_ATS_ERS_BE_ALLW_10 value: 1000. BE criteria not fulfilled, BE calculated; ERR_FV_ATS_ERS_BE_10 value: 2000. BE criteria not fulfilled, BE not calculated; ERR_FV_ATS_ERS_EARLY_END_10 value: 4000. early end criteria;"
						}, {
							"Id": 19726427,
							"ShortName": "E-ATSp",
							"LongName": "Error ATSplus",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Parameter FV_ERROR_ATS_P 90 E-ATSp / Error ATSplus error codes for extended ATS criteria: ERR_FV_ATS_LATE_PEF_10 value:1. PEF was to late; ERR_FV_ATS_COUGH_10 value: 10. cough during expiration; ERR_FV_ATS_EARLY_END_10 value: 100. early end criteria;"
						}, {
							"Id": 19726428,
							"ShortName": "E-ERS",
							"LongName": "Error ERS",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Parameter FV_ERROR_ERS 91 E-ERS / Error ERS error codes for ERS criteria: ERR_REPORD_LESS, ERR_REPROD_ONE_VALID value: 1 error code for reproducibility, not enough valid trials; ERR_REPROD_FEV1 value: 2 error code for reproducibility, FEV1 unstable; ERR_REPROD_FVC value: 4 error code for reproducibility, FVC unstable; ERR_REPROD_PEF value: 8 error code for reproducibility, PEF unstable; ERR_FV_ERS_NOPLAT_10 value: 100. no end expiration plateau found; ERR_FV_ERS_BE_ALLW_10 value: 1000. BE criteria not fulfilled, BE calculated; ERR_FV_ERS_BE_10 value: 2000. BE not calculated;"
						}, {
							"Id": 19726429,
							"ShortName": "E-Mayo",
							"LongName": "Error Mayo",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Parameter FV_ERROR_MAYO 92 E-Mayo / Error Mayo error codes for Mayo criteria: ERR_REPORD_LESS, ERR_REPROD_ONE_VALID value: 1 error code for reproducibility, not enough valid trials; ERR_REPROD_FEV1 value: 2 error code for reproducibility, FEV1 unstable; ERR_REPROD_FVC value: 4 error code for reproducibility, FVC unstable; ERR_REPROD_PEF value: 8 error code for reproducibility, PEF unstable; ERR_FV_MAYO_SHORT_10 value: 40.0 expiration to short; ERR_FV_MAYO_NOPLAT_10 value: 20.0 no end expiration plateau found; ERR_FV_MAYO_BE_ALLW_10 value: 100.0 BE criteria not fulfilled, BE calculated; ERR_FV_MAYO_BE_10 value: 200.0 BE not calculated; ERR_FV_MAYO_COUGH_10 value: 1000.0 cough during expiration; ERR_FV_MAYO_EARLY_END_10 2000.0 early end criteria"
						}, {
							"Id": 19726356,
							"ShortName": "EMF   ",
							"LongName": "EMF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "Average expiratory flow during MVV measurement"
						}, {
							"Id": 19726453,
							"ShortName": "E-Off",
							"LongName": "Error Office",
							"UnitShort": null,
							"UnitLong": null,
							"Comment":  "Parameter FV_ERROR_OFFICE 116 E-Off / Error Office error codes for office spirometry: ERR_FV_OFFICE_BE value: 1 volume of backextrapolation to large; ERR_FV_OFFICE_LATE_PEF value: 2 time to peak flow to late; ERR_FV_OFFICE_EARLY_END value: 4 early end criteria; ERR_FV_OFFICE_PEF_REPROD value: 8 reproducibility of PEF; ERR_FV_OFFICE_FEV6_REPROD value: 16 reproducibility of FEV6; ERR_FV_OFFICE_LESS2 value: 32 less than 2 valid trials"
						}, {
							"Id": 19726473,
							"ShortName": "EOTT",
							"LongName": "EOTT",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": "End of test time"
						}, {
							"Id": 19726482,
							"ShortName": "EOTV",
							"LongName": "EOTV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "End of test Volume. Volume of the forced expiration last second"
						}, {
							"Id": 19726478,
							"ShortName": "Error NIOSH",
							"LongName": "Error NIOSH",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "Parameter FV_ERROR_NIOSH 141 E NIOSH / Error NIOSH error codes for NIOSH criteria: ERR_REPORD_LESS, ERR_REPROD_ONE_VALID value: 1 error code for reproducibility, not enough valid trials; ERR_REPROD_FEV1 value: 2 error code for reproducibility, FEV1 unstable; ERR_REPROD_FVC value: 4 error code for reproducibility, FVC unstable; ERR_FV_ATS_ERS_COUGH_10 value: 10. cough during expiration; ERR_FV_ATS_ERS_EOT_10 value: 100. no end of test criteria found; ERR_FV_ATS_ERS_SHORT_10 value: 200. expiration to short; ERR_FV_ATS_ERS_NOPLAT_10 value: 400. no end expiration plateau found; ERR_FV_ATS_ERS_BE_ALLW_10 value: 1000. BE criteria not fulfilled, BE calculated; ERR_FV_ATS_ERS_BE_10 value: 2000. BE criteria not fulfilled, BE not calculated; ERR_FV_ATS_ERS_EARLY_END_10 value: 4000. early end criteria;"
						}, {
							"Id": 19726339,
							"ShortName": "ERV   ",
							"LongName": "ERV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Expiratory Reserve Volume"
						}, {
							"Id": 19726467,
							"ShortName": "ERV_F",
							"LongName": "ERV forced",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19726434,
							"ShortName": "F CT",
							"LongName": "Error CT",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19726459,
							"ShortName": "F05FVC",
							"LongName": "FEV05 % FVC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "FEV05 in percent of FVC"
						}, {
							"Id": 19726460,
							"ShortName": "F05VCM",
							"LongName": "FEV05 % VCMax",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "FEV05 in percent of VC max"
						}, {
							"Id": 19726441,
							"ShortName": "F75-85",
							"LongName": "MFEF 75-85",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": " FVC*0.1/(FET85-FET75)"
						}, {
							"Id": 19726461,
							"ShortName": "F75FVC",
							"LongName": "FEV075 % FVC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "FEV75 in percent of FVC"
						}, {
							"Id": 19726462,
							"ShortName": "F75VCM",
							"LongName": "FEV075 % VCMax",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "FEV75 in percent of VC max"
						}, {
							"Id": 19726458,
							"ShortName": "FE Ran",
							"LongName": "Trial Ranking",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19726397,
							"ShortName": "FE%FIF",
							"LongName": "FEF50 % FIF50",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726442,
							"ShortName": "FE%MIF",
							"LongName": "FEF50 % MIF 50",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": " 100*FEF50/MIF50"
						}, {
							"Id": 19726438,
							"ShortName": "FEF 25",
							"LongName": "FEF 25",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "Expiratory flow at 25% percent of FVC"
						}, {
							"Id": 19726437,
							"ShortName": "FEF 50",
							"LongName": "FEF 50",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "Expiratory flow at 50% percent of FVC"
						}, {
							"Id": 19726436,
							"ShortName": "FEF 75",
							"LongName": "FEF 75",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "Expiratory flow at 75% percent of FVC"
						}, {
							"Id": 19726469,
							"ShortName": "FEF1s",
							"LongName": "Forced expiratory flow after 1 second",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726445,
							"ShortName": "FEF40",
							"LongName": "FEF40",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "Expiratory flow at 75% percent of FVC"
						}, {
							"Id": 19726439,
							"ShortName": "FEF50%",
							"LongName": "FEF 50 % FVC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "100*FEF50/FVC"
						}, {
							"Id": 19726485,
							"ShortName": "FEF50/75",
							"LongName": "FEF50/FEF75",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "FEF50/75"
						}, {
							"Id": 19726484,
							"ShortName": "FEF50/Ht",
							"LongName": "FEF50/Ht",
							"UnitShort": "L/s/m",
							"UnitLong": "L/s/m",
							"Comment": "FEF50 devided by patient height"
						}, {
							"Id": 19726446,
							"ShortName": "FEF60",
							"LongName": "FEF60",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "Expiratory flow at 75% percent of FVC"
						}, {
							"Id": 19726483,
							"ShortName": "FEF75/Ht",
							"LongName": "FEF75/Ht",
							"UnitShort": "L/s/m",
							"UnitLong": "L/s/m",
							"Comment": "FEF75 devided by patient height"
						}, {
							"Id": 19726447,
							"ShortName": "FEF80",
							"LongName": "FEF80",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726369,
							"ShortName": "FET   ",
							"LongName": "FET",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19726449,
							"ShortName": "FET100",
							"LongName": "FET100",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19726481,
							"ShortName": "FET25ml",
							"LongName": "FET_25ml",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": "Forced Expiration Time. Duration of the forced expiration."
						}, {
							"Id": 19726375,
							"ShortName": "FETPEF",
							"LongName": "FET PEF",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19726347,
							"ShortName": "FEV 1 ",
							"LongName": "FEV 1",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Forced Expiratory Volume after 1 sec"
						}, {
							"Id": 19726348,
							"ShortName": "FEV 2 ",
							"LongName": "FEV 2",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Forced Expiratory Volume after 2 sec"
						}, {
							"Id": 19726349,
							"ShortName": "FEV 3 ",
							"LongName": "FEV 3",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Forced Expiratory Volume after 3 sec"
						}, {
							"Id": 19726357,
							"ShortName": "FEV*30",
							"LongName": "FEV 1*30",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": "Estimation of MVV"
						}, {
							"Id": 19726346,
							"ShortName": "FEV.5",
							"LongName": "FEV 0.5",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Forced Expiratory Volume after 0.5 sec"
						}, {
							"Id": 19726424,
							"ShortName": "FEV.75",
							"LongName": "FEV 0.75",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Forced expiratory volume after 0.75 sec"
						}, {
							"Id": 19726426,
							"ShortName": "FEV1%6",
							"LongName": "FEV1 % FEV6",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "FEV1 in percent of FEV6"
						}, {
							"Id": 19726368,
							"ShortName": "FEV1%E",
							"LongName": "FEV 1 % VC EX",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726351,
							"ShortName": "FEV1%F",
							"LongName": "FEV 1 % FVC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "FEV1 in percent of FVC"
						}, {
							"Id": 19726350,
							"ShortName": "FEV1%I",
							"LongName": "FEV 1 % VC IN",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "FEV1 in percent of FVC in"
						}, {
							"Id": 19726352,
							"ShortName": "FEV1%M",
							"LongName": "FEV 1 % VC MAX",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "FEV1 in percent of VC max"
						}, {
							"Id": 19726477,
							"ShortName": "FEV1%VCin_F",
							"LongName": "FEV 1 % VC IN forced",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "FEV1/VC IN from forced spirometry"
						}, {
							"Id": 19726403,
							"ShortName": "FEV1Mp",
							"LongName": "FEV1/VCmax % pred",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726402,
							"ShortName": "FEV1p",
							"LongName": "FEV1 % pred",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726389,
							"ShortName": "FEV3%E",
							"LongName": "FEV3 % FVC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726425,
							"ShortName": "FEV6",
							"LongName": "FEV6",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Forced expiratory volume after 6 sec"
						}, {
							"Id": 19726374,
							"ShortName": "FEVPEF",
							"LongName": "FEV PEF",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19726393,
							"ShortName": "FIF 50",
							"LongName": "FIF 50",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726450,
							"ShortName": "FIF25",
							"LongName": "FIF25",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "Inspiratory flow at 25% percent of FVCin"
						}, {
							"Id": 19726451,
							"ShortName": "FIF75",
							"LongName": "FIF75",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "Inspiratory flow at 75% percent of FVCin"
						}, {
							"Id": 19726431,
							"ShortName": "FIV0.5",
							"LongName": "FIV 0.5",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Forced inspiratory flow after 0.5 sec"
						}, {
							"Id": 19726394,
							"ShortName": "FIV1  ",
							"LongName": "FIV1",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19726395,
							"ShortName": "FIV1%F",
							"LongName": "FIV1 % FVC ",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726396,
							"ShortName": "FIV1%M",
							"LongName": "FIV1 % VC MAX",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726448,
							"ShortName": "FT2575",
							"LongName": "FET25-75",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": "FET75-FET25"
						}, {
							"Id": 19726407,
							"ShortName": "FV1e%i",
							"LongName": "FEV1 % FIV1",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726432,
							"ShortName": "FV5e/i",
							"LongName": "FEV0.5 / FIV0.5",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "FEV05 in relation to FIV05"
						}, {
							"Id": 19726367,
							"ShortName": "FVC   ",
							"LongName": "FVC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19726391,
							"ShortName": "FVC IN",
							"LongName": "FVC IN",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19726401,
							"ShortName": "FVCp  ",
							"LongName": "FVC % pred",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726455,
							"ShortName": "Grade",
							"LongName": "Office Grade",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19726338,
							"ShortName": "IC    ",
							"LongName": "IC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Inspiratory Capacity"
						}, {
							"Id": 19726470,
							"ShortName": "IC_AV",
							"LongName": "IC average",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19726468,
							"ShortName": "IC_F",
							"LongName": "IC forced",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19726343,
							"ShortName": "IRV   ",
							"LongName": "IRV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "inspiratory reserve volume"
						}, {
							"Id": 19726476,
							"ShortName": "IRV_F",
							"LongName": "IRV forced",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "IRV from forced spirometry"
						}, {
							"Id": 19726443,
							"ShortName": "ISOFEF",
							"LongName": "ISOFEF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726412,
							"ShortName": "M50%FR",
							"LongName": "MEF50 % FRC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726366,
							"ShortName": "MEF   ",
							"LongName": "MEF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726372,
							"ShortName": "MEF 25",
							"LongName": "MEF 25",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726371,
							"ShortName": "MEF 50",
							"LongName": "MEF 50",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726370,
							"ShortName": "MEF 75",
							"LongName": "MEF 75",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726488,
							"ShortName": "MEF25/Ht",
							"LongName": "MEF25/Ht",
							"UnitShort": "L/s/m",
							"UnitLong": "L/s/m",
							"Comment": "MEF25 devided by patient height"
						}, {
							"Id": 19726380,
							"ShortName": "MEF50%",
							"LongName": "MEF 50 % FVC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726490,
							"ShortName": "MEF50/25",
							"LongName": "MEF50/MEF25",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "MEF50 / MEF25"
						}, {
							"Id": 19726489,
							"ShortName": "MEF50/Ht",
							"LongName": "MEF50/Ht",
							"UnitShort": "L/s/m",
							"UnitLong": "L/s/m",
							"Comment": "MEF50 devided by patient height"
						}, {
							"Id": 19726413,
							"ShortName": "MEFFRC",
							"LongName": "MEF at FRC",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726440,
							"ShortName": "MFEF",
							"LongName": "MFEF 75-25",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "FVC*0.5(FET75 – FET25)"
						}, {
							"Id": 19726420,
							"ShortName": "mFEV 1",
							"LongName": "mean FEV 1",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "smallest FEV1 value"
						}, {
							"Id": 19726365,
							"ShortName": "MIF   ",
							"LongName": "MIF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726381,
							"ShortName": "MMEF  ",
							"LongName": "MMEF 75-25",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726384,
							"ShortName": "MTT   ",
							"LongName": "Mean transit time",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19726341,
							"ShortName": "MV    ",
							"LongName": "MV",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": "Minute volume"
						}, {
							"Id": 19726466,
							"ShortName": "MV_F",
							"LongName": "MV forced",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 19726353,
							"ShortName": "MVV   ",
							"LongName": "MVV",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": "Maximal voluntary ventilation"
						}, {
							"Id": 19726472,
							"ShortName": "MVV En",
							"LongName": "MVV End",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19726471,
							"ShortName": "MVV Start",
							"LongName": "MVV Start",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19726452,
							"ShortName": "MVV_6",
							"LongName": "MVV_6",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 19726430,
							"ShortName": "OrigNo",
							"LongName": "Orig. trial no.",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "original trial number"
						}, {
							"Id": 19726399,
							"ShortName": "OSC EX",
							"LongName": "Oscillation EX",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726400,
							"ShortName": "OSC IN",
							"LongName": "Oscillation IN",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726373,
							"ShortName": "PEF   ",
							"LongName": "PEF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726390,
							"ShortName": "PEF*  ",
							"LongName": "FEF 200-1200",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726392,
							"ShortName": "PIF   ",
							"LongName": "PIF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726379,
							"ShortName": "QC-ok",
							"LongName": "QC-Accepted",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19726480,
							"ShortName": "Quality Grade FEV1",
							"LongName": "Quality Grade FEV1",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "Quality Grade  for FEV1"
						}, {
							"Id": 19726479,
							"ShortName": "Quality Grade FVC",
							"LongName": "Quality Grade FVC",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "Quality Grade for FVC"
						}, {
							"Id": 19726406,
							"ShortName": "SPIMOD",
							"LongName": "SPIMOD",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19726444,
							"ShortName": "STDFEF",
							"LongName": "STDFEF2575",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19726360,
							"ShortName": "T EX  ",
							"LongName": "T EX",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19726359,
							"ShortName": "T IN  ",
							"LongName": "T IN",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19726358,
							"ShortName": "T MVV ",
							"LongName": "TIME MVV",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19726361,
							"ShortName": "T TOT ",
							"LongName": "T TOT",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19726383,
							"ShortName": "T25-50",
							"LongName": "TC 25-50",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19726376,
							"ShortName": "TBEex ",
							"LongName": "T backextrapol. ex ",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19726409,
							"ShortName": "TBEin ",
							"LongName": "T backextrapol. in ",
							"UnitShort": "ms",
							"UnitLong": "ms",
							"Comment": null
						}, {
							"Id": 19726463,
							"ShortName": "Tdel",
							"LongName": "T delay",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19726363,
							"ShortName": "TE/TOT",
							"LongName": "TEX/TTOT",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19726364,
							"ShortName": "TI/TE ",
							"LongName": "TIN/TEX",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19726362,
							"ShortName": "TI/TOT",
							"LongName": "TIN/TTOT",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19726415,
							"ShortName": "Tr.ctr",
							"LongName": "Trial-Counter",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19726408,
							"ShortName": "Trial ",
							"LongName": "Trial-Number             ",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19726416,
							"ShortName": "TTrial",
							"LongName": "Time of Trial",
							"UnitShort": "min",
							"UnitLong": "min",
							"Comment": null
						}, {
							"Id": 19726378,
							"ShortName": "VBe%FV",
							"LongName": "V backextrapol. % FVC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726377,
							"ShortName": "VBEex ",
							"LongName": "V backextrapolation ex",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19726410,
							"ShortName": "VBEin ",
							"LongName": "V backextrapolation in",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19726411,
							"ShortName": "VBi%FV",
							"LongName": "VBEin % FVCin",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19726344,
							"ShortName": "VC EX ",
							"LongName": "VC EX",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Expiratory vital Capacity"
						}, {
							"Id": 19726337,
							"ShortName": "VC IN ",
							"LongName": "VC IN ",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Inspiratory Vital Capacity"
						}, {
							"Id": 19726345,
							"ShortName": "VC MAX",
							"LongName": "VC MAX",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Maximum of vital capacity"
						}, {
							"Id": 19726475,
							"ShortName": "VCex_F",
							"LongName": "VC EX forced",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "VC EX from forced spirometry"
						}, {
							"Id": 19726474,
							"ShortName": "VCin_F",
							"LongName": "VC IN forced",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "VC IN from forced spirometry"
						}, {
							"Id": 19726487,
							"ShortName": "VCm%FVC",
							"LongName": "VCmax%FVC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "VCmax%FVC"
						}, {
							"Id": 19726486,
							"ShortName": "VCm%pred",
							"LongName": "VCmax%pred",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "VCmax%pred"
						}, {
							"Id": 19726435,
							"ShortName": "VCmod",
							"LongName": "VCmod",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19726386,
							"ShortName": "Vers  ",
							"LongName": "Version",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19726414,
							"ShortName": "VMEF",
							"LongName": "VMEF",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19726340,
							"ShortName": "VT    ",
							"LongName": "VT",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "tidal volume of RES trials, if available value copied to SP data set"
						}, {
							"Id": 19726354,
							"ShortName": "VT MVV",
							"LongName": "VT MVV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Tidal volume during MVV measurement"
						}, {
							"Id": 19726464,
							"ShortName": "VT_F",
							"LongName": "VT forced",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}, {
					"Id": 302,
					"Childs": [],
					"ParentId": 3,
					"ShortName": "Transmural F/V",
					"LongName": "Transmural F/V",
					"Comment": null,
					"Parameters": [{
							"Id": 19791909,
							"ShortName": "......",
							"LongName": "",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19791886,
							"ShortName": "75-85 ",
							"LongName": "FEF 75-85",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19791895,
							"ShortName": "AEX   ",
							"LongName": "Exspir. F/V-area",
							"UnitShort": "L*L/s",
							"UnitLong": "L*L/s",
							"Comment": null
						}, {
							"Id": 19791914,
							"ShortName": "ATS-ok",
							"LongName": "ATS-Accepted",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19791924,
							"ShortName": "CexpUs",
							"LongName": "Cexp used",
							"UnitShort": "1/kPa",
							"UnitLong": "1/kPa",
							"Comment": null
						}, {
							"Id": 19791926,
							"ShortName": "CstUs",
							"LongName": "Cstat used",
							"UnitShort": "L/kPa",
							"UnitLong": "L/kPa",
							"Comment": null
						}, {
							"Id": 19791915,
							"ShortName": "dPmaxe",
							"LongName": "dP max Tm ex",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 19791916,
							"ShortName": "dPmaxi",
							"LongName": "dP max Tm in",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 19791917,
							"ShortName": "Dsourc",
							"LongName": "Data source",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19791910,
							"ShortName": "dVmaxe",
							"LongName": "dVol max Tm ex",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791911,
							"ShortName": "dVmaxi",
							"LongName": "dVol max Tm in",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791918,
							"ShortName": "F/VMOD",
							"LongName": "F/VMOD",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 19791908,
							"ShortName": "FE%FIF",
							"LongName": "FEF 50 % FIF 50",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19791893,
							"ShortName": "FET   ",
							"LongName": "FET",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19791892,
							"ShortName": "FETPEF",
							"LongName": "FET PEF",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19791873,
							"ShortName": "FEV .5",
							"LongName": "FEV 0.5",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791874,
							"ShortName": "FEV 1 ",
							"LongName": "FEV 1",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791875,
							"ShortName": "FEV 2 ",
							"LongName": "FEV 2",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791876,
							"ShortName": "FEV 3 ",
							"LongName": "FEV 3",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791887,
							"ShortName": "FEV1%F",
							"LongName": "FEV 1 % FVC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19791884,
							"ShortName": "FEV1%I",
							"LongName": "FEV 1 % VC IN",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19791888,
							"ShortName": "FEV1%M",
							"LongName": "FEV 1 % VCmax",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19791902,
							"ShortName": "FEV1%P",
							"LongName": "FEV 1 % Pred",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19791906,
							"ShortName": "FEV3%E",
							"LongName": "FEV3 % FVC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19791894,
							"ShortName": "FEVPEF",
							"LongName": "FEV PEF",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791900,
							"ShortName": "FIF 50",
							"LongName": "FIF 50",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19791898,
							"ShortName": "FIV 1 ",
							"LongName": "FIV 1",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791901,
							"ShortName": "FIV1%I",
							"LongName": "FIV 1 % VC IN",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19791883,
							"ShortName": "FVC   ",
							"LongName": "FVC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791903,
							"ShortName": "FVC %P",
							"LongName": "FVC % Pred",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19791899,
							"ShortName": "FVC IN",
							"LongName": "FVC IN",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791904,
							"ShortName": "ME25%P",
							"LongName": "MEF 25 % Pred",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19791905,
							"ShortName": "ME50%P",
							"LongName": "MEF 50 % Pred",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19791879,
							"ShortName": "MEF 25",
							"LongName": "MEF 25",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19791878,
							"ShortName": "MEF 50",
							"LongName": "MEF 50",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19791877,
							"ShortName": "MEF 75",
							"LongName": "MEF 75",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19791881,
							"ShortName": "MEF50%",
							"LongName": "MEF 50 % FVC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19791885,
							"ShortName": "MMEF  ",
							"LongName": "MMEF 75-25",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19791891,
							"ShortName": "MTT   ",
							"LongName": "Mean transit time",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19791889,
							"ShortName": "MVV-Th",
							"LongName": "MVV Th. (FEV1*30)",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 19791880,
							"ShortName": "PEF   ",
							"LongName": "PEF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19791907,
							"ShortName": "PEF*  ",
							"LongName": "FEF 200-1200",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19791897,
							"ShortName": "PIF   ",
							"LongName": "PIF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 19791921,
							"ShortName": "Rus",
							"LongName": "R upstream",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 19791925,
							"ShortName": "RusSt",
							"LongName": "R upstr. stat",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 19791890,
							"ShortName": "T25/50",
							"LongName": "TC 25/50",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 19791912,
							"ShortName": "V be  ",
							"LongName": "V backextrapol. ",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791919,
							"ShortName": "VbeIN",
							"LongName": "V backestr. IN",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791920,
							"ShortName": "VBi%FV",
							"LongName": "VBEin % FVCin",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19791882,
							"ShortName": "VC IN ",
							"LongName": "VC IN",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791896,
							"ShortName": "VC Max",
							"LongName": "VC Max",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791922,
							"ShortName": "VinfUs",
							"LongName": "Vinf used",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791913,
							"ShortName": "Vre%FV",
							"LongName": "V Rückextrapol. % FVC ",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 19791927,
							"ShortName": "VstUs",
							"LongName": "Vzero stat used",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 19791923,
							"ShortName": "VzeroU",
							"LongName": "Vzero used",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}, {
					"Id": 303,
					"Childs": [],
					"ParentId": 3,
					"ShortName": "Body",
					"LongName": "Body",
					"Comment": null,
					"Parameters": [{
							"Id": 19857538,
							"ShortName": "-",
							"LongName": "-",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "reserved"
						}, {
							"Id": 19857518,
							"ShortName": "AR",
							"LongName": "Area R/V loop",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": "area within the RV-Loop"
						}, {
							"Id": 19857469,
							"ShortName": "ASC   ",
							"LongName": "ASC-Setting.......... ",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "ASC value"
						}, {
							"Id": 19857437,
							"ShortName": "Bar.Pr",
							"LongName": "Bar.Pressure",
							"UnitShort": "hPa",
							"UnitLong": "hPa",
							"Comment": "pressure during measurement"
						}, {
							"Id": 19857488,
							"ShortName": "BdyMod",
							"LongName": "Bodyplet. modified ",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "reserved"
						}, {
							"Id": 19857493,
							"ShortName": "BF FRC",
							"LongName": "BF FRCpl",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": "breathing frequency during ITGV"
						}, {
							"Id": 19857492,
							"ShortName": "BF res",
							"LongName": "BF Res",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": "breathing frequency during slow spirometry"
						}, {
							"Id": 19857436,
							"ShortName": "BoxVol",
							"LongName": "Box Volume",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "volume of used cabine"
						}, {
							"Id": 19857482,
							"ShortName": "Cl.cap",
							"LongName": "Closing capacity",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "value of closing cap, manually set"
						}, {
							"Id": 19857470,
							"ShortName": "Cl.vol",
							"LongName": "Closing volume",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "value of closing volume, manually set"
						}, {
							"Id": 19857456,
							"ShortName": "Conf 1",
							"LongName": "Configuration 1 ",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "reserved"
						}, {
							"Id": 19857457,
							"ShortName": "Conf 2",
							"LongName": "Configuration 2 ",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "reserved"
						}, {
							"Id": 19857460,
							"ShortName": "Cycles",
							"LongName": "FRCpl-iterations ",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "number of needed cycles"
						}, {
							"Id": 19857432,
							"ShortName": "d_PAO ",
							"LongName": "d-PAO",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": "delta pressure, EX - IN"
						}, {
							"Id": 19857517,
							"ShortName": "dR",
							"LongName": "Diff RexM RinM",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": "difference between RexMean and RinMean"
						}, {
							"Id": 19857485,
							"ShortName": "DSapp",
							"LongName": "App. deadspace",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "dead space of device"
						}, {
							"Id": 19857454,
							"ShortName": "dVol  ",
							"LongName": "Delta volume FRCpl ",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Delta volume FRCpl"
						}, {
							"Id": 19857540,
							"ShortName": "E-FRC-EA05",
							"LongName": "Error FRCpl ATS ERS 05",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 19857496,
							"ShortName": "ER-EJ",
							"LongName": "Res error Jaeger",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Error code for RES measurement Jaeger"
						}, {
							"Id": 19857495,
							"ShortName": "ERmayo",
							"LongName": "Res error Mayo",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Error code for Res measurement Mayo"
						}, {
							"Id": 19857434,
							"ShortName": "ERV   ",
							"LongName": "ERV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "expiratory reserve volume"
						}, {
							"Id": 19857494,
							"ShortName": "ETmayo",
							"LongName": "FRCpl error Mayo",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Error code for ITGV measurement Mayo"
						}, {
							"Id": 19857439,
							"ShortName": "FEV1  ",
							"LongName": "FEV1",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "volume after 1 s of forced expiration"
						}, {
							"Id": 19857422,
							"ShortName": "FRC%PR",
							"LongName": "FRCpleth % PRED",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "ITGV%Predicted"
						}, {
							"Id": 19857532,
							"ShortName": "FRC·Cv",
							"LongName": "FRCpl·Cv%",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "CVR of FRCpl values used for guidance"
						}, {
							"Id": 19857489,
							"ShortName": "FRCerr",
							"LongName": "FRCpl Error",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Error code for ITGV measurement Jaeger"
						}, {
							"Id": 19857427,
							"ShortName": "FRCpl",
							"LongName": "FRCpleth",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "ITGV"
						}, {
							"Id": 19857453,
							"ShortName": "FRCpl' ",
							"LongName": "FRCpl used in resis. ",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Value used to convert specific resistance to resistance. If Resistance linked with ITGV, ITGV of trial is used else BEST value is taken into account"
						}, {
							"Id": 19857431,
							"ShortName": "FRCpl%",
							"LongName": "FRCpl% TLC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "ITGV percent of TLC"
						}, {
							"Id": 19857502,
							"ShortName": "G 0.5 ",
							"LongName": "G 0.5 ",
							"UnitShort": "(L/s)/kPa",
							"UnitLong": "(L/s)/kPa",
							"Comment": "conductance according to Dubois"
						}, {
							"Id": 19857424,
							"ShortName": "G eff ",
							"LongName": "G eff",
							"UnitShort": "(L/s)/kPa",
							"UnitLong": "(L/s)/kPa",
							"Comment": "conductance, effective method"
						}, {
							"Id": 19857473,
							"ShortName": "G mid ",
							"LongName": "G mid",
							"UnitShort": "(L/s)/kPa",
							"UnitLong": "(L/s)/kPa",
							"Comment": "conductance, mid method"
						}, {
							"Id": 19857423,
							"ShortName": "G tot ",
							"LongName": "G tot",
							"UnitShort": "(L/s)/kPa",
							"UnitLong": "(L/s)/kPa",
							"Comment": "conductance, tangent method"
						}, {
							"Id": 19857443,
							"ShortName": "G0.5IN",
							"LongName": "G 0.5 IN",
							"UnitShort": "(L/s)/kPa",
							"UnitLong": "(L/s)/kPa",
							"Comment": "conductance according to Dubois"
						}, {
							"Id": 19857536,
							"ShortName": "Gaw",
							"LongName": "Gaw",
							"UnitShort": "(L/s)/kPa",
							"UnitLong": "(L/s)/kPa",
							"Comment": "Conductance of chosen method"
						}, {
							"Id": 19857450,
							"ShortName": "Gpeak ",
							"LongName": "G peak",
							"UnitShort": "(L/s)/kPa",
							"UnitLong": "(L/s)/kPa",
							"Comment": "conductance, peak method"
						}, {
							"Id": 19857435,
							"ShortName": "IC    ",
							"LongName": "IC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "inspiratory capacity"
						}, {
							"Id": 19857484,
							"ShortName": "mBest ",
							"LongName": "Manual best  BY,FRCpl,SP,FV",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "marker for manual BEST"
						}, {
							"Id": 19857483,
							"ShortName": "mSlope",
							"LongName": "Manual slope BY,FRCpl,SPIR",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "marker for manual modification"
						}, {
							"Id": 19857498,
							"ShortName": "OrigNo",
							"LongName": "Orig. trial no. R,T",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "reserved"
						}, {
							"Id": 19857468,
							"ShortName": "P0.1  ",
							"LongName": "P 0.1 (FRCpl)",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": "P01 during ITGV"
						}, {
							"Id": 19857458,
							"ShortName": "PE max",
							"LongName": "PE max (FRCpl) ",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": "max insp. pressure during ITGV"
						}, {
							"Id": 19857438,
							"ShortName": "PEF   ",
							"LongName": "PEF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "peak expiratory flow"
						}, {
							"Id": 19857459,
							"ShortName": "PI max",
							"LongName": "PI max (FRCpl) ",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": "max esp. Pressure during ITGV"
						}, {
							"Id": 19857467,
							"ShortName": "POB   ",
							"LongName": "Power of breathing ",
							"UnitShort": "W",
							"UnitLong": "Watt",
							"Comment": "power of breathing"
						}, {
							"Id": 19857519,
							"ShortName": "PtPR",
							"LongName": "Peak to Peak",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": "Peak to Peak, REmax - RImin"
						}, {
							"Id": 19857501,
							"ShortName": "R 0.5 ",
							"LongName": "R 0.5",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": "resistance according to Dubois"
						}, {
							"Id": 19857506,
							"ShortName": "R bi",
							"LongName": "R begin in",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": "resistance begin inspiration"
						}, {
							"Id": 19857510,
							"ShortName": "R ee",
							"LongName": "R end ex",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": "resistance end expiration"
						}, {
							"Id": 19857411,
							"ShortName": "R eff ",
							"LongName": "R eff",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": "resistance value, effective method"
						}, {
							"Id": 19857413,
							"ShortName": "R EX  ",
							"LongName": "R EX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": "resistance value, tangent method, expiratory part"
						}, {
							"Id": 19857412,
							"ShortName": "R IN  ",
							"LongName": "R IN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": "resistance value, tangent method, inspiratory part"
						}, {
							"Id": 19857471,
							"ShortName": "R mid ",
							"LongName": "R mid",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": "resistance value, mid method"
						}, {
							"Id": 19857416,
							"ShortName": "R PEAK",
							"LongName": "R PEAK",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": "resistance value, Peak method"
						}, {
							"Id": 19857509,
							"ShortName": "r R be",
							"LongName": "reg R be",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "regression resistance begin expiration"
						}, {
							"Id": 19857507,
							"ShortName": "r R bi",
							"LongName": "reg R bi",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "regression of resistance begin inspiration"
						}, {
							"Id": 19857511,
							"ShortName": "r Ree",
							"LongName": "reg R ee",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "regression resistance end expiration"
						}, {
							"Id": 19857410,
							"ShortName": "R tot ",
							"LongName": "R tot",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": "resistance value, tangent method"
						}, {
							"Id": 19857497,
							"ShortName": "R,T ok",
							"LongName": "Res, FRCpl accepted",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Accepted (RES and ITGV)"
						}, {
							"Id": 19857505,
							"ShortName": "R0.5EX",
							"LongName": "R 0.5 EX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": "resistance according to Dubois, expiratory part"
						}, {
							"Id": 19857409,
							"ShortName": "R0.5IN",
							"LongName": "R 0.5 IN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": "resistance value according to Dubois, inspiratory part"
						}, {
							"Id": 19857534,
							"ShortName": "Raw",
							"LongName": "Raw",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": "Resistance value of chosen method"
						}, {
							"Id": 19857508,
							"ShortName": "Rbe",
							"LongName": "R begin ex",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": "resistance begin expiration"
						}, {
							"Id": 19857415,
							"ShortName": "RE eff",
							"LongName": "R EX eff",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": "resistance value, effective method, expiratory part"
						}, {
							"Id": 19857512,
							"ShortName": "ReeRbe",
							"LongName": "Ree/Rbe",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "inhomogenity index Ree/Rbe"
						}, {
							"Id": 19857520,
							"ShortName": "REFMa",
							"LongName": "REFmax",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": "resistance at maximum flow"
						}, {
							"Id": 19857486,
							"ShortName": "RES 1 ",
							"LongName": "BODY RES 1   ",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "close volume percent of VC"
						}, {
							"Id": 19857487,
							"ShortName": "RES 2 ",
							"LongName": "BODY RES 2   ",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "close cap percent of TLC"
						}, {
							"Id": 19857513,
							"ShortName": "RexMax",
							"LongName": "R ex Max",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 19857514,
							"ShortName": "RexMea",
							"LongName": "R ex Mean",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": "mean resistance at expiration"
						}, {
							"Id": 19857414,
							"ShortName": "RI eff",
							"LongName": "R IN eff",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": "resistance value, effective method, inspiratory part"
						}, {
							"Id": 19857521,
							"ShortName": "RIFMa",
							"LongName": "RIFmax",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": "resistance at maximum flow"
						}, {
							"Id": 19857522,
							"ShortName": "RinMax",
							"LongName": "R in Max",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": "maximal resistance at inspiration"
						}, {
							"Id": 19857515,
							"ShortName": "RinMea",
							"LongName": "R in Mean",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": "mean resistance at inspiration"
						}, {
							"Id": 19857516,
							"ShortName": "RinMin",
							"LongName": "R in Min",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": "minimal resistance at inspiration"
						}, {
							"Id": 19857478,
							"ShortName": "RmidEX",
							"LongName": "R mid EX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": "resistance, mid method, expiratory part"
						}, {
							"Id": 19857476,
							"ShortName": "RmidIN",
							"LongName": "R mid IN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": "resistance, mid method, inspiratory part"
						}, {
							"Id": 19857428,
							"ShortName": "RV    ",
							"LongName": "RV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Residual volume"
						}, {
							"Id": 19857430,
							"ShortName": "RV%TLC",
							"LongName": "RV % TLC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "RV in percent of TLC"
						}, {
							"Id": 19857525,
							"ShortName": "SBE",
							"LongName": "SBE",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "slope of linear regression between resistance of begin of expiration and REmax"
						}, {
							"Id": 19857526,
							"ShortName": "SBI",
							"LongName": "SBI",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "slope of linear regression between resistance of begin of inspiration and end of inspiration"
						}, {
							"Id": 19857503,
							"ShortName": "sG 0.5",
							"LongName": "sG 0.5 ",
							"UnitShort": "1/(kPa*s)",
							"UnitLong": "1/(kPa*s)",
							"Comment": "specific conductance according to Dubois"
						}, {
							"Id": 19857426,
							"ShortName": "sG eff",
							"LongName": "sG eff",
							"UnitShort": "1/(kPa*s)",
							"UnitLong": "1/(kPa*s)",
							"Comment": "specific conductance, effective method"
						}, {
							"Id": 19857474,
							"ShortName": "sG mid",
							"LongName": "sG mid",
							"UnitShort": "1/(kPa*s)",
							"UnitLong": "1/(kPa*s)",
							"Comment": "specific conductance, mid method"
						}, {
							"Id": 19857425,
							"ShortName": "sG tot",
							"LongName": "sG tot",
							"UnitShort": "1/(kPa*s)",
							"UnitLong": "1/(kPa*s)",
							"Comment": "specific conductance, tangent method"
						}, {
							"Id": 19857444,
							"ShortName": "sG05IN",
							"LongName": "sG 0.5 IN",
							"UnitShort": "1/(kPa*s)",
							"UnitLong": "1/(kPa*s)",
							"Comment": "specific conduction according to Dubois"
						}, {
							"Id": 19857537,
							"ShortName": "sGaw",
							"LongName": "sGaw",
							"UnitShort": "1/(kPa*s)",
							"UnitLong": "1/(kPa*s)",
							"Comment": "specific conductance of chosen method"
						}, {
							"Id": 19857451,
							"ShortName": "sGpeak",
							"LongName": "sG peak",
							"UnitShort": "1/(kPa*s)",
							"UnitLong": "1/(kPa*s)",
							"Comment": "specific conductance, peak method"
						}, {
							"Id": 19857481,
							"ShortName": "SpNo  ",
							"LongName": "Original Spirom. Test No",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "test number of spir trial, needed to protect pre read spir test"
						}, {
							"Id": 19857500,
							"ShortName": "sR 0.5",
							"LongName": "sR 0.5",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": "specific resistance according to Dubois"
						}, {
							"Id": 19857418,
							"ShortName": "sR eff",
							"LongName": "sR eff",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": "specific resistance value, effective method"
						}, {
							"Id": 19857472,
							"ShortName": "sR mid",
							"LongName": "sR mid",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": "specific resistance, mid method"
						}, {
							"Id": 19857417,
							"ShortName": "sR tot",
							"LongName": "sR tot",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": "specific resistance value, tangent method"
						}, {
							"Id": 19857504,
							"ShortName": "sR05EX",
							"LongName": "sR 0.5 EX",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": "specific resistance according to Dubois, expiratory"
						}, {
							"Id": 19857442,
							"ShortName": "sR05IN",
							"LongName": "sR 0.5 IN",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": "specific resistance according to Dubois, inspiratory"
						}, {
							"Id": 19857535,
							"ShortName": "sRaw",
							"LongName": "sRaw",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": "specific resistance of chosen method"
						}, {
							"Id": 19857533,
							"ShortName": "sRaw-Cv",
							"LongName": "sRaw-Cv%",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "CVR of resistance values used for guidance"
						}, {
							"Id": 19857441,
							"ShortName": "sRE t ",
							"LongName": "sRt EX",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": "specific resistance value, tangent method, expiratory part"
						}, {
							"Id": 19857447,
							"ShortName": "sREeff",
							"LongName": "sR eff EX",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": "specific resistance value, effective method, expiratory part"
						}, {
							"Id": 19857440,
							"ShortName": "sRI t ",
							"LongName": "sRt IN",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": "specific resistance value, tangent method, inspiratory part"
						}, {
							"Id": 19857446,
							"ShortName": "sRIeff",
							"LongName": "sR eff IN",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": "specific resistance value, effective method, inspiratory part"
						}, {
							"Id": 19857479,
							"ShortName": "sRmdEX",
							"LongName": "sR mid EX",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": "specific resistance, mid method, expiratory part"
						}, {
							"Id": 19857477,
							"ShortName": "sRmdIN",
							"LongName": "sR mid IN",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": "specific resistance, mid method, inspiratory part"
						}, {
							"Id": 19857449,
							"ShortName": "sRpeak",
							"LongName": "sR peak",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": "specific resistance, peak method"
						}, {
							"Id": 19857463,
							"ShortName": "sWOB  ",
							"LongName": "sWOB ",
							"UnitShort": "kPa*L*L",
							"UnitLong": "kPa*L*L",
							"Comment": "specific work of breathing, expiratory part"
						}, {
							"Id": 19857462,
							"ShortName": "sWOBex",
							"LongName": "sWOB EX ",
							"UnitShort": "kPa*L*L",
							"UnitLong": "kPa*L*L",
							"Comment": "specific work of breathing"
						}, {
							"Id": 19857461,
							"ShortName": "sWOBin",
							"LongName": "sWOB IN ",
							"UnitShort": "kPa*L*L",
							"UnitLong": "kPa*L*L",
							"Comment": "specific work of breathing, inspiratory part"
						}, {
							"Id": 19857480,
							"ShortName": "TestNo",
							"LongName": "Original Test No",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "number of test"
						}, {
							"Id": 19857420,
							"ShortName": "tg a  ",
							"LongName": "tg alpha",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "tangent alpha"
						}, {
							"Id": 19857421,
							"ShortName": "tg ß t",
							"LongName": "tg beta tot",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "tangent beta, tangent method"
						}, {
							"Id": 19857452,
							"ShortName": "tgß pk",
							"LongName": "tg ß peak",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "tangent beta, peak method"
						}, {
							"Id": 19857445,
							"ShortName": "tgß0.5",
							"LongName": "tg ß 0.5",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "tangent beta, according to Dubois"
						}, {
							"Id": 19857448,
							"ShortName": "tgßEff",
							"LongName": "tg ß eff",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "tangent beta, effective method"
						}, {
							"Id": 19857475,
							"ShortName": "tgßmid",
							"LongName": "tg ß R mid",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "tangent beta, mid method"
						}, {
							"Id": 19857490,
							"ShortName": "TGVdPM",
							"LongName": "FRCpl PM swing",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": "average PV, mouth pressure"
						}, {
							"Id": 19857491,
							"ShortName": "TGVdVb",
							"LongName": "FRCpl box vol swing",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": "average box pressure"
						}, {
							"Id": 19857429,
							"ShortName": "TLC   ",
							"LongName": "TLC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Lung volume"
						}, {
							"Id": 19857528,
							"ShortName": "V b e",
							"LongName": "Vol begin Ex",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Volume at begin expiration"
						}, {
							"Id": 19857527,
							"ShortName": "V B In",
							"LongName": "Vol begin In",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Volume at begin inspiration"
						}, {
							"Id": 19857529,
							"ShortName": "V ee",
							"LongName": "Vol end Ex",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Volume at end expiration"
						}, {
							"Id": 19857433,
							"ShortName": "VC    ",
							"LongName": "VC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "vital capacity"
						}, {
							"Id": 19857499,
							"ShortName": "VCref",
							"LongName": "VC ref for QC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "VC needed for QC-Mayo, condition if Spir trial is accepted"
						}, {
							"Id": 19857531,
							"ShortName": "VEFma",
							"LongName": "Vol Max Flow ex",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Volume at maximum flow (inpiration)"
						}, {
							"Id": 19857455,
							"ShortName": "Vers  ",
							"LongName": "Version ",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "version of application"
						}, {
							"Id": 19857530,
							"ShortName": "VIFma",
							"LongName": "Vol Max Flow in",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Volume at maximum flow (expiration)"
						}, {
							"Id": 19857523,
							"ShortName": "VR%VE",
							"LongName": "VRmax%VE",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "expiratory volume at REmax related to expiratory volume"
						}, {
							"Id": 19857524,
							"ShortName": "VR%VI",
							"LongName": "VRmax%VI",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "inspiratory volume at RImax related to inspiratory volume"
						}, {
							"Id": 19857419,
							"ShortName": "VT    ",
							"LongName": "VT",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "tidal volume of RES trials,"
						}, {
							"Id": 19857539,
							"ShortName": "Vtg",
							"LongName": "Vtg",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Thoracic gas volume. Internal used name also “ITGV”. Used in FRCpleth calculation."
						}, {
							"Id": 19857466,
							"ShortName": "WOB   ",
							"LongName": "Work of breathing ",
							"UnitShort": "kPa*L",
							"UnitLong": "kPa*L",
							"Comment": "work of breathing"
						}, {
							"Id": 19857465,
							"ShortName": "WOB ex",
							"LongName": "Work of breath.EX ",
							"UnitShort": "kPa*L",
							"UnitLong": "kPa*L",
							"Comment": "work of breathing, expiratory part"
						}, {
							"Id": 19857464,
							"ShortName": "WOB in",
							"LongName": "Work of breath.IN ",
							"UnitShort": "kPa*L",
							"UnitLong": "kPa*L",
							"Comment": "work of breathing, aspiratory part"
						}
					]
				}, {
					"Id": 399,
					"Childs": [],
					"ParentId": 3,
					"ShortName": "VMAX",
					"LongName": "VMAX",
					"Comment": null,
					"Parameters": [{
							"Id": 26148870,
							"ShortName": "DyspneaIndex",
							"LongName": "DyspneaIndex",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148869,
							"ShortName": "EOTV",
							"LongName": "EOTV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148874,
							"ShortName": "FEF10",
							"LongName": "FEF10",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148871,
							"ShortName": "FEF70",
							"LongName": "FEF70",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148872,
							"ShortName": "FEF85",
							"LongName": "FEF85",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148873,
							"ShortName": "FEF90",
							"LongName": "FEF90",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148888,
							"ShortName": "FEV05/FEV2",
							"LongName": "FEV05/FEV2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148877,
							"ShortName": "FEV10",
							"LongName": "FEV10",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148875,
							"ShortName": "FEV7",
							"LongName": "FEV7",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148876,
							"ShortName": "FIF2575",
							"LongName": "FIF2575",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148880,
							"ShortName": "FVL_TAMB",
							"LongName": "FVL_TAMB",
							"UnitShort": "°C",
							"UnitLong": "Celsius",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148886,
							"ShortName": "fVTG",
							"LongName": "VTGFreq",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148865,
							"ShortName": "ISOSTDFEF",
							"LongName": "ISOSTDFEF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148883,
							"ShortName": "LUNG_ECODE",
							"LongName": "LUNG_ECODE",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148882,
							"ShortName": "MVV_ECODE",
							"LongName": "MVV_ECODE",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148878,
							"ShortName": "NLHEP",
							"LongName": "NLHEP",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148879,
							"ShortName": "P_GRADE",
							"LongName": "P_GRADE",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148885,
							"ShortName": "RAW_ECODE",
							"LongName": "RAW_ECODE",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148881,
							"ShortName": "SPIR_ECODE",
							"LongName": "SPIR_ECODE",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148868,
							"ShortName": "VMAX60",
							"LongName": "VMAX60",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148867,
							"ShortName": "VMAX70",
							"LongName": "VMAX70",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148866,
							"ShortName": "VMAX80",
							"LongName": "VMAX80",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148887,
							"ShortName": "VTG",
							"LongName": "VTG",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "VMAX imported data"
						}, {
							"Id": 26148884,
							"ShortName": "VTG Raw",
							"LongName": "VTG Raw",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "VMAX imported data"
						}
					]
				}
			],
			"ParentId": 0,
			"ShortName": "Body",
			"LongName": "Bodyplethysmography",
			"Comment": null,
			"Parameters": []
		}, {
			"Id": 4,
			"Childs": [{
					"Id": 499,
					"Childs": [],
					"ParentId": 4,
					"ShortName": "VMAX",
					"LongName": "VMAX",
					"Comment": null,
					"Parameters": [{
							"Id": 32702465,
							"ShortName": "CO_TC",
							"LongName": "Time constant CO",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 32702470,
							"ShortName": "DLET100",
							"LongName": "Time btw. tests",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 32702469,
							"ShortName": "DM",
							"LongName": "Membran DLCO",
							"UnitShort": "mmol/(min*kPa)",
							"UnitLong": "mmol/(min*kPa)",
							"Comment": null
						}, {
							"Id": 32702466,
							"ShortName": "DM_FIO2",
							"LongName": "DM_FIO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 32702467,
							"ShortName": "DM_PaCO2",
							"LongName": "DM_PaCO2",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": null
						}, {
							"Id": 32702468,
							"ShortName": "DM_VC",
							"LongName": "DM_VC",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}
					]
				}
			],
			"ParentId": 0,
			"ShortName": "Diff SB",
			"LongName": "Diffusion SB",
			"Comment": null,
			"Parameters": [{
					"Id": 262177,
					"ShortName": "%COHb",
					"LongName": "Carboxyhemoglobin",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262217,
					"ShortName": "%prRCL",
					"LongName": "KCO (RCL) %pred",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262193,
					"ShortName": "AT Auth",
					"LongName": "Author AT calculation",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 262201,
					"ShortName": "BF_SB",
					"LongName": "BF Single Breath",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": null
				}, {
					"Id": 262149,
					"ShortName": "BHT",
					"LongName": "Breath-hold time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 262206,
					"ShortName": "BHT Auth",
					"LongName": "Author BHT calculation",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 262175,
					"ShortName": "DCOMOD",
					"LongName": "DCOMOD",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 262192,
					"ShortName": "Device",
					"LongName": "Measuring device",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 262205,
					"ShortName": "DLCO Altc",
					"LongName": "DLCO Altitude corr",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 262194,
					"ShortName": "DLCO Avg",
					"LongName": "DLCO Average",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 262233,
					"ShortName": "DLCO StdPB",
					"LongName": "DLCO Std PB",
					"UnitShort": "mmol/(min*kPa)",
					"UnitLong": "mmol/(min*kPa)",
					"Comment": null
				}, {
					"Id": 262157,
					"ShortName": "DLCO/BSA",
					"LongName": "DLCO_SB / BSA",
					"UnitShort": "mmol/(min*kPa*m²)",
					"UnitLong": "mmol/(min*kPa*m²)",
					"Comment": null
				}, {
					"Id": 262148,
					"ShortName": "DLCO_SB",
					"LongName": "DLCO Single Breath",
					"UnitShort": "mmol/(min*kPa)",
					"UnitLong": "mmol/(min*kPa)",
					"Comment": null
				}, {
					"Id": 262158,
					"ShortName": "DLCOc/BSA",
					"LongName": "DLCOc_SB / BSA",
					"UnitShort": "mmol/(min*kPa*m²)",
					"UnitLong": "mmol/(min*kPa*m²)",
					"Comment": null
				}, {
					"Id": 262162,
					"ShortName": "DLCOcSB",
					"LongName": "DLCOc Single Breath",
					"UnitShort": "mmol/(min*kPa)",
					"UnitLong": "mmol/(min*kPa)",
					"Comment": null
				}, {
					"Id": 262171,
					"ShortName": "DV",
					"LongName": "Discard volume",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262212,
					"ShortName": "DV AUTO",
					"LongName": "Auto. Discard Volume ",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 262199,
					"ShortName": "E crit",
					"LongName": "Error ctriteria",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 262161,
					"ShortName": "ERV_SB",
					"LongName": "ERV Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262209,
					"ShortName": "ERV_SP",
					"LongName": "ERV Spirometry",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262197,
					"ShortName": "FA CH4",
					"LongName": "FA CH4",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262224,
					"ShortName": "FA CH4 end",
					"LongName": "FA CH4 end",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262156,
					"ShortName": "FA CO ",
					"LongName": "FA CO",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262167,
					"ShortName": "FA CO2   ",
					"LongName": "FA CO2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262154,
					"ShortName": "FA He ",
					"LongName": "FA He",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262183,
					"ShortName": "FA O2",
					"LongName": "FA O2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262213,
					"ShortName": "FACH4bp",
					"LongName": "FACH4 backpressure",
					"UnitShort": "ppm",
					"UnitLong": "ppm",
					"Comment": null
				}, {
					"Id": 262214,
					"ShortName": "FACObp",
					"LongName": "FACO backpressure",
					"UnitShort": "ppm",
					"UnitLong": "ppm",
					"Comment": null
				}, {
					"Id": 262210,
					"ShortName": "FHB",
					"LongName": "HB corr. factor",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 262229,
					"ShortName": "FHBCO",
					"LongName": "HBCO corr. factor",
					"UnitShort": " ",
					"UnitLong": "numerical ratio without percent",
					"Comment": null
				}, {
					"Id": 262196,
					"ShortName": "FI CH4",
					"LongName": "FI CH4",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262155,
					"ShortName": "FI CO ",
					"LongName": "FI CO",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262153,
					"ShortName": "FI He ",
					"LongName": "FI He",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262231,
					"ShortName": "FPB",
					"LongName": "PB corr. factor",
					"UnitShort": " ",
					"UnitLong": "numerical ratio without percent",
					"Comment": null
				}, {
					"Id": 262230,
					"ShortName": "FPIO2",
					"LongName": "PIO2 corr. factor",
					"UnitShort": " ",
					"UnitLong": "numerical ratio without percent",
					"Comment": null
				}, {
					"Id": 262160,
					"ShortName": "FRC%TLC",
					"LongName": "FRC % TLC Single Breath",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262159,
					"ShortName": "FRC_SB",
					"LongName": "FRC Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262220,
					"ShortName": "Grade",
					"LongName": "Quality Grade",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 262165,
					"ShortName": "Hb    ",
					"LongName": "Hb",
					"UnitShort": "g(Hb)/dL",
					"UnitLong": "Gramms Haemoglobin per 100 mL",
					"Comment": null
				}, {
					"Id": 262204,
					"ShortName": "IC_SB",
					"LongName": "IC Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262203,
					"ShortName": "IRV_SB",
					"LongName": "IRV Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262207,
					"ShortName": "KCO STPD",
					"LongName": "DLCO / VA STPD",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 262150,
					"ShortName": "KCO_SB   ",
					"LongName": "DLCO_SB / VA",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 262208,
					"ShortName": "KCOc STPD",
					"LongName": "DLCOc / VA STPD",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 262163,
					"ShortName": "KCOc_SB",
					"LongName": "DLCOc_SB / VA",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 262202,
					"ShortName": "MV_SB",
					"LongName": "MV Single Breath",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": null
				}, {
					"Id": 262184,
					"ShortName": "Pbar",
					"LongName": "Barometric Pressure",
					"UnitShort": "hPa",
					"UnitLong": "hPa",
					"Comment": null
				}, {
					"Id": 262180,
					"ShortName": "Pmean",
					"LongName": "Pmean",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 262211,
					"ShortName": "QEC AE05_SB",
					"LongName": "Quality Error Code ATS/ERS 2005 SB",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 262219,
					"ShortName": "QEC AE17 SB",
					"LongName": "Quality Error Code ATS/ERS 2017 SB",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 262190,
					"ShortName": "QEC ATS_SB",
					"LongName": "Quality Error Code ATS SB",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 262191,
					"ShortName": "QEC Mayo_SB",
					"LongName": "Quality Error Code Mayo SB",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 262215,
					"ShortName": "RefRCL",
					"LongName": "KCO (RCL) pred",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 262189,
					"ShortName": "RV%RV_PL",
					"LongName": "RV SB % RV Plethysmograph",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262228,
					"ShortName": "RV%TLC_EX_SB",
					"LongName": "RV%TLC EX Single Breath",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262146,
					"ShortName": "RV%TLC_SB",
					"LongName": "RV % TLC Single Breath",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262226,
					"ShortName": "RV_EX_SB",
					"LongName": "RV EX Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262187,
					"ShortName": "RV_PL",
					"LongName": "RV Plethysmograph",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262145,
					"ShortName": "RV_SB",
					"LongName": "RV Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262216,
					"ShortName": "SD RCL",
					"LongName": "KCO (RCL) SD",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 262172,
					"ShortName": "SV    ",
					"LongName": "Sample volume",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262186,
					"ShortName": "TEX_SB",
					"LongName": "Exp. Time Single Breath",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 262234,
					"ShortName": "TEX_SV_SB",
					"LongName": "Exp. Time SV Single Breath",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 262185,
					"ShortName": "TIN_SB",
					"LongName": "Insp. Time Single Breath",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 262198,
					"ShortName": "TLC%TLC_PL",
					"LongName": "TLC SB % TLC Plethysmograph",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262227,
					"ShortName": "TLC_EX_SB",
					"LongName": "TLC EX Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262188,
					"ShortName": "TLC_PL",
					"LongName": "TLC Plethysmograph",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262147,
					"ShortName": "TLC_SB",
					"LongName": "TLC Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262164,
					"ShortName": "Trials",
					"LongName": "Number of Trials",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 262176,
					"ShortName": "VA CO_SB",
					"LongName": "VA CO SeSQM",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262182,
					"ShortName": "VA LIN",
					"LongName": "VA LIN SeS QM",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262221,
					"ShortName": "VA_EX_SB",
					"LongName": "VA Ex Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262151,
					"ShortName": "VA_SB",
					"LongName": "VA Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262181,
					"ShortName": "VA_SQM",
					"LongName": "VA SeS QM",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262178,
					"ShortName": "VAT   ",
					"LongName": "System dead space",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 262166,
					"ShortName": "VC Trg",
					"LongName": "VCmax Target",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262195,
					"ShortName": "VCmax",
					"LongName": "VC max",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262168,
					"ShortName": "VD    ",
					"LongName": "Deadspace FA-SB",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 262223,
					"ShortName": "VD_Ex_Equip",
					"LongName": "Expiratory equipment dead space",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 262170,
					"ShortName": "VDA   ",
					"LongName": "Anatom. dead space",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 262222,
					"ShortName": "VDA_Fowler",
					"LongName": "Anatom. dead space Fowler",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 262169,
					"ShortName": "VDreal",
					"LongName": "Real dead space",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 262225,
					"ShortName": "VEX_CH4",
					"LongName": "VEX CH4",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 262179,
					"ShortName": "VEX_SB",
					"LongName": "VEX Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262173,
					"ShortName": "VIN L ",
					"LongName": "VIN limit ON/OFF",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 262174,
					"ShortName": "VIN L%",
					"LongName": "VIN limit",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262232,
					"ShortName": "VIN%VCmax",
					"LongName": "VIN % VC max",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 262152,
					"ShortName": "VIN_SB",
					"LongName": "VIN Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262200,
					"ShortName": "VT_SB",
					"LongName": "VT Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 262218,
					"ShortName": "ZSDRCL",
					"LongName": "Z Score KCO (RCL)",
					"UnitShort": "*1.0",
					"UnitLong": "Number with no unit",
					"Comment": null
				}
			]
		}, {
			"Id": 6,
			"Childs": [{
					"Id": 601,
					"Childs": [],
					"ParentId": 6,
					"ShortName": "Compl. Dyn",
					"LongName": "Dynamic Compliance",
					"Comment": null,
					"Parameters": []
				}, {
					"Id": 602,
					"Childs": [],
					"ParentId": 6,
					"ShortName": "Compl. Stat",
					"LongName": "Static Compliance",
					"Comment": null,
					"Parameters": []
				}, {
					"Id": 603,
					"Childs": [],
					"ParentId": 6,
					"ShortName": "Compl. Pos",
					"LongName": "Compliance Catheter Positioning",
					"Comment": null,
					"Parameters": []
				}
			],
			"ParentId": 0,
			"ShortName": "Comp",
			"LongName": "Compliance",
			"Comment": null,
			"Parameters": [{
					"Id": 393224,
					"ShortName": "BF    ",
					"LongName": "BF",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": null
				}, {
					"Id": 393217,
					"ShortName": "C dyn ",
					"LongName": "Compliance dyn",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 393244,
					"ShortName": "C eff ",
					"LongName": "C eff",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 393228,
					"ShortName": "C stat",
					"LongName": "Compliance st",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 393220,
					"ShortName": "Cd/FRC",
					"LongName": "C dyn / FRC",
					"UnitShort": "1/kPa",
					"UnitLong": "1/kPa",
					"Comment": null
				}, {
					"Id": 393219,
					"ShortName": "Cd/TLC",
					"LongName": "C dyn / TLC",
					"UnitShort": "1/kPa",
					"UnitLong": "1/kPa",
					"Comment": null
				}, {
					"Id": 393268,
					"ShortName": "Cdy%st",
					"LongName": "Cdyn / Cstat",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 393246,
					"ShortName": "CEXeff",
					"LongName": "C EX eff",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 393277,
					"ShortName": "Cexp*",
					"LongName": "CS Cexp fit",
					"UnitShort": "1/kPa",
					"UnitLong": "1/kPa",
					"Comment": null
				}, {
					"Id": 393245,
					"ShortName": "CINeff",
					"LongName": "C IN eff",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 393255,
					"ShortName": "Cnf1  ",
					"LongName": "Configuration 1",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 393256,
					"ShortName": "Cnf2  ",
					"LongName": "Configuration 2",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 393231,
					"ShortName": "Cs/FRC",
					"LongName": "C stat / FRC",
					"UnitShort": "1/kPa",
					"UnitLong": "1/kPa",
					"Comment": null
				}, {
					"Id": 393230,
					"ShortName": "Cs/TLC",
					"LongName": "C stat / TLC",
					"UnitShort": "1/kPa",
					"UnitLong": "1/kPa",
					"Comment": null
				}, {
					"Id": 393218,
					"ShortName": "E dyn ",
					"LongName": "Elastance dyn",
					"UnitShort": "kPa/L",
					"UnitLong": "kPa/L",
					"Comment": null
				}, {
					"Id": 393229,
					"ShortName": "E stat",
					"LongName": "Elastance st",
					"UnitShort": "kPa/L",
					"UnitLong": "kPa/L",
					"Comment": null
				}, {
					"Id": 393270,
					"ShortName": "Edyn*",
					"LongName": "Edyn fit",
					"UnitShort": "kPa/L",
					"UnitLong": "kPa/L",
					"Comment": null
				}, {
					"Id": 393284,
					"ShortName": "EsCV",
					"LongName": "Estat CV",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 393251,
					"ShortName": "FRC   ",
					"LongName": "FRC",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 393280,
					"ShortName": "FRCusd",
					"LongName": "FRC used",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 393235,
					"ShortName": "HIP   ",
					"LongName": "Half infl. pressure ",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393238,
					"ShortName": "IC    ",
					"LongName": "IC",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 393279,
					"ShortName": "ITGVac",
					"LongName": "ITGV measured",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 393262,
					"ShortName": "Mbest",
					"LongName": "Manual best selektion",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 393263,
					"ShortName": "Mslope",
					"LongName": "Manual slope ",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 393265,
					"ShortName": "NoBrth",
					"LongName": "Cdyn no of breath ",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 393264,
					"ShortName": "Org.No",
					"LongName": "Orig.test no ",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 393236,
					"ShortName": "P el50",
					"LongName": "P el.50%TLC",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393240,
					"ShortName": "P el60",
					"LongName": "P el.60%TLC",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393241,
					"ShortName": "P el70",
					"LongName": "P el.70%TLC",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393242,
					"ShortName": "P el80",
					"LongName": "P el.80%TLC",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393243,
					"ShortName": "P el90",
					"LongName": "P el.90%TLC",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393225,
					"ShortName": "P0dyn ",
					"LongName": "Pel FRC dyn",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393232,
					"ShortName": "P0stat",
					"LongName": "Pel FRC stat",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393285,
					"ShortName": "Pcough",
					"LongName": "Pcough",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393234,
					"ShortName": "Pel RV",
					"LongName": "Pel RV",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393282,
					"ShortName": "Pel100",
					"LongName": "P el.100%TLC",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393269,
					"ShortName": "PelFRC",
					"LongName": "Pel FRC",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393233,
					"ShortName": "PeTLC",
					"LongName": "PelTLC",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393266,
					"ShortName": "POBvis",
					"LongName": "POB vis",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 393286,
					"ShortName": "Psniff",
					"LongName": "Psniff",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393261,
					"ShortName": "PtdFRC",
					"LongName": "Transdia.press. FRC ",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393259,
					"ShortName": "PtdMax",
					"LongName": "Max. transdia.press",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393260,
					"ShortName": "PtdMin",
					"LongName": "Min. transdia.press",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393239,
					"ShortName": "PTL/IC",
					"LongName": "P TLC / IC",
					"UnitShort": "kPa/L",
					"UnitLong": "kPa/L",
					"Comment": null
				}, {
					"Id": 393257,
					"ShortName": "PtpMax",
					"LongName": "Max. transpl. press",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393258,
					"ShortName": "PtpMin",
					"LongName": "Min. transpl. press",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393247,
					"ShortName": "R eff ",
					"LongName": "R eff",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 393274,
					"ShortName": "Reserv",
					"LongName": "Reserved",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 393278,
					"ShortName": "Reserv",
					"LongName": "Reserved",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 393272,
					"ShortName": "Rex*",
					"LongName": "Rex fit",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 393249,
					"ShortName": "REXeff",
					"LongName": "R EX eff",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 393273,
					"ShortName": "RexF*",
					"LongName": "Rex vol fit",
					"UnitShort": "kPa/(L*L/s)",
					"UnitLong": "kPa/L*L/s",
					"Comment": null
				}, {
					"Id": 393271,
					"ShortName": "Rin*",
					"LongName": "Rin fit",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 393248,
					"ShortName": "RINeff",
					"LongName": "R IN eff",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 393267,
					"ShortName": "RtrIdx",
					"LongName": "Retr.idx.PTLC/TLC ",
					"UnitShort": "kPa/L",
					"UnitLong": "kPa/L",
					"Comment": null
				}, {
					"Id": 393253,
					"ShortName": "SW-el ",
					"LongName": "Specific W-el",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393252,
					"ShortName": "SW-vis",
					"LongName": "Specific W-visc",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 393250,
					"ShortName": "TLC   ",
					"LongName": "TLC",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 393281,
					"ShortName": "TLCusd",
					"LongName": "TLC used",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 393287,
					"ShortName": "TPcgh",
					"LongName": "Time Pcough",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 393288,
					"ShortName": "TPsnif",
					"LongName": "Time Psniff",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 393283,
					"ShortName": "V0stat",
					"LongName": "Cstat Vzero",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 393237,
					"ShortName": "VC    ",
					"LongName": "VC",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 393254,
					"ShortName": "Ver   ",
					"LongName": "Version",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 393275,
					"ShortName": "Vinf*",
					"LongName": "Cs Vinf fit",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 393223,
					"ShortName": "VT    ",
					"LongName": "VT",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 393276,
					"ShortName": "Vzero*",
					"LongName": "Cs Vzero fit",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 393222,
					"ShortName": "W el  ",
					"LongName": "W el",
					"UnitShort": "kPa*L",
					"UnitLong": "kPa*L",
					"Comment": null
				}, {
					"Id": 393221,
					"ShortName": "W vis ",
					"LongName": "W vis",
					"UnitShort": "kPa*L",
					"UnitLong": "kPa*L",
					"Comment": null
				}, {
					"Id": 393226,
					"ShortName": "WelFRC",
					"LongName": "W el FRC",
					"UnitShort": "kPa*L",
					"UnitLong": "kPa*L",
					"Comment": null
				}, {
					"Id": 393227,
					"ShortName": "WelTOT",
					"LongName": "W el TOT",
					"UnitShort": "kPa*L",
					"UnitLong": "kPa*L",
					"Comment": null
				}
			]
		}, {
			"Id": 7,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "Rocc",
			"LongName": "R Occlusion",
			"Comment": null,
			"Parameters": [{
					"Id": 458756,
					"ShortName": "BF",
					"LongName": "BF",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": null
				}, {
					"Id": 458766,
					"ShortName": "Cnf1  ",
					"LongName": "Configuration 1",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 458769,
					"ShortName": "Error",
					"LongName": "Error",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 458760,
					"ShortName": "F_ALV",
					"LongName": "F_ALV",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 458754,
					"ShortName": "G Occ ",
					"LongName": "G occ",
					"UnitShort": "(L/s)/kPa",
					"UnitLong": "(L/s)/kPa",
					"Comment": null
				}, {
					"Id": 458768,
					"ShortName": "Mode",
					"LongName": "Mode",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 458758,
					"ShortName": "MV",
					"LongName": "MV",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": null
				}, {
					"Id": 458764,
					"ShortName": "NoCiA ",
					"LongName": "No. of Curves in  Average",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 458755,
					"ShortName": "P alv ",
					"LongName": "P alv",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 458753,
					"ShortName": "R Occ ",
					"LongName": "R occ",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 458767,
					"ShortName": "RBr ",
					"LongName": "Recovery breaths",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 458759,
					"ShortName": "RoccCV",
					"LongName": "RoccCV",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 458761,
					"ShortName": "T Occ ",
					"LongName": "Occlusion time",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 458762,
					"ShortName": "T smp ",
					"LongName": "Flow sample time",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 458763,
					"ShortName": "V skip",
					"LongName": "Volume trigger",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 458765,
					"ShortName": "Vers  ",
					"LongName": "Version",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 458757,
					"ShortName": "VT",
					"LongName": "VT",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}
			]
		}, {
			"Id": 12,
			"Childs": [{
					"Id": 1201,
					"Childs": [],
					"ParentId": 12,
					"ShortName": "BxB",
					"LongName": "BxB",
					"Comment": null,
					"Parameters": [{
							"Id": 78708761,
							"ShortName": "-",
							"LongName": "-",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "reserved"
						}, {
							"Id": 78708764,
							"ShortName": "-",
							"LongName": "-",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "reserved"
						}, {
							"Id": 78708776,
							"ShortName": "-",
							"LongName": "-",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "reserved"
						}, {
							"Id": 78708814,
							"ShortName": "-",
							"LongName": "-",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "reserved"
						}, {
							"Id": 78708745,
							"ShortName": "Aux-1",
							"LongName": "Aux [1]",
							"UnitShort": "V",
							"UnitLong": "V",
							"Comment": "External Analog Channel, measured directly from the analogue signal on the connector board. No corrections are made."
						}, {
							"Id": 78708768,
							"ShortName": "Aux-2",
							"LongName": "Aux [2]",
							"UnitShort": "V",
							"UnitLong": "V",
							"Comment": "External Analog Channel, measured directly from the analogue signal on the connector board. No corrections are made."
						}, {
							"Id": 78708769,
							"ShortName": "Aux-3",
							"LongName": "Aux [3]",
							"UnitShort": "V",
							"UnitLong": "V",
							"Comment": "External Analog Channel, measured directly from the analogue signal on the connector board. No corrections are made."
						}, {
							"Id": 78708770,
							"ShortName": "Aux-4",
							"LongName": "Aux [4]",
							"UnitShort": "V",
							"UnitLong": "V",
							"Comment": "External Analog Channel, measured directly from the analogue signal on the connector board. No corrections are made."
						}, {
							"Id": 78708771,
							"ShortName": "Aux-5",
							"LongName": "Aux [5]",
							"UnitShort": "V",
							"UnitLong": "V",
							"Comment": "External Analog Channel, measured directly from the analogue signal on the connector board. No corrections are made."
						}, {
							"Id": 78708772,
							"ShortName": "Aux-6",
							"LongName": "Aux [6]",
							"UnitShort": "V",
							"UnitLong": "V",
							"Comment": "External Analog Channel, measured directly from the analogue signal on the connector board. No corrections are made."
						}, {
							"Id": 78708773,
							"ShortName": "Aux-7",
							"LongName": "Aux [7]",
							"UnitShort": "V",
							"UnitLong": "V",
							"Comment": "External Analog Channel, measured directly from the analogue signal on the connector board. No corrections are made."
						}, {
							"Id": 78708774,
							"ShortName": "Aux-8",
							"LongName": "Aux [8]",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "External Analog Channel, measured directly from the analogue signal on the connector board. No corrections are made."
						}, {
							"Id": 78708775,
							"ShortName": "Aux-9",
							"LongName": "Aux [9]",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "External Analog Channel, measured directly from the analogue signal on the connector board. No corrections are made."
						}, {
							"Id": 78708789,
							"ShortName": "BF",
							"LongName": "Breathing frequency",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": "Breathing frequency, The breathing time is measured in 10 ms counts"
						}, {
							"Id": 78708861,
							"ShortName": "BF/VTex",
							"LongName": "BF/VTex",
							"UnitShort": "1/(min*L)",
							"UnitLong": "1/(min*L)",
							"Comment": "BF/VTex"
						}, {
							"Id": 78708858,
							"ShortName": "BR FEV",
							"LongName": "Breathing reserve (FEV1, L)",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": "Breathing Reserve (based on FEV1)"
						}, {
							"Id": 78708804,
							"ShortName": "BR FEV%",
							"LongName": "Breathing reserve (FEV1) %",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Breathing reserve in % based on FEV1"
						}, {
							"Id": 78708865,
							"ShortName": "BR max",
							"LongName": "Breathing reserve (max, L)",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": "Maximal Breathing reserve (L)"
						}, {
							"Id": 78708866,
							"ShortName": "BR MVV",
							"LongName": "Breathing reserve (MVV, L)",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": "Breathing reserve based on MVV (L)"
						}, {
							"Id": 78708867,
							"ShortName": "BR MVV%",
							"LongName": "Breathing reserve (MVV, %)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Breathing reserve based on MVV (%)"
						}, {
							"Id": 78708827,
							"ShortName": "BRmax%",
							"LongName": "Breathing reserve (max) %",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Maximal breathing reserve"
						}, {
							"Id": 78708872,
							"ShortName": "CHO E%",
							"LongName": "CHO percent (Energy)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 78708871,
							"ShortName": "CHO E/d",
							"LongName": "CHO (Energy/day)",
							"UnitShort": "kcal/day",
							"UnitLong": "kcal/day",
							"Comment": null
						}, {
							"Id": 78708822,
							"ShortName": "CHO g/d",
							"LongName": "Carbohydrates (g/day)",
							"UnitShort": "g/day",
							"UnitLong": "g/day",
							"Comment": "Carbohydrate oxidation rate"
						}, {
							"Id": 78708884,
							"ShortName": "CI_c",
							"LongName": "Cardiac index - calc",
							"UnitShort": "L/(min*m²)",
							"UnitLong": "L/(min*m²)",
							"Comment": null
						}, {
							"Id": 78708838,
							"ShortName": "CircPow",
							"LongName": "CircularPower",
							"UnitShort": "Pa*L/(kg*min)",
							"UnitLong": "Pa*L/(kg*min)",
							"Comment": "Circular Power"
						}, {
							"Id": 78708852,
							"ShortName": "dFECO2",
							"LongName": "Delta FECO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "delta FCO2"
						}, {
							"Id": 78708851,
							"ShortName": "dFEO2",
							"LongName": "Delta FEO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "delta FO2"
						}, {
							"Id": 78708850,
							"ShortName": "dFEO2hc",
							"LongName": "Delta FEO2 True",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "delta FO2 – Haldane"
						}, {
							"Id": 78708845,
							"ShortName": "dFETCO2",
							"LongName": "Delta FETCO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "delta FETCO2"
						}, {
							"Id": 78708844,
							"ShortName": "dFETO2",
							"LongName": "Delta FETO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "delta FETO2"
						}, {
							"Id": 78708803,
							"ShortName": "DI FEV",
							"LongName": "Dyspnea index (FEV1)",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "Dispneu Index (based on FEV1)"
						}, {
							"Id": 78708781,
							"ShortName": "Dis Br",
							"LongName": "Disabled Breath",
							"UnitShort": "num",
							"UnitLong": "number",
							"Comment": "Disabled Breath"
						}, {
							"Id": 78708756,
							"ShortName": "Dist",
							"LongName": "Distance",
							"UnitShort": "m",
							"UnitLong": "Meter",
							"Comment": "Treadmill distance, direct measurement of the analogue treadmill signal."
						}, {
							"Id": 78708839,
							"ShortName": "DProd",
							"LongName": "Double Product",
							"UnitShort": "Pa/min",
							"UnitLong": "PASCAL_PER_MINUTE",
							"Comment": "Double product"
						}, {
							"Id": 78708880,
							"ShortName": "E/ETCO2",
							"LongName": "PECO2 / PETCO2",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 78708879,
							"ShortName": "EE/BSA",
							"LongName": "EE / BSA (per day)",
							"UnitShort": "(kcal/day)/(m*m)",
							"UnitLong": "(kcal/day)/(m*m)",
							"Comment": null
						}, {
							"Id": 78708878,
							"ShortName": "EE/CO2",
							"LongName": "EE / V'CO2 (per day)",
							"UnitShort": "(kcal/day)/(L/min)",
							"UnitLong": "(kcal/day)/(L/min)",
							"Comment": null
						}, {
							"Id": 78708810,
							"ShortName": "EE/d",
							"LongName": "Energy expenditure (per/day)",
							"UnitShort": "kcal/day",
							"UnitLong": "kcal/day",
							"Comment": "Energy Expenditure"
						}, {
							"Id": 78708870,
							"ShortName": "EE/kg",
							"LongName": "EE/Bodyweight (per day)",
							"UnitShort": "(kcal/day)/kg",
							"UnitLong": "(kcal/day)/kg",
							"Comment": null
						}, {
							"Id": 78708755,
							"ShortName": "Elev",
							"LongName": "Elevation",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Treadmill elevation, direct measurement of the analogue treadmill signal or with a serial interface to an external treadmill device."
						}, {
							"Id": 78708857,
							"ShortName": "End Time",
							"LongName": "End Time",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": "End Time – no BxB parameter"
						}, {
							"Id": 78708796,
							"ShortName": "EqCO2",
							"LongName": "Breathing equiv. CO2",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "Breathing Equivalent for CO2 (BTPS/STPD) "
						}, {
							"Id": 78708797,
							"ShortName": "EqO2",
							"LongName": "Breathing equiv. O2",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "Breathing Equivalent for O2 (BTPS/STPD) "
						}, {
							"Id": 78708767,
							"ShortName": "ES",
							"LongName": "Ectopic beats",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "Etopic Beats, Read from CardiSoft program of GE through shared file."
						}, {
							"Id": 78708821,
							"ShortName": "EV'CO2",
							"LongName": "Excess-V'CO2",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": "Excess CO2 production "
						}, {
							"Id": 78708853,
							"ShortName": "Evt",
							"LongName": "Events",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "This parameter is used to collect all events for displaying the event type in the time table and also needed for filtering the table to certain events."
						}, {
							"Id": 78708874,
							"ShortName": "FAT E%",
							"LongName": "FAT percent (Energy)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 78708873,
							"ShortName": "FAT E/d",
							"LongName": "FAT (Energy/day)",
							"UnitShort": "kcal/day",
							"UnitLong": "kcal/day",
							"Comment": null
						}, {
							"Id": 78708823,
							"ShortName": "FAT g/d",
							"LongName": "Fat (g/day)",
							"UnitShort": "g/day",
							"UnitLong": "g/day",
							"Comment": "Fat oxidation rate"
						}, {
							"Id": 78708757,
							"ShortName": "FECO2",
							"LongName": "FECO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Mixed expired CO2 fraction, for the open mode the value is calculated each breath with the results of the physiological dead space measurement."
						}, {
							"Id": 78708759,
							"ShortName": "FEO2",
							"LongName": "FEO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Mixed expired O2 fraction, For the open mode the value is calculated each breath with the results of the physiological dead space measurement."
						}, {
							"Id": 78708758,
							"ShortName": "FETCO2",
							"LongName": "FETCO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "End tidal CO2 fraction, For the open mode the values are measured each breath"
						}, {
							"Id": 78708760,
							"ShortName": "FETO2",
							"LongName": "FETO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "End Tidal O2 fraction, For the open mode the values are measured each breath."
						}, {
							"Id": 78708748,
							"ShortName": "FICO2",
							"LongName": "FICO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Inspiratory Carbon dioxide, in the open mode FICO2 is measured each breath cycle."
						}, {
							"Id": 78708749,
							"ShortName": "FIO2",
							"LongName": "FIO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Inspiratory Oxygen, in the open mode FIO2 is measured each breath cycle."
						}, {
							"Id": 78708753,
							"ShortName": "HR",
							"LongName": "Heart rate",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": "Heart rate, direct measurement of the pulse output or scope signal of an external ECG monitor. With an serial interface to an external ECG device. Through an interface with an ECG application."
						}, {
							"Id": 78708813,
							"ShortName": "HRR (B)",
							"LongName": "Heart rate reserve (B/min)",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": "Heart rate reserve"
						}, {
							"Id": 78708828,
							"ShortName": "HRR%",
							"LongName": "Heart rate reserve (%)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Heart rate reserve"
						}, {
							"Id": 78708826,
							"ShortName": "HRresp",
							"LongName": "HR(V'O2) response",
							"UnitShort": "1/L",
							"UnitLong": "1/L",
							"Comment": "Heart rate response "
						}, {
							"Id": 78708816,
							"ShortName": "HRslkg",
							"LongName": "HR (V'O2/kg)  slope",
							"UnitShort": "1/(L/kg)",
							"UnitLong": "1/(L/kg)",
							"Comment": "Heart rate slope"
						}, {
							"Id": 78708883,
							"ShortName": "HRslo",
							"LongName": "HR (V'O2) slope",
							"UnitShort": "1/L",
							"UnitLong": "1/L",
							"Comment": null
						}, {
							"Id": 78708856,
							"ShortName": "Interval",
							"LongName": "Interval ",
							"UnitShort": "num",
							"UnitLong": "number",
							"Comment": "Interval – no BxB parameter"
						}, {
							"Id": 78708817,
							"ShortName": "Lac",
							"LongName": "Lactate",
							"UnitShort": "mmol/L",
							"UnitLong": "mmol/L",
							"Comment": "Lactate, Original lactate value is stored into the Blood gas data set. This parameter is either used for an exponential lactate curve or as spare."
						}, {
							"Id": 78708747,
							"ShortName": "Load",
							"LongName": "Load",
							"UnitShort": "W",
							"UnitLong": "Watt",
							"Comment": "Power, measured directly from the analogue signal on the connector board or via the serial interface with the bicycle."
						}, {
							"Id": 78708886,
							"ShortName": "Load_i",
							"LongName": "Load interpolated",
							"UnitShort": "W",
							"UnitLong": "Watt",
							"Comment": null
						}, {
							"Id": 78708892,
							"ShortName": "Log (V'E)",
							"LongName": "Log (Ventilation [BTPS])",
							"UnitShort": "Log(L/min)",
							"UnitLong": "LOG OF LITER PER MINUTE",
							"Comment": "Log (Minute ventilation in BTPS)"
						}, {
							"Id": 78708785,
							"ShortName": "METc",
							"LongName": "Metabolic units calculated",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "Metabolic equivalent of task calculated"
						}, {
							"Id": 78708802,
							"ShortName": "METS",
							"LongName": "Metabolic units",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "Metabolic units"
						}, {
							"Id": 78708891,
							"ShortName": "MRT",
							"LongName": "Mean Response time",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 78708890,
							"ShortName": "NETV'O2",
							"LongName": "Net V'O2",
							"UnitShort": "(L/min)/kg",
							"UnitLong": "(L/min)/kg",
							"Comment": null
						}, {
							"Id": 78708869,
							"ShortName": "npRER",
							"LongName": "non Protein RER",
							"UnitShort": "num",
							"UnitLong": "number",
							"Comment": null
						}, {
							"Id": 78708887,
							"ShortName": "O2pslo",
							"LongName": "O2pulse (Watt) slope",
							"UnitShort": "L/W",
							"UnitLong": "L/Watt",
							"Comment": null
						}, {
							"Id": 78708801,
							"ShortName": "O2pulse",
							"LongName": "O2 pulse",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Oxygen uptake / Heart rate"
						}, {
							"Id": 78708842,
							"ShortName": "PACE",
							"LongName": "PACE",
							"UnitShort": "s/m",
							"UnitLong": "s/m",
							"Comment": "PACE"
						}, {
							"Id": 78708782,
							"ShortName": "PBar",
							"LongName": "Barometric pressure",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": "Barometric Pressure"
						}, {
							"Id": 78708763,
							"ShortName": "Pdia",
							"LongName": "Blood press.-dia",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": "Diastolic blood pressure, Direct measurement of the analogue diastolic blood pressure signal or with a serial interface to an external blood pressure device."
						}, {
							"Id": 78708792,
							"ShortName": "PECO2",
							"LongName": "PECO2",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": "Mixed expired CO2 pressure"
						}, {
							"Id": 78708794,
							"ShortName": "PEO2",
							"LongName": "PEO2",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": "Mixed expired O2 pressure "
						}, {
							"Id": 78708793,
							"ShortName": "PETCO2",
							"LongName": "PETCO2",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": "End Tidal CO2 pressure"
						}, {
							"Id": 78708795,
							"ShortName": "PETO2",
							"LongName": "PETO2",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": "End Tidal O2 pressure "
						}, {
							"Id": 78708868,
							"ShortName": "Pmean",
							"LongName": "Blood press.-mean",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": "Mean blood pressure"
						}, {
							"Id": 78708876,
							"ShortName": "PRO E%",
							"LongName": "PRO percent (Energy)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 78708875,
							"ShortName": "PRO E/d",
							"LongName": "PRO (Energy/day)",
							"UnitShort": "kcal/day",
							"UnitLong": "kcal/day",
							"Comment": null
						}, {
							"Id": 78708824,
							"ShortName": "PRO g/d",
							"LongName": "Protein (g/day)",
							"UnitShort": "g/day",
							"UnitLong": "g/day",
							"Comment": "Protein oxidation rate"
						}, {
							"Id": 78708762,
							"ShortName": "Psys",
							"LongName": "Blood press.-sys",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": "Systolic blood pressure, Direct measurement of the analogue systolic blood pressure signal or with a serial interface to an external blood pressure device."
						}, {
							"Id": 78708862,
							"ShortName": "PulseP",
							"LongName": "Pulse Pressure",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": "Pulse Pressure"
						}, {
							"Id": 78708786,
							"ShortName": "QSPO2",
							"LongName": "Quality Signal SPO2",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "Quality of SPO2 signal is available with internal SPO2 sensor (XPOD) only (0-3)"
						}, {
							"Id": 78708830,
							"ShortName": "Qtc",
							"LongName": "Cardiac Output calc",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": "Cardiac Output calculated"
						}, {
							"Id": 78708791,
							"ShortName": "RER",
							"LongName": "Respir. exchange ratio",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "Respiratory exchange ratio"
						}, {
							"Id": 78708834,
							"ShortName": "reserved",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "reserved"
						}, {
							"Id": 78708843,
							"ShortName": "reserved",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": "reserved)"
						}, {
							"Id": 78708752,
							"ShortName": "RHex",
							"LongName": "Rel. Expiratory Humidity",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Relative expiratory humidity, depends on the measurement mode: = 100 % in BxB and mixing chamber, = measured in hood or ventilator mode with bias flow."
						}, {
							"Id": 78708779,
							"ShortName": "RPE Dys",
							"LongName": "Perc. Exertion Dyspnea",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Perceived Exertion dyspnea, Entered by the user, online or offline"
						}, {
							"Id": 78708778,
							"ShortName": "RPE Fat",
							"LongName": "Perc. Exertion Fatigue",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Perceived Exertion fatigue, Entered by the user, online or offline"
						}, {
							"Id": 78708780,
							"ShortName": "RPE Tot",
							"LongName": "Perc. ExtertionTotal",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Perceived Exertion total, Entered by the user, online or offline"
						}, {
							"Id": 78708746,
							"ShortName": "RPM",
							"LongName": "Rotations/min",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": "Rotation per minute, measured directly from the analogue signal on the connector board or through a serial interface with the bicycle. No corrections are made."
						}, {
							"Id": 78708754,
							"ShortName": "Speed",
							"LongName": "Speed",
							"UnitShort": "m/s",
							"UnitLong": "m/s",
							"Comment": "Treadmill speed, direct measurement of the analogue treadmill signal or with a serial interface to an external treadmill device."
						}, {
							"Id": 78708750,
							"ShortName": "SpO2",
							"LongName": "O2 saturation",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Oxygen saturation, measured direct either with the analog pulse oximeter or with a serial interface to an external pulse oximeter."
						}, {
							"Id": 78708893,
							"ShortName": "SS-Idx",
							"LongName": "Steady State Index",
							"UnitShort": "num",
							"UnitLong": "number",
							"Comment": "Index of steady state"
						}, {
							"Id": 78708765,
							"ShortName": "St Lev",
							"LongName": "St Level",
							"UnitShort": "V",
							"UnitLong": "V",
							"Comment": "Read from CardioSoft program of GE through shared file."
						}, {
							"Id": 78708766,
							"ShortName": "St Slo",
							"LongName": "ST Slope",
							"UnitShort": "V/s",
							"UnitLong": "V/s",
							"Comment": "Read from CardioSoft program of GE through shared file."
						}, {
							"Id": 78708777,
							"ShortName": "Stage",
							"LongName": "Stage",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": "Indicates the stage within the test phase of an exercise test. The stage is normally used in combination with steady state workload profiles. This stage parameter will be zero for ramping profiles."
						}, {
							"Id": 78708855,
							"ShortName": "SUM EE",
							"LongName": "Sum EE (Energy)",
							"UnitShort": "kcal",
							"UnitLong": "1000 calories",
							"Comment": "Total Sum EE"
						}, {
							"Id": 78708877,
							"ShortName": "SUM g",
							"LongName": "SUM (gramm)",
							"UnitShort": "g",
							"UnitLong": "Gramms",
							"Comment": null
						}, {
							"Id": 78708854,
							"ShortName": "SUM V'O2",
							"LongName": "Sum V'O2",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Total Sum V’O2"
						}, {
							"Id": 78708831,
							"ShortName": "SVc",
							"LongName": "Stroke volume calc",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Stroke volume calculated"
						}, {
							"Id": 78708885,
							"ShortName": "SVI_c",
							"LongName": "Stroke volume index - calc",
							"UnitShort": "L/m²",
							"UnitLong": "L/m²",
							"Comment": null
						}, {
							"Id": 78708783,
							"ShortName": "Tamb",
							"LongName": "Temperature ambient",
							"UnitShort": "°C",
							"UnitLong": "Celsius",
							"Comment": "Temperature ambient"
						}, {
							"Id": 78708894,
							"ShortName": "Tau",
							"LongName": "Time constant (Tau)",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": "Time, the resolution is 100 ms"
						}, {
							"Id": 78708751,
							"ShortName": "TBody",
							"LongName": "Body temperature",
							"UnitShort": "°C",
							"UnitLong": "Celsius",
							"Comment": "Skin temperature, measured direct from connector board with a YSI 400 series probe. Then temperature will be measured on a person. Supported by the Oxycon Pro only."
						}, {
							"Id": 78708743,
							"ShortName": "t-ex",
							"LongName": "Time expiration",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": "Expiration time , the breathing time is measured in 10 ms counts"
						}, {
							"Id": 78708863,
							"ShortName": "Texp",
							"LongName": "Temperature expiratory",
							"UnitShort": "°C",
							"UnitLong": "Celsius",
							"Comment": "Expiratory temperature"
						}, {
							"Id": 78708864,
							"ShortName": "ti/te",
							"LongName": "t-in/t-ex",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Time ti/te"
						}, {
							"Id": 78708811,
							"ShortName": "ti/ttot",
							"LongName": "t-in/t-tot",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Inspiratory / total breath time"
						}, {
							"Id": 78708737,
							"ShortName": "Time",
							"LongName": "Time",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": "Time, the resolution is 10 ms"
						}, {
							"Id": 78708812,
							"ShortName": "t-in",
							"LongName": "Time inspiration",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": "inspiration time"
						}, {
							"Id": 78708784,
							"ShortName": "Tmix",
							"LongName": "Temperature Mix. Chamber",
							"UnitShort": "°C",
							"UnitLong": "Celsius",
							"Comment": "Temperature mixing chamber"
						}, {
							"Id": 78708825,
							"ShortName": "Tot g/d",
							"LongName": "Total consumption (g/day)",
							"UnitShort": "g/day",
							"UnitLong": "g/day",
							"Comment": "Total energy consumption"
						}, {
							"Id": 78708744,
							"ShortName": "t-ph",
							"LongName": "Time phase",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": "Phase timer, elapsed time of exercise phase. Each new workload value of a steady state protocol the phase is set to zero. Also at change of the four different phases: rest, reference, test and recovery."
						}, {
							"Id": 78708742,
							"ShortName": "t-tot",
							"LongName": "Time total breath",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": "Total time of one breath. The resolution of the time is 10 ms."
						}, {
							"Id": 78708859,
							"ShortName": "V'A",
							"LongName": "Alveolar ventilation",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": "Alveolar ventilation"
						}, {
							"Id": 78708787,
							"ShortName": "V'CO2",
							"LongName": "V'CO2 production",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": "Carbon Dioxide production in STPD."
						}, {
							"Id": 78708840,
							"ShortName": "V'CO2/kg",
							"LongName": "V'CO2 production/BW",
							"UnitShort": "(L/min)/kg",
							"UnitLong": "(L/min)/kg",
							"Comment": "CO2 production / BW"
						}, {
							"Id": 78708808,
							"ShortName": "VDc",
							"LongName": "Dead space-calc",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Dead space calculated"
						}, {
							"Id": 78708805,
							"ShortName": "VDc/VT",
							"LongName": "Rel. dead space-calc",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Relative Dead space calculated"
						}, {
							"Id": 78708833,
							"ShortName": "VDe",
							"LongName": "Dead space-et",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Dead space endtidal"
						}, {
							"Id": 78708807,
							"ShortName": "VDe/VT",
							"LongName": "Rel. dead space-et",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Relative Dead space endtidal"
						}, {
							"Id": 78708809,
							"ShortName": "VDf",
							"LongName": "Dead space-phys",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Dead space phys"
						}, {
							"Id": 78708806,
							"ShortName": "VDf/VT",
							"LongName": "Rel. dead space-phys",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Relative Dead space phys"
						}, {
							"Id": 78708790,
							"ShortName": "V'E",
							"LongName": "Ventilation [BTPS]",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": "Minute ventilation in BTPS"
						}, {
							"Id": 78708836,
							"ShortName": "V'E [AT]",
							"LongName": "Ventilation [ATPS]",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": "V’E in ATPS, V’ E measured and not corrected for temperature, pressure and humidity"
						}, {
							"Id": 78708835,
							"ShortName": "V'E [ST]",
							"LongName": "Ventilation [STPD]",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": "V’E in STPD"
						}, {
							"Id": 78708849,
							"ShortName": "V'E%MVV",
							"LongName": "Dispnoea index (MVV)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Dyspnea index in respect of MVV"
						}, {
							"Id": 78708841,
							"ShortName": "V'E/kg",
							"LongName": "Ventilation/BW",
							"UnitShort": "(L/min)/kg",
							"UnitLong": "(L/min)/kg",
							"Comment": "Ventilation / BW"
						}, {
							"Id": 78708881,
							"ShortName": "V'Eslo",
							"LongName": "V'E (V'CO2) slope",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 78708788,
							"ShortName": "V'O2",
							"LongName": "V'O2 Uptake",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": "Oxygen consumption in STPD."
						}, {
							"Id": 78708815,
							"ShortName": "V'O2%max",
							"LongName": "V'O2%V'O2max",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "V’O2 over V’O2 max"
						}, {
							"Id": 78708798,
							"ShortName": "V'O2/kg",
							"LongName": "V'O2 uptake/BW",
							"UnitShort": "(L/min)/kg",
							"UnitLong": "(L/min)/kg",
							"Comment": "Oxygen uptake / BW"
						}, {
							"Id": 78708889,
							"ShortName": "V'O2/kg2/3",
							"LongName": "V'O2 uptake/BW^(2/3)",
							"UnitShort": "(L/min)/kg",
							"UnitLong": "(L/min)/kg",
							"Comment": null
						}, {
							"Id": 78708799,
							"ShortName": "V'O2/LW",
							"LongName": "V'O2 uptake/LBW",
							"UnitShort": "(L/min)/kg",
							"UnitLong": "(L/min)/kg",
							"Comment": "Oxygen uptake  / LBW "
						}, {
							"Id": 78708800,
							"ShortName": "V'O2/PW",
							"LongName": "V'O2 uptake/PBW",
							"UnitShort": "(L/min)/kg",
							"UnitLong": "(L/min)/kg",
							"Comment": "Oxygen uptake / PBW"
						}, {
							"Id": 78708837,
							"ShortName": "V'O2c",
							"LongName": "V'O2 calculated",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": "V’O2 Calculated according to Wassermann "
						}, {
							"Id": 78708819,
							"ShortName": "V'O2deb",
							"LongName": "V'O2 debt",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Oxygen debt"
						}, {
							"Id": 78708820,
							"ShortName": "V'O2def",
							"LongName": "V'O2 deficit",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Oxygen deficit"
						}, {
							"Id": 78708882,
							"ShortName": "V'O2kgsl",
							"LongName": "V'O2/kg (HR) slope",
							"UnitShort": "L/kg",
							"UnitLong": "L/kg",
							"Comment": null
						}, {
							"Id": 78708818,
							"ShortName": "V'O2slo",
							"LongName": "V'O2 (Watt) slope",
							"UnitShort": "(L/min)/W",
							"UnitLong": "(L/min)/W",
							"Comment": "V’O2/Watt - slope"
						}, {
							"Id": 78708738,
							"ShortName": "VolCO2",
							"LongName": "VolCO2",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Volume CO2 measured in this breath, STPD and Haldane corrected"
						}, {
							"Id": 78708739,
							"ShortName": "VolO2 ",
							"LongName": "VolO2",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Volume O2 measured in this breath, STPD and Haldane corrected"
						}, {
							"Id": 78708888,
							"ShortName": "Vslope",
							"LongName": "Vslope",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 78708740,
							"ShortName": "VTex",
							"LongName": "Tidal volume-ex",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Expired volume"
						}, {
							"Id": 78708846,
							"ShortName": "VTex [ST]",
							"LongName": "Tidal Volume-ex [STPD]",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "VTex STPD"
						}, {
							"Id": 78708848,
							"ShortName": "VTex%VC",
							"LongName": "Tidal Volume-ex % VC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": "Tidal volume compared to VCin"
						}, {
							"Id": 78708741,
							"ShortName": "VTin",
							"LongName": "Tidal volume-in",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "Inspired volume"
						}, {
							"Id": 78708847,
							"ShortName": "VTin [ST]",
							"LongName": "Tidal Volume-in [STPD]",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": "VTin STPD"
						}, {
							"Id": 78708860,
							"ShortName": "VTin/ti",
							"LongName": "VTin/t-in",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": "VTin/Ti"
						}, {
							"Id": 78708832,
							"ShortName": "W/kg",
							"LongName": "Rel. workload Watt/BW",
							"UnitShort": "W/kg",
							"UnitLong": "W/kg",
							"Comment": "Workload / BW "
						}, {
							"Id": 78708829,
							"ShortName": "Wtot",
							"LongName": "Total work",
							"UnitShort": "W*h",
							"UnitLong": "W*h",
							"Comment": "Total work"
						}
					]
				}, {
					"Id": 1202,
					"Childs": [],
					"ParentId": 12,
					"ShortName": "ExDiffusion",
					"LongName": "ExDiffusion",
					"Comment": null,
					"Parameters": [{
							"Id": 78774276,
							"ShortName": "CO_Diff",
							"LongName": "Cardiac Output",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 78774274,
							"ShortName": "DLCO",
							"LongName": "DCLO",
							"UnitShort": "mmol/(min*kPa)",
							"UnitLong": "mmol/(min*kPa)",
							"Comment": null
						}, {
							"Id": 78774273,
							"ShortName": "Time",
							"LongName": "Time",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 78774275,
							"ShortName": "VA",
							"LongName": "Alveolar Volume",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}, {
					"Id": 1203,
					"Childs": [],
					"ParentId": 12,
					"ShortName": "C.O.",
					"LongName": "Cardiac Output",
					"Comment": null,
					"Parameters": [{
							"Id": 78839829,
							"ShortName": "BagCO2",
							"LongName": "RB bag FICO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 78839830,
							"ShortName": "BagO2",
							"LongName": "RB bag FIO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 78839831,
							"ShortName": "CI",
							"LongName": "Cardiac index",
							"UnitShort": "L/(min*m²)",
							"UnitLong": "L/(min*m²)",
							"Comment": null
						}, {
							"Id": 78839820,
							"ShortName": "FEQCO2",
							"LongName": "CO2 equil. fraction",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 78839827,
							"ShortName": "Hb",
							"LongName": "Hemoglobin",
							"UnitShort": "mmol/L",
							"UnitLong": "mmol/L",
							"Comment": null
						}, {
							"Id": 78839825,
							"ShortName": "HR",
							"LongName": "Heart rate",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 78839817,
							"ShortName": "PaCO2",
							"LongName": "PaCO2",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": null
						}, {
							"Id": 78839812,
							"ShortName": "PaCO2c",
							"LongName": "PaCO2-calc",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": null
						}, {
							"Id": 78839815,
							"ShortName": "Pbar",
							"LongName": "Barometric pressure",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 78839816,
							"ShortName": "PECO2",
							"LongName": "PECO2",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": null
						}, {
							"Id": 78839818,
							"ShortName": "PEQCO2",
							"LongName": "CO2 equil. pressure",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": null
						}, {
							"Id": 78839811,
							"ShortName": "PETCO2",
							"LongName": "PETCO2",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": null
						}, {
							"Id": 78839819,
							"ShortName": "PvCO2",
							"LongName": "PvCO2",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": null
						}, {
							"Id": 78839822,
							"ShortName": "Qt",
							"LongName": "Cardiac output",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 78839826,
							"ShortName": "QtPred",
							"LongName": "Qt Pred",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 78839828,
							"ShortName": "SaO2",
							"LongName": "O2 saturation",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 78839824,
							"ShortName": "SV",
							"LongName": "Stroke volume",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 78839832,
							"ShortName": "SVI",
							"LongName": "Stroke vol. index",
							"UnitShort": "L/m²",
							"UnitLong": "L/m²",
							"Comment": null
						}, {
							"Id": 78839809,
							"ShortName": "Time",
							"LongName": "Time",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 78839821,
							"ShortName": "vaCCO2",
							"LongName": "C(v-a)CO2",
							"UnitShort": "Vol%",
							"UnitLong": "Vol%",
							"Comment": null
						}, {
							"Id": 78839823,
							"ShortName": "V'CO2",
							"LongName": "CO2 production",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 78839813,
							"ShortName": "VDc",
							"LongName": "Dead space-calc",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 78839814,
							"ShortName": "VDc/VT",
							"LongName": "Rel. dead sp.-calc",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 78839810,
							"ShortName": "VT",
							"LongName": "Tidal volume",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}, {
					"Id": 1204,
					"Childs": [],
					"ParentId": 12,
					"ShortName": "EFVL",
					"LongName": "EFVL",
					"Comment": null,
					"Parameters": [{
							"Id": 78905355,
							"ShortName": "Calculated",
							"LongName": "Param-Set Calculated",
							"UnitShort": "*1.0",
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 78905357,
							"ShortName": "EELV",
							"LongName": "End-exp-lung volume",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 78905371,
							"ShortName": "EEV%RV",
							"LongName": "EELV%RV",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 78905369,
							"ShortName": "EEV%TC",
							"LongName": "EEV%TC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 78905376,
							"ShortName": "EFR",
							"LongName": "EFR",
							"UnitShort": "L*L/s",
							"UnitLong": "L*L/s",
							"Comment": null
						}, {
							"Id": 78905356,
							"ShortName": "EILV",
							"LongName": "End-isp-lung volume",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 78905370,
							"ShortName": "EIV%TC",
							"LongName": "EILV%TLC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 78905359,
							"ShortName": "IB FVC",
							"LongName": "I-FVC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 78905358,
							"ShortName": "IB PEF",
							"LongName": "I-PEF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 78905367,
							"ShortName": "IC%EEV",
							"LongName": "IC%EEV",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 78905366,
							"ShortName": "ICdyn",
							"LongName": "IB ICdyn",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 78905365,
							"ShortName": "I-F1%F",
							"LongName": "IB FEV1%FVC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 78905360,
							"ShortName": "I-FEV1",
							"LongName": "IB FEV1",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 78905375,
							"ShortName": "IFR",
							"LongName": "IFR",
							"UnitShort": "L*L/s",
							"UnitLong": "L*L/s",
							"Comment": null
						}, {
							"Id": 78905363,
							"ShortName": "I-MEF25",
							"LongName": "IB MEF25",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 78905362,
							"ShortName": "I-MEF50",
							"LongName": "IB MEF50",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 78905361,
							"ShortName": "I-MEF75",
							"LongName": "IB MEF75",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 78905364,
							"ShortName": "I-MIF50",
							"LongName": "IB MIF50",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 78905346,
							"ShortName": "Index",
							"LongName": "Breath Index",
							"UnitShort": "num",
							"UnitLong": "number",
							"Comment": null
						}, {
							"Id": 78905368,
							"ShortName": "IRVdyn",
							"LongName": "IB IRVdyn",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 78905378,
							"ShortName": "MeanEF",
							"LongName": "Mean expiratory flow",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 78905354,
							"ShortName": "Min Vol",
							"LongName": "Curve min. Volume",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 78905349,
							"ShortName": "Res1",
							"LongName": "Reserved 1",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 78905350,
							"ShortName": "Res2",
							"LongName": "Reserved 2",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 78905351,
							"ShortName": "Res3",
							"LongName": "Reserved 3",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 78905352,
							"ShortName": "Res4",
							"LongName": "Reserved 4",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 78905353,
							"ShortName": "Res5",
							"LongName": "Reserved 5",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 78905347,
							"ShortName": "Start",
							"LongName": "Start Index",
							"UnitShort": "num",
							"UnitLong": "number",
							"Comment": null
						}, {
							"Id": 78905348,
							"ShortName": "Stop",
							"LongName": "Stop Index",
							"UnitShort": "num",
							"UnitLong": "number",
							"Comment": null
						}, {
							"Id": 78905345,
							"ShortName": "t",
							"LongName": "Time",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 78905374,
							"ShortName": "Vf%Vt",
							"LongName": "Vfl/Vt",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 78905373,
							"ShortName": "Vfl",
							"LongName": "Vfl",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 78905377,
							"ShortName": "VolOffset",
							"LongName": "Volume offset curve",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 78905372,
							"ShortName": "VTe%IC",
							"LongName": "VTex%IC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}
					]
				}, {
					"Id": 1206,
					"Childs": [],
					"ParentId": 12,
					"ShortName": "CurveFit",
					"LongName": "Curve Fitting",
					"Comment": null,
					"Parameters": [{
							"Id": 79036434,
							"ShortName": "BxB-Av",
							"LongName": "BxB averaging method",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 79036435,
							"ShortName": "BxB-Av-val",
							"LongName": "BxB average value",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 79036427,
							"ShortName": "Coef A",
							"LongName": "Coefficient A",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 79036428,
							"ShortName": "Coef B",
							"LongName": "Coefficient B",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 79036429,
							"ShortName": "Coef C",
							"LongName": "Coefficient C",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 79036430,
							"ShortName": "Coef D",
							"LongName": "Coefficient D",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 79036419,
							"ShortName": "Form.ID",
							"LongName": "Formula ID",
							"UnitShort": "*1.0",
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 79036418,
							"ShortName": "ID no.",
							"LongName": "ID number",
							"UnitShort": "num",
							"UnitLong": "number",
							"Comment": null
						}, {
							"Id": 79036426,
							"ShortName": "Info",
							"LongName": "Info",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 79036423,
							"ShortName": "Is cal.",
							"LongName": "Is calculated",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 79036422,
							"ShortName": "Is sel.",
							"LongName": "Is selected",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 79036420,
							"ShortName": "Para.xID",
							"LongName": "Parameter ID x",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 79036421,
							"ShortName": "Para.yID",
							"LongName": "Parameter ID y",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 79036433,
							"ShortName": "R",
							"LongName": "Covariance",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 79036431,
							"ShortName": "Result",
							"LongName": "Result",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 79036432,
							"ShortName": "SD",
							"LongName": "Standard deviation",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 79036425,
							"ShortName": "tEnd",
							"LongName": "Time Range end",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 79036424,
							"ShortName": "tStart",
							"LongName": "Time Range start",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 79036417,
							"ShortName": "Version",
							"LongName": "Version",
							"UnitShort": "V",
							"UnitLong": "Version number",
							"Comment": null
						}
					]
				}, {
					"Id": 1210,
					"Childs": [],
					"ParentId": 12,
					"ShortName": "P 0.1",
					"LongName": "P 0.1",
					"Comment": null,
					"Parameters": [{
							"Id": 79298562,
							"ShortName": "P0.1",
							"LongName": "P 0.1",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": null
						}, {
							"Id": 79298561,
							"ShortName": "Time",
							"LongName": "Time",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}
					]
				}, {
					"Id": 1211,
					"Childs": [],
					"ParentId": 12,
					"ShortName": "Poes",
					"LongName": "Poes",
					"Comment": null,
					"Parameters": [{
							"Id": 79364111,
							"ShortName": "Cdyn",
							"LongName": "Compliance dyn",
							"UnitShort": "L/Pa",
							"UnitLong": "L/Pa",
							"Comment": null
						}, {
							"Id": 79364099,
							"ShortName": "dP",
							"LongName": "Pressure change",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": null
						}, {
							"Id": 79364098,
							"ShortName": "dV",
							"LongName": "Volume change",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 79364112,
							"ShortName": "Edyn",
							"LongName": "Elastance dyn",
							"UnitShort": "Pa/L",
							"UnitLong": "Pa/L",
							"Comment": null
						}, {
							"Id": 79364116,
							"ShortName": "GEXeff",
							"LongName": "G EX eff",
							"UnitShort": "L/(Pa*s)",
							"UnitLong": "L/(Pa*s)",
							"Comment": null
						}, {
							"Id": 79364118,
							"ShortName": "GINeff",
							"LongName": "G IN eff",
							"UnitShort": "L/(Pa*s)",
							"UnitLong": "L/(Pa*s)",
							"Comment": null
						}, {
							"Id": 79364101,
							"ShortName": "P0dyn",
							"LongName": "Pel FRC dyn",
							"UnitShort": "Pa",
							"UnitLong": "Pa",
							"Comment": null
						}, {
							"Id": 79364104,
							"ShortName": "Res1",
							"LongName": "Reserved 1",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 79364105,
							"ShortName": "Res2",
							"LongName": "Reserved 2",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 79364106,
							"ShortName": "Res3",
							"LongName": "Reserved 3",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 79364107,
							"ShortName": "Res4",
							"LongName": "Reserved 4",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 79364108,
							"ShortName": "Res5",
							"LongName": "Reserved 5",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 79364109,
							"ShortName": "Res6",
							"LongName": "Reserved 6",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 79364110,
							"ShortName": "Res7",
							"LongName": "Reserved 7",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 79364102,
							"ShortName": "REXeff",
							"LongName": "R EX eff",
							"UnitShort": "Pa*s/L",
							"UnitLong": "Pa*s/L",
							"Comment": null
						}, {
							"Id": 79364103,
							"ShortName": "RINeff",
							"LongName": "R IN eff",
							"UnitShort": "Pa*s/L",
							"UnitLong": "Pa*s/L",
							"Comment": null
						}, {
							"Id": 79364117,
							"ShortName": "sGEXef",
							"LongName": "Spec. G EX eff",
							"UnitShort": "1/(Pa*s)",
							"UnitLong": "1/(Pa*s)",
							"Comment": null
						}, {
							"Id": 79364119,
							"ShortName": "sGINef",
							"LongName": "Spec. G IN eff",
							"UnitShort": "1/(Pa*s)",
							"UnitLong": "1/(Pa*s)",
							"Comment": null
						}, {
							"Id": 79364115,
							"ShortName": "sWel",
							"LongName": "Specific W el",
							"UnitShort": "mWs/L",
							"UnitLong": "mWs/L",
							"Comment": null
						}, {
							"Id": 79364113,
							"ShortName": "sWvis",
							"LongName": "Specific W vis",
							"UnitShort": "mWs/L",
							"UnitLong": "mWs/L",
							"Comment": null
						}, {
							"Id": 79364097,
							"ShortName": "Time",
							"LongName": "Time",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 79364114,
							"ShortName": "Wel",
							"LongName": "W el",
							"UnitShort": "mJ",
							"UnitLong": "mJ",
							"Comment": null
						}, {
							"Id": 79364100,
							"ShortName": "Wvis",
							"LongName": "W vis",
							"UnitShort": "mJ",
							"UnitLong": "mJ",
							"Comment": null
						}
					]
				}, {
					"Id": 1212,
					"Childs": [],
					"ParentId": 12,
					"ShortName": "REE",
					"LongName": "REE",
					"Comment": null,
					"Parameters": [{
							"Id": 79429633,
							"ShortName": "Begin",
							"LongName": "Range Begin",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 79429648,
							"ShortName": "CHO %",
							"LongName": "CHO percent",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 79429646,
							"ShortName": "CHO g",
							"LongName": "CHO gram",
							"UnitShort": "g/day",
							"UnitLong": "g/day",
							"Comment": null
						}, {
							"Id": 79429647,
							"ShortName": "CHO kc",
							"LongName": "CHO kcal",
							"UnitShort": "kcal/day",
							"UnitLong": "kcal/day",
							"Comment": null
						}, {
							"Id": 79429644,
							"ShortName": "EE",
							"LongName": "Energy Expenditure",
							"UnitShort": "kcal/day",
							"UnitLong": "kcal/day",
							"Comment": null
						}, {
							"Id": 79429659,
							"ShortName": "EE/BSA",
							"LongName": "EE / BSA",
							"UnitShort": "(kcal/day)/(m*m)",
							"UnitLong": "(kcal/day)/(m*m)",
							"Comment": null
						}, {
							"Id": 79429658,
							"ShortName": "EE/CO2",
							"LongName": "EE / CO2",
							"UnitShort": "(kcal/day)/(L/min)",
							"UnitLong": "(kcal/day)/(L/min)",
							"Comment": null
						}, {
							"Id": 79429645,
							"ShortName": "EE/kg",
							"LongName": "EE per kg",
							"UnitShort": "W/kg",
							"UnitLong": "W/kg",
							"Comment": null
						}, {
							"Id": 79429634,
							"ShortName": "End",
							"LongName": "Range End",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 79429651,
							"ShortName": "FAT %",
							"LongName": "FAT percent",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 79429649,
							"ShortName": "FAT g",
							"LongName": "FAT gram",
							"UnitShort": "g/day",
							"UnitLong": "g/day",
							"Comment": null
						}, {
							"Id": 79429650,
							"ShortName": "FAT kc",
							"LongName": "FAT kcal",
							"UnitShort": "kcal/day",
							"UnitLong": "kcal/day",
							"Comment": null
						}, {
							"Id": 79429643,
							"ShortName": "npRER",
							"LongName": "npRER",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 79429654,
							"ShortName": "PRO %",
							"LongName": "PRO percent",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 79429652,
							"ShortName": "PRO g",
							"LongName": "PRO gram",
							"UnitShort": "g/day",
							"UnitLong": "g/day",
							"Comment": null
						}, {
							"Id": 79429653,
							"ShortName": "PRO kc",
							"LongName": "PRO kcal",
							"UnitShort": "kcal/day",
							"UnitLong": "kcal/day",
							"Comment": null
						}, {
							"Id": 79429639,
							"ShortName": "RERm",
							"LongName": "RER mean",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 79429657,
							"ShortName": "Tot %",
							"LongName": "Total percent",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 79429655,
							"ShortName": "Tot g",
							"LongName": "Total gram",
							"UnitShort": "g/day",
							"UnitLong": "g/day",
							"Comment": null
						}, {
							"Id": 79429656,
							"ShortName": "Toto kc",
							"LongName": "Total kcal",
							"UnitShort": "kcal/day",
							"UnitLong": "kcal/day",
							"Comment": null
						}, {
							"Id": 79429635,
							"ShortName": "UN",
							"LongName": "Urinal Nitrogen",
							"UnitShort": "g/day",
							"UnitLong": "g/day",
							"Comment": null
						}, {
							"Id": 79429642,
							"ShortName": "V'CO2d",
							"LongName": "V'CO2 deviation",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 79429638,
							"ShortName": "V'CO2m",
							"LongName": "V'CO2 mean",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 79429640,
							"ShortName": "V'Ed",
							"LongName": "V'E deviation",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 79429636,
							"ShortName": "V'Em",
							"LongName": "V'E mean",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 79429641,
							"ShortName": "V'O2d",
							"LongName": "V'O2 deviation",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 79429637,
							"ShortName": "V'O2m",
							"LongName": "V'O2 mean",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}
					]
				}, {
					"Id": 1215,
					"Childs": [],
					"ParentId": 12,
					"ShortName": "Slope",
					"LongName": "Slope",
					"Comment": null,
					"Parameters": [{
							"Id": 79626252,
							"ShortName": "Delay",
							"LongName": "Tau delay",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 79626255,
							"ShortName": "HR/VO2o",
							"LongName": "HR/VO2 offset",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 79626254,
							"ShortName": "HR/VO2s",
							"LongName": "HR/VO2 slope",
							"UnitShort": "1/L",
							"UnitLong": "1/L",
							"Comment": null
						}, {
							"Id": 79626245,
							"ShortName": "HRO2Wo",
							"LongName": "HR/VO2/Kg offset",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 79626246,
							"ShortName": "HRO2Ws",
							"LongName": "HR/VO2/Kg slope",
							"UnitShort": "1/(L/kg)",
							"UnitLong": "1/(L/kg)",
							"Comment": null
						}, {
							"Id": 79626251,
							"ShortName": "Load",
							"LongName": "Load",
							"UnitShort": "W",
							"UnitLong": "Watt",
							"Comment": null
						}, {
							"Id": 79626249,
							"ShortName": "OUESo",
							"LongName": "OUES offset",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 79626250,
							"ShortName": "OUESs",
							"LongName": "OUES slope",
							"UnitShort": "(L/min)/log10",
							"UnitLong": "",
							"Comment": null
						}, {
							"Id": 79626253,
							"ShortName": "Tau",
							"LongName": "Tau",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 79626241,
							"ShortName": "VECO2o",
							"LongName": "VE/VCO2 offset",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 79626242,
							"ShortName": "VECO2s",
							"LongName": "VE/VCO2 slope",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 79626247,
							"ShortName": "VEO2o",
							"LongName": "VE/VO2 offset",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 79626248,
							"ShortName": "VEO2s",
							"LongName": "VE/VO2 slope",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 79626243,
							"ShortName": "VO2Wo",
							"LongName": "VO2/Watt offset",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 79626244,
							"ShortName": "VO2Ws",
							"LongName": "VO2/Watt slope",
							"UnitShort": "(L/min)/W",
							"UnitLong": "(L/min)/W",
							"Comment": null
						}
					]
				}, {
					"Id": 1216,
					"Childs": [],
					"ParentId": 12,
					"ShortName": "EOV",
					"LongName": "Exercise Oscillatory Ventilation",
					"Comment": null,
					"Parameters": [{
							"Id": 79691782,
							"ShortName": "OSCtot",
							"LongName": "Total oscillatory time",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 79691788,
							"ShortName": "OSCtot (accum.)",
							"LongName": "Total oscillatory time (accumulated)",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 79691784,
							"ShortName": "OSCtot (exerc.)",
							"LongName": "Total oscillatory time (exercise)",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 79691785,
							"ShortName": "OSCtot (exerc.) %",
							"LongName": "Total oscillatory time (exercise) %",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 79691786,
							"ShortName": "OSCtot (rest)",
							"LongName": "Total oscillatory time (rest)",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 79691787,
							"ShortName": "OSCtot (rest) %",
							"LongName": "Total oscillatory time (rest) %",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 79691783,
							"ShortName": "OSCtot%",
							"LongName": "Total oscillatory time %",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 79691777,
							"ShortName": "Time",
							"LongName": "Time",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 79691779,
							"ShortName": "V'Eamp",
							"LongName": "V'Eamplitude",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 79691781,
							"ShortName": "V'EHigh",
							"LongName": "V'E local maximum",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 79691780,
							"ShortName": "V'ELow",
							"LongName": "V'E local minimum",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 79691778,
							"ShortName": "V'Eper",
							"LongName": "V'Eperiod",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}
					]
				}
			],
			"ParentId": 0,
			"ShortName": "CPET",
			"LongName": "CPET",
			"Comment": null,
			"Parameters": [{
					"Id": 786444,
					"ShortName": "delete",
					"LongName": "delete",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 786445,
					"ShortName": "delete",
					"LongName": "delete",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 786436,
					"ShortName": "dVol",
					"LongName": "delta Volume",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 786440,
					"ShortName": "FCO2",
					"LongName": "Fraction CO2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 786434,
					"ShortName": "Flow",
					"LongName": "Flow",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 786439,
					"ShortName": "FO2",
					"LongName": "Fraction O2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 786443,
					"ShortName": "MF1",
					"LongName": "MF1",
					"UnitShort": "V",
					"UnitLong": "V",
					"Comment": null
				}, {
					"Id": 786441,
					"ShortName": "PMouth",
					"LongName": "Mouth pressure",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 786442,
					"ShortName": "Poes",
					"LongName": "Oesophagus Pressure",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 786433,
					"ShortName": "time",
					"LongName": "time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 786435,
					"ShortName": "Vol",
					"LongName": "Volume",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 786438,
					"ShortName": "VolCO2",
					"LongName": "Volume CO2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 786437,
					"ShortName": "VolO2",
					"LongName": "Volume O2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}
			]
		}, {
			"Id": 13,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "ABG",
			"LongName": "Blood gases",
			"Comment": null,
			"Parameters": [{
					"Id": 851991,
					"ShortName": "AaDO2",
					"LongName": "P(A-a)O2",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 851997,
					"ShortName": "AB",
					"LongName": "Actual Bicarbonate",
					"UnitShort": "mmol/L",
					"UnitLong": "mmol/L",
					"Comment": null
				}, {
					"Id": 852010,
					"ShortName": "avDO2",
					"LongName": "P(a-v)O2",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 852000,
					"ShortName": "BB",
					"LongName": "Buffer base",
					"UnitShort": "mmol/L",
					"UnitLong": "mmol/L",
					"Comment": null
				}, {
					"Id": 851999,
					"ShortName": "BE",
					"LongName": "Base excess",
					"UnitShort": "mmol/L",
					"UnitLong": "mmol/L",
					"Comment": null
				}, {
					"Id": 852001,
					"ShortName": "BEecf",
					"LongName": "Base excess-ecf",
					"UnitShort": "mmol/L",
					"UnitLong": "mmol/L",
					"Comment": null
				}, {
					"Id": 852030,
					"ShortName": "BGMOD*",
					"LongName": "BGMOD*",
					"UnitShort": "Vol%",
					"UnitLong": "Vol%",
					"Comment": null
				}, {
					"Id": 852017,
					"ShortName": "Ca",
					"LongName": "Calcium",
					"UnitShort": "mmol(Cal)/L",
					"UnitLong": "mmol(Calcium)/L",
					"Comment": null
				}, {
					"Id": 851995,
					"ShortName": "CaCO2",
					"LongName": "Arterial CO2 content",
					"UnitShort": "Vol L %",
					"UnitLong": "Volume Liter %",
					"Comment": null
				}, {
					"Id": 851993,
					"ShortName": "CaO2",
					"LongName": "Arterial O2 content",
					"UnitShort": "Vol L %",
					"UnitLong": "Volume Liter %",
					"Comment": null
				}, {
					"Id": 851994,
					"ShortName": "CaO2*",
					"LongName": "Arterial O2 content*",
					"UnitShort": "Vol L %",
					"UnitLong": "Volume Liter %",
					"Comment": null
				}, {
					"Id": 852026,
					"ShortName": "CaO2**",
					"LongName": "Arterial O2 content**",
					"UnitShort": "Vol L %",
					"UnitLong": "Volume Liter %",
					"Comment": null
				}, {
					"Id": 852037,
					"ShortName": "CavO2",
					"LongName": "C(a-v)O2",
					"UnitShort": "Vol%",
					"UnitLong": "Vol%",
					"Comment": null
				}, {
					"Id": 852039,
					"ShortName": "CI_dF",
					"LongName": "Cardiac index - direct Fick",
					"UnitShort": "L/(min*m²)",
					"UnitLong": "L/(min*m²)",
					"Comment": null
				}, {
					"Id": 852019,
					"ShortName": "Cl",
					"LongName": "Chloride",
					"UnitShort": "mmol(Cl)/L",
					"UnitLong": "mmol(Chloride)/L",
					"Comment": null
				}, {
					"Id": 852022,
					"ShortName": "COHb",
					"LongName": "Carboxy-haemoglobin",
					"UnitShort": "Vol%",
					"UnitLong": "Vol%",
					"Comment": null
				}, {
					"Id": 851996,
					"ShortName": "CPCO2",
					"LongName": "Total CO2 content (Plasma)",
					"UnitShort": "mmol/L",
					"UnitLong": "mmol/L",
					"Comment": null
				}, {
					"Id": 852038,
					"ShortName": "CvaCO2",
					"LongName": "C(v-a)CO2",
					"UnitShort": "Vol%",
					"UnitLong": "Vol%",
					"Comment": null
				}, {
					"Id": 852005,
					"ShortName": "CvCO2",
					"LongName": "Venous CO2 content",
					"UnitShort": "Vol L %",
					"UnitLong": "Volume Liter %",
					"Comment": null
				}, {
					"Id": 852004,
					"ShortName": "CvO2",
					"LongName": "Venous O2 content",
					"UnitShort": "Vol L %",
					"UnitLong": "Volume Liter %",
					"Comment": null
				}, {
					"Id": 852029,
					"ShortName": "CxO2",
					"LongName": "O2-extractable content",
					"UnitShort": "Vol L %",
					"UnitLong": "Volume Liter %",
					"Comment": null
				}, {
					"Id": 851969,
					"ShortName": "Dev ID",
					"LongName": "Device ID",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 851977,
					"ShortName": "FIO2",
					"LongName": "FIO2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 851978,
					"ShortName": "FIO2s",
					"LongName": "FIO2 – shunt",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 852018,
					"ShortName": "Glu",
					"LongName": "Glucose",
					"UnitShort": "mmol(Glu)/L",
					"UnitLong": "mmol(Glucose)/L",
					"Comment": null
				}, {
					"Id": 852036,
					"ShortName": "H+",
					"LongName": "Hydrogen concentration",
					"UnitShort": "mmol/L",
					"UnitLong": "mmol/L",
					"Comment": null
				}, {
					"Id": 851982,
					"ShortName": "Hb",
					"LongName": "Haemoglobin (tot)",
					"UnitShort": "g(Hb)/dL",
					"UnitLong": "Gramms Haemoglobin per 100 mL",
					"Comment": null
				}, {
					"Id": 852021,
					"ShortName": "Hb*",
					"LongName": "Haemoglobin (tot)*",
					"UnitShort": "g(Hb)/dL",
					"UnitLong": "Gramms Haemoglobin per 100 mL",
					"Comment": null
				}, {
					"Id": 851983,
					"ShortName": "Hct",
					"LongName": "Hematocrit",
					"UnitShort": "g(Hb)/dL",
					"UnitLong": "Gramms Haemoglobin per 100 mL",
					"Comment": null
				}, {
					"Id": 852025,
					"ShortName": "Hct*",
					"LongName": "Hematocrit*",
					"UnitShort": "g(Hb)/dL",
					"UnitLong": "Gramms Haemoglobin per 100 mL",
					"Comment": null
				}, {
					"Id": 852024,
					"ShortName": "HHb",
					"LongName": "Deoxy-haemoglobin",
					"UnitShort": "Vol%",
					"UnitLong": "Vol%",
					"Comment": null
				}, {
					"Id": 852031,
					"ShortName": "HHb*",
					"LongName": "Deoxy-haemoglobin*",
					"UnitShort": "Vol%",
					"UnitLong": "Vol%",
					"Comment": null
				}, {
					"Id": 852042,
					"ShortName": "HR",
					"LongName": "Heart rate",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": null
				}, {
					"Id": 851973,
					"ShortName": "Index",
					"LongName": "Breath Index",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 852015,
					"ShortName": "K",
					"LongName": "Potassium",
					"UnitShort": "mmol(K)/L",
					"UnitLong": "mmol(Potassium)/L",
					"Comment": null
				}, {
					"Id": 851986,
					"ShortName": "Lac",
					"LongName": "Lactate",
					"UnitShort": "mmol(Lac)/L",
					"UnitLong": "mmol(Lactate)/L",
					"Comment": null
				}, {
					"Id": 851976,
					"ShortName": "Load",
					"LongName": "Load",
					"UnitShort": "W",
					"UnitLong": "Watt",
					"Comment": null
				}, {
					"Id": 852023,
					"ShortName": "MetHb",
					"LongName": "Met-haemoglobin",
					"UnitShort": "Vol%",
					"UnitLong": "Vol%",
					"Comment": null
				}, {
					"Id": 852016,
					"ShortName": "Na",
					"LongName": "Natrium",
					"UnitShort": "mmol(Na)/L",
					"UnitLong": "mmol(Natrium)/L",
					"Comment": null
				}, {
					"Id": 852043,
					"ShortName": "O2 ADM",
					"LongName": "O2 administration",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 852020,
					"ShortName": "O2Hb",
					"LongName": "Oxy-haemoglobin",
					"UnitShort": "Vol%",
					"UnitLong": "Vol%",
					"Comment": null
				}, {
					"Id": 852027,
					"ShortName": "P50",
					"LongName": "PaO2(50%SaO2)",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 851989,
					"ShortName": "PaCO2",
					"LongName": "Arterial CO2 pressure",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 852014,
					"ShortName": "PaETCO2",
					"LongName": "P(a-ET)CO2",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 851987,
					"ShortName": "PaO2",
					"LongName": "Arterial O2 pressure",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 851990,
					"ShortName": "PAO2",
					"LongName": "Alveolar PAO2",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 851988,
					"ShortName": "PaO2s",
					"LongName": "Arterial O2 pressure shunt",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 851992,
					"ShortName": "PaO2st",
					"LongName": "PaO2 standardised",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 852032,
					"ShortName": "PBar",
					"LongName": "Barometric pressure",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 852033,
					"ShortName": "PETCO2",
					"LongName": "End-tidal CO2 pressure",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 852034,
					"ShortName": "PETO2",
					"LongName": "End-tidal O2 pressure",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 851981,
					"ShortName": "pHa",
					"LongName": "pH arterial",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 852006,
					"ShortName": "pHv",
					"LongName": "pH venous",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 852008,
					"ShortName": "PvCO2",
					"LongName": "venuous CO2 pressure",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 852007,
					"ShortName": "PvO2",
					"LongName": "venuous O2 pressure",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 852028,
					"ShortName": "PxO2",
					"LongName": "O2-extractable pressure",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 852002,
					"ShortName": "Qs",
					"LongName": "Shunt",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": null
				}, {
					"Id": 852003,
					"ShortName": "Qs/Qt",
					"LongName": "Shunt (%)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 852013,
					"ShortName": "Qt-Fic",
					"LongName": "Qt direct Fick",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": null
				}, {
					"Id": 851972,
					"ShortName": "Recalc",
					"LongName": "Recalculate",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 851980,
					"ShortName": "RER",
					"LongName": "Resp. Exchange ratio",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 852012,
					"ShortName": "RQ",
					"LongName": "Resp. Quotient",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 851970,
					"ShortName": "Sam ID",
					"LongName": "Sample ID",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 851971,
					"ShortName": "Sample",
					"LongName": "Sample Type",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 851985,
					"ShortName": "SaO2",
					"LongName": "O2-Saturation (arterial)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 852035,
					"ShortName": "SpO2",
					"LongName": "O2-Saturation (p)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 851998,
					"ShortName": "STB",
					"LongName": "Standard Bicarbonate",
					"UnitShort": "mmol/L",
					"UnitLong": "mmol/L",
					"Comment": null
				}, {
					"Id": 852040,
					"ShortName": "SV_dF",
					"LongName": "Stroke volume - direct Fick",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 852041,
					"ShortName": "SVI_dF",
					"LongName": "Stroke volume index - direct Fick",
					"UnitShort": "L/m²",
					"UnitLong": "L/m²",
					"Comment": null
				}, {
					"Id": 852009,
					"ShortName": "SvO2",
					"LongName": "O2-Saturation (venous)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 851974,
					"ShortName": "t",
					"LongName": "Time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 851975,
					"ShortName": "TBody",
					"LongName": "Body temperature",
					"UnitShort": "°C",
					"UnitLong": "Celsius",
					"Comment": null
				}, {
					"Id": 851984,
					"ShortName": "UN",
					"LongName": "Urinal Nitrogen",
					"UnitShort": "g/day",
					"UnitLong": "g/day",
					"Comment": null
				}, {
					"Id": 852011,
					"ShortName": "vaDCO2",
					"LongName": "P(v-a)CO2",
					"UnitShort": "Pa",
					"UnitLong": "Pa",
					"Comment": null
				}, {
					"Id": 851979,
					"ShortName": "V'O2",
					"LongName": "Oxygen Uptake",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": null
				}
			]
		}, {
			"Id": 14,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "Cardiology",
			"LongName": "Cardiology",
			"Comment": null,
			"Parameters": [{
					"Id": 917517,
					"ShortName": "< P",
					"LongName": "P axis",
					"UnitShort": "°",
					"UnitLong": "°",
					"Comment": null
				}, {
					"Id": 917518,
					"ShortName": "< QRS",
					"LongName": "QRS axis",
					"UnitShort": "°",
					"UnitLong": "°",
					"Comment": null
				}, {
					"Id": 917519,
					"ShortName": "< T",
					"LongName": "T axis",
					"UnitShort": "°",
					"UnitLong": "°",
					"Comment": null
				}, {
					"Id": 917523,
					"ShortName": "HPP",
					"LongName": "HRBPProd",
					"UnitShort": "mmHg/min",
					"UnitLong": "mmHg/min",
					"Comment": null
				}, {
					"Id": 917506,
					"ShortName": "HR",
					"LongName": "Heart rate",
					"UnitShort": "BPM",
					"UnitLong": "Beats per Minute",
					"Comment": null
				}, {
					"Id": 917520,
					"ShortName": "P",
					"LongName": "P duration",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 917509,
					"ShortName": "Pdia",
					"LongName": "Blood press.-dia",
					"UnitShort": "mmHg",
					"UnitLong": "mmHg",
					"Comment": null
				}, {
					"Id": 917510,
					"ShortName": "Pmean",
					"LongName": "Blood press.-mean",
					"UnitShort": "mmHg",
					"UnitLong": "mmHg",
					"Comment": null
				}, {
					"Id": 917513,
					"ShortName": "PQ",
					"LongName": "PQ",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 917512,
					"ShortName": "PR",
					"LongName": "PR",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 917508,
					"ShortName": "Psys",
					"LongName": "Blood press.-sys",
					"UnitShort": "mmHg",
					"UnitLong": "mmHg",
					"Comment": null
				}, {
					"Id": 917514,
					"ShortName": "QRS",
					"LongName": "QRS",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 917515,
					"ShortName": "QT",
					"LongName": "QT",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 917522,
					"ShortName": "QTcB",
					"LongName": "QTc (Bassett)",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 917516,
					"ShortName": "QTcF",
					"LongName": "QTc (Fridericia)",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 917511,
					"ShortName": "RPP",
					"LongName": "Rate Press",
					"UnitShort": "mmHg/min",
					"UnitLong": "mmHg/min",
					"Comment": null
				}, {
					"Id": 917521,
					"ShortName": "RR",
					"LongName": "RR interval",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 917507,
					"ShortName": "SysDia",
					"LongName": "Blood pressure",
					"UnitShort": "mmHg",
					"UnitLong": "mmHg",
					"Comment": null
				}, {
					"Id": 917505,
					"ShortName": "Time",
					"LongName": "Time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}
			]
		}, {
			"Id": 18,
			"Childs": [{
					"Id": 1801,
					"Childs": [],
					"ParentId": 18,
					"ShortName": "Vt mean v.",
					"LongName": "Tidal breathing mean v.",
					"Comment": null,
					"Parameters": [{
							"Id": 118030403,
							"ShortName": "AX",
							"LongName": "AX",
							"UnitShort": "kPa/L",
							"UnitLong": "kPa/L",
							"Comment": null
						}, {
							"Id": 118030412,
							"ShortName": "AXCV%",
							"LongName": "AXCV%",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 118030397,
							"ShortName": "C",
							"LongName": "Capacitance",
							"UnitShort": "L/kPa",
							"UnitLong": "L/kPa",
							"Comment": null
						}, {
							"Id": 118030377,
							"ShortName": "CO05Hz",
							"LongName": "CO at 0.5 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030382,
							"ShortName": "CO10Hz",
							"LongName": "CO at 10 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030383,
							"ShortName": "CO15Hz",
							"LongName": "CO at 15 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030378,
							"ShortName": "CO1Hz",
							"LongName": "CO at 1 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030384,
							"ShortName": "CO20Hz",
							"LongName": "CO at 20 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030385,
							"ShortName": "CO25Hz",
							"LongName": "CO at 25 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030379,
							"ShortName": "CO2Hz",
							"LongName": "CO at 2 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030387,
							"ShortName": "CO30Hz",
							"LongName": "CO at 30 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030386,
							"ShortName": "CO35Hz",
							"LongName": "CO at 35 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030380,
							"ShortName": "CO3Hz",
							"LongName": "CO at 3 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030381,
							"ShortName": "CO5Hz",
							"LongName": "CO at 5 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030401,
							"ShortName": "Ers",
							"LongName": "Erespir",
							"UnitShort": "kPa/L",
							"UnitLong": "kPa/L",
							"Comment": null
						}, {
							"Id": 118030411,
							"ShortName": "FreCV%",
							"LongName": "FresCV%",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 118030402,
							"ShortName": "Fres",
							"LongName": "Resonant frequency",
							"UnitShort": "Hz",
							"UnitLong": "Hertz ( 1/s )",
							"Comment": null
						}, {
							"Id": 118030398,
							"ShortName": "I",
							"LongName": "Inertance",
							"UnitShort": "kPa/(L/s²)",
							"UnitLong": "kPa/(L/s²)",
							"Comment": null
						}, {
							"Id": 118030337,
							"ShortName": "R05Hz",
							"LongName": "R at 0.5 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030342,
							"ShortName": "R10Hz",
							"LongName": "R at 10 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030343,
							"ShortName": "R15Hz",
							"LongName": "R at 15 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030338,
							"ShortName": "R1Hz",
							"LongName": "R at 1 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030409,
							"ShortName": "R20CV%",
							"LongName": "R20CV%",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 118030344,
							"ShortName": "R20Hz",
							"LongName": "R at 20 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030345,
							"ShortName": "R25Hz",
							"LongName": "R at 25 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030339,
							"ShortName": "R2Hz",
							"LongName": "R at 2 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030347,
							"ShortName": "R30Hz",
							"LongName": "R at 30 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030346,
							"ShortName": "R35Hz",
							"LongName": "R at 35 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030340,
							"ShortName": "R3Hz",
							"LongName": "R at 3 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030408,
							"ShortName": "R5CV%",
							"LongName": "R5CV%",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 118030341,
							"ShortName": "R5Hz",
							"LongName": "R at 5 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030399,
							"ShortName": "Rc",
							"LongName": "Rcentral",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030348,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030349,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030350,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030351,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030352,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030353,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030354,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030355,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030356,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030368,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030369,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030370,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030371,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030372,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030373,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030374,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030375,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030376,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030388,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030389,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030390,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030391,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030392,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030393,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030394,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030395,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030396,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030404,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030405,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030406,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118030400,
							"ShortName": "Rp",
							"LongName": "Rperipheral",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030357,
							"ShortName": "X05Hz",
							"LongName": "X at 0.5 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030362,
							"ShortName": "X10Hz",
							"LongName": "X at 10 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030363,
							"ShortName": "X15Hz",
							"LongName": "X at 15 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030358,
							"ShortName": "X1Hz",
							"LongName": "X at 1 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030364,
							"ShortName": "X20Hz",
							"LongName": "X at 20 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030365,
							"ShortName": "X25Hz",
							"LongName": "X at 25 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030359,
							"ShortName": "X2Hz",
							"LongName": "X at 2 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030367,
							"ShortName": "X30Hz",
							"LongName": "X at 30 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030366,
							"ShortName": "X35Hz",
							"LongName": "X at 35 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030360,
							"ShortName": "X3Hz",
							"LongName": "X at 3 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030410,
							"ShortName": "X5CV%",
							"LongName": "X5CV%",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 118030361,
							"ShortName": "X5Hz",
							"LongName": "X at 5 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118030407,
							"ShortName": "Z5CV%",
							"LongName": "Z5CV%",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}
					]
				}, {
					"Id": 1802,
					"Childs": [],
					"ParentId": 18,
					"ShortName": "Vt In-param",
					"LongName": "Tidal breathing In-param",
					"Comment": null,
					"Parameters": [{
							"Id": 118095939,
							"ShortName": "AXi",
							"LongName": "AX in",
							"UnitShort": "kPa/L",
							"UnitLong": "kPa/L",
							"Comment": null
						}, {
							"Id": 118095933,
							"ShortName": "Cin",
							"LongName": "Capacitance in",
							"UnitShort": "L/kPa",
							"UnitLong": "L/kPa",
							"Comment": null
						}, {
							"Id": 118095913,
							"ShortName": "COin05",
							"LongName": "COin at 0.5 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095914,
							"ShortName": "COin1",
							"LongName": "COin at 1 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095918,
							"ShortName": "COin10",
							"LongName": "COin at 10 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095919,
							"ShortName": "COin15",
							"LongName": "COin at 15 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095915,
							"ShortName": "COin2",
							"LongName": "COin at 2 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095920,
							"ShortName": "COin20",
							"LongName": "COin at 20 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095921,
							"ShortName": "COin25",
							"LongName": "COin at 25 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095916,
							"ShortName": "COin3",
							"LongName": "COin at 3 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095923,
							"ShortName": "COin30",
							"LongName": "COin at 30 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095922,
							"ShortName": "COin35",
							"LongName": "COin at 35 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095917,
							"ShortName": "COin5",
							"LongName": "COin at 5 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095937,
							"ShortName": "Ersin",
							"LongName": "Erespir in",
							"UnitShort": "kPa/L",
							"UnitLong": "kPa/L",
							"Comment": null
						}, {
							"Id": 118095938,
							"ShortName": "Fresin",
							"LongName": "Resonant frequ. in",
							"UnitShort": "Hz",
							"UnitLong": "Hertz ( 1/s )",
							"Comment": null
						}, {
							"Id": 118095934,
							"ShortName": "Iin",
							"LongName": "Inertance in",
							"UnitShort": "kPa/(L/s²)",
							"UnitLong": "kPa/(L/s²)",
							"Comment": null
						}, {
							"Id": 118095948,
							"ShortName": "IOSMOD",
							"LongName": "IOSMOD",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095944,
							"ShortName": "MaX5in",
							"LongName": "Max X5 in",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095943,
							"ShortName": "MX5in",
							"LongName": "Mean X5 in",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095935,
							"ShortName": "Rcin",
							"LongName": "Rcentral in",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095884,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095885,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095886,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095887,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095888,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095889,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095890,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095891,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095892,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095904,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095905,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095906,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095907,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095908,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095909,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095910,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095911,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095912,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095924,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095925,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095926,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095927,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095928,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095929,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095930,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095931,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095932,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095940,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095941,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095942,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095946,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095947,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095873,
							"ShortName": "Rin05",
							"LongName": "Rin at 0.5 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095874,
							"ShortName": "Rin1",
							"LongName": "Rin at 1 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095878,
							"ShortName": "Rin10",
							"LongName": "Rin at 10 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095879,
							"ShortName": "Rin15",
							"LongName": "Rin at 15 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095875,
							"ShortName": "Rin2",
							"LongName": "Rin at 2 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095880,
							"ShortName": "Rin20",
							"LongName": "Rin at 20 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095881,
							"ShortName": "Rin25",
							"LongName": "Rin at 25 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095876,
							"ShortName": "Rin3",
							"LongName": "Rin at 3 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095883,
							"ShortName": "Rin30",
							"LongName": "Rin at 30 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095882,
							"ShortName": "Rin35",
							"LongName": "Rin at 35 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095877,
							"ShortName": "Rin5",
							"LongName": "Rin at 5 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095936,
							"ShortName": "Rpin",
							"LongName": "Rperipheral in",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095945,
							"ShortName": "SDMX5i",
							"LongName": "SD Mean X5 in",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118095893,
							"ShortName": "Xin05",
							"LongName": "Xin at 0.5 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095894,
							"ShortName": "Xin1",
							"LongName": "Xin at 1 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095898,
							"ShortName": "Xin10",
							"LongName": "Xin at 10 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095899,
							"ShortName": "Xin15",
							"LongName": "Xin at 15 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095895,
							"ShortName": "Xin2",
							"LongName": "Xin at 2 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095900,
							"ShortName": "Xin20",
							"LongName": "Xin at 20 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095901,
							"ShortName": "Xin25",
							"LongName": "Xin at 25 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095896,
							"ShortName": "Xin3",
							"LongName": "Xin at 3 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095903,
							"ShortName": "Xin30",
							"LongName": "Xin at 30 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095902,
							"ShortName": "Xin35",
							"LongName": "Xin at 35 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118095897,
							"ShortName": "Xin5",
							"LongName": "Xin at 5 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}
					]
				}, {
					"Id": 1803,
					"Childs": [],
					"ParentId": 18,
					"ShortName": "Vt Ex-param",
					"LongName": "Tidal breathing Ex-param",
					"Comment": null,
					"Parameters": [{
							"Id": 118161475,
							"ShortName": "AXe",
							"LongName": "AX ex",
							"UnitShort": "kPa/L",
							"UnitLong": "kPa/L",
							"Comment": null
						}, {
							"Id": 118161469,
							"ShortName": "Cex",
							"LongName": "Capacitance ex",
							"UnitShort": "L/kPa",
							"UnitLong": "L/kPa",
							"Comment": null
						}, {
							"Id": 118161449,
							"ShortName": "COex05",
							"LongName": "COex at 0.5 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161450,
							"ShortName": "COex1",
							"LongName": "COex at 1 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161454,
							"ShortName": "COex10",
							"LongName": "COex at 10 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161455,
							"ShortName": "COex15",
							"LongName": "COex at 15 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161451,
							"ShortName": "COex2",
							"LongName": "COex at 2 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161456,
							"ShortName": "COex20",
							"LongName": "COex at 20 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161457,
							"ShortName": "COex25",
							"LongName": "COex at 25 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161452,
							"ShortName": "COex3",
							"LongName": "COex at 3 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161459,
							"ShortName": "COex30",
							"LongName": "COex at 30 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161458,
							"ShortName": "COex35",
							"LongName": "COex at 35 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161453,
							"ShortName": "COex5",
							"LongName": "COex at 5 Hz",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161473,
							"ShortName": "Ersex",
							"LongName": "Erespir ex",
							"UnitShort": "kPa/L",
							"UnitLong": "kPa/L",
							"Comment": null
						}, {
							"Id": 118161474,
							"ShortName": "Fresex",
							"LongName": "Resonant frequ. ex",
							"UnitShort": "Hz",
							"UnitLong": "Hertz ( 1/s )",
							"Comment": null
						}, {
							"Id": 118161470,
							"ShortName": "Iex",
							"LongName": "Inertance ex",
							"UnitShort": "kPa/(L/s²)",
							"UnitLong": "kPa/(L/s²)",
							"Comment": null
						}, {
							"Id": 118161484,
							"ShortName": "IOSMOD",
							"LongName": "IOSMOD",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161480,
							"ShortName": "MiX5ex",
							"LongName": "Min X5 ex",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161479,
							"ShortName": "MX5ex",
							"LongName": "Mean X5 ex",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161471,
							"ShortName": "Rcex",
							"LongName": "Rcentral ex",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161420,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161421,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161422,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161423,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161424,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161425,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161426,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161427,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161428,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161440,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161441,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161442,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161443,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161444,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161445,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161446,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161447,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161448,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161460,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161461,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161462,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161463,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161464,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161465,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161466,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161467,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161468,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161476,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161477,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161478,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161482,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161483,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161409,
							"ShortName": "Rex05",
							"LongName": "Rex at 0.5 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161410,
							"ShortName": "Rex1",
							"LongName": "Rex at 1 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161414,
							"ShortName": "Rex10",
							"LongName": "Rex at 10 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161415,
							"ShortName": "Rex15",
							"LongName": "Rex at 15 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161411,
							"ShortName": "Rex2",
							"LongName": "Rex at 2 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161416,
							"ShortName": "Rex20",
							"LongName": "Rex at 20 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161417,
							"ShortName": "Rex25",
							"LongName": "Rex at 25 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161412,
							"ShortName": "Rex3",
							"LongName": "Rex at 3 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161419,
							"ShortName": "Rex30",
							"LongName": "Rex at 30 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161418,
							"ShortName": "Rex35",
							"LongName": "Rex at 35 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161413,
							"ShortName": "Rex5",
							"LongName": "Rex at 5 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161472,
							"ShortName": "Rpex",
							"LongName": "Rperipheral ex",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161481,
							"ShortName": "SDMX5e",
							"LongName": "SD Mean X5 ex",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118161429,
							"ShortName": "Xex05",
							"LongName": "Xex at 0.5 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161430,
							"ShortName": "Xex1",
							"LongName": "Xex at 1 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161434,
							"ShortName": "Xex10",
							"LongName": "Xex at 10 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161435,
							"ShortName": "Xex15",
							"LongName": "Xex at 15 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161431,
							"ShortName": "Xex2",
							"LongName": "Xex at 2 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161436,
							"ShortName": "Xex20",
							"LongName": "Xex at 20 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161437,
							"ShortName": "Xex25",
							"LongName": "Xex at 25 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161432,
							"ShortName": "Xex3",
							"LongName": "Xex at 3 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161439,
							"ShortName": "Xex30",
							"LongName": "Xex at 30 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161438,
							"ShortName": "Xex35",
							"LongName": "Xex at 35 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118161433,
							"ShortName": "Xex5",
							"LongName": "Xex at 5 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}
					]
				}, {
					"Id": 1804,
					"Childs": [],
					"ParentId": 18,
					"ShortName": "VCmax In-param",
					"LongName": "VCmax In-parameter",
					"Comment": null,
					"Parameters": [{
							"Id": 118227011,
							"ShortName": "AXI",
							"LongName": "AX VIN",
							"UnitShort": "kPa/L",
							"UnitLong": "kPa/L",
							"Comment": null
						}, {
							"Id": 118226985,
							"ShortName": "CO05VI",
							"LongName": "CO 0.5 Hz at VIN",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226990,
							"ShortName": "CO10VI",
							"LongName": "CO 10 Hz at VIN",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226991,
							"ShortName": "CO15VI",
							"LongName": "CO 15 Hz at VIN",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226986,
							"ShortName": "CO1VIN",
							"LongName": "CO 1 Hz at VIN",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226992,
							"ShortName": "CO20VI",
							"LongName": "CO 20 Hz at VIN",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226993,
							"ShortName": "CO25VI",
							"LongName": "CO 25 Hz at VIN",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226987,
							"ShortName": "CO2VIN",
							"LongName": "CO 2 Hz at VIN",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226995,
							"ShortName": "CO30VI",
							"LongName": "CO 30 Hz at VIN",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226994,
							"ShortName": "CO35VI",
							"LongName": "CO 35 Hz at VIN",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226988,
							"ShortName": "CO3VIN",
							"LongName": "CO 3 Hz at VIN",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226989,
							"ShortName": "CO5VIN",
							"LongName": "CO 5 Hz at VIN",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227005,
							"ShortName": "CVIN",
							"LongName": "Capacitance VIN",
							"UnitShort": "L/kPa",
							"UnitLong": "L/kPa",
							"Comment": null
						}, {
							"Id": 118227009,
							"ShortName": "ErsVIN",
							"LongName": "Erespir VIN",
							"UnitShort": "kPa/L",
							"UnitLong": "kPa/L",
							"Comment": null
						}, {
							"Id": 118227010,
							"ShortName": "FresVI",
							"LongName": "Resonant frequ. VIN",
							"UnitShort": "Hz",
							"UnitLong": "Hertz ( 1/s )",
							"Comment": null
						}, {
							"Id": 118227020,
							"ShortName": "IOSMOD",
							"LongName": "IOSMOD",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227006,
							"ShortName": "IVIN",
							"LongName": "Inertance VIN",
							"UnitShort": "kPa/(L/s²)",
							"UnitLong": "kPa/(L/s²)",
							"Comment": null
						}, {
							"Id": 118226945,
							"ShortName": "R05VIN",
							"LongName": "R 0.5Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226950,
							"ShortName": "R10VIN",
							"LongName": "R 10Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226951,
							"ShortName": "R15VIN",
							"LongName": "R 15Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226946,
							"ShortName": "R1VIN",
							"LongName": "R 1 Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226952,
							"ShortName": "R20VIN",
							"LongName": "R 20Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226953,
							"ShortName": "R25VIN",
							"LongName": "R 25Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226947,
							"ShortName": "R2VIN",
							"LongName": "R 2 Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226955,
							"ShortName": "R30VIN",
							"LongName": "R 30 Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226954,
							"ShortName": "R35VIN",
							"LongName": "R 35Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226948,
							"ShortName": "R3VIN",
							"LongName": "R 3 Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226949,
							"ShortName": "R5VIN",
							"LongName": "R 5 Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118227007,
							"ShortName": "RcVIN",
							"LongName": "Rcentral VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226956,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226957,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226958,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226959,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226960,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226961,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226962,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226963,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226964,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226976,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226977,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226978,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226979,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226980,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226981,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226982,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226983,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226984,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226996,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226997,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226998,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118226999,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227000,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227001,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227002,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227003,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227004,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227012,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227013,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227014,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227015,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227016,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227017,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227018,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227019,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118227008,
							"ShortName": "RpVIN",
							"LongName": "Rperiph. VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226965,
							"ShortName": "X05VIN",
							"LongName": "X 0.5Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226970,
							"ShortName": "X10VIN",
							"LongName": "X 10Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226971,
							"ShortName": "X15VIN",
							"LongName": "X 15Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226966,
							"ShortName": "X1VIN",
							"LongName": "X 1 Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226972,
							"ShortName": "X20VIN",
							"LongName": "X 20Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226973,
							"ShortName": "X25VIN",
							"LongName": "X 25Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226967,
							"ShortName": "X2VIN",
							"LongName": "X 2 Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226975,
							"ShortName": "X30VIN",
							"LongName": "X 30 Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226974,
							"ShortName": "X35VIN",
							"LongName": "X 35Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226968,
							"ShortName": "X3VIN",
							"LongName": "X 3 Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118226969,
							"ShortName": "X5VIN",
							"LongName": "X 5 Hz at VIN",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}
					]
				}, {
					"Id": 1805,
					"Childs": [],
					"ParentId": 18,
					"ShortName": "VCmax Ex-param",
					"LongName": "VCmax Ex-parameter",
					"Comment": null,
					"Parameters": [{
							"Id": 118292547,
							"ShortName": "AXE",
							"LongName": "AX VEX",
							"UnitShort": "kPa/L",
							"UnitLong": "kPa/L",
							"Comment": null
						}, {
							"Id": 118292521,
							"ShortName": "CO05VE",
							"LongName": "CO 0.5 Hz at VEX",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292526,
							"ShortName": "CO10VE",
							"LongName": "CO 10 Hz at VEX",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292527,
							"ShortName": "CO15VE",
							"LongName": "CO 15 Hz at VEX",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292522,
							"ShortName": "CO1VEX",
							"LongName": "CO 1 Hz at VEX",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292528,
							"ShortName": "CO20VE",
							"LongName": "CO 20 Hz at VEX",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292529,
							"ShortName": "CO25VE",
							"LongName": "CO 25 Hz at VEX",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292523,
							"ShortName": "CO2VEX",
							"LongName": "CO 2 Hz at VEX",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292531,
							"ShortName": "CO30VE",
							"LongName": "CO 30 Hz at VEX",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292530,
							"ShortName": "CO35VE",
							"LongName": "CO 35 Hz at VEX",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292524,
							"ShortName": "CO3VEX",
							"LongName": "CO 3 Hz at VEX",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292525,
							"ShortName": "CO5VEX",
							"LongName": "CO 5 Hz at VEX",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292541,
							"ShortName": "CVEX",
							"LongName": "Capacitance VEX",
							"UnitShort": "L/kPa",
							"UnitLong": "L/kPa",
							"Comment": null
						}, {
							"Id": 118292545,
							"ShortName": "ErsVEX",
							"LongName": "Erespir VEX",
							"UnitShort": "kPa/L",
							"UnitLong": "kPa/L",
							"Comment": null
						}, {
							"Id": 118292546,
							"ShortName": "FresVE",
							"LongName": "Resonant frequ. VEX",
							"UnitShort": "Hz",
							"UnitLong": "Hertz ( 1/s )",
							"Comment": null
						}, {
							"Id": 118292556,
							"ShortName": "IOSMOD",
							"LongName": "IOSMOD",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292542,
							"ShortName": "IVEX",
							"LongName": "Inertance VEX",
							"UnitShort": "kPa/(L/s²)",
							"UnitLong": "kPa/(L/s²)",
							"Comment": null
						}, {
							"Id": 118292481,
							"ShortName": "R05VEX",
							"LongName": "R 0.5Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292486,
							"ShortName": "R10VEX",
							"LongName": "R 10Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292487,
							"ShortName": "R15VEX",
							"LongName": "R 15Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292482,
							"ShortName": "R1VEX",
							"LongName": "R 1 Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292488,
							"ShortName": "R20VEX",
							"LongName": "R 20Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292489,
							"ShortName": "R25VEX",
							"LongName": "R 25Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292483,
							"ShortName": "R2VEX",
							"LongName": "R 2 Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292491,
							"ShortName": "R30VEX",
							"LongName": "R 30 Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292490,
							"ShortName": "R35VEX",
							"LongName": "R 35Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292484,
							"ShortName": "R3VEX",
							"LongName": "R 3 Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292485,
							"ShortName": "R5VEX",
							"LongName": "R 5 Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292543,
							"ShortName": "RcVEX",
							"LongName": "Rcentral VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292492,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292493,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292494,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292495,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292496,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292497,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292498,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292499,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292500,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292512,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292513,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292514,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292515,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292516,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292517,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292518,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292519,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292520,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292532,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292533,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292534,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292535,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292536,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292537,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292538,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292539,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292540,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292548,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292549,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292550,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292551,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292552,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292553,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292554,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292555,
							"ShortName": "res",
							"LongName": "reserved",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 118292544,
							"ShortName": "RpVEX",
							"LongName": "Rperiph. VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292501,
							"ShortName": "X05VEX",
							"LongName": "X 0.5Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292506,
							"ShortName": "X10VEX",
							"LongName": "X 10Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292507,
							"ShortName": "X15VEX",
							"LongName": "X 15Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292502,
							"ShortName": "X1VEX",
							"LongName": "X 1 Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292508,
							"ShortName": "X20VEX",
							"LongName": "X 20Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292509,
							"ShortName": "X25VEX",
							"LongName": "X 25Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292503,
							"ShortName": "X2VEX",
							"LongName": "X 2 Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292511,
							"ShortName": "X30VEX",
							"LongName": "X 30 Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292510,
							"ShortName": "X35VEX",
							"LongName": "X 35Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292504,
							"ShortName": "X3VEX",
							"LongName": "X 3 Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118292505,
							"ShortName": "X5VEX",
							"LongName": "X 5 Hz at VEX",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}
					]
				}, {
					"Id": 1806,
					"Childs": [],
					"ParentId": 18,
					"ShortName": "FOT",
					"LongName": "Forced oscillation",
					"Comment": null,
					"Parameters": [{
							"Id": 118358019,
							"ShortName": "R16Hz",
							"LongName": "R at 16 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118358020,
							"ShortName": "R24Hz",
							"LongName": "R at 24 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118358018,
							"ShortName": "R6Hz",
							"LongName": "R at 6 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118358017,
							"ShortName": "Rmean",
							"LongName": "Mean Resistance",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118358023,
							"ShortName": "RSlope",
							"LongName": "Resistance Slope",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 118358022,
							"ShortName": "X30Hz",
							"LongName": "X at 30Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 118358021,
							"ShortName": "X6Hz",
							"LongName": "X at 6 Hz",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}
					]
				}
			],
			"ParentId": 0,
			"ShortName": "IOS",
			"LongName": "Impulse oscillom.",
			"Comment": null,
			"Parameters": [{
					"Id": 1179673,
					"ShortName": "      ",
					"LongName": "                         ",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1179698,
					"ShortName": "      ",
					"LongName": "                         ",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1179732,
					"ShortName": "AI",
					"LongName": "Asthma Intellig.",
					"UnitShort": "kPa/L",
					"UnitLong": "kPa/L",
					"Comment": null
				}, {
					"Id": 1179672,
					"ShortName": "AX",
					"LongName": "AX",
					"UnitShort": "kPa/L",
					"UnitLong": "kPa/L",
					"Comment": null
				}, {
					"Id": 1179722,
					"ShortName": "AXE",
					"LongName": "AX at VEX",
					"UnitShort": "kPa/L",
					"UnitLong": "kPa/L",
					"Comment": null
				}, {
					"Id": 1179697,
					"ShortName": "AXI",
					"LongName": "AX at VIN",
					"UnitShort": "kPa/L",
					"UnitLong": "kPa/L",
					"Comment": null
				}, {
					"Id": 1179723,
					"ShortName": "Best est",
					"LongName": "Best estimation",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1179725,
					"ShortName": "BF",
					"LongName": "BF",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": null
				}, {
					"Id": 1179655,
					"ShortName": "Cb    ",
					"LongName": "Cbronchial",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 1179705,
					"ShortName": "Cb VEX",
					"LongName": "Cb at VEX",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 1179680,
					"ShortName": "Cb VIN",
					"LongName": "Cb at VIN",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 1179654,
					"ShortName": "Cl    ",
					"LongName": "Clung",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 1179704,
					"ShortName": "Cl VEX",
					"LongName": "Cl at VEX",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 1179679,
					"ShortName": "Cl VIN",
					"LongName": "Cl at VIN.......  ",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 1179657,
					"ShortName": "Cm    ",
					"LongName": "Cmouth",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 1179707,
					"ShortName": "Cm VEX",
					"LongName": "Cm at VEX",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 1179682,
					"ShortName": "Cm VIN",
					"LongName": "Cm at VIN",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 1179742,
					"ShortName": "CO20Hz",
					"LongName": "CO at 20 Hz",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 1179741,
					"ShortName": "CO5Hz",
					"LongName": "CO at 5 Hz",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 1179656,
					"ShortName": "Cw    ",
					"LongName": "Cwall",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 1179706,
					"ShortName": "Cw VEX",
					"LongName": "Cw at VEX",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 1179681,
					"ShortName": "Cw VIN",
					"LongName": "Cw at VIN",
					"UnitShort": "L/kPa",
					"UnitLong": "L/kPa",
					"Comment": null
				}, {
					"Id": 1179736,
					"ShortName": "D5-15%",
					"LongName": "Delta R5-R15",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1179733,
					"ShortName": "D5-20%",
					"LongName": "Delta R5-R20",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1179738,
					"ShortName": "Di5-15",
					"LongName": "Diff R5-R15",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179735,
					"ShortName": "Di5-20",
					"LongName": "Diff R5-R20",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179745,
					"ShortName": "Dstage",
					"LongName": "Stage·of·disease",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 1179747,
					"ShortName": "Error",
					"LongName": "Error Code",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 1179671,
					"ShortName": "Ers",
					"LongName": "Ers",
					"UnitShort": "kPa/L",
					"UnitLong": "kPa/L",
					"Comment": null
				}, {
					"Id": 1179721,
					"ShortName": "ErsE",
					"LongName": "Ers at VEX",
					"UnitShort": "kPa/L",
					"UnitLong": "kPa/L",
					"Comment": null
				}, {
					"Id": 1179696,
					"ShortName": "ErsI",
					"LongName": "Ers at VIN",
					"UnitShort": "kPa/L",
					"UnitLong": "kPa/L",
					"Comment": null
				}, {
					"Id": 1179727,
					"ShortName": "ERV",
					"LongName": "ERV",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 1179653,
					"ShortName": "Fres. ",
					"LongName": "Resonant frequency",
					"UnitShort": "Hz",
					"UnitLong": "Hertz ( 1/s )",
					"Comment": null
				}, {
					"Id": 1179703,
					"ShortName": "FreVEX",
					"LongName": "Fres. at VEX",
					"UnitShort": "Hz",
					"UnitLong": "Hertz ( 1/s )",
					"Comment": null
				}, {
					"Id": 1179678,
					"ShortName": "FreVIN",
					"LongName": "Fres. at VIN",
					"UnitShort": "Hz",
					"UnitLong": "Hertz ( 1/s )",
					"Comment": null
				}, {
					"Id": 1179746,
					"ShortName": "G5",
					"LongName": "Cond.·at·5·Hz",
					"UnitShort": "L/(cmH2O*s)",
					"UnitLong": "L/(cmH2O*s)",
					"Comment": null
				}, {
					"Id": 1179726,
					"ShortName": "IC",
					"LongName": "IC",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 1179743,
					"ShortName": "IH1",
					"LongName": "Inhomog. index 1",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179744,
					"ShortName": "IH2",
					"LongName": "Inhomog. index 2",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179724,
					"ShortName": "IOSMOD",
					"LongName": "IOSMOD",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 1179728,
					"ShortName": "IRV",
					"LongName": "IRV",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 1179658,
					"ShortName": "Lc    ",
					"LongName": "Lcentral",
					"UnitShort": "kPa/(L/s²)",
					"UnitLong": "kPa/(L/s²)",
					"Comment": null
				}, {
					"Id": 1179708,
					"ShortName": "Lz VEX",
					"LongName": "Lz at VEX",
					"UnitShort": "kPa/(L/s²)",
					"UnitLong": "kPa/(L/s²)",
					"Comment": null
				}, {
					"Id": 1179683,
					"ShortName": "Lz VIN",
					"LongName": "Lz at VIN",
					"UnitShort": "kPa/(L/s²)",
					"UnitLong": "kPa/(L/s²)",
					"Comment": null
				}, {
					"Id": 1179737,
					"ShortName": "MR5-15",
					"LongName": "Mean R5-R15",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179734,
					"ShortName": "MR5-20",
					"LongName": "Mean R5-R20",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179661,
					"ShortName": "R10Hz ",
					"LongName": "R at 10 Hz",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179711,
					"ShortName": "R10VEX",
					"LongName": "R 10Hz at VEX",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179686,
					"ShortName": "R10VIN",
					"LongName": "R 10Hz at VIN",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179663,
					"ShortName": "R15Hz ",
					"LongName": "R at 15 Hz",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179713,
					"ShortName": "R15VEX",
					"LongName": "R 15Hz at VEX",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179688,
					"ShortName": "R15VIN",
					"LongName": "R 15Hz at VIN ",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179665,
					"ShortName": "R20Hz ",
					"LongName": "R at 20 Hz",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179715,
					"ShortName": "R20VEX",
					"LongName": "R 20Hz at VEX",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179690,
					"ShortName": "R20VIN",
					"LongName": "R 20Hz at VIN",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179667,
					"ShortName": "R25Hz ",
					"LongName": "R at 25 Hz",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179717,
					"ShortName": "R25VEX",
					"LongName": "R 25Hz at VEX",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179692,
					"ShortName": "R25VIN",
					"LongName": "R 25Hz at VIN",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179739,
					"ShortName": "R30Hz",
					"LongName": "R at 30 Hz",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179669,
					"ShortName": "R35Hz ",
					"LongName": "R at 35 Hz",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179719,
					"ShortName": "R35VEX",
					"LongName": "R 35Hz at VEX",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179694,
					"ShortName": "R35VIN",
					"LongName": "R 35Hz at VIN",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179709,
					"ShortName": "R5 VEX",
					"LongName": "R 5 Hz at VEX",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179684,
					"ShortName": "R5 VIN",
					"LongName": "R 5 Hz at VIN",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179659,
					"ShortName": "R5Hz  ",
					"LongName": "R at 5 Hz",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179651,
					"ShortName": "Rc    ",
					"LongName": "R central",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179652,
					"ShortName": "Rp    ",
					"LongName": "R peripheral",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179702,
					"ShortName": "Rp VEX",
					"LongName": "Rp at VEX",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179677,
					"ShortName": "Rp VIN",
					"LongName": "Rp at VIN",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179701,
					"ShortName": "Rz VEX",
					"LongName": "Rz at VEX",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179676,
					"ShortName": "Rz VIN",
					"LongName": "Rc at VIN",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179731,
					"ShortName": "VC EX",
					"LongName": "VC EX",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 1179730,
					"ShortName": "VC IN",
					"LongName": "VC IN",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 1179729,
					"ShortName": "VC MAX",
					"LongName": "VC MAX",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 1179699,
					"ShortName": "VEXmax",
					"LongName": "VEX max",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 1179674,
					"ShortName": "VINmax",
					"LongName": "VIN max",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 1179649,
					"ShortName": "VT    ",
					"LongName": "VT",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 1179662,
					"ShortName": "X10Hz ",
					"LongName": "X at 10 Hz",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179712,
					"ShortName": "X10VEX",
					"LongName": "X 10Hz at VEX",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179687,
					"ShortName": "X10VIN",
					"LongName": "X 10Hz at VIN",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179664,
					"ShortName": "X15Hz ",
					"LongName": "X at 15 Hz",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179714,
					"ShortName": "X15VEX",
					"LongName": "X 15Hz at VEX",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179689,
					"ShortName": "X15VIN",
					"LongName": "X 15Hz at VIN",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179666,
					"ShortName": "X20Hz ",
					"LongName": "X at 20 Hz",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179716,
					"ShortName": "X20VEX",
					"LongName": "X 20Hz at VEX",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179691,
					"ShortName": "X20VIN",
					"LongName": "X 20Hz at VIN",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179668,
					"ShortName": "X25Hz ",
					"LongName": "X at 25 Hz",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179718,
					"ShortName": "X25VEX",
					"LongName": "X 25Hz at VEX",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179693,
					"ShortName": "X25VIN",
					"LongName": "X 25Hz at VIN",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179740,
					"ShortName": "X30Hz",
					"LongName": "X at 30 Hz",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179670,
					"ShortName": "X35Hz ",
					"LongName": "X at 35 Hz",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179720,
					"ShortName": "X35VEX",
					"LongName": "X 35Hz at VEX",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179695,
					"ShortName": "X35VIN",
					"LongName": "X 35Hz at VIN",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179748,
					"ShortName": "X3Hz c",
					"LongName": "X at 3 Hz corr",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179710,
					"ShortName": "X5 VEX",
					"LongName": "X 5 Hz at VEX",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179685,
					"ShortName": "X5 VIN",
					"LongName": "X 5 Hz at VIN",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179660,
					"ShortName": "X5Hz  ",
					"LongName": "X at 5 Hz",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179749,
					"ShortName": "X5Hz c",
					"LongName": "X at 5 Hz corr",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179650,
					"ShortName": "Z5Hz",
					"LongName": "Z at 5 Hz",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179700,
					"ShortName": "Z5-VEX",
					"LongName": "Z 5 Hz at VEX",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}, {
					"Id": 1179675,
					"ShortName": "Z5-VIN",
					"LongName": "Z 5 Hz at VIN",
					"UnitShort": "kPa/(L/s)",
					"UnitLong": "kPa/(L/s)",
					"Comment": null
				}
			]
		}, {
			"Id": 19,
			"Childs": [{
					"Id": 1901,
					"Childs": [],
					"ParentId": 19,
					"ShortName": "Capno MB",
					"LongName": "Multibreath",
					"Comment": null,
					"Parameters": [{
							"Id": 124583981,
							"ShortName": "",
							"LongName": "",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124583959,
							"ShortName": "A0",
							"LongName": "A0",
							"UnitShort": "%CO2*L",
							"UnitLong": "%CO2*L",
							"Comment": null
						}, {
							"Id": 124583960,
							"ShortName": "A1",
							"LongName": "A1",
							"UnitShort": "%CO2*L",
							"UnitLong": "%CO2*L",
							"Comment": null
						}, {
							"Id": 124583953,
							"ShortName": "BF",
							"LongName": "Breath. frequency",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 124583954,
							"ShortName": "CO2et",
							"LongName": "CO2 endtidal",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124583946,
							"ShortName": "CO2max",
							"LongName": "Maximal CO2 conc.",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124583944,
							"ShortName": "CV4",
							"LongName": "Closing Volume",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124583956,
							"ShortName": "dC/dV2",
							"LongName": "dC/dV2",
							"UnitShort": "%CO2/L",
							"UnitLong": "%CO2/L",
							"Comment": null
						}, {
							"Id": 124583957,
							"ShortName": "dC/dV3",
							"LongName": "dC/dV3",
							"UnitShort": "%CO2/L",
							"UnitLong": "%CO2/L",
							"Comment": null
						}, {
							"Id": 124583958,
							"ShortName": "dC/dV4",
							"LongName": "dC/dV4",
							"UnitShort": "%CO2/L",
							"UnitLong": "%CO2/L",
							"Comment": null
						}, {
							"Id": 124583977,
							"ShortName": "dC2/dt",
							"LongName": "dC2/dt",
							"UnitShort": "%CO2/s",
							"UnitLong": "%CO2/s",
							"Comment": null
						}, {
							"Id": 124583978,
							"ShortName": "dC3/dt",
							"LongName": "dC3/dt",
							"UnitShort": "%CO2/s",
							"UnitLong": "%CO2/s",
							"Comment": null
						}, {
							"Id": 124583941,
							"ShortName": "dV/dC2",
							"LongName": "dV/dC2",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 124583942,
							"ShortName": "dV/dC3",
							"LongName": "dV/dC3",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 124583943,
							"ShortName": "dV/dC4",
							"LongName": "dV/dC4",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 124583986,
							"ShortName": "Mod",
							"LongName": "CapnoMod",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124583982,
							"ShortName": "PaCO2",
							"LongName": "PaCO2",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 124583955,
							"ShortName": "PCO2et",
							"LongName": "PCO2 endtidal",
							"UnitShort": "mmHg",
							"UnitLong": "mmHg",
							"Comment": null
						}, {
							"Id": 124583945,
							"ShortName": "SR23%",
							"LongName": "Slope ratio",
							"UnitShort": "%/100",
							"UnitLong": "%/100",
							"Comment": null
						}, {
							"Id": 124583980,
							"ShortName": "SR32",
							"LongName": "Inv. Slope ratio",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124583979,
							"ShortName": "T3/T2",
							"LongName": "T3/T2",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124583976,
							"ShortName": "Tex",
							"LongName": "Exsp. Zeit",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 124583937,
							"ShortName": "Time",
							"LongName": "Time",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 124583975,
							"ShortName": "Tin",
							"LongName": "Insp. Zeit",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 124583973,
							"ShortName": "VCO2",
							"LongName": "CO2 Volumen",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124583985,
							"ShortName": "VD5%Tp",
							"LongName": "VD5%TLCp",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124583966,
							"ShortName": "VD-A a",
							"LongName": "VD-alv approx.",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124583972,
							"ShortName": "VD-A r",
							"LongName": "VD-alv appr. rel",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124583984,
							"ShortName": "VD-Alv",
							"LongName": "VD-Alv",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124583965,
							"ShortName": "VD-B",
							"LongName": "VD-Bohr",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124583971,
							"ShortName": "VD-B r",
							"LongName": "VD-Bohr rel",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124583963,
							"ShortName": "VD-F",
							"LongName": "VD-Fowler",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124583969,
							"ShortName": "VD-F r",
							"LongName": "VD-Fowler rel",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124583962,
							"ShortName": "VD-L",
							"LongName": "VD-Langley",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124583968,
							"ShortName": "VD-L r",
							"LongName": "VD-Langley rel.",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124583983,
							"ShortName": "VD-Phy",
							"LongName": "VD-Phys",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124583961,
							"ShortName": "VD-T",
							"LongName": "VD-Threshold",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124583967,
							"ShortName": "VD-T r",
							"LongName": "VD-Threshold rel.",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124583964,
							"ShortName": "VD-W",
							"LongName": "VD-Wolff",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124583970,
							"ShortName": "VD-W r",
							"LongName": "VD-Wolff rel",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124583974,
							"ShortName": "Vent",
							"LongName": "Ventil. equivalent",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 124583950,
							"ShortName": "V'ex",
							"LongName": "mean exp. Flow",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 124583949,
							"ShortName": "V'in",
							"LongName": "mean insp. Flow",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 124583952,
							"ShortName": "VLex",
							"LongName": "Volume level exp.",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 124583951,
							"ShortName": "VLin",
							"LongName": "Volume level insp.",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 124583939,
							"ShortName": "Vm2550",
							"LongName": "Vm25-50",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124583940,
							"ShortName": "Vm5075",
							"LongName": "Vm50-75",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124583938,
							"ShortName": "VT",
							"LongName": "VT",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 124583948,
							"ShortName": "VTex",
							"LongName": "VTex",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 124583947,
							"ShortName": "VTin",
							"LongName": "VTin",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}, {
					"Id": 1902,
					"Childs": [],
					"ParentId": 19,
					"ShortName": "Capno SB",
					"LongName": "Single-Breath",
					"Comment": null,
					"Parameters": [{
							"Id": 124649517,
							"ShortName": "",
							"LongName": "",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124649518,
							"ShortName": "",
							"LongName": "",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124649519,
							"ShortName": "",
							"LongName": "",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124649520,
							"ShortName": "",
							"LongName": "",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124649521,
							"ShortName": "",
							"LongName": "",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124649495,
							"ShortName": "A0",
							"LongName": "A0",
							"UnitShort": "%CO2*L",
							"UnitLong": "%CO2*L",
							"Comment": null
						}, {
							"Id": 124649496,
							"ShortName": "A1",
							"LongName": "A1",
							"UnitShort": "%CO2*L",
							"UnitLong": "%CO2*L",
							"Comment": null
						}, {
							"Id": 124649489,
							"ShortName": "BF",
							"LongName": "Breath. frequency",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 124649490,
							"ShortName": "CO2et",
							"LongName": "CO2 endtidal",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124649482,
							"ShortName": "CO2max",
							"LongName": "Maximal CO2 conc.",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124649480,
							"ShortName": "CV4",
							"LongName": "Closing Volume",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124649492,
							"ShortName": "dC/dV2",
							"LongName": "dC/dV2",
							"UnitShort": "%CO2/L",
							"UnitLong": "%CO2/L",
							"Comment": null
						}, {
							"Id": 124649493,
							"ShortName": "dC/dV3",
							"LongName": "dC/dV3",
							"UnitShort": "%CO2/L",
							"UnitLong": "%CO2/L",
							"Comment": null
						}, {
							"Id": 124649494,
							"ShortName": "dC/dV4",
							"LongName": "dC/dV4",
							"UnitShort": "%CO2/L",
							"UnitLong": "%CO2/L",
							"Comment": null
						}, {
							"Id": 124649513,
							"ShortName": "dC2/dt",
							"LongName": "dC2/dt",
							"UnitShort": "%CO2/s",
							"UnitLong": "%CO2/s",
							"Comment": null
						}, {
							"Id": 124649514,
							"ShortName": "dC3/dt",
							"LongName": "dC3/dt",
							"UnitShort": "%CO2/s",
							"UnitLong": "%CO2/s",
							"Comment": null
						}, {
							"Id": 124649477,
							"ShortName": "dV/dC2",
							"LongName": "dV/dC2",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 124649478,
							"ShortName": "dV/dC3",
							"LongName": "dV/dC3",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 124649479,
							"ShortName": "dV/dC4",
							"LongName": "dV/dC4",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 124649522,
							"ShortName": "Mod",
							"LongName": "CapnoMod",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124649491,
							"ShortName": "PCO2et",
							"LongName": "PCO2 endtidal",
							"UnitShort": "mmHg",
							"UnitLong": "mmHg",
							"Comment": null
						}, {
							"Id": 124649481,
							"ShortName": "SR23%",
							"LongName": "Slope ratio",
							"UnitShort": "%/100",
							"UnitLong": "%/100",
							"Comment": null
						}, {
							"Id": 124649516,
							"ShortName": "SR32",
							"LongName": "Inv. Slope ratio",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124649515,
							"ShortName": "T3/T2",
							"LongName": "T3/T2",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124649512,
							"ShortName": "Tex",
							"LongName": "Exsp. Zeit",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 124649473,
							"ShortName": "Time",
							"LongName": "Time",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 124649511,
							"ShortName": "Tin",
							"LongName": "Insp. Zeit",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 124649509,
							"ShortName": "VCO2",
							"LongName": "CO2 Volumen",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124649502,
							"ShortName": "VD-A a",
							"LongName": "VD-alv approx.",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124649508,
							"ShortName": "VD-A r",
							"LongName": "VD-alv appr. rel",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124649501,
							"ShortName": "VD-B",
							"LongName": "VD-Bohr",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124649507,
							"ShortName": "VD-B r",
							"LongName": "VD-Bohr rel",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124649499,
							"ShortName": "VD-F",
							"LongName": "VD-Fowler",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124649505,
							"ShortName": "VD-F r",
							"LongName": "VD-Fowler rel",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124649498,
							"ShortName": "VD-L",
							"LongName": "VD-Langley",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124649504,
							"ShortName": "VD-L r",
							"LongName": "VD-Langley rel.",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124649497,
							"ShortName": "VD-T",
							"LongName": "VD-Threshold",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124649503,
							"ShortName": "VD-T r",
							"LongName": "VD-Threshold rel.",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124649500,
							"ShortName": "VD-W",
							"LongName": "VD-Wolff",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124649506,
							"ShortName": "VD-W r",
							"LongName": "VD-Wolff rel",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124649510,
							"ShortName": "Vent",
							"LongName": "Ventil. equivalent",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 124649486,
							"ShortName": "V'ex",
							"LongName": "mean exp. Flow",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 124649485,
							"ShortName": "V'in",
							"LongName": "mean insp. Flow",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 124649488,
							"ShortName": "VLex",
							"LongName": "Volume level exsp.",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 124649487,
							"ShortName": "VLin",
							"LongName": "Volume level insp.",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 124649475,
							"ShortName": "Vm2550",
							"LongName": "Vm25-50",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124649476,
							"ShortName": "Vm5075",
							"LongName": "Vm50-75",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124649474,
							"ShortName": "VT",
							"LongName": "VT",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 124649484,
							"ShortName": "VTex",
							"LongName": "VTex",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 124649483,
							"ShortName": "VTin",
							"LongName": "VTin",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}, {
					"Id": 1903,
					"Childs": [],
					"ParentId": 19,
					"ShortName": "Emphysema diagn.",
					"LongName": "Emphysema diagnostics",
					"Comment": null,
					"Parameters": [{
							"Id": 124715009,
							"ShortName": "2550a0",
							"LongName": "rVm2550 a0 (Interc)",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124715010,
							"ShortName": "2550a1",
							"LongName": "rVm2550 a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715011,
							"ShortName": "2550r",
							"LongName": "rVm2550 r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715012,
							"ShortName": "5075a0",
							"LongName": "rVm5075 a0 (Interc)",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124715013,
							"ShortName": "5075a1",
							"LongName": "rVm5075 a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715014,
							"ShortName": "5075r",
							"LongName": "rVm5075 r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715026,
							"ShortName": "CO2m r",
							"LongName": "rCO2max r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715024,
							"ShortName": "CO2ma0",
							"LongName": "rCO2max a0 (Intercept)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124715025,
							"ShortName": "CO2ma1",
							"LongName": "rCO2max a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715063,
							"ShortName": "dCV2a0",
							"LongName": "rdCdV2 a0 (Ic)",
							"UnitShort": "%CO2/L",
							"UnitLong": "%CO2/L",
							"Comment": null
						}, {
							"Id": 124715064,
							"ShortName": "dCV2a1",
							"LongName": "rdCdV2 a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715065,
							"ShortName": "dCV2r",
							"LongName": "rdCdV2 r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715066,
							"ShortName": "dCV3a0",
							"LongName": "rdCdV3 a0 (Ic)",
							"UnitShort": "%CO2/L",
							"UnitLong": "%CO2/L",
							"Comment": null
						}, {
							"Id": 124715067,
							"ShortName": "dCV3a1",
							"LongName": "rdCdV3 a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715068,
							"ShortName": "dCV3r",
							"LongName": "rdCdV3 r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715015,
							"ShortName": "dVC2a0",
							"LongName": "rdVdC2 a0 (Ic)",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 124715016,
							"ShortName": "dVC2a1",
							"LongName": "rdVdC2 a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715017,
							"ShortName": "dVC2r",
							"LongName": "rdVdC2 r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715018,
							"ShortName": "dVC3a0",
							"LongName": "rdVdC3 a0 (Ic)",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 124715019,
							"ShortName": "dVC3a1",
							"LongName": "rdVdC3 a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715020,
							"ShortName": "dVC3r",
							"LongName": "rdVdC3 r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715021,
							"ShortName": "SR32a0",
							"LongName": "rSR32 a0 (Intercept)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124715022,
							"ShortName": "SR32a1",
							"LongName": "rSR32 a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715023,
							"ShortName": "SR32r",
							"LongName": "rSR32 r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715060,
							"ShortName": "VARa0",
							"LongName": "rVAR a0 (Intercept)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715061,
							"ShortName": "VARa1",
							"LongName": "rVAR a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715062,
							"ShortName": "VARr",
							"LongName": "rVAR r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715057,
							"ShortName": "VBRa0",
							"LongName": "rVBR a0 (Intercept)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715058,
							"ShortName": "VBRa1",
							"LongName": "rVBR a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715059,
							"ShortName": "VBRr",
							"LongName": "rVBR r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715042,
							"ShortName": "VDAa0",
							"LongName": "rVDA a0 (Intercept)",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124715043,
							"ShortName": "VDAa1",
							"LongName": "rVDA a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715044,
							"ShortName": "VDAr",
							"LongName": "rVDA r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715039,
							"ShortName": "VDBa0",
							"LongName": "rVDB a0 (Intercept)",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124715040,
							"ShortName": "VDBa1",
							"LongName": "rVDB a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715041,
							"ShortName": "VDBr",
							"LongName": "rVDB r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715033,
							"ShortName": "VDFa0",
							"LongName": "rVDF a0 (Intercept)",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124715034,
							"ShortName": "VDFa1",
							"LongName": "rVDF a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715035,
							"ShortName": "VDFr",
							"LongName": "rVDF r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715030,
							"ShortName": "VDLa0",
							"LongName": "rVDL a0 (Intercept)",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124715031,
							"ShortName": "VDLa1",
							"LongName": "rVDL a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715032,
							"ShortName": "VDLr",
							"LongName": "rVDL r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715027,
							"ShortName": "VDTa0",
							"LongName": "rVDT a0 (Intercept)",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124715028,
							"ShortName": "VDTa1",
							"LongName": "rVDT a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715029,
							"ShortName": "VDTr",
							"LongName": "rVDT r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715036,
							"ShortName": "VDWa0",
							"LongName": "rVDW a0 (Intercept)",
							"UnitShort": "mL",
							"UnitLong": "mL",
							"Comment": null
						}, {
							"Id": 124715037,
							"ShortName": "VDWa1",
							"LongName": "rVDW a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715038,
							"ShortName": "VDWr",
							"LongName": "rVDW r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715051,
							"ShortName": "VFRa0",
							"LongName": "rVFR a0 (Intercept)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715052,
							"ShortName": "VFRa1",
							"LongName": "rVFR a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715053,
							"ShortName": "VFRr",
							"LongName": "rVFR r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715048,
							"ShortName": "VLRa0",
							"LongName": "rVLR a0 (Intercept)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715049,
							"ShortName": "VLRa1",
							"LongName": "rVLR a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715050,
							"ShortName": "VLRr",
							"LongName": "rVLR r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715045,
							"ShortName": "VTRa0",
							"LongName": "rVTR a0 (Intercept)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715046,
							"ShortName": "VTRa1",
							"LongName": "rVTR a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715047,
							"ShortName": "VTRr",
							"LongName": "rVTR r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715054,
							"ShortName": "VWRa0",
							"LongName": "rVWR a0 (Intercept)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715055,
							"ShortName": "VWRa1",
							"LongName": "rVWR a1 (Slope)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 124715056,
							"ShortName": "VWRr",
							"LongName": "rVWR r (Qualität)",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}
					]
				}, {
					"Id": 1904,
					"Childs": [],
					"ParentId": 19,
					"ShortName": "Hypervent.",
					"LongName": "Hyperventilation test",
					"Comment": null,
					"Parameters": [{
							"Id": 124780547,
							"ShortName": "hCO2%m",
							"LongName": "hCO2%mean",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780546,
							"ShortName": "hCO2et",
							"LongName": "hypventCO2et",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780545,
							"ShortName": "mCO2et",
							"LongName": "meanCO2et",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780549,
							"ShortName": "r1CO2%",
							"LongName": "r1mCO2%mean",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780548,
							"ShortName": "r1mCO2",
							"LongName": "recover1mCO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780551,
							"ShortName": "r2CO2%",
							"LongName": "r2mCO2%mean",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780550,
							"ShortName": "r2mCO2",
							"LongName": "recover2mCO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780553,
							"ShortName": "r3CO2%",
							"LongName": "r3mCO2%mean",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780552,
							"ShortName": "r3mCO2",
							"LongName": "recover3mCO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780555,
							"ShortName": "r4CO2%",
							"LongName": "r4mCO2%mean",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780554,
							"ShortName": "r4mCO2",
							"LongName": "recover4mCO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780557,
							"ShortName": "r5CO2%",
							"LongName": "r5mCO2%mean",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780556,
							"ShortName": "r5mCO2",
							"LongName": "recover5mCO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780559,
							"ShortName": "r6CO2%",
							"LongName": "r6mCO2%mean",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780558,
							"ShortName": "r6mCO2",
							"LongName": "recover6mCO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780561,
							"ShortName": "r7CO2%",
							"LongName": "r7mCO2%mean",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780560,
							"ShortName": "r7mCO2",
							"LongName": "recover7mCO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780563,
							"ShortName": "r8CO2%",
							"LongName": "r8mCO2%mean",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780562,
							"ShortName": "r8mCO2",
							"LongName": "recover8mCO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780565,
							"ShortName": "r9CO2%",
							"LongName": "r9mCO2%mean",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 124780564,
							"ShortName": "r9mCO2",
							"LongName": "recover9mCO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}
					]
				}
			],
			"ParentId": 0,
			"ShortName": "Capno",
			"LongName": "Capnography",
			"Comment": null,
			"Parameters": [{
					"Id": 1245229,
					"ShortName": "",
					"LongName": "",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1245230,
					"ShortName": "",
					"LongName": "",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1245231,
					"ShortName": "",
					"LongName": "",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1245232,
					"ShortName": "",
					"LongName": "",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1245233,
					"ShortName": "",
					"LongName": "",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1245207,
					"ShortName": "A0",
					"LongName": "A0",
					"UnitShort": "%CO2*L",
					"UnitLong": "%CO2*L",
					"Comment": null
				}, {
					"Id": 1245208,
					"ShortName": "A1",
					"LongName": "A1",
					"UnitShort": "%CO2*L",
					"UnitLong": "%CO2*L",
					"Comment": null
				}, {
					"Id": 1245201,
					"ShortName": "BF",
					"LongName": "Breath. frequency",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": null
				}, {
					"Id": 1245202,
					"ShortName": "CO2et",
					"LongName": "CO2 endtidal",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1245194,
					"ShortName": "CO2max",
					"LongName": "Maximal CO2 conc.",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1245192,
					"ShortName": "CV4",
					"LongName": "Closing Volume",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 1245204,
					"ShortName": "dC/dV2",
					"LongName": "dC/dV2",
					"UnitShort": "%CO2/L",
					"UnitLong": "%CO2/L",
					"Comment": null
				}, {
					"Id": 1245205,
					"ShortName": "dC/dV3",
					"LongName": "dC/dV3",
					"UnitShort": "%CO2/L",
					"UnitLong": "%CO2/L",
					"Comment": null
				}, {
					"Id": 1245206,
					"ShortName": "dC/dV4",
					"LongName": "dC/dV4",
					"UnitShort": "%CO2/L",
					"UnitLong": "%CO2/L",
					"Comment": null
				}, {
					"Id": 1245225,
					"ShortName": "dC2/dt",
					"LongName": "dC2/dt",
					"UnitShort": "%CO2/s",
					"UnitLong": "%CO2/s",
					"Comment": null
				}, {
					"Id": 1245226,
					"ShortName": "dC3/dt",
					"LongName": "dC3/dt",
					"UnitShort": "%CO2/s",
					"UnitLong": "%CO2/s",
					"Comment": null
				}, {
					"Id": 1245189,
					"ShortName": "dV/dC2",
					"LongName": "dV/dC2",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 1245190,
					"ShortName": "dV/dC3",
					"LongName": "dV/dC3",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 1245191,
					"ShortName": "dV/dC4",
					"LongName": "dV/dC4",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 1245234,
					"ShortName": "Mod",
					"LongName": "CapnoMod",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1245203,
					"ShortName": "PCO2et",
					"LongName": "PCO2 endtidal",
					"UnitShort": "mmHg",
					"UnitLong": "mmHg",
					"Comment": null
				}, {
					"Id": 1245193,
					"ShortName": "SR23%",
					"LongName": "Slope ratio",
					"UnitShort": "%/100",
					"UnitLong": "%/100",
					"Comment": null
				}, {
					"Id": 1245228,
					"ShortName": "SR32",
					"LongName": "Inv. Slope ratio",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1245227,
					"ShortName": "T3/T2",
					"LongName": "T3/T2",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1245224,
					"ShortName": "Tex",
					"LongName": "Exsp. Zeit",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 1245185,
					"ShortName": "Time",
					"LongName": "Time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 1245223,
					"ShortName": "Tin",
					"LongName": "Insp. Zeit",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 1245221,
					"ShortName": "VCO2",
					"LongName": "CO2 Volumen",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 1245214,
					"ShortName": "VD-A a",
					"LongName": "VD-alv approx.",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 1245220,
					"ShortName": "VD-A r",
					"LongName": "VD-alv appr. rel",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1245213,
					"ShortName": "VD-B",
					"LongName": "VD-Bohr",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 1245219,
					"ShortName": "VD-B r",
					"LongName": "VD-Bohr rel",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1245211,
					"ShortName": "VD-F",
					"LongName": "VD-Fowler",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 1245217,
					"ShortName": "VD-F r",
					"LongName": "VD-Fowler rel",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1245210,
					"ShortName": "VD-L",
					"LongName": "VD-Langley",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 1245216,
					"ShortName": "VD-L r",
					"LongName": "VD-Langley rel.",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1245209,
					"ShortName": "VD-T",
					"LongName": "VD-Threshold",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 1245215,
					"ShortName": "VD-T r",
					"LongName": "VD-Threshold rel.",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1245212,
					"ShortName": "VD-W",
					"LongName": "VD-Wolff",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 1245218,
					"ShortName": "VD-W r",
					"LongName": "VD-Wolff rel",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1245222,
					"ShortName": "Vent",
					"LongName": "Ventil. equivalent",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": null
				}, {
					"Id": 1245198,
					"ShortName": "V'ex",
					"LongName": "mean exp. Flow",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 1245197,
					"ShortName": "V'in",
					"LongName": "mean insp. Flow",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 1245200,
					"ShortName": "VLex",
					"LongName": "Volume level exsp.",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 1245199,
					"ShortName": "VLin",
					"LongName": "Volume level insp.",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 1245187,
					"ShortName": "Vm2550",
					"LongName": "Vm25-50",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 1245188,
					"ShortName": "Vm5075",
					"LongName": "Vm50-75",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 1245186,
					"ShortName": "VT",
					"LongName": "VT",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 1245196,
					"ShortName": "VTex",
					"LongName": "VTex",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 1245195,
					"ShortName": "VTin",
					"LongName": "VTin",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}
			]
		}, {
			"Id": 22,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "Tidal",
			"LongName": "Tidal breathing",
			"Comment": null,
			"Parameters": [{
					"Id": 1441830,
					"ShortName": "Cnf 1 ",
					"LongName": "Configuration 1",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1441831,
					"ShortName": "Cnf 2 ",
					"LongName": "Configuration 2",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1441836,
					"ShortName": "M_BEST",
					"LongName": "Manual·Best",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1441837,
					"ShortName": "M_SLOPE",
					"LongName": "Manual Slope",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1441816,
					"ShortName": "MTEF  ",
					"LongName": "Mean exsp. flow",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 1441815,
					"ShortName": "MTIF",
					"LongName": "Mean insp. flow",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 1441808,
					"ShortName": "MV    ",
					"LongName": "Minute ventilation",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": null
				}, {
					"Id": 1441826,
					"ShortName": "MV/Kg",
					"LongName": "MV/Kg",
					"UnitShort": "(L/min)/kg",
					"UnitLong": "(L/min)/kg",
					"Comment": null
				}, {
					"Id": 1441838,
					"ShortName": "O_TRIAL",
					"LongName": "Original Trial",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1441828,
					"ShortName": "PEF%PIF",
					"LongName": "PEF%PIF",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1441834,
					"ShortName": "PEF%VT",
					"LongName": "PTEF as % VT",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1441835,
					"ShortName": "PF%T25",
					"LongName": "PTEF as % TEF25",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1441827,
					"ShortName": "PIF",
					"LongName": "Peak·insp·flow",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 1441817,
					"ShortName": "PTEF",
					"LongName": "Peak exsp. flow",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 1441821,
					"ShortName": "Res1",
					"LongName": "Res Tidal1",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1441822,
					"ShortName": "Res2",
					"LongName": "Res Tidal2",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1441807,
					"ShortName": "RR",
					"LongName": "Respiratory rate",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": null
				}, {
					"Id": 1441810,
					"ShortName": "tE",
					"LongName": "Expiratory time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 1441813,
					"ShortName": "tE/tT",
					"LongName": "Ratio tE to tTot",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1441833,
					"ShortName": "TEF%IF",
					"LongName": "TEF50 as % TIF50",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1441820,
					"ShortName": "TEF25",
					"LongName": "TEF 25% remaining",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 1441819,
					"ShortName": "TEF50",
					"LongName": "TEF 50% remaining",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 1441818,
					"ShortName": "TEF75",
					"LongName": "TEF 75% remaining",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 1441809,
					"ShortName": "tI",
					"LongName": "Inspiratory time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 1441814,
					"ShortName": "tI/tE",
					"LongName": "Ratio tI to tE",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1441812,
					"ShortName": "tI/tT",
					"LongName": "Ratio tI to tTot",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1441832,
					"ShortName": "TIF50",
					"LongName": "TIF at 50% of VT",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 1441794,
					"ShortName": "tPF%tE",
					"LongName": "tPTEF%tE (mean)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1441793,
					"ShortName": "tPTEF",
					"LongName": "Time to PTEF",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 1441797,
					"ShortName": "tPtE-H",
					"LongName": "tPTEF%tE (highest)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1441796,
					"ShortName": "tPtE-L",
					"LongName": "tPTEF%tE (lowest)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1441798,
					"ShortName": "tPtE-n",
					"LongName": "tPTEF%tE (number)",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1441795,
					"ShortName": "tPtE-s",
					"LongName": "tPTEF%tE (SD)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1441811,
					"ShortName": "tTot",
					"LongName": "Total breath time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 1441824,
					"ShortName": "Vers  ",
					"LongName": "Version",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1441829,
					"ShortName": "VPef",
					"LongName": "volume at peak flow",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1441799,
					"ShortName": "VPEF",
					"LongName": "Exp. volume at PTEF",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 1441800,
					"ShortName": "VPF%VE",
					"LongName": "VPEF%VE (mean)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1441803,
					"ShortName": "VPVE-H",
					"LongName": "VPEF%VE (highest)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1441802,
					"ShortName": "VPVE-L",
					"LongName": "VPEF%VE (lowest)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1441804,
					"ShortName": "VPVE-n",
					"LongName": "VPEF%VE (number)",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1441801,
					"ShortName": "VPVE-s",
					"LongName": "VPEF%VE (SD)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1441805,
					"ShortName": "VT",
					"LongName": "Tidal Volume",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 1441825,
					"ShortName": "VT/kg",
					"LongName": "Tidal volume / kg",
					"UnitShort": "L/kg",
					"UnitLong": "L/kg",
					"Comment": null
				}, {
					"Id": 1441806,
					"ShortName": "VT-CV",
					"LongName": "Coeff. of var. for VT",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 1441823,
					"ShortName": "VTn",
					"LongName": "No. of valid VT",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}
			]
		}, {
			"Id": 25,
			"Childs": [{
					"Id": 2501,
					"Childs": [],
					"ParentId": 25,
					"ShortName": "Rhino L",
					"LongName": "Rhino Left",
					"Comment": null,
					"Parameters": [{
							"Id": 163905568,
							"ShortName": "DEL1-3",
							"LongName": "Delta FEL 150 - 300",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163905566,
							"ShortName": "DEL7-1",
							"LongName": "Delta FEL 75 - 150",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163905567,
							"ShortName": "DIL1-3",
							"LongName": "Delta FIL 150 - 300",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163905565,
							"ShortName": "DIL7-1",
							"LongName": "Delta FIL 75 - 150",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163905551,
							"ShortName": "FEL ",
							"LongName": "Flow ex L",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163905556,
							"ShortName": "FEL1/7",
							"LongName": "Flow ex L 150 / 75",
							"UnitShort": " ",
							"UnitLong": "numerical ratio without percent",
							"Comment": null
						}, {
							"Id": 163905543,
							"ShortName": "FEL150",
							"LongName": "Flow ex L 150",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163905554,
							"ShortName": "FEL3/1",
							"LongName": "Flow ex L 300 / 150",
							"UnitShort": " ",
							"UnitLong": "numerical ratio without percent",
							"Comment": null
						}, {
							"Id": 163905547,
							"ShortName": "FEL300",
							"LongName": "Flow ex L 300",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163905539,
							"ShortName": "FEL75",
							"LongName": "Flow ex L 75",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163905558,
							"ShortName": "FELmax",
							"LongName": "Flow ex L max",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163905549,
							"ShortName": "FIL ",
							"LongName": "Flow in L",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163905555,
							"ShortName": "FIL1/7",
							"LongName": "Flow in L 150 / 75",
							"UnitShort": " ",
							"UnitLong": "numerical ratio without percent",
							"Comment": null
						}, {
							"Id": 163905541,
							"ShortName": "FIL150",
							"LongName": "Flow in L 150",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163905553,
							"ShortName": "FIL3/1",
							"LongName": "Flow in L 300 / 150",
							"UnitShort": " ",
							"UnitLong": "numerical ratio without percent",
							"Comment": null
						}, {
							"Id": 163905545,
							"ShortName": "FIL300",
							"LongName": "Flow in L 300",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163905537,
							"ShortName": "FIL75 ",
							"LongName": "Flow in L 75",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163905557,
							"ShortName": "FILmax",
							"LongName": "Flow in L max",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163905578,
							"ShortName": "LRefEL",
							"LongName": "Log Reff ex Left",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163905576,
							"ShortName": "LReffL",
							"LongName": "Log Reff  Left",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163905577,
							"ShortName": "LRefIL",
							"LongName": "Log Reff in Left",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163905572,
							"ShortName": "LVR EL",
							"LongName": "Log VR ex Left",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163905571,
							"ShortName": "LVR IL",
							"LongName": "Log VR in Left",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163905573,
							"ShortName": "Reff L",
							"LongName": "Reff Left",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163905575,
							"ShortName": "ReffEL",
							"LongName": "Reff ex Left",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163905574,
							"ShortName": "ReffIL",
							"LongName": "Reff in Left",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163905552,
							"ShortName": "REL ",
							"LongName": "RAAR ex L",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163905544,
							"ShortName": "REL150",
							"LongName": "RAAR ex L 150",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163905548,
							"ShortName": "REL300",
							"LongName": "RAAR ex L 300",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163905540,
							"ShortName": "REL75",
							"LongName": "RAAR ex L 75",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163905550,
							"ShortName": "RIL ",
							"LongName": "RAAR in L",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163905542,
							"ShortName": "RIL150",
							"LongName": "RAAR in L 150",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163905546,
							"ShortName": "RIL300",
							"LongName": "RAAR in L 300",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163905538,
							"ShortName": "RIL75 ",
							"LongName": "RAAR in L 75",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163905564,
							"ShortName": "TCongJ",
							"LongName": "Total Congestion (JLab)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163905579,
							"ShortName": "TCongL",
							"LongName": "Total Congestion Left",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 163905563,
							"ShortName": "Valid",
							"LongName": "Valid (JLab)",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 163905559,
							"ShortName": "VarIL",
							"LongName": "Var. coeffic. FIL (JLab)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163905561,
							"ShortName": "VarIL1",
							"LongName": "Var. coeffic. FIL 150 (JLab)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163905562,
							"ShortName": "VarIL3",
							"LongName": "Var. coeffic. FIL 300 (JLab)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163905560,
							"ShortName": "VarIL7",
							"LongName": "Var. coeffic. FIL 75 (JLab)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163905570,
							"ShortName": "VR EL",
							"LongName": "VR ex Left",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163905569,
							"ShortName": "VR IL",
							"LongName": "VR in Left ",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}
					]
				}, {
					"Id": 2502,
					"Childs": [],
					"ParentId": 25,
					"ShortName": "Rhino R",
					"LongName": "Rhino Right",
					"Comment": null,
					"Parameters": [{
							"Id": 163971104,
							"ShortName": "DER1-3",
							"LongName": "Delta FER 150 - 300",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163971102,
							"ShortName": "DER7-1",
							"LongName": "Delta FER 75 - 150",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163971103,
							"ShortName": "DIR1-3",
							"LongName": "Delta FIR 150 - 300",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163971101,
							"ShortName": "DIR7-1",
							"LongName": "Delta FIR 75 - 150",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163971087,
							"ShortName": "FER ",
							"LongName": "Flow ex R",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163971092,
							"ShortName": "FER1/7",
							"LongName": "Flow ex R 150 / 75",
							"UnitShort": " ",
							"UnitLong": "numerical ratio without percent",
							"Comment": null
						}, {
							"Id": 163971079,
							"ShortName": "FER150",
							"LongName": "Flow ex R 150",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163971090,
							"ShortName": "FER3/1",
							"LongName": "Flow ex R 300 / 150",
							"UnitShort": " ",
							"UnitLong": "numerical ratio without percent",
							"Comment": null
						}, {
							"Id": 163971083,
							"ShortName": "FER300",
							"LongName": "Flow ex R 300",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163971075,
							"ShortName": "FER75",
							"LongName": "Flow ex R 75",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163971094,
							"ShortName": "FERmax",
							"LongName": "Flow ex R max",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163971085,
							"ShortName": "FIR ",
							"LongName": "Flow in R",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163971091,
							"ShortName": "FIR1/7",
							"LongName": "Flow in R 150 / 75",
							"UnitShort": " ",
							"UnitLong": "numerical ratio without percent",
							"Comment": null
						}, {
							"Id": 163971077,
							"ShortName": "FIR150",
							"LongName": "Flow in R 150",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163971089,
							"ShortName": "FIR3/1",
							"LongName": "Flow in R 300 / 150",
							"UnitShort": " ",
							"UnitLong": "numerical ratio without percent",
							"Comment": null
						}, {
							"Id": 163971081,
							"ShortName": "FIR300",
							"LongName": "Flow in R 300",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163971073,
							"ShortName": "FIR75 ",
							"LongName": "Flow in R 75",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163971093,
							"ShortName": "FIRmax",
							"LongName": "Flow in R max",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 163971114,
							"ShortName": "LRefER",
							"LongName": "Log Reff ex Right",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163971112,
							"ShortName": "LReffR",
							"LongName": "Log Reff Right",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163971113,
							"ShortName": "LRefIR",
							"LongName": "Log Reff in Right",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163971108,
							"ShortName": "LVR ER",
							"LongName": "Log VR ex Right",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163971107,
							"ShortName": "LVR IR",
							"LongName": "Log VR in Right",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163971109,
							"ShortName": "Reff R",
							"LongName": "Reff Right",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163971111,
							"ShortName": "ReffER",
							"LongName": "Reff ex Right",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163971110,
							"ShortName": "ReffIR",
							"LongName": "Reff in Right",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163971088,
							"ShortName": "RER ",
							"LongName": "RAAR ex R",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163971080,
							"ShortName": "RER150",
							"LongName": "RAAR ex R 150",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163971084,
							"ShortName": "RER300",
							"LongName": "RAAR ex R 300",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163971076,
							"ShortName": "RER75",
							"LongName": "RAAR ex R 75",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163971086,
							"ShortName": "RIR  ",
							"LongName": "RAAR in R",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163971078,
							"ShortName": "RIR150",
							"LongName": "RAAR in R 150",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163971082,
							"ShortName": "RIR300",
							"LongName": "RAAR in R 300",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163971074,
							"ShortName": "RIR75 ",
							"LongName": "RAAR in R 75",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 163971100,
							"ShortName": "TCongJ",
							"LongName": "Total Congestion (JLab)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163971115,
							"ShortName": "TCongR",
							"LongName": "Total Congestion Right",
							"UnitShort": null,
							"UnitLong": null,
							"Comment": null
						}, {
							"Id": 163971099,
							"ShortName": "Valid",
							"LongName": "Valid (JLab)",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 163971095,
							"ShortName": "VarIR",
							"LongName": "Var. coeffic. FIR (JLab)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163971097,
							"ShortName": "VarIR1",
							"LongName": "Var. coeffic. FIR 150 (JLab)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163971098,
							"ShortName": "VarIR3",
							"LongName": "Var. coeffic. FIR 300 (JLab)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163971096,
							"ShortName": "VarIR7",
							"LongName": "Var. coeffic. FIR 75 (JLab)",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 163971106,
							"ShortName": "VR ER",
							"LongName": "VR ex Right",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 163971105,
							"ShortName": "VR IR",
							"LongName": "VR in Right ",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}
					]
				}
			],
			"ParentId": 0,
			"ShortName": "Rhino",
			"LongName": "Rhinomanometry",
			"Comment": null,
			"Parameters": [{
					"Id": 1638405,
					"ShortName": "AdapSz",
					"LongName": "Adapter size",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1638402,
					"ShortName": "FSUME",
					"LongName": "Flow sum ex",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 1638401,
					"ShortName": "FSUMI",
					"LongName": "Flow sum in",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 1638403,
					"ShortName": "P meas",
					"LongName": "Pressure marker",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 1638406,
					"ShortName": "SideS",
					"LongName": "Side substance (0=R, 1=L)",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1638404,
					"ShortName": "SQ    ",
					"LongName": "Side ratio",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 1638407,
					"ShortName": "Step",
					"LongName": "Step",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}
			]
		}, {
			"Id": 36,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "Inhaler",
			"LongName": "Inhaler",
			"Comment": null,
			"Parameters": [{
					"Id": 2359323,
					"ShortName": "EFPF",
					"LongName": "EFPF (Parts < 2.1)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 2359305,
					"ShortName": "Fm100",
					"LongName": "FlowMean100",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 2359306,
					"ShortName": "Fm200",
					"LongName": "FlowMean200",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 2359307,
					"ShortName": "Fm300",
					"LongName": "FlowMean300",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 2359322,
					"ShortName": "FPF",
					"LongName": "FPF (Parts < 4.7)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 2359304,
					"ShortName": "Ftrig",
					"LongName": "Flow trigger",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 2359314,
					"ShortName": "Inh No",
					"LongName": "Inhaler No.",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 2359308,
					"ShortName": "mPIF",
					"LongName": "Slope PIF",
					"UnitShort": "L/(min*min)",
					"UnitLong": "L/(min*min)",
					"Comment": null
				}, {
					"Id": 2359319,
					"ShortName": "Nshot",
					"LongName": "Count Shots",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 2359316,
					"ShortName": "OrgTst",
					"LongName": "Orig. Test No",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 2359313,
					"ShortName": "PAR 17",
					"LongName": "Parameter 17",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 2359303,
					"ShortName": "PEF",
					"LongName": "PeakExspFlow",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 2359302,
					"ShortName": "PIF",
					"LongName": "PeakInspFlow",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 2359309,
					"ShortName": "PIF1,5",
					"LongName": "PeakInspFlow1,5s",
					"UnitShort": "L/s",
					"UnitLong": "L/s",
					"Comment": null
				}, {
					"Id": 2359297,
					"ShortName": "Spec",
					"LongName": "Spektrum No.",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 2359299,
					"ShortName": "Tftrig",
					"LongName": "TFlussTrigger",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 2359298,
					"ShortName": "Ti",
					"LongName": "T Inspiration",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 2359311,
					"ShortName": "Ti30",
					"LongName": "Inh.Zeit >30l/min",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 2359312,
					"ShortName": "TiS",
					"LongName": "Inh.Zeit nach Ausl.",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 2359301,
					"ShortName": "TPif",
					"LongName": "TimePeakInspFlow",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 2359310,
					"ShortName": "TPif1",
					"LongName": "TimePeakInspFlow1,5",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 2359318,
					"ShortName": "TsEx",
					"LongName": "TimeShotEx",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 2359317,
					"ShortName": "TsIn",
					"LongName": "TimeShotIn",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 2359315,
					"ShortName": "Vers",
					"LongName": "Version",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 2359300,
					"ShortName": "Vin",
					"LongName": "Volume inspired",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 2359320,
					"ShortName": "VinS",
					"LongName": "Insp.Volumen n. Ausl.",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 2359321,
					"ShortName": "Vs%Vin",
					"LongName": "Vol Shot % Vin",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}
			]
		}, {
			"Id": 43,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "HIRES",
			"LongName": "ECG - HIRES",
			"Comment": null,
			"Parameters": [{
					"Id": 2818049,
					"ShortName": "",
					"LongName": "",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}
			]
		}, {
			"Id": 44,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "ECG-Resting",
			"LongName": "ECG-Resting",
			"Comment": null,
			"Parameters": [{
					"Id": 2883675,
					"ShortName": "dST20 aVF",
					"LongName": "dST20 aVF",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883674,
					"ShortName": "dST20 aVL",
					"LongName": "dST20 aVL",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883673,
					"ShortName": "dST20 aVR",
					"LongName": "dST20 aVR",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883670,
					"ShortName": "dST20 I",
					"LongName": "dST20 I",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883671,
					"ShortName": "dST20 II",
					"LongName": "dST20 II",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883672,
					"ShortName": "dST20 III",
					"LongName": "dST20 III",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883668,
					"ShortName": "dST20 max",
					"LongName": "dST20 max",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883669,
					"ShortName": "dST20 min",
					"LongName": "dST20 min",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883667,
					"ShortName": "dST20 most",
					"LongName": "dST20 most",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883676,
					"ShortName": "dST20 V1",
					"LongName": "dST20 V1",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883677,
					"ShortName": "dST20 V2",
					"LongName": "dST20 V2",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883678,
					"ShortName": "dST20 V3",
					"LongName": "dST20 V3",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883679,
					"ShortName": "dST20 V4",
					"LongName": "dST20 V4",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883680,
					"ShortName": "dST20 V5",
					"LongName": "dST20 V5",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883681,
					"ShortName": "dST20 V6",
					"LongName": "dST20 V6",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883690,
					"ShortName": "dST60 aVF",
					"LongName": "dST60 aVF",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883689,
					"ShortName": "dST60 aVL",
					"LongName": "dST60 aVL",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883688,
					"ShortName": "dST60 aVR",
					"LongName": "dST60 aVR",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883685,
					"ShortName": "dST60 I",
					"LongName": "dST60 I",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883686,
					"ShortName": "dST60 II",
					"LongName": "dST60 II",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883687,
					"ShortName": "dST60 III",
					"LongName": "dST60 III",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883683,
					"ShortName": "dST60 max",
					"LongName": "dST60 max",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883684,
					"ShortName": "dST60 min",
					"LongName": "dST60 min",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883682,
					"ShortName": "dST60 most",
					"LongName": "dST60 most",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883691,
					"ShortName": "dST60 V1",
					"LongName": "dST60 V1",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883692,
					"ShortName": "dST60 V2",
					"LongName": "dST60 V2",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883693,
					"ShortName": "dST60 V3",
					"LongName": "dST60 V3",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883694,
					"ShortName": "dST60 V4",
					"LongName": "dST60 V4",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883695,
					"ShortName": "dST60 V5",
					"LongName": "dST60 V5",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883696,
					"ShortName": "dST60 V6",
					"LongName": "dST60 V6",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883705,
					"ShortName": "dST80 aVF",
					"LongName": "dST80 aVF",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883704,
					"ShortName": "dST80 aVL",
					"LongName": "dST80 aVL",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883703,
					"ShortName": "dST80 aVR",
					"LongName": "dST80 aVR",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883700,
					"ShortName": "dST80 I",
					"LongName": "dST80 I",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883701,
					"ShortName": "dST80 II",
					"LongName": "dST80 II",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883702,
					"ShortName": "dST80 III",
					"LongName": "dST80 III",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883698,
					"ShortName": "dST80 max",
					"LongName": "dST80 max",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883699,
					"ShortName": "dST80 min",
					"LongName": "dST80 min",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883697,
					"ShortName": "dST80 most",
					"LongName": "dST80 most",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883706,
					"ShortName": "dST80 V1",
					"LongName": "dST80 V1",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883707,
					"ShortName": "dST80 V2",
					"LongName": "dST80 V2",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883708,
					"ShortName": "dST80 V3",
					"LongName": "dST80 V3",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883709,
					"ShortName": "dST80 V4",
					"LongName": "dST80 V4",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883710,
					"ShortName": "dST80 V5",
					"LongName": "dST80 V5",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883711,
					"ShortName": "dST80 V6",
					"LongName": "dST80 V6",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883720,
					"ShortName": "dSTslope aVF",
					"LongName": "dSTslope aVF",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883719,
					"ShortName": "dSTslope aVL",
					"LongName": "dSTslope aVL",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883718,
					"ShortName": "dSTslope aVR",
					"LongName": "dSTslope aVR",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883715,
					"ShortName": "dSTslope I",
					"LongName": "dSTslope I",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883716,
					"ShortName": "dSTslope II",
					"LongName": "dSTslope II",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883717,
					"ShortName": "dSTslope III",
					"LongName": "dSTslope III",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883713,
					"ShortName": "dSTslope max",
					"LongName": "dSTslope max",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883714,
					"ShortName": "dSTslope min",
					"LongName": "dSTslope min",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883712,
					"ShortName": "dSTslope most",
					"LongName": "dSTslope most",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883721,
					"ShortName": "dSTslope V1",
					"LongName": "dSTslope V1",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883722,
					"ShortName": "dSTslope V2",
					"LongName": "dSTslope V2",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883723,
					"ShortName": "dSTslope V3",
					"LongName": "dSTslope V3",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883724,
					"ShortName": "dSTslope V4",
					"LongName": "dSTslope V4",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883725,
					"ShortName": "dSTslope V5",
					"LongName": "dSTslope V5",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883726,
					"ShortName": "dSTslope V6",
					"LongName": "dSTslope V6",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883590,
					"ShortName": "Elev",
					"LongName": "Elevation",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 2883592,
					"ShortName": "HR",
					"LongName": "Heart rate",
					"UnitShort": "BPM",
					"UnitLong": "Beats per Minute",
					"Comment": null
				}, {
					"Id": 2883588,
					"ShortName": "Load",
					"LongName": "Load",
					"UnitShort": "W",
					"UnitLong": "Watt",
					"Comment": null
				}, {
					"Id": 2883591,
					"ShortName": "METS",
					"LongName": "Metabolic units",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 2883618,
					"ShortName": "NoOfCycles",
					"LongName": "NoOfCycles",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 2883594,
					"ShortName": "P",
					"LongName": "P",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 2883600,
					"ShortName": "Pangle",
					"LongName": "Pangle",
					"UnitShort": "°",
					"UnitLong": "°",
					"Comment": null
				}, {
					"Id": 2883607,
					"ShortName": "Pend",
					"LongName": "Pend",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2883585,
					"ShortName": "Phase",
					"LongName": "Phase",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 2883603,
					"ShortName": "Ppeak",
					"LongName": "Ppeak",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883615,
					"ShortName": "PpeakChest",
					"LongName": "PpeakChest",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2883612,
					"ShortName": "PpeakLimb",
					"LongName": "PpeakLimb",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2883595,
					"ShortName": "PR",
					"LongName": "PR",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 2883606,
					"ShortName": "Pstart",
					"LongName": "Pstart",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2883596,
					"ShortName": "QRS",
					"LongName": "QRS",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 2883601,
					"ShortName": "QRSangle",
					"LongName": "QRSangle",
					"UnitShort": "°",
					"UnitLong": "°",
					"Comment": null
				}, {
					"Id": 2883609,
					"ShortName": "QRSend",
					"LongName": "QRSend",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2883604,
					"ShortName": "QRSpeak",
					"LongName": "QRSpeak",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883616,
					"ShortName": "QRSpeakChest",
					"LongName": "QRSpeakChest",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2883613,
					"ShortName": "QRSpeakLimb",
					"LongName": "QRSpeakLimb",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2883608,
					"ShortName": "QRSstart",
					"LongName": "QRSstart",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2883597,
					"ShortName": "QT",
					"LongName": "QT",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 2883598,
					"ShortName": "QTcB",
					"LongName": "QTc (Bassett)",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 2883599,
					"ShortName": "QTcF",
					"LongName": "QTcF",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 2883611,
					"ShortName": "Rpeak",
					"LongName": "Rpeak",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2883593,
					"ShortName": "RR",
					"LongName": "RR",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 2883589,
					"ShortName": "Speed",
					"LongName": "Speed",
					"UnitShort": "m/s",
					"UnitLong": "m/s",
					"Comment": null
				}, {
					"Id": 2883622,
					"ShortName": "ST20  aVR",
					"LongName": "ST20 aVR",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883624,
					"ShortName": "ST20 aVF",
					"LongName": "ST20 aVF",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883623,
					"ShortName": "ST20 aVL",
					"LongName": "ST20 aVL",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883619,
					"ShortName": "ST20 I",
					"LongName": "ST20 I",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883620,
					"ShortName": "ST20 II",
					"LongName": "ST20 II",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883621,
					"ShortName": "ST20 III",
					"LongName": "ST20 III",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883625,
					"ShortName": "ST20 V1",
					"LongName": "ST20 V1",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883626,
					"ShortName": "ST20 V2",
					"LongName": "ST20 V2",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883627,
					"ShortName": "ST20 V3",
					"LongName": "ST20 V3",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883628,
					"ShortName": "ST20 V4",
					"LongName": "ST20 V4",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883629,
					"ShortName": "ST20 V5",
					"LongName": "ST20 V5",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883630,
					"ShortName": "ST20 V6",
					"LongName": "ST20 V6",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883636,
					"ShortName": "ST60 aVF",
					"LongName": "ST60 aVF",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883635,
					"ShortName": "ST60 aVL",
					"LongName": "ST60 aVL",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883634,
					"ShortName": "ST60 aVR",
					"LongName": "ST60 aVR",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883631,
					"ShortName": "ST60 I",
					"LongName": "ST60 I",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883632,
					"ShortName": "ST60 II",
					"LongName": "ST60 II",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883633,
					"ShortName": "ST60 III",
					"LongName": "ST60 III",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883637,
					"ShortName": "ST60 V1",
					"LongName": "ST60 V1",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883638,
					"ShortName": "ST60 V2",
					"LongName": "ST60 V2",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883639,
					"ShortName": "ST60 V3",
					"LongName": "ST60 V3",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883640,
					"ShortName": "ST60 V4",
					"LongName": "ST60 V4",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883641,
					"ShortName": "ST60 V5",
					"LongName": "ST60 V5",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883642,
					"ShortName": "ST60 V6",
					"LongName": "ST60 V6",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883648,
					"ShortName": "ST80 aVF",
					"LongName": "ST80 aVF",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883647,
					"ShortName": "ST80 aVL",
					"LongName": "ST80 aVL",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883646,
					"ShortName": "ST80 aVR",
					"LongName": "ST80 aVR",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883643,
					"ShortName": "ST80 I",
					"LongName": "ST80 I",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883644,
					"ShortName": "ST80 II",
					"LongName": "ST80 II",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883645,
					"ShortName": "ST80 III",
					"LongName": "ST80 III",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883649,
					"ShortName": "ST80 V1",
					"LongName": "ST80 V1",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883650,
					"ShortName": "ST80 V2",
					"LongName": "ST80 V2",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883651,
					"ShortName": "ST80 V3",
					"LongName": "ST80 V3",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883652,
					"ShortName": "ST80 V4",
					"LongName": "ST80 V4",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883653,
					"ShortName": "ST80 V5",
					"LongName": "ST80 V5",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883654,
					"ShortName": "ST80 V6",
					"LongName": "ST80 V6",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883660,
					"ShortName": "STslope aVF",
					"LongName": "STslope aVF",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883659,
					"ShortName": "STslope aVL",
					"LongName": "STslope aVL",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883658,
					"ShortName": "STslope aVR",
					"LongName": "STslope aVR",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883655,
					"ShortName": "STslope I",
					"LongName": "STslope I",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883656,
					"ShortName": "STslope II",
					"LongName": "STslope II",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883657,
					"ShortName": "STslope III",
					"LongName": "STslope III",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883661,
					"ShortName": "STslope V1",
					"LongName": "STslope V1",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883662,
					"ShortName": "STslope V2",
					"LongName": "STslope V2",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883663,
					"ShortName": "STslope V3",
					"LongName": "STslope V3",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883664,
					"ShortName": "STslope V4",
					"LongName": "STslope V4",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883665,
					"ShortName": "STslope V5",
					"LongName": "STslope V5",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883666,
					"ShortName": "STslope V6",
					"LongName": "STslope V6",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2883602,
					"ShortName": "Tangle",
					"LongName": "Tangle",
					"UnitShort": "°",
					"UnitLong": "°",
					"Comment": null
				}, {
					"Id": 2883610,
					"ShortName": "Tend",
					"LongName": "Tend",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2883586,
					"ShortName": "Time",
					"LongName": "Time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 2883605,
					"ShortName": "Tpeak",
					"LongName": "Tpeak",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2883617,
					"ShortName": "TpeakChest",
					"LongName": "TpeakChest",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2883614,
					"ShortName": "TpeakLimb",
					"LongName": "TpeakLimb",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2883587,
					"ShortName": "t-ph",
					"LongName": "Phase Time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}
			]
		}, {
			"Id": 45,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "ECG-Stress",
			"LongName": "ECG-Stress",
			"Comment": null,
			"Parameters": [{
					"Id": 2949211,
					"ShortName": "dST20 aVF",
					"LongName": "dST20 aVF",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949210,
					"ShortName": "dST20 aVL",
					"LongName": "dST20 aVL",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949209,
					"ShortName": "dST20 aVR",
					"LongName": "dST20 aVR",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949206,
					"ShortName": "dST20 I",
					"LongName": "dST20 I",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949207,
					"ShortName": "dST20 II",
					"LongName": "dST20 II",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949208,
					"ShortName": "dST20 III",
					"LongName": "dST20 III",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949204,
					"ShortName": "dST20 max",
					"LongName": "dST20 max",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949205,
					"ShortName": "dST20 min",
					"LongName": "dST20 min",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949203,
					"ShortName": "dST20 most",
					"LongName": "dST20 most",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949212,
					"ShortName": "dST20 V1",
					"LongName": "dST20 V1",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949213,
					"ShortName": "dST20 V2",
					"LongName": "dST20 V2",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949214,
					"ShortName": "dST20 V3",
					"LongName": "dST20 V3",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949215,
					"ShortName": "dST20 V4",
					"LongName": "dST20 V4",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949216,
					"ShortName": "dST20 V5",
					"LongName": "dST20 V5",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949217,
					"ShortName": "dST20 V6",
					"LongName": "dST20 V6",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949226,
					"ShortName": "dST60 aVF",
					"LongName": "dST60 aVF",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949225,
					"ShortName": "dST60 aVL",
					"LongName": "dST60 aVL",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949224,
					"ShortName": "dST60 aVR",
					"LongName": "dST60 aVR",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949221,
					"ShortName": "dST60 I",
					"LongName": "dST60 I",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949222,
					"ShortName": "dST60 II",
					"LongName": "dST60 II",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949223,
					"ShortName": "dST60 III",
					"LongName": "dST60 III",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949219,
					"ShortName": "dST60 max",
					"LongName": "dST60 max",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949220,
					"ShortName": "dST60 min",
					"LongName": "dST60 min",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949218,
					"ShortName": "dST60 most",
					"LongName": "dST60 most",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949227,
					"ShortName": "dST60 V1",
					"LongName": "dST60 V1",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949228,
					"ShortName": "dST60 V2",
					"LongName": "dST60 V2",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949229,
					"ShortName": "dST60 V3",
					"LongName": "dST60 V3",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949230,
					"ShortName": "dST60 V4",
					"LongName": "dST60 V4",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949231,
					"ShortName": "dST60 V5",
					"LongName": "dST60 V5",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949232,
					"ShortName": "dST60 V6",
					"LongName": "dST60 V6",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949241,
					"ShortName": "dST80 aVF",
					"LongName": "dST80 aVF",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949240,
					"ShortName": "dST80 aVL",
					"LongName": "dST80 aVL",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949239,
					"ShortName": "dST80 aVR",
					"LongName": "dST80 aVR",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949236,
					"ShortName": "dST80 I",
					"LongName": "dST80 I",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949237,
					"ShortName": "dST80 II",
					"LongName": "dST80 II",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949238,
					"ShortName": "dST80 III",
					"LongName": "dST80 III",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949234,
					"ShortName": "dST80 max",
					"LongName": "dST80 max",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949235,
					"ShortName": "dST80 min",
					"LongName": "dST80 min",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949233,
					"ShortName": "dST80 most",
					"LongName": "dST80 most",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949242,
					"ShortName": "dST80 V1",
					"LongName": "dST80 V1",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949243,
					"ShortName": "dST80 V2",
					"LongName": "dST80 V2",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949244,
					"ShortName": "dST80 V3",
					"LongName": "dST80 V3",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949245,
					"ShortName": "dST80 V4",
					"LongName": "dST80 V4",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949246,
					"ShortName": "dST80 V5",
					"LongName": "dST80 V5",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949247,
					"ShortName": "dST80 V6",
					"LongName": "dST80 V6",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949256,
					"ShortName": "dSTslope aVF",
					"LongName": "dSTslope aVF",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949255,
					"ShortName": "dSTslope aVL",
					"LongName": "dSTslope aVL",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949254,
					"ShortName": "dSTslope aVR",
					"LongName": "dSTslope aVR",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949251,
					"ShortName": "dSTslope I",
					"LongName": "dSTslope I",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949252,
					"ShortName": "dSTslope II",
					"LongName": "dSTslope II",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949253,
					"ShortName": "dSTslope III",
					"LongName": "dSTslope III",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949249,
					"ShortName": "dSTslope max",
					"LongName": "dSTslope max",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949250,
					"ShortName": "dSTslope min",
					"LongName": "dSTslope min",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949248,
					"ShortName": "dSTslope most",
					"LongName": "dSTslope most",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949257,
					"ShortName": "dSTslope V1",
					"LongName": "dSTslope V1",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949258,
					"ShortName": "dSTslope V2",
					"LongName": "dSTslope V2",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949259,
					"ShortName": "dSTslope V3",
					"LongName": "dSTslope V3",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949260,
					"ShortName": "dSTslope V4",
					"LongName": "dSTslope V4",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949261,
					"ShortName": "dSTslope V5",
					"LongName": "dSTslope V5",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949262,
					"ShortName": "dSTslope V6",
					"LongName": "dSTslope V6",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949126,
					"ShortName": "Elev",
					"LongName": "Elevation",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 2949128,
					"ShortName": "HR",
					"LongName": "Heart rate",
					"UnitShort": "BPM",
					"UnitLong": "Beats per Minute",
					"Comment": null
				}, {
					"Id": 2949124,
					"ShortName": "Load",
					"LongName": "Load",
					"UnitShort": "W",
					"UnitLong": "Watt",
					"Comment": null
				}, {
					"Id": 2949127,
					"ShortName": "METS",
					"LongName": "Metabolic units",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 2949154,
					"ShortName": "NoOfCycles",
					"LongName": "NoOfCycles",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 2949130,
					"ShortName": "P",
					"LongName": "P",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 2949136,
					"ShortName": "Pangle",
					"LongName": "Pangle",
					"UnitShort": "°",
					"UnitLong": "°",
					"Comment": null
				}, {
					"Id": 2949143,
					"ShortName": "Pend",
					"LongName": "Pend",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2949121,
					"ShortName": "Phase",
					"LongName": "Phase",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 2949139,
					"ShortName": "Ppeak",
					"LongName": "Ppeak",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949151,
					"ShortName": "PpeakChest",
					"LongName": "PpeakChest",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2949148,
					"ShortName": "PpeakLimb",
					"LongName": "PpeakLimb",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2949131,
					"ShortName": "PR",
					"LongName": "PR",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 2949142,
					"ShortName": "Pstart",
					"LongName": "Pstart",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2949132,
					"ShortName": "QRS",
					"LongName": "QRS",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 2949137,
					"ShortName": "QRSangle",
					"LongName": "QRSangle",
					"UnitShort": "°",
					"UnitLong": "°",
					"Comment": null
				}, {
					"Id": 2949145,
					"ShortName": "QRSend",
					"LongName": "QRSend",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2949140,
					"ShortName": "QRSpeak",
					"LongName": "QRSpeak",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949152,
					"ShortName": "QRSpeakChest",
					"LongName": "QRSpeakChest",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2949149,
					"ShortName": "QRSpeakLimb",
					"LongName": "QRSpeakLimb",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2949144,
					"ShortName": "QRSstart",
					"LongName": "QRSstart",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2949133,
					"ShortName": "QT",
					"LongName": "QT",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 2949134,
					"ShortName": "QTcB",
					"LongName": "QTc (Bassett)",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 2949135,
					"ShortName": "QTcF",
					"LongName": "QTcF",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 2949147,
					"ShortName": "Rpeak",
					"LongName": "Rpeak",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2949129,
					"ShortName": "RR",
					"LongName": "RR",
					"UnitShort": "ms",
					"UnitLong": "ms",
					"Comment": null
				}, {
					"Id": 2949125,
					"ShortName": "Speed",
					"LongName": "Speed",
					"UnitShort": "m/s",
					"UnitLong": "m/s",
					"Comment": null
				}, {
					"Id": 2949158,
					"ShortName": "ST20  aVR",
					"LongName": "ST20 aVR",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949160,
					"ShortName": "ST20 aVF",
					"LongName": "ST20 aVF",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949159,
					"ShortName": "ST20 aVL",
					"LongName": "ST20 aVL",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949155,
					"ShortName": "ST20 I",
					"LongName": "ST20 I",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949156,
					"ShortName": "ST20 II",
					"LongName": "ST20 II",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949157,
					"ShortName": "ST20 III",
					"LongName": "ST20 III",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949161,
					"ShortName": "ST20 V1",
					"LongName": "ST20 V1",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949162,
					"ShortName": "ST20 V2",
					"LongName": "ST20 V2",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949163,
					"ShortName": "ST20 V3",
					"LongName": "ST20 V3",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949164,
					"ShortName": "ST20 V4",
					"LongName": "ST20 V4",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949165,
					"ShortName": "ST20 V5",
					"LongName": "ST20 V5",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949166,
					"ShortName": "ST20 V6",
					"LongName": "ST20 V6",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949172,
					"ShortName": "ST60 aVF",
					"LongName": "ST60 aVF",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949171,
					"ShortName": "ST60 aVL",
					"LongName": "ST60 aVL",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949170,
					"ShortName": "ST60 aVR",
					"LongName": "ST60 aVR",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949167,
					"ShortName": "ST60 I",
					"LongName": "ST60 I",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949168,
					"ShortName": "ST60 II",
					"LongName": "ST60 II",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949169,
					"ShortName": "ST60 III",
					"LongName": "ST60 III",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949173,
					"ShortName": "ST60 V1",
					"LongName": "ST60 V1",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949174,
					"ShortName": "ST60 V2",
					"LongName": "ST60 V2",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949175,
					"ShortName": "ST60 V3",
					"LongName": "ST60 V3",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949176,
					"ShortName": "ST60 V4",
					"LongName": "ST60 V4",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949177,
					"ShortName": "ST60 V5",
					"LongName": "ST60 V5",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949178,
					"ShortName": "ST60 V6",
					"LongName": "ST60 V6",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949184,
					"ShortName": "ST80 aVF",
					"LongName": "ST80 aVF",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949183,
					"ShortName": "ST80 aVL",
					"LongName": "ST80 aVL",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949182,
					"ShortName": "ST80 aVR",
					"LongName": "ST80 aVR",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949179,
					"ShortName": "ST80 I",
					"LongName": "ST80 I",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949180,
					"ShortName": "ST80 II",
					"LongName": "ST80 II",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949181,
					"ShortName": "ST80 III",
					"LongName": "ST80 III",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949185,
					"ShortName": "ST80 V1",
					"LongName": "ST80 V1",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949186,
					"ShortName": "ST80 V2",
					"LongName": "ST80 V2",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949187,
					"ShortName": "ST80 V3",
					"LongName": "ST80 V3",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949188,
					"ShortName": "ST80 V4",
					"LongName": "ST80 V4",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949189,
					"ShortName": "ST80 V5",
					"LongName": "ST80 V5",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949190,
					"ShortName": "ST80 V6",
					"LongName": "ST80 V6",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949196,
					"ShortName": "STslope aVF",
					"LongName": "STslope aVF",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949195,
					"ShortName": "STslope aVL",
					"LongName": "STslope aVL",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949194,
					"ShortName": "STslope aVR",
					"LongName": "STslope aVR",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949191,
					"ShortName": "STslope I",
					"LongName": "STslope I",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949192,
					"ShortName": "STslope II",
					"LongName": "STslope II",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949193,
					"ShortName": "STslope III",
					"LongName": "STslope III",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949197,
					"ShortName": "STslope V1",
					"LongName": "STslope V1",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949198,
					"ShortName": "STslope V2",
					"LongName": "STslope V2",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949199,
					"ShortName": "STslope V3",
					"LongName": "STslope V3",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949200,
					"ShortName": "STslope V4",
					"LongName": "STslope V4",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949201,
					"ShortName": "STslope V5",
					"LongName": "STslope V5",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949202,
					"ShortName": "STslope V6",
					"LongName": "STslope V6",
					"UnitShort": "µV/ms",
					"UnitLong": "µV/ms",
					"Comment": null
				}, {
					"Id": 2949138,
					"ShortName": "Tangle",
					"LongName": "Tangle",
					"UnitShort": "°",
					"UnitLong": "°",
					"Comment": null
				}, {
					"Id": 2949146,
					"ShortName": "Tend",
					"LongName": "Tend",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2949122,
					"ShortName": "Time",
					"LongName": "Time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 2949141,
					"ShortName": "Tpeak",
					"LongName": "Tpeak",
					"UnitShort": "µV",
					"UnitLong": "µV",
					"Comment": null
				}, {
					"Id": 2949153,
					"ShortName": "TpeakChest",
					"LongName": "TpeakChest",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2949150,
					"ShortName": "TpeakLimb",
					"LongName": "TpeakLimb",
					"UnitShort": "samples",
					"UnitLong": "samples",
					"Comment": null
				}, {
					"Id": 2949123,
					"ShortName": "t-ph",
					"LongName": "Phase Time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}
			]
		}, {
			"Id": 46,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "ECG2012_REST",
			"LongName": "ECG2012_REST",
			"Comment": null,
			"Parameters": [{
					"Id": 3014657,
					"ShortName": "",
					"LongName": "",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}
			]
		}, {
			"Id": 47,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "RECG",
			"LongName": "ECG2012_STRESS",
			"Comment": null,
			"Parameters": [{
					"Id": 3080193,
					"ShortName": "",
					"LongName": "",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}
			]
		}, {
			"Id": 53,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "Sleep",
			"LongName": "Sleep",
			"Comment": null,
			"Parameters": [{
					"Id": 3473409,
					"ShortName": "AHI",
					"LongName": "Apnea Hypopnea Index",
					"UnitShort": "1/h",
					"UnitLong": "1/hour",
					"Comment": null
				}, {
					"Id": 3473412,
					"ShortName": "AHI (Supine)",
					"LongName": "Apnea Hypopnea Index (Supine)",
					"UnitShort": "1/h",
					"UnitLong": "1/hour",
					"Comment": null
				}, {
					"Id": 3473410,
					"ShortName": "AI",
					"LongName": "Apnea Index",
					"UnitShort": "1/h",
					"UnitLong": "1/hour",
					"Comment": null
				}, {
					"Id": 3473413,
					"ShortName": "AI (Supine)",
					"LongName": "Apnea Index (Supine)",
					"UnitShort": "1/h",
					"UnitLong": "1/hour",
					"Comment": null
				}, {
					"Id": 3473421,
					"ShortName": "Avg. Apnea",
					"LongName": "Average Apnea duration",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 3473437,
					"ShortName": "Avg. Pressure ",
					"LongName": "Average Pressure  (CPAP)",
					"UnitShort": "cmH2O",
					"UnitLong": "cmH2O at 4°C",
					"Comment": null
				}, {
					"Id": 3473432,
					"ShortName": "Avg. Puls",
					"LongName": "Average Puls",
					"UnitShort": "BPM",
					"UnitLong": "Beats per Minute",
					"Comment": null
				}, {
					"Id": 3473430,
					"ShortName": "Basal O2",
					"LongName": "Basal O2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3473431,
					"ShortName": "Basal O2 (Supine)",
					"LongName": "Basal O2 (Supine)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3473416,
					"ShortName": "CA",
					"LongName": "Central Apnea",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3473418,
					"ShortName": "CA(Supine)",
					"LongName": "Central Apnea (Supine)",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3473436,
					"ShortName": "CPAP Pressure max.",
					"LongName": "Max. PAP Pressure (CPAP)",
					"UnitShort": "cmH2O",
					"UnitLong": "cmH2O at 4°C",
					"Comment": null
				}, {
					"Id": 3473438,
					"ShortName": "Date",
					"LongName": "Recording Date",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3473426,
					"ShortName": "Desat",
					"LongName": "Desaturation",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3473427,
					"ShortName": "Desat (Supine) ",
					"LongName": "Desaturation (Supine) ",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3473411,
					"ShortName": "HI",
					"LongName": "Hypopnea Index",
					"UnitShort": "1/h",
					"UnitLong": "1/hour",
					"Comment": null
				}, {
					"Id": 3473414,
					"ShortName": "HI (Supine)",
					"LongName": "Hypopnea Index (Supine)",
					"UnitShort": "1/h",
					"UnitLong": "1/hour",
					"Comment": null
				}, {
					"Id": 3473419,
					"ShortName": "Hypopnea",
					"LongName": "Hypopnea",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3473420,
					"ShortName": "Hypopnea (Supine)",
					"LongName": "Hypopnea (Supine)",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3473428,
					"ShortName": "Min. Spo2",
					"LongName": "Lowest Saturation",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3473429,
					"ShortName": "Min. Spo2 (Supine)",
					"LongName": "Lowest Saturation (Supine)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3473415,
					"ShortName": "OA",
					"LongName": "Obstructive Apnea",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3473417,
					"ShortName": "OA (Supine)",
					"LongName": "Obstructive Apnea (Supine)",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3473424,
					"ShortName": "ODI",
					"LongName": "Desaturation Index",
					"UnitShort": "1/h",
					"UnitLong": "1/hour",
					"Comment": null
				}, {
					"Id": 3473425,
					"ShortName": "ODI (Supine) ",
					"LongName": "Desaturation Index (Supine) ",
					"UnitShort": "1/h",
					"UnitLong": "1/hour",
					"Comment": null
				}, {
					"Id": 3473433,
					"ShortName": "Puls max.",
					"LongName": "Highest Puls",
					"UnitShort": "BPM",
					"UnitLong": "Beats per Minute",
					"Comment": null
				}, {
					"Id": 3473434,
					"ShortName": "Puls min.",
					"LongName": "Lowest Puls",
					"UnitShort": "BPM",
					"UnitLong": "Beats per Minute",
					"Comment": null
				}, {
					"Id": 3473441,
					"ShortName": "Recording duration",
					"LongName": "Recording duration (Analysis)",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 3473440,
					"ShortName": "Recording End",
					"LongName": "Recording End (Analysis)",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3473439,
					"ShortName": "Recording Start",
					"LongName": "Recording Start (Analysis)",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3473435,
					"ShortName": "Sleep Efficiency",
					"LongName": "Sleep Efficiency",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3473422,
					"ShortName": "Snoring Index",
					"LongName": "Snoring Index",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3473423,
					"ShortName": "Snoring Index  (Supine)",
					"LongName": "Snoring Index  (Supine)",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}
			]
		}, {
			"Id": 59,
			"Childs": [{
					"Id": 5999,
					"Childs": [],
					"ParentId": 59,
					"ShortName": "VMAX",
					"LongName": "VMAX",
					"Comment": null,
					"Parameters": [{
							"Id": 393150466,
							"ShortName": "C Index",
							"LongName": "C Index",
							"UnitShort": "L/m²",
							"UnitLong": "L/m²",
							"Comment": null
						}, {
							"Id": 393150467,
							"ShortName": "VS",
							"LongName": "VS",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 393150468,
							"ShortName": "VS Index",
							"LongName": "VS Index",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 393150465,
							"ShortName": "VTISS",
							"LongName": "VTISS",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}
			],
			"ParentId": 0,
			"ShortName": "Diff SB IB",
			"LongName": "Diffusion SB Intrabreath",
			"Comment": null,
			"Parameters": [{
					"Id": 3866665,
					"ShortName": "%COHb",
					"LongName": "Carboxyhemoglobin",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3866668,
					"ShortName": "AD-A",
					"LongName": "Author AD calculation",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 3866691,
					"ShortName": "ATS2005",
					"LongName": "ATS 2005 Error code",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3866666,
					"ShortName": "Bar.Pr",
					"LongName": "Bar.Pressure",
					"UnitShort": "hPa",
					"UnitLong": "hPa",
					"Comment": null
				}, {
					"Id": 3866670,
					"ShortName": "BF-IB",
					"LongName": "BF-IB",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": null
				}, {
					"Id": 3866651,
					"ShortName": "CO2",
					"LongName": "CO2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3866685,
					"ShortName": "DCOAcor",
					"LongName": "DCO Altitude corr.",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3866631,
					"ShortName": "DCOIBS",
					"LongName": "DLCO IB/BSA",
					"UnitShort": "mmol/(min*kPa*m²)",
					"UnitLong": "mmol/(min*kPa*m²)",
					"Comment": null
				}, {
					"Id": 3866632,
					"ShortName": "DIBSAc",
					"LongName": "DLCO IBc/BSA",
					"UnitShort": "mmol/(min*kPa*m²)",
					"UnitLong": "mmol/(min*kPa*m²)",
					"Comment": null
				}, {
					"Id": 3866692,
					"ShortName": "DLCOIav",
					"LongName": "DLCOI average",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3866625,
					"ShortName": "DLCOIB",
					"LongName": "DLCO IB",
					"UnitShort": "mmol/(min*kPa)",
					"UnitLong": "mmol/(min*kPa)",
					"Comment": null
				}, {
					"Id": 3866627,
					"ShortName": "DLCOIc",
					"LongName": "DLCO IBc",
					"UnitShort": "mmol/(min*kPa)",
					"UnitLong": "mmol/(min*kPa)",
					"Comment": null
				}, {
					"Id": 3866629,
					"ShortName": "DLCOIp",
					"LongName": "DLCO IBpc",
					"UnitShort": "mmol/(min*kPa)",
					"UnitLong": "mmol/(min*kPa)",
					"Comment": null
				}, {
					"Id": 3866683,
					"ShortName": "E-code",
					"LongName": "Error code",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 3866682,
					"ShortName": "E-crit",
					"LongName": "Error criteria",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 3866641,
					"ShortName": "ERV-IB",
					"LongName": "ERV-IB",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866688,
					"ShortName": "ERV-SP",
					"LongName": "ERV-Spir",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866643,
					"ShortName": "FA CH4",
					"LongName": "FA CH4",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3866646,
					"ShortName": "FA1 CO",
					"LongName": "FA1 CO",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3866649,
					"ShortName": "FA1C2H2",
					"LongName": "FA1 C2H2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3866647,
					"ShortName": "FA2 CO",
					"LongName": "FA2 CO",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3866650,
					"ShortName": "FA2C2H2",
					"LongName": "FA2 C2H2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3866693,
					"ShortName": "FACH4s",
					"LongName": "FACH4s",
					"UnitShort": "ppm",
					"UnitLong": "ppm",
					"Comment": null
				}, {
					"Id": 3866694,
					"ShortName": "FACOs",
					"LongName": "FACOs",
					"UnitShort": "ppm",
					"UnitLong": "ppm",
					"Comment": null
				}, {
					"Id": 3866644,
					"ShortName": "FAdCH4",
					"LongName": "FAd CH4",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3866689,
					"ShortName": "FHB",
					"LongName": "HB corr. factor",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3866642,
					"ShortName": "FI CH4",
					"LongName": "FI CH4",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3866645,
					"ShortName": "FI CO",
					"LongName": "FI CO",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3866648,
					"ShortName": "FIC2H2",
					"LongName": "FI C2H2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3866675,
					"ShortName": "FRC%TLCIB",
					"LongName": "FRC%TLC-IB",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3866637,
					"ShortName": "FRC-IB",
					"LongName": "FRC-IB",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866633,
					"ShortName": "Hb",
					"LongName": "Hb",
					"UnitShort": "g(Hb)/dL",
					"UnitLong": "Gramms Haemoglobin per 100 mL",
					"Comment": null
				}, {
					"Id": 3866635,
					"ShortName": "HR",
					"LongName": "Heart rate",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": null
				}, {
					"Id": 3866640,
					"ShortName": "IC-IB",
					"LongName": "IC-IB",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866672,
					"ShortName": "IRV-IB",
					"LongName": "IRV-IB",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866626,
					"ShortName": "KCO IB",
					"LongName": "DLCO I/VA",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 3866686,
					"ShortName": "KCOI_STPD",
					"LongName": "DLCOI/VA_STPD",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 3866628,
					"ShortName": "KCOIBc",
					"LongName": "KCO IBc",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 3866630,
					"ShortName": "KCOIBp",
					"LongName": "KCO IBpc",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 3866687,
					"ShortName": "KCOIc_STPD",
					"LongName": "DLCOIc/VA_STPD",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 3866690,
					"ShortName": "MAYO",
					"LongName": "MAYO Error code",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3866671,
					"ShortName": "MV-IB",
					"LongName": "MV-IB",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": null
				}, {
					"Id": 3866695,
					"ShortName": "QEC AE17",
					"LongName": "Quality Error Code ATS/ERS 2017",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 3866634,
					"ShortName": "Qt",
					"LongName": "Cardiac Output",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": null
				}, {
					"Id": 3866681,
					"ShortName": "Qt slope",
					"LongName": "Tang[LN(C2H2)/LN(VA)]",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 3866674,
					"ShortName": "RV%TLCIB",
					"LongName": "RV%TLC-IB",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3866677,
					"ShortName": "RV-BY",
					"LongName": "RV-Body",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866679,
					"ShortName": "RVI%RVB",
					"LongName": "RV-IB%RV-Body",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3866636,
					"ShortName": "RV-IB",
					"LongName": "RV-IB",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866667,
					"ShortName": "Sv",
					"LongName": "Strokevolume",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 3866684,
					"ShortName": "Tests",
					"LongName": "Number of Tests",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 3866656,
					"ShortName": "Tex 1",
					"LongName": "Tex 1",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 3866657,
					"ShortName": "Tex 2",
					"LongName": "Tex 2",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 3866655,
					"ShortName": "Texmax",
					"LongName": "Tex max",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 3866661,
					"ShortName": "Tin",
					"LongName": "T In",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 3866678,
					"ShortName": "TLC-BY",
					"LongName": "TLC-Body",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866680,
					"ShortName": "TLCI%TLCB",
					"LongName": "TLC-IB%TLC-Body",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 3866638,
					"ShortName": "TLC-IB",
					"LongName": "TLC-IB",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866652,
					"ShortName": "VA max",
					"LongName": "VA max",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866653,
					"ShortName": "VAact1",
					"LongName": "VA act 1",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866654,
					"ShortName": "VAact2",
					"LongName": "VA act 2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866663,
					"ShortName": "VAT   ",
					"LongName": "System dead space",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 3866673,
					"ShortName": "VCmaxIB",
					"LongName": "VC max-IB",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866676,
					"ShortName": "VCmaxSP",
					"LongName": "VCmax-Spir",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866662,
					"ShortName": "VDA   ",
					"LongName": "Anatom. dead space",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 3866664,
					"ShortName": "VDreal",
					"LongName": "Real dead space",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 3866659,
					"ShortName": "Vex 1",
					"LongName": "Vex 1",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866660,
					"ShortName": "Vex 2",
					"LongName": "Vex 2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866658,
					"ShortName": "Vexmax",
					"LongName": "Vex max",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866639,
					"ShortName": "VIN-IB",
					"LongName": "VIN-IB",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 3866669,
					"ShortName": "VT-IB",
					"LongName": "VT-IB",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}
			]
		}, {
			"Id": 62,
			"Childs": [{
					"Id": 6299,
					"Childs": [],
					"ParentId": 62,
					"ShortName": "VMAX",
					"LongName": "VMAX",
					"Comment": null,
					"Parameters": [{
							"Id": 412811265,
							"ShortName": "RAW_ECODE",
							"LongName": "RAW_ECODE",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}
					]
				}
			],
			"ParentId": 0,
			"ShortName": "N2 Washout",
			"LongName": "N2 Washout",
			"Comment": null,
			"Parameters": [{
					"Id": 4063233,
					"ShortName": "",
					"LongName": "",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4063234,
					"ShortName": "BF",
					"LongName": "BF",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": null
				}, {
					"Id": 4063250,
					"ShortName": "dVSwitch",
					"LongName": "dVSwitch",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063264,
					"ShortName": "E-Code",
					"LongName": "Error Code",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 4063235,
					"ShortName": "ERV_N2",
					"LongName": "ERV_N2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063236,
					"ShortName": "FAST_VOL",
					"LongName": "FAST_VOL",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063237,
					"ShortName": "FINAL_N2",
					"LongName": "FINAL_N2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4063259,
					"ShortName": "FRC",
					"LongName": "FRC",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063240,
					"ShortName": "FRC_N2",
					"LongName": "FRC_N2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063238,
					"ShortName": "FRC_N2/TLC_N2",
					"LongName": "FRC_N2/TLC_N2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4063239,
					"ShortName": "FRC_N2_EXTRAP",
					"LongName": "FRC_N2_EXTRAP",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063241,
					"ShortName": "IC_N2",
					"LongName": "IC_N2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063242,
					"ShortName": "IRV_N2",
					"LongName": "IRV_N2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063243,
					"ShortName": "LCI",
					"LongName": "LCI",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4063253,
					"ShortName": "MV",
					"LongName": "MV",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": null
				}, {
					"Id": 4063245,
					"ShortName": "n.a",
					"LongName": "",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4063244,
					"ShortName": "N2_EXCRETE",
					"LongName": "N2_EXCRETE",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063246,
					"ShortName": "PETCO2",
					"LongName": "PETCO2",
					"UnitShort": "mmHg",
					"UnitLong": "mmHg",
					"Comment": null
				}, {
					"Id": 4063248,
					"ShortName": "RV_N2",
					"LongName": "RV_N2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063247,
					"ShortName": "RV_N2/TLC_N2",
					"LongName": "RV_N2/TLC_N2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4063262,
					"ShortName": "SLOWVOL",
					"LongName": "SLOWVOL",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063249,
					"ShortName": "START_N2",
					"LongName": "START_N2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4063251,
					"ShortName": "TLC_N2",
					"LongName": "TLC_N2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063258,
					"ShortName": "t-WO",
					"LongName": "Washout time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 4063252,
					"ShortName": "Valve_Vd_N2",
					"LongName": "Valve_Vd_N2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063260,
					"ShortName": "VAR_FRC",
					"LongName": "VAR_FRC",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4063261,
					"ShortName": "VAR_VT",
					"LongName": "VAR_VT",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4063255,
					"ShortName": "VCEX_N2",
					"LongName": "VCEX_N2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063254,
					"ShortName": "VCIN_N2",
					"LongName": "VCIN_N2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063256,
					"ShortName": "VCMAX_N2",
					"LongName": "VCMAX_N2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063263,
					"ShortName": "VT",
					"LongName": "VT",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4063257,
					"ShortName": "VT_N2",
					"LongName": "VT_N2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}
			]
		}, {
			"Id": 63,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "Diff SB NO",
			"LongName": "Diffusion SB NO",
			"Comment": null,
			"Parameters": [{
					"Id": 4128837,
					"ShortName": "alpha",
					"LongName": "Bunsen coeff. (Alpha)",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128833,
					"ShortName": "AT-A",
					"LongName": "Author AT calculation",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128852,
					"ShortName": "BF NO",
					"LongName": "Breathing Frequency NO",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": null
				}, {
					"Id": 4128823,
					"ShortName": "BHT",
					"LongName": "Breath-hold time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 4128858,
					"ShortName": "BHT Auth",
					"LongName": "Author BHT calculation",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 4128796,
					"ShortName": "COHb",
					"LongName": "Carboxyhemoglobin",
					"UnitShort": "Vol%",
					"UnitLong": "Vol%",
					"Comment": null
				}, {
					"Id": 4128857,
					"ShortName": "DCO Acor",
					"LongName": "DCO Altitude correction",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 4128793,
					"ShortName": "DCO3QB",
					"LongName": "DLCO3EQ/BSA",
					"UnitShort": "mmol/(min*kPa*m²)",
					"UnitLong": "mmol/(min*kPa*m²)",
					"Comment": null
				}, {
					"Id": 4128781,
					"ShortName": "DCOBSA",
					"LongName": "DLCO / BSA",
					"UnitShort": "mmol/(min*kPa*m²)",
					"UnitLong": "mmol/(min*kPa*m²)",
					"Comment": null
				}, {
					"Id": 4128794,
					"ShortName": "DCOc3QB",
					"LongName": "DLCOc3EQ/BSA",
					"UnitShort": "mmol/(min*kPa*m²)",
					"UnitLong": "mmol/(min*kPa*m²)",
					"Comment": null
				}, {
					"Id": 4128782,
					"ShortName": "DCOcBSA",
					"LongName": "DLCOc / BSA",
					"UnitShort": "mmol/(min*kPa*m²)",
					"UnitLong": "mmol/(min*kPa*m²)",
					"Comment": null
				}, {
					"Id": 4128839,
					"ShortName": "Decay",
					"LongName": "NO decay",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 4128789,
					"ShortName": "DLCO3Q",
					"LongName": "DLCO 3EQ",
					"UnitShort": "mmol/(min*kPa)",
					"UnitLong": "mmol/(min*kPa)",
					"Comment": null
				}, {
					"Id": 4128841,
					"ShortName": "DLCOav",
					"LongName": "DLCO Average",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128790,
					"ShortName": "DLCOc3Q",
					"LongName": "DLCOc 3EQ",
					"UnitShort": "mmol/(min*kPa)",
					"UnitLong": "mmol/(min*kPa)",
					"Comment": null
				}, {
					"Id": 4128778,
					"ShortName": "DLCOcSB",
					"LongName": "DLCOc Single Breath",
					"UnitShort": "mmol/(min*kPa)",
					"UnitLong": "mmol/(min*kPa)",
					"Comment": null
				}, {
					"Id": 4128777,
					"ShortName": "DLCOSB",
					"LongName": "DLCO Single Breath",
					"UnitShort": "mmol/(min*kPa)",
					"UnitLong": "mmol/(min*kPa)",
					"Comment": null
				}, {
					"Id": 4128783,
					"ShortName": "DLNO3Q",
					"LongName": "DLNO 3EQ",
					"UnitShort": "mmol/(min*kPa)",
					"UnitLong": "mmol/(min*kPa)",
					"Comment": null
				}, {
					"Id": 4128771,
					"ShortName": "DLNOSB",
					"LongName": "DLNO Single Breath",
					"UnitShort": "mmol/(min*kPa)",
					"UnitLong": "mmol/(min*kPa)",
					"Comment": null
				}, {
					"Id": 4128769,
					"ShortName": "Dm",
					"LongName": "Membrane factor",
					"UnitShort": "mmol/(min*kPa)",
					"UnitLong": "mmol/(min*kPa)",
					"Comment": null
				}, {
					"Id": 4128808,
					"ShortName": "Dm CV",
					"LongName": "Dm coeff.var.",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4128787,
					"ShortName": "DNO3QB",
					"LongName": "DLNO3EQ/BSA",
					"UnitShort": "mmol/(min*kPa*m²)",
					"UnitLong": "mmol/(min*kPa*m²)",
					"Comment": null
				}, {
					"Id": 4128775,
					"ShortName": "DNOBSA",
					"LongName": "DLNO / BSA",
					"UnitShort": "mmol/(min*kPa*m²)",
					"UnitLong": "mmol/(min*kPa*m²)",
					"Comment": null
				}, {
					"Id": 4128834,
					"ShortName": "DV",
					"LongName": "Discard volume",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128803,
					"ShortName": "ERV-SB",
					"LongName": "ERV Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128840,
					"ShortName": "EvalMd",
					"LongName": "Eval mode",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128817,
					"ShortName": "FA CO",
					"LongName": "FA CO",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4128820,
					"ShortName": "FA CO2",
					"LongName": "FA CO2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4128813,
					"ShortName": "FA He",
					"LongName": "FA He",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4128819,
					"ShortName": "FA NO",
					"LongName": "FA NO",
					"UnitShort": "0.0001*%",
					"UnitLong": "0.0001*%",
					"Comment": null
				}, {
					"Id": 4128822,
					"ShortName": "FA O2",
					"LongName": "FA O2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4128816,
					"ShortName": "FI CO",
					"LongName": "FI CO",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4128812,
					"ShortName": "FI He",
					"LongName": "FI He",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4128818,
					"ShortName": "FI NO",
					"LongName": "FI NO",
					"UnitShort": "0.0001*%",
					"UnitLong": "0.0001*%",
					"Comment": null
				}, {
					"Id": 4128821,
					"ShortName": "FI O2",
					"LongName": "FI O2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4128802,
					"ShortName": "FRC%TLC-SB",
					"LongName": "FRC%TLC Single Breath",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4128797,
					"ShortName": "FRC-SB",
					"LongName": "FRC Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128795,
					"ShortName": "Hb",
					"LongName": "Hemoglobin",
					"UnitShort": "g(Hb)/dL",
					"UnitLong": "Gramms Haemoglobin per 100 mL",
					"Comment": null
				}, {
					"Id": 4128804,
					"ShortName": "IC-SB",
					"LongName": "IC Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128855,
					"ShortName": "IRV NO",
					"LongName": "IRV NO",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128779,
					"ShortName": "KCO",
					"LongName": "DLCO / VA",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 4128860,
					"ShortName": "KCO_STPD",
					"LongName": "DLCO/VA_STPD",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 4128791,
					"ShortName": "KCO3Q",
					"LongName": "DLCO3EQ/VA",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 4128780,
					"ShortName": "KCOc",
					"LongName": "DLCOc / VA",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 4128861,
					"ShortName": "KCOc_STPD",
					"LongName": "DLCOc/VA_STPD",
					"UnitShort": "1/(kPa*s)",
					"UnitLong": "1/(kPa*s)",
					"Comment": null
				}, {
					"Id": 4128792,
					"ShortName": "KCOc3Q",
					"LongName": "DLCOc3EQ/VA",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 4128773,
					"ShortName": "KNO",
					"LongName": "DLNO / VA",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 4128859,
					"ShortName": "KNO_STPD",
					"LongName": "DLNO/VA_STPD",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 4128785,
					"ShortName": "KNO3Q",
					"LongName": "DLNO3EQ/VA",
					"UnitShort": "mmol/(min*kPa*L)",
					"UnitLong": "mmol/(min*kPa*L)",
					"Comment": null
				}, {
					"Id": 4128854,
					"ShortName": "MV NO",
					"LongName": "Minute Ventilation NO",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": null
				}, {
					"Id": 4128772,
					"ShortName": "reserved",
					"LongName": "reserved",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128774,
					"ShortName": "reserved",
					"LongName": "reserved",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128776,
					"ShortName": "reserved",
					"LongName": "reserved",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128784,
					"ShortName": "reserved",
					"LongName": "reserved",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128786,
					"ShortName": "reserved",
					"LongName": "reserved",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128788,
					"ShortName": "reserved",
					"LongName": "reserved",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128807,
					"ShortName": "reserved",
					"LongName": "reserved",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128814,
					"ShortName": "reserved",
					"LongName": "reserved",
					"UnitShort": "0.0001*%",
					"UnitLong": "0.0001*%",
					"Comment": null
				}, {
					"Id": 4128815,
					"ShortName": "reserved",
					"LongName": "reserved",
					"UnitShort": "0.0001*%",
					"UnitLong": "0.0001*%",
					"Comment": null
				}, {
					"Id": 4128836,
					"ShortName": "Pbar",
					"LongName": "Barometric Pressure",
					"UnitShort": "hPa",
					"UnitLong": "hPa",
					"Comment": null
				}, {
					"Id": 4128810,
					"ShortName": "Pmean",
					"LongName": "Pmean",
					"UnitShort": "kPa",
					"UnitLong": "kPa",
					"Comment": null
				}, {
					"Id": 4128862,
					"ShortName": "QEC AE05 SB",
					"LongName": "Quality error code ATS/ERS 2005 SB",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128863,
					"ShortName": "QEC AE17 SB",
					"LongName": "Quality error code ATS/ERS 2017 SB",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128805,
					"ShortName": "QEC ATS SB",
					"LongName": "ATS Error codes",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128806,
					"ShortName": "QEC Mayo SB",
					"LongName": "ATS+Mayo error codes",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128851,
					"ShortName": "QNOCO",
					"LongName": "DLNO / DLCO ratio",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 4128856,
					"ShortName": "Quality",
					"LongName": "Quality criteria",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 4128848,
					"ShortName": "RV Pl",
					"LongName": "RV Plethysmograph",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128850,
					"ShortName": "RV%RVB",
					"LongName": "RV SB % RV Plethysmograph",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4128801,
					"ShortName": "RV%TLC-SB",
					"LongName": "RV%TLC Single Breath",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4128798,
					"ShortName": "RV-SB",
					"LongName": "RV Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128835,
					"ShortName": "SV",
					"LongName": "Sample volume",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128825,
					"ShortName": "Tex SB",
					"LongName": "Expiration time Single Breath",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 4128838,
					"ShortName": "Theta",
					"LongName": "Theta CO",
					"UnitShort": "mmol/(min*kPa*ml)",
					"UnitLong": "mmol/(min*kPa*ml)",
					"Comment": null
				}, {
					"Id": 4128824,
					"ShortName": "Tin SB",
					"LongName": "Inspiration time Single Breath",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 4128849,
					"ShortName": "TLC Pl",
					"LongName": "TLC Plethysmograph",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128799,
					"ShortName": "TLC-SB",
					"LongName": "TLC Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128811,
					"ShortName": "Trials",
					"LongName": "Number of Trials",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128844,
					"ShortName": "VA SQM",
					"LongName": "VA SeS QM",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128845,
					"ShortName": "VA SQM CO",
					"LongName": "VA SeS QM CO",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128847,
					"ShortName": "VA SQM LIN",
					"LongName": "VA SeS QM LIN",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4128846,
					"ShortName": "VA SQM NO",
					"LongName": "VA SeS QM NO",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128800,
					"ShortName": "VA-SB",
					"LongName": "Alveolar Volume Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128830,
					"ShortName": "VAT",
					"LongName": "System dead space",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 4128770,
					"ShortName": "Vc  (QC)",
					"LongName": "Capillary blood volume",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 4128809,
					"ShortName": "Vc CV",
					"LongName": "Vc coeff.var.",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4128828,
					"ShortName": "VC Trg",
					"LongName": "VCmax Target",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128829,
					"ShortName": "VCmax",
					"LongName": "VC max",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128832,
					"ShortName": "VDA",
					"LongName": "Anatomic dead space",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 4128831,
					"ShortName": "VDreal",
					"LongName": "Real dead space",
					"UnitShort": "mL",
					"UnitLong": "mL",
					"Comment": null
				}, {
					"Id": 4128827,
					"ShortName": "Vex SB",
					"LongName": "Vex Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128842,
					"ShortName": "VIN L",
					"LongName": "VIN limit ON/OFF",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4128843,
					"ShortName": "VIN L%",
					"LongName": "VIN limit",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4128826,
					"ShortName": "VIN SB",
					"LongName": "Vin Single Breath",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4128853,
					"ShortName": "VT NO",
					"LongName": "Tidal Volume NO",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}
			]
		}, {
			"Id": 65,
			"Childs": [{
					"Id": 6501,
					"Childs": [],
					"ParentId": 65,
					"ShortName": "MIP",
					"LongName": "MIP",
					"Comment": null,
					"Parameters": [{
							"Id": 426049543,
							"ShortName": "At_MIP",
							"LongName": "A TOT MIP",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": null
						}, {
							"Id": 426049546,
							"ShortName": "BF_MIP",
							"LongName": "BF MIP",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 426049547,
							"ShortName": "ERV_MIP",
							"LongName": "ERV MIP",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 426049550,
							"ShortName": "FRC_MIP",
							"LongName": "FRC MIP",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 426049549,
							"ShortName": "IC_MIP",
							"LongName": "IC MIP",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 426049537,
							"ShortName": "MIP",
							"LongName": "MIP",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 426049539,
							"ShortName": "MIP Ave",
							"LongName": "MIP Average",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 426049538,
							"ShortName": "MIP Peak",
							"LongName": "MIP Peak",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 426049540,
							"ShortName": "MIP Sus",
							"LongName": "MIP Sustain",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 426049545,
							"ShortName": "MV_MIP",
							"LongName": "MV MIP",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 426049551,
							"ShortName": "QEC ATS_MIP",
							"LongName": "Quality Error Code ATS/ERS 2001 MIP",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 426049554,
							"ShortName": "QEC DAL_MIP",
							"LongName": "Quality Error Code Deutsche Atemwegsliga MIP",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 426049553,
							"ShortName": "QEC Mayo_MIP",
							"LongName": "Quality Error Code Mayo MIP",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 426049552,
							"ShortName": "Rank_MIP",
							"LongName": "Trial Ranking MIP",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 426049541,
							"ShortName": "T50_MIP",
							"LongName": "T 50 MIP",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 426049548,
							"ShortName": "TLC_MIP",
							"LongName": "TLC MIP",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 426049542,
							"ShortName": "TTOT_MIP",
							"LongName": "T TOT MIP",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 426049544,
							"ShortName": "VT_MIP",
							"LongName": "VT MIP",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}, {
					"Id": 6502,
					"Childs": [],
					"ParentId": 65,
					"ShortName": "MEP",
					"LongName": "MEP",
					"Comment": null,
					"Parameters": [{
							"Id": 426115079,
							"ShortName": "At_MEP",
							"LongName": "A TOT MEP",
							"UnitShort": "kPa*s",
							"UnitLong": "kPa*s",
							"Comment": null
						}, {
							"Id": 426115082,
							"ShortName": "BF_MEP",
							"LongName": "BF MEP",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 426115083,
							"ShortName": "ERV_MEP",
							"LongName": "ERV MEP",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 426115086,
							"ShortName": "FRC_MEP",
							"LongName": "FRC MEP",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 426115085,
							"ShortName": "IC_MEP",
							"LongName": "IC MEP",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 426115073,
							"ShortName": "MEP",
							"LongName": "MEP",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 426115075,
							"ShortName": "MEP Ave",
							"LongName": "MEP Average",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 426115074,
							"ShortName": "MEP Peak",
							"LongName": "MEP Peak",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 426115076,
							"ShortName": "MEP Sus",
							"LongName": "MEP Sustain",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 426115081,
							"ShortName": "MV_MEP",
							"LongName": "MV MEP",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 426115087,
							"ShortName": "QEC ATS_MEP",
							"LongName": "Quality Error Code ATS/ERS 2001 MEP",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 426115090,
							"ShortName": "QEC DAL_MEP",
							"LongName": "Quality Error Code Deutsche Atemwegsliga MEP",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 426115089,
							"ShortName": "QEC Mayo_MEP",
							"LongName": "Quality Error Code Mayo MEP",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 426115088,
							"ShortName": "Rank_MEP",
							"LongName": "Trial Ranking MEP",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 426115077,
							"ShortName": "T50_MEP",
							"LongName": "T 50 MEP",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 426115084,
							"ShortName": "TLC_MEP",
							"LongName": "TLC MEP",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 426115078,
							"ShortName": "TTOT_MEP",
							"LongName": "T TOT MEP",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 426115080,
							"ShortName": "VT_MEP",
							"LongName": "VT MEP",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}, {
					"Id": 6599,
					"Childs": [],
					"ParentId": 65,
					"ShortName": "VMAX",
					"LongName": "VMAX",
					"Comment": null,
					"Parameters": [{
							"Id": 432472065,
							"ShortName": "RAW_ECODE",
							"LongName": "RAW_ECODE",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}
					]
				}
			],
			"ParentId": 0,
			"ShortName": "MIP / MEP",
			"LongName": "MIP / MEP",
			"Comment": null,
			"Parameters": []
		}, {
			"Id": 66,
			"Childs": [{
					"Id": 6601,
					"Childs": [],
					"ParentId": 66,
					"ShortName": "P 0.1 Rest",
					"LongName": "P 0.1 Rest",
					"Comment": null,
					"Parameters": [{
							"Id": 432603137,
							"ShortName": "BF_P0.1",
							"LongName": "BF P 0.1",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 432603158,
							"ShortName": "CV% Tot",
							"LongName": "Coefficient of Variation % Total",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 432603157,
							"ShortName": "CV% Trial",
							"LongName": "Coefficient of Variation % per Trial",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 432603138,
							"ShortName": "MEF_P0.1",
							"LongName": "MEF P 0.1",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 432603139,
							"ShortName": "MIF_P0.1",
							"LongName": "MIF P 0.1",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 432603140,
							"ShortName": "MIP",
							"LongName": "MIP",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 432603141,
							"ShortName": "MV_P0.1",
							"LongName": "MV P 0.1",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 432603145,
							"ShortName": "P0.1",
							"LongName": "P 0.1",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 432603159,
							"ShortName": "P0.1*TIN/VT",
							"LongName": "P0.1*TIN/VT",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 432603142,
							"ShortName": "P0.1/MIF",
							"LongName": "P 0.1 / MIF",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 432603144,
							"ShortName": "P0.1/MIP",
							"LongName": "P 0.1 / MIP",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432603143,
							"ShortName": "P0.1/MV",
							"LongName": "P 0.1 / MV",
							"UnitShort": "kPa/(L/min)",
							"UnitLong": "kPa/(L/min)",
							"Comment": null
						}, {
							"Id": 432603146,
							"ShortName": "P0.1SD",
							"LongName": "P 0.1 Standard Deviation",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432603147,
							"ShortName": "PaCO2",
							"LongName": "PaCO2",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 432603154,
							"ShortName": "QEC ATS_P0.1",
							"LongName": "Quality Error Code ATS/ERS 2001 P 0.1",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432603156,
							"ShortName": "QEC DAL_P0.1",
							"LongName": "Quality Error Code Deutsche Atemwegsliga P 0.1",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432603155,
							"ShortName": "QEC Mayo_P0.1",
							"LongName": "Quality Error Code Mayo P 0.1",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432603151,
							"ShortName": "TEX/TTOT",
							"LongName": "TEX / TTOT",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432603148,
							"ShortName": "TEX_P0.1",
							"LongName": "Exp. Time P 0.1",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 432603152,
							"ShortName": "TIN/TTOT",
							"LongName": "TIN / TTOT",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432603149,
							"ShortName": "TIN_P0.1",
							"LongName": "Insp. Time P 0.1",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 432603150,
							"ShortName": "TTOT_P0.1",
							"LongName": "TTOT P 0.1",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 432603153,
							"ShortName": "VT_P0.1",
							"LongName": "VT P 0.1",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}, {
					"Id": 6602,
					"Childs": [],
					"ParentId": 66,
					"ShortName": "P 0.1 Max",
					"LongName": "P 0.1 Max",
					"Comment": null,
					"Parameters": [{
							"Id": 432668673,
							"ShortName": "BF",
							"LongName": "BF",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 432668674,
							"ShortName": "MEF",
							"LongName": "MEF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 432668675,
							"ShortName": "MIF",
							"LongName": "MIF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 432668676,
							"ShortName": "MIP",
							"LongName": "MIP",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 432668677,
							"ShortName": "MV",
							"LongName": "MV",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 432668683,
							"ShortName": "P0.1",
							"LongName": "P0.1",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 432668694,
							"ShortName": "P0.1*TIN/VT",
							"LongName": "P0.1*TIN/VT",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 432668679,
							"ShortName": "P0.1/P0.1Max",
							"LongName": "P0.1/P0.1Max",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432668678,
							"ShortName": "P0.1Max",
							"LongName": "P0.1Max",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 432668680,
							"ShortName": "P0.1Max/MIF",
							"LongName": "P0.1Max/MIF",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 432668682,
							"ShortName": "P0.1Max/MIP",
							"LongName": "P0.1Max/MIP",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432668681,
							"ShortName": "P0.1Max/MV",
							"LongName": "P0.1Max/MV",
							"UnitShort": "kPa/(L/min)",
							"UnitLong": "kPa/(L/min)",
							"Comment": null
						}, {
							"Id": 432668684,
							"ShortName": "P0.1MaxSD",
							"LongName": "P0.1MaxSD",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432668685,
							"ShortName": "PaCO2",
							"LongName": "PaCO2",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 432668692,
							"ShortName": "QEC ATS",
							"LongName": "QEC ATS",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432668693,
							"ShortName": "QEC Mayo",
							"LongName": "QEC Mayo",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432668686,
							"ShortName": "TEX",
							"LongName": "TEX",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 432668689,
							"ShortName": "TEX/TTOT",
							"LongName": "TEX/TTOT",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432668687,
							"ShortName": "TIN",
							"LongName": "TIN",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 432668690,
							"ShortName": "TIN/TTOT",
							"LongName": "TIN/TTOT",
							"UnitShort": "*1.0",
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432668688,
							"ShortName": "TTOT",
							"LongName": "TTOT",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 432668691,
							"ShortName": "VT",
							"LongName": "VT",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}, {
					"Id": 6603,
					"Childs": [],
					"ParentId": 66,
					"ShortName": "P 0.1 CO2-1",
					"LongName": "P 0.1 CO2-1",
					"Comment": null,
					"Parameters": [{
							"Id": 432734209,
							"ShortName": "BF",
							"LongName": "BF",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 432734226,
							"ShortName": "FICO2",
							"LongName": "FICO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 432734210,
							"ShortName": "MEF",
							"LongName": "MEF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 432734211,
							"ShortName": "MIF",
							"LongName": "MIF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 432734212,
							"ShortName": "MIP",
							"LongName": "MIP",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 432734213,
							"ShortName": "MV",
							"LongName": "MV",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 432734231,
							"ShortName": "P0.1*TIN/VT",
							"LongName": "P0.1*TIN/VT",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 432734217,
							"ShortName": "P0.1CO2-1",
							"LongName": "P0.1CO2-1",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 432734214,
							"ShortName": "P0.1CO2-1/MIF",
							"LongName": "P0.1CO2-1/MIF",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 432734216,
							"ShortName": "P0.1CO2-1/MIP",
							"LongName": "P0.1CO2-1/MIP",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432734215,
							"ShortName": "P0.1CO2-1/MV",
							"LongName": "P0.1CO2-1/MV",
							"UnitShort": "kPa/(L/min)",
							"UnitLong": "kPa/(L/min)",
							"Comment": null
						}, {
							"Id": 432734227,
							"ShortName": "P0.1CO2-1/P0.1",
							"LongName": "P0.1CO2-1/P0.1",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432734228,
							"ShortName": "P0.1CO2-1/P0.1Max",
							"LongName": "P0.1CO2-1/P0.1Max",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432734218,
							"ShortName": "P0.1CO2-1SD",
							"LongName": "P0.1CO2-1SD",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432734219,
							"ShortName": "PaCO2",
							"LongName": "PaCO2",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 432734229,
							"ShortName": "QEC ATS",
							"LongName": "QEC ATS",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432734230,
							"ShortName": "QEC Mayo",
							"LongName": "QEC Mayo",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432734220,
							"ShortName": "TEX",
							"LongName": "TEX",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 432734223,
							"ShortName": "TEX/TTOT",
							"LongName": "TEX/TTOT",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432734221,
							"ShortName": "TIN",
							"LongName": "TIN",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 432734224,
							"ShortName": "TIN/TTOT",
							"LongName": "TIN/TTOT",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432734222,
							"ShortName": "TTOT",
							"LongName": "TTOT",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 432734225,
							"ShortName": "VT",
							"LongName": "VT",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}, {
					"Id": 6604,
					"Childs": [],
					"ParentId": 66,
					"ShortName": "P 0.1 CO2-2",
					"LongName": "P 0.1 CO2-2",
					"Comment": null,
					"Parameters": [{
							"Id": 432799745,
							"ShortName": "BF",
							"LongName": "BF",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 432799762,
							"ShortName": "FICO2",
							"LongName": "FICO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 432799746,
							"ShortName": "MEF",
							"LongName": "MEF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 432799747,
							"ShortName": "MIF",
							"LongName": "MIF",
							"UnitShort": "L/s",
							"UnitLong": "L/s",
							"Comment": null
						}, {
							"Id": 432799748,
							"ShortName": "MIP",
							"LongName": "MIP",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 432799749,
							"ShortName": "MV",
							"LongName": "MV",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 432799767,
							"ShortName": "P0.1*TIN/VT",
							"LongName": "P0.1*TIN/VT",
							"UnitShort": "kPa*s/L",
							"UnitLong": "kPa*s/L",
							"Comment": null
						}, {
							"Id": 432799753,
							"ShortName": "P0.1CO2-2",
							"LongName": "P0.1CO2-2",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 432799750,
							"ShortName": "P0.1CO2-2/MIF",
							"LongName": "P0.1CO2-2/MIF",
							"UnitShort": "kPa/(L/s)",
							"UnitLong": "kPa/(L/s)",
							"Comment": null
						}, {
							"Id": 432799752,
							"ShortName": "P0.1CO2-2/MIP",
							"LongName": "P0.1CO2-2/MIP",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432799751,
							"ShortName": "P0.1CO2-2/MV",
							"LongName": "P0.1CO2-2/MV",
							"UnitShort": "kPa/(L/min)",
							"UnitLong": "kPa/(L/min)",
							"Comment": null
						}, {
							"Id": 432799763,
							"ShortName": "P0.1CO2-2/P0.1",
							"LongName": "P0.1CO2-2/P0.1",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432799764,
							"ShortName": "P0.1CO2-2/P0.1Max",
							"LongName": "P0.1CO2-2/P0.1Max",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432799754,
							"ShortName": "P0.1CO2-2SD",
							"LongName": "P0.1CO2-2SD",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432799755,
							"ShortName": "PaCO2",
							"LongName": "PaCO2",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 432799765,
							"ShortName": "QEC ATS",
							"LongName": "QEC ATS",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432799766,
							"ShortName": "QEC Mayo",
							"LongName": "QEC Mayo",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432799756,
							"ShortName": "TEX",
							"LongName": "TEX",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 432799759,
							"ShortName": "TEX/TTOT",
							"LongName": "TEX/TTOT",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432799757,
							"ShortName": "TIN",
							"LongName": "TIN",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 432799760,
							"ShortName": "TIN/TTOT",
							"LongName": "TIN/TTOT",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 432799758,
							"ShortName": "TTOT",
							"LongName": "TTOT",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 432799761,
							"ShortName": "VT",
							"LongName": "VT",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}, {
					"Id": 6699,
					"Childs": [],
					"ParentId": 66,
					"ShortName": "VMAX",
					"LongName": "VMAX",
					"Comment": null,
					"Parameters": [{
							"Id": 439025665,
							"ShortName": "FET_CO2",
							"LongName": "FET_CO2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 439025667,
							"ShortName": "FI_O2",
							"LongName": "FI_O2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 439025670,
							"ShortName": "Pet CO2",
							"LongName": "Pet CO2",
							"UnitShort": "mmHg",
							"UnitLong": "mmHg",
							"Comment": null
						}, {
							"Id": 439025668,
							"ShortName": "RAW_ECODE",
							"LongName": "RAW_ECODE",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 439025666,
							"ShortName": "SP_O2",
							"LongName": "SP_O2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 439025669,
							"ShortName": "VT/T insp",
							"LongName": "VT/T insp",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}
					]
				}
			],
			"ParentId": 0,
			"ShortName": "P 0.1",
			"LongName": "P 0.1",
			"Comment": null,
			"Parameters": []
		}, {
			"Id": 67,
			"Childs": [{
					"Id": 6701,
					"Childs": [],
					"ParentId": 67,
					"ShortName": "Sniff",
					"LongName": "Sniff",
					"Comment": null,
					"Parameters": [{
							"Id": 439156737,
							"ShortName": "SNIP",
							"LongName": "SNIP",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 439156742,
							"ShortName": "SNIP ECODE",
							"LongName": "SNIP ECODE",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 439156739,
							"ShortName": "SNIPAve",
							"LongName": "SNIP Average",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 439156741,
							"ShortName": "SnipMTime",
							"LongName": "SNIP time to peak",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 439156738,
							"ShortName": "SNIPPk",
							"LongName": "SNIP Peak",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}, {
							"Id": 439156740,
							"ShortName": "SNIPSus",
							"LongName": "SNIP Sustain",
							"UnitShort": "kPa",
							"UnitLong": "kPa",
							"Comment": null
						}
					]
				}
			],
			"ParentId": 0,
			"ShortName": "SNIP",
			"LongName": "SNIP",
			"Comment": null,
			"Parameters": []
		}, {
			"Id": 68,
			"Childs": [{
					"Id": 6801,
					"Childs": [],
					"ParentId": 68,
					"ShortName": "FRC Rb",
					"LongName": "FRC Rebreathing",
					"Comment": null,
					"Parameters": [{
							"Id": 445710372,
							"ShortName": "B Cnt",
							"LongName": "Breath count",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 445710352,
							"ShortName": "BF",
							"LongName": "BF",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 445710368,
							"ShortName": "BFrb",
							"LongName": "BFrb",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 445710366,
							"ShortName": "dFRC",
							"LongName": "dFRC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710371,
							"ShortName": "dHe",
							"LongName": "dHe",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 445710367,
							"ShortName": "dRV",
							"LongName": "dRV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710349,
							"ShortName": "ERV",
							"LongName": "ERV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710341,
							"ShortName": "FA He",
							"LongName": "FA He",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 445710342,
							"ShortName": "FA O2",
							"LongName": "FA O2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 445710338,
							"ShortName": "FI He",
							"LongName": "FI He",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 445710339,
							"ShortName": "FI O2",
							"LongName": "FI O2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 445710357,
							"ShortName": "FRC%TLC",
							"LongName": "FRC%TLC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 445710358,
							"ShortName": "FRCATP",
							"LongName": "FRCATP",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710343,
							"ShortName": "FRC-He",
							"LongName": "FRC-He",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710348,
							"ShortName": "IC",
							"LongName": "IC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710353,
							"ShortName": "IRV",
							"LongName": "IRV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710351,
							"ShortName": "MV",
							"LongName": "MV",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 445710369,
							"ShortName": "MVrb",
							"LongName": "MVrb",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 445710359,
							"ShortName": "obsolete (DV)",
							"LongName": "obsolete (DV)",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710361,
							"ShortName": "obsolete (VA)",
							"LongName": "obsolete (VA)",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710365,
							"ShortName": "QEC Mayo",
							"LongName": "QEC Mayo",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 445710354,
							"ShortName": "RV",
							"LongName": "RV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710356,
							"ShortName": "RV%TLC",
							"LongName": "RV%TLC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 445710355,
							"ShortName": "TLC",
							"LongName": "TLC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710344,
							"ShortName": "T-RB",
							"LongName": "T-RB",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 445710362,
							"ShortName": "VAT",
							"LongName": "VAT",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710346,
							"ShortName": "VC_EX",
							"LongName": "VC_EX",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710345,
							"ShortName": "VC_IN",
							"LongName": "VC_IN",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710347,
							"ShortName": "VC_max",
							"LongName": "VC_max",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710364,
							"ShortName": "VCref",
							"LongName": "VCref",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710337,
							"ShortName": "V-INI",
							"LongName": "V-INI",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710360,
							"ShortName": "VO2",
							"LongName": "VO2",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710363,
							"ShortName": "V'O2",
							"LongName": "V'O2",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 445710340,
							"ShortName": "V-RB",
							"LongName": "V-RB",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710350,
							"ShortName": "VT",
							"LongName": "VT",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 445710370,
							"ShortName": "VTrb",
							"LongName": "VTrb",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}
			],
			"ParentId": 0,
			"ShortName": "FRC Rb",
			"LongName": "FRC Rebreathing",
			"Comment": null,
			"Parameters": []
		}, {
			"Id": 69,
			"Childs": [{
					"Id": 6901,
					"Childs": [],
					"ParentId": 69,
					"ShortName": "Diff Rb",
					"LongName": "Diffusion Rebreathing",
					"Comment": null,
					"Parameters": [{
							"Id": 452263945,
							"ShortName": "%COHb",
							"LongName": "Carboxyhemoglobin",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 452263978,
							"ShortName": "BF",
							"LongName": "BF",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 452263948,
							"ShortName": "BF rb",
							"LongName": "BF rb",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 452263940,
							"ShortName": "BP CO",
							"LongName": "CO Backpressure",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 452263955,
							"ShortName": "CorrHb",
							"LongName": "Hb correction",
							"UnitShort": "g/100mL",
							"UnitLong": "g/100mL",
							"Comment": null
						}, {
							"Id": 452263989,
							"ShortName": "dFRC",
							"LongName": "dFRC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263953,
							"ShortName": "DLCO rb",
							"LongName": "DLCO rb",
							"UnitShort": "mmol/(min*kPa)",
							"UnitLong": "mmol/(min*kPa)",
							"Comment": null
						}, {
							"Id": 452263990,
							"ShortName": "dRV",
							"LongName": "dRV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263985,
							"ShortName": "DV",
							"LongName": "DV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263975,
							"ShortName": "ERV",
							"LongName": "ERV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263943,
							"ShortName": "EvalT",
							"LongName": "Evaluation time",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 452263942,
							"ShortName": "FA CO",
							"LongName": "FA CO",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 452263967,
							"ShortName": "FA He",
							"LongName": "FA He",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 452263968,
							"ShortName": "FA O2",
							"LongName": "FA O2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 452263941,
							"ShortName": "FI CO",
							"LongName": "FI CO",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 452263964,
							"ShortName": "FI He",
							"LongName": "FI He",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 452263965,
							"ShortName": "FI O2",
							"LongName": "FI O2",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 452263983,
							"ShortName": "FRC %",
							"LongName": "FRC % TLC-He",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 452263984,
							"ShortName": "FRCATP",
							"LongName": "FRCATP",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263969,
							"ShortName": "FRC-He",
							"LongName": "FRC-He",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263944,
							"ShortName": "Hb",
							"LongName": "Hb",
							"UnitShort": "g/100mL",
							"UnitLong": "g/100mL",
							"Comment": null
						}, {
							"Id": 452263974,
							"ShortName": "IC",
							"LongName": "IC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263979,
							"ShortName": "IRV",
							"LongName": "IRV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263954,
							"ShortName": "KCO rb",
							"LongName": "DLCOrb/VA",
							"UnitShort": "mmol/(min*kPa*L)",
							"UnitLong": "mmol/(min*kPa*L)",
							"Comment": null
						}, {
							"Id": 452263962,
							"ShortName": "KCO%KCON",
							"LongName": "KCO/KCO_Norm",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 452263961,
							"ShortName": "KCO_Norm",
							"LongName": "KCO normalized",
							"UnitShort": "mmol/(min*kPa*L)",
							"UnitLong": "mmol/(min*kPa*L)",
							"Comment": null
						}, {
							"Id": 452263977,
							"ShortName": "MV",
							"LongName": "MV",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 452263956,
							"ShortName": "Pamb",
							"LongName": "P ambient",
							"UnitShort": "hPa",
							"UnitLong": "hPa",
							"Comment": null
						}, {
							"Id": 452263958,
							"ShortName": "RHamb",
							"LongName": "RH ambient",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 452263980,
							"ShortName": "RV",
							"LongName": "RV",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263982,
							"ShortName": "RV%TLC",
							"LongName": "RV%TLC",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 452263957,
							"ShortName": "Tamb",
							"LongName": "T ambient",
							"UnitShort": "°C",
							"UnitLong": "Celsius",
							"Comment": null
						}, {
							"Id": 452263959,
							"ShortName": "TauCO",
							"LongName": "TauCO",
							"UnitShort": "1/min",
							"UnitLong": "1/min",
							"Comment": null
						}, {
							"Id": 452263937,
							"ShortName": "TauCO (old)",
							"LongName": "TauCO (old)",
							"UnitShort": "Hz",
							"UnitLong": "Hertz ( 1/s )",
							"Comment": null
						}, {
							"Id": 452263960,
							"ShortName": "TauQuality",
							"LongName": "TauQuality",
							"UnitShort": "%",
							"UnitLong": "%",
							"Comment": null
						}, {
							"Id": 452263981,
							"ShortName": "TLC",
							"LongName": "TLC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263970,
							"ShortName": "T-RB",
							"LongName": "Time-RB",
							"UnitShort": "sec",
							"UnitLong": "sec",
							"Comment": null
						}, {
							"Id": 452263947,
							"ShortName": "VA rb",
							"LongName": "Alveolar volume STPD",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263952,
							"ShortName": "VA' rb",
							"LongName": "Alveolar ventilation STPD",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 452263938,
							"ShortName": "VA rb (old)",
							"LongName": "Alveolar volume (old)",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263939,
							"ShortName": "VA' rb (old)",
							"LongName": "Alveolar ventilation (old)",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 452263946,
							"ShortName": "VBag",
							"LongName": "VBag STPD",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263972,
							"ShortName": "VC EX",
							"LongName": "VC EX",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263971,
							"ShortName": "VC IN",
							"LongName": "VC IN",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263973,
							"ShortName": "VC max",
							"LongName": "VC max",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263988,
							"ShortName": "VCref",
							"LongName": "VC ref for QC",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263950,
							"ShortName": "VDana",
							"LongName": "VDana rb STPD",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263951,
							"ShortName": "VDapp",
							"LongName": "VDapp STPD",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263963,
							"ShortName": "V-INI",
							"LongName": "V-INI",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263986,
							"ShortName": "VO2",
							"LongName": "VO2",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263987,
							"ShortName": "V'O2",
							"LongName": "V'O2",
							"UnitShort": "L/min",
							"UnitLong": "L/min",
							"Comment": null
						}, {
							"Id": 452263966,
							"ShortName": "V-RB",
							"LongName": "V-RB",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263976,
							"ShortName": "VT",
							"LongName": "VT",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}, {
							"Id": 452263949,
							"ShortName": "VT rb",
							"LongName": "VT rb STPD",
							"UnitShort": "L",
							"UnitLong": "Liter",
							"Comment": null
						}
					]
				}
			],
			"ParentId": 0,
			"ShortName": "Diff Rb",
			"LongName": "Diffusion Rebreathing",
			"Comment": null,
			"Parameters": []
		}, {
			"Id": 70,
			"Childs": [{
					"Id": 7099,
					"Childs": [],
					"ParentId": 70,
					"ShortName": "VMAX",
					"LongName": "VMAX",
					"Comment": null,
					"Parameters": [{
							"Id": 465240066,
							"ShortName": "Breaths",
							"LongName": "Breaths",
							"UnitShort": null,
							"UnitLong": "Number with no unit",
							"Comment": null
						}, {
							"Id": 465240070,
							"ShortName": "HoldTime",
							"LongName": "Breath hold time",
							"UnitShort": "ms",
							"UnitLong": "ms",
							"Comment": null
						}, {
							"Id": 465240069,
							"ShortName": "InspTime",
							"LongName": "Inspiration time ",
							"UnitShort": "ms",
							"UnitLong": "ms",
							"Comment": null
						}, {
							"Id": 465240068,
							"ShortName": "NebDel",
							"LongName": "Nebulization start delay",
							"UnitShort": "ms",
							"UnitLong": "ms",
							"Comment": null
						}, {
							"Id": 465240065,
							"ShortName": "PulsDur",
							"LongName": "Pulse duration",
							"UnitShort": "ms",
							"UnitLong": "ms",
							"Comment": null
						}, {
							"Id": 465240067,
							"ShortName": "TNebTime",
							"LongName": "Total nebulization time",
							"UnitShort": "ms",
							"UnitLong": "ms",
							"Comment": null
						}
					]
				}
			],
			"ParentId": 0,
			"ShortName": "Provo",
			"LongName": "Bronchial Challenge",
			"Comment": null,
			"Parameters": [{
					"Id": 4587534,
					"ShortName": "Conc",
					"LongName": "Concentration",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4587528,
					"ShortName": "Cum. Dose",
					"LongName": "Cumulated dose",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4587527,
					"ShortName": "Dose",
					"LongName": "Dose",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4587521,
					"ShortName": "Para1",
					"LongName": "ObservationParam1",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4587529,
					"ShortName": "Para1%Base",
					"LongName": "Para1%Base",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4587535,
					"ShortName": "Para1%Chg/Base",
					"LongName": "Para1%Chg/Base",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4587522,
					"ShortName": "Para2",
					"LongName": "ObservationParam2",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4587530,
					"ShortName": "Para2%Base",
					"LongName": "Para2%Base",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4587536,
					"ShortName": "Para2%Chg/Base",
					"LongName": "Para2%Chg/Base",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4587523,
					"ShortName": "Para3",
					"LongName": "ObservationParam3",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4587531,
					"ShortName": "Para3%Base",
					"LongName": "Para3%Base",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4587537,
					"ShortName": "Para3%Chg/Base",
					"LongName": "Para3%Chg/Base",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4587524,
					"ShortName": "Para4",
					"LongName": "ObservationParam4",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4587532,
					"ShortName": "Para4%Base",
					"LongName": "Para4%Base",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4587538,
					"ShortName": "Para4%Chg/Base",
					"LongName": "Para4%Chg/Base",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4587525,
					"ShortName": "Para5",
					"LongName": "ObservationParam5",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4587533,
					"ShortName": "Para5%Base",
					"LongName": "Para5%Base",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4587539,
					"ShortName": "Para5%Chg/Base",
					"LongName": "Para5%Chg/Base",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4587526,
					"ShortName": "Substance",
					"LongName": "Substance",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}
			]
		}, {
			"Id": 71,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "SB O2",
			"LongName": "Single Breath O2",
			"Comment": null,
			"Parameters": [{
					"Id": 4653060,
					"ShortName": "ADS",
					"LongName": "Anatomical Dead Space",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4653059,
					"ShortName": "CV",
					"LongName": "Closing volume",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}, {
					"Id": 4653061,
					"ShortName": "Error",
					"LongName": "Error code",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 4653058,
					"ShortName": "N2 Delta",
					"LongName": "N2 Delta",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4653057,
					"ShortName": "VC_SBO2",
					"LongName": "VC_SBO2",
					"UnitShort": "L",
					"UnitLong": "Liter",
					"Comment": null
				}
			]
		}, {
			"Id": 72,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "Ex Temp",
			"LongName": "Exhaled temperature",
			"Comment": null,
			"Parameters": [{
					"Id": 4718593,
					"ShortName": "BTemp",
					"LongName": "Baseline temperature",
					"UnitShort": "°C",
					"UnitLong": "Celsius",
					"Comment": null
				}, {
					"Id": 4718597,
					"ShortName": "Error",
					"LongName": "Error code",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 4718594,
					"ShortName": "PET",
					"LongName": "Peak expired temperature",
					"UnitShort": "°C",
					"UnitLong": "Celsius",
					"Comment": null
				}, {
					"Id": 4718595,
					"ShortName": "PLET",
					"LongName": "Plateau exp. temp.",
					"UnitShort": "°C",
					"UnitLong": "Celsius",
					"Comment": null
				}, {
					"Id": 4718596,
					"ShortName": "TxSlope",
					"LongName": "Temp. change/sec",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}
			]
		}, {
			"Id": 73,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "6 MWT",
			"LongName": "6 Minute Walk Test",
			"Comment": null,
			"Parameters": [{
					"Id": 4784153,
					"ShortName": "6 MWW",
					"LongName": "6 Minute Walk Work",
					"UnitShort": "kg*m",
					"UnitLong": "Walk Work",
					"Comment": null
				}, {
					"Id": 4784131,
					"ShortName": "6MWD",
					"LongName": "Six Minute Walk Test Distance",
					"UnitShort": "m",
					"UnitLong": "Meter",
					"Comment": null
				}, {
					"Id": 4784155,
					"ShortName": "6MWS",
					"LongName": "6 Minute Walk Speed",
					"UnitShort": "m/s",
					"UnitLong": "m/s",
					"Comment": null
				}, {
					"Id": 4784152,
					"ShortName": "Aid",
					"LongName": "Walking Aid",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4784156,
					"ShortName": "Bode",
					"LongName": "Bode Index",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4784145,
					"ShortName": "BP Dia",
					"LongName": "BP Diastolic",
					"UnitShort": "mmHg",
					"UnitLong": "mmHg",
					"Comment": null
				}, {
					"Id": 4784144,
					"ShortName": "BP Sys",
					"LongName": "BP Systolic",
					"UnitShort": "mmHg",
					"UnitLong": "mmHg",
					"Comment": null
				}, {
					"Id": 4784150,
					"ShortName": "Dist. final",
					"LongName": "Distance final Lap",
					"UnitShort": "m",
					"UnitLong": "Meter",
					"Comment": null
				}, {
					"Id": 4784132,
					"ShortName": "Duration",
					"LongName": "Duration of Test",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 4784137,
					"ShortName": "Dyspnea",
					"LongName": "Borg scale Dyspnea",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 4784142,
					"ShortName": "End",
					"LongName": "End Time",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 4784138,
					"ShortName": "Exertion",
					"LongName": "Borg scale Fatigue",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 4784129,
					"ShortName": "Header",
					"LongName": "Column header",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4784133,
					"ShortName": "HR",
					"LongName": "Heart rate",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": null
				}, {
					"Id": 4784134,
					"ShortName": "HRmax",
					"LongName": "Highest Heart Rate",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": null
				}, {
					"Id": 4784139,
					"ShortName": "Lap Dist",
					"LongName": "Lap distance",
					"UnitShort": "m",
					"UnitLong": "Meter",
					"Comment": null
				}, {
					"Id": 4784147,
					"ShortName": "Laps",
					"LongName": "Lap Count",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 4784159,
					"ShortName": "Mean Spo2",
					"LongName": "Mean Spo2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4784157,
					"ShortName": "MMRC",
					"LongName": "Modified MRC Dyspnoea Scale",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4784146,
					"ShortName": "O2 Flow",
					"LongName": "Suppl. O2 Flow",
					"UnitShort": "L/min",
					"UnitLong": "L/min",
					"Comment": null
				}, {
					"Id": 4784151,
					"ShortName": "O2 Type",
					"LongName": "Suppl. O2 Flow Type",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4784149,
					"ShortName": "Pause",
					"LongName": "Duration of Pauses",
					"UnitShort": "min",
					"UnitLong": "min",
					"Comment": null
				}, {
					"Id": 4784148,
					"ShortName": "Pauses",
					"LongName": "Number of Pauses",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 4784140,
					"ShortName": "Pre_Stop",
					"LongName": "Premature stop",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 4784135,
					"ShortName": "SpO2",
					"LongName": "O2 saturation",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4784136,
					"ShortName": "SpO2min",
					"LongName": "Lowest SpO2",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4784141,
					"ShortName": "Start",
					"LongName": "Start Time",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 4784143,
					"ShortName": "Target",
					"LongName": "Target Time",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 4784154,
					"ShortName": "Ti88",
					"LongName": "Time under 88 percent",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 4784130,
					"ShortName": "Time",
					"LongName": "6MWT-Time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 4784158,
					"ShortName": "Total Distance",
					"LongName": "Total Distance",
					"UnitShort": "m",
					"UnitLong": "Meter",
					"Comment": null
				}
			]
		}, {
			"Id": 74,
			"Childs": [],
			"ParentId": 0,
			"ShortName": "SkinFold",
			"LongName": "Skin Fold Information",
			"Comment": null,
			"Parameters": [{
					"Id": 4849669,
					"ShortName": "BodyFat",
					"LongName": "Body Fat",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4849672,
					"ShortName": "IdealLBM",
					"LongName": "Ideal Lean Body Mass",
					"UnitShort": "kg",
					"UnitLong": "kg",
					"Comment": null
				}, {
					"Id": 4849670,
					"ShortName": "LBM",
					"LongName": "Lean Body Mass",
					"UnitShort": "kg",
					"UnitLong": "kg",
					"Comment": null
				}, {
					"Id": 4849674,
					"ShortName": "PAR 10",
					"LongName": "Parameter 10",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849675,
					"ShortName": "PAR 11",
					"LongName": "Parameter 11",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849676,
					"ShortName": "PAR 12",
					"LongName": "Parameter 12",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849677,
					"ShortName": "PAR 13",
					"LongName": "Parameter 13",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849678,
					"ShortName": "PAR 14",
					"LongName": "Parameter 14",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849679,
					"ShortName": "PAR 15",
					"LongName": "Parameter 15",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849680,
					"ShortName": "PAR 16",
					"LongName": "Parameter 16",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849681,
					"ShortName": "PAR 17",
					"LongName": "Parameter 17",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849682,
					"ShortName": "PAR 18",
					"LongName": "Parameter 18",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849683,
					"ShortName": "PAR 19",
					"LongName": "Parameter 19",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849684,
					"ShortName": "PAR 20",
					"LongName": "Parameter 20",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849685,
					"ShortName": "PAR 21",
					"LongName": "Parameter 21",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849686,
					"ShortName": "PAR 22",
					"LongName": "Parameter 22",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849687,
					"ShortName": "PAR 23",
					"LongName": "Parameter 23",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849688,
					"ShortName": "PAR 24",
					"LongName": "Parameter 24",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849689,
					"ShortName": "PAR 25",
					"LongName": "Parameter 25",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849690,
					"ShortName": "PAR 26",
					"LongName": "Parameter 26",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849691,
					"ShortName": "PAR 27",
					"LongName": "Parameter 27",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849692,
					"ShortName": "PAR 28",
					"LongName": "Parameter 28",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849693,
					"ShortName": "PAR 29",
					"LongName": "Parameter 29",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849694,
					"ShortName": "PAR 30",
					"LongName": "Parameter 30",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849695,
					"ShortName": "PAR 31",
					"LongName": "Parameter 31",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849696,
					"ShortName": "PAR 32",
					"LongName": "Parameter 32",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849697,
					"ShortName": "PAR 33",
					"LongName": "Parameter 33",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849698,
					"ShortName": "PAR 34",
					"LongName": "Parameter 34",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849699,
					"ShortName": "PAR 35",
					"LongName": "Parameter 35",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849700,
					"ShortName": "PAR 36",
					"LongName": "Parameter 36",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849701,
					"ShortName": "PAR 37",
					"LongName": "Parameter 37",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849702,
					"ShortName": "PAR 38",
					"LongName": "Parameter 38",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849703,
					"ShortName": "PAR 39",
					"LongName": "Parameter 39",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849704,
					"ShortName": "PAR 40",
					"LongName": "Parameter 40",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849705,
					"ShortName": "PAR 41",
					"LongName": "Parameter 41",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849706,
					"ShortName": "PAR 42",
					"LongName": "Parameter 42",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849707,
					"ShortName": "PAR 43",
					"LongName": "Parameter 43",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849708,
					"ShortName": "PAR 44",
					"LongName": "Parameter 44",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849709,
					"ShortName": "PAR 45",
					"LongName": "Parameter 45",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849710,
					"ShortName": "PAR 46",
					"LongName": "Parameter 46",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849711,
					"ShortName": "PAR 47",
					"LongName": "Parameter 47",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849712,
					"ShortName": "PAR 48",
					"LongName": "Parameter 48",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849713,
					"ShortName": "PAR 49",
					"LongName": "Parameter 49",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849714,
					"ShortName": "PAR 50",
					"LongName": "Parameter 50",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849715,
					"ShortName": "PAR 51",
					"LongName": "Parameter 51",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849716,
					"ShortName": "PAR 52",
					"LongName": "Parameter 52",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849717,
					"ShortName": "PAR 53",
					"LongName": "Parameter 53",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849718,
					"ShortName": "PAR 54",
					"LongName": "Parameter 54",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849719,
					"ShortName": "PAR 55",
					"LongName": "Parameter 55",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849720,
					"ShortName": "PAR 56",
					"LongName": "Parameter 56",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849721,
					"ShortName": "PAR 57",
					"LongName": "Parameter 57",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849722,
					"ShortName": "PAR 58",
					"LongName": "Parameter 58",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849723,
					"ShortName": "PAR 59",
					"LongName": "Parameter 59",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849724,
					"ShortName": "PAR 60",
					"LongName": "Parameter 60",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849725,
					"ShortName": "PAR 61",
					"LongName": "Parameter 61",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849726,
					"ShortName": "PAR 62",
					"LongName": "Parameter 62",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849727,
					"ShortName": "PAR 63",
					"LongName": "Parameter 63",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849728,
					"ShortName": "PAR 64",
					"LongName": "Parameter 64",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849729,
					"ShortName": "PAR 65",
					"LongName": "Parameter 65",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849730,
					"ShortName": "PAR 66",
					"LongName": "Parameter 66",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849731,
					"ShortName": "PAR 67",
					"LongName": "Parameter 67",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849732,
					"ShortName": "PAR 68",
					"LongName": "Parameter 68",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849733,
					"ShortName": "PAR 69",
					"LongName": "Parameter 69",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849734,
					"ShortName": "PAR 70",
					"LongName": "Parameter 70",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849735,
					"ShortName": "PAR 71",
					"LongName": "Parameter 71",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849736,
					"ShortName": "PAR 72",
					"LongName": "Parameter 72",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849737,
					"ShortName": "PAR 73",
					"LongName": "Parameter 73",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849738,
					"ShortName": "PAR 74",
					"LongName": "Parameter 74",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849739,
					"ShortName": "PAR 75",
					"LongName": "Parameter 75",
					"UnitShort": null,
					"UnitLong": null,
					"Comment": null
				}, {
					"Id": 4849665,
					"ShortName": "Skin 1",
					"LongName": "Skin Fold 1",
					"UnitShort": "mm",
					"UnitLong": "mm",
					"Comment": null
				}, {
					"Id": 4849666,
					"ShortName": "Skin2",
					"LongName": "Skin Fold 2",
					"UnitShort": "mm",
					"UnitLong": "mm",
					"Comment": null
				}, {
					"Id": 4849667,
					"ShortName": "Skin3",
					"LongName": "Skin Fold 3",
					"UnitShort": "mm",
					"UnitLong": "mm",
					"Comment": null
				}, {
					"Id": 4849668,
					"ShortName": "SkinSum",
					"LongName": "Skin Fold Sum",
					"UnitShort": "mm",
					"UnitLong": "mm",
					"Comment": null
				}, {
					"Id": 4849671,
					"ShortName": "TargetFat",
					"LongName": "Target Body Fat",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 4849673,
					"ShortName": "TargetWeight",
					"LongName": "Target Weight",
					"UnitShort": "kg",
					"UnitLong": "kg",
					"Comment": null
				}
			]
		}, {
			"Id": 1401,
			"Childs": [],
			"ParentId": 14,
			"ShortName": "Leads",
			"LongName": "Leads",
			"Comment": null,
			"Parameters": [{
					"Id": 91815937,
					"ShortName": "ST",
					"LongName": "ST",
					"UnitShort": "10uV",
					"UnitLong": "10uV",
					"Comment": null
				}, {
					"Id": 91815938,
					"ShortName": "STslo",
					"LongName": "ST slope",
					"UnitShort": "10uV/s",
					"UnitLong": "10uV/s",
					"Comment": null
				}
			]
		}, {
			"Id": 1402,
			"Childs": [],
			"ParentId": 14,
			"ShortName": "Stress",
			"LongName": "Stress",
			"Comment": null,
			"Parameters": [{
					"Id": 91881486,
					"ShortName": "% THR",
					"LongName": "%Target Heart rate",
					"UnitShort": "%",
					"UnitLong": "%",
					"Comment": null
				}, {
					"Id": 91881490,
					"ShortName": "BP max",
					"LongName": "Blood Press max",
					"UnitShort": "mmHg",
					"UnitLong": "mmHg",
					"Comment": null
				}, {
					"Id": 91881489,
					"ShortName": "BPrest",
					"LongName": "Blood Press Rest",
					"UnitShort": "mmHg",
					"UnitLong": "mmHg",
					"Comment": null
				}, {
					"Id": 91881476,
					"ShortName": "Elev.",
					"LongName": "Elevation",
					"UnitShort": "0.1*%",
					"UnitLong": "0.1*%",
					"Comment": null
				}, {
					"Id": 91881498,
					"ShortName": "EMtype",
					"LongName": "Ergometer type",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 91881484,
					"ShortName": "HR max",
					"LongName": "Heart rate max",
					"UnitShort": "BPM",
					"UnitLong": "Beats per Minute",
					"Comment": null
				}, {
					"Id": 91881483,
					"ShortName": "HRrest",
					"LongName": "Heart rate Rest",
					"UnitShort": "BPM",
					"UnitLong": "Beats per Minute",
					"Comment": null
				}, {
					"Id": 91881473,
					"ShortName": "Load",
					"LongName": "Load",
					"UnitShort": "W",
					"UnitLong": "Watt",
					"Comment": null
				}, {
					"Id": 91881492,
					"ShortName": "METS",
					"LongName": "METS",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 91881496,
					"ShortName": "Phase",
					"LongName": "Phase",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 91881499,
					"ShortName": "PRNAME",
					"LongName": "Protocol name",
					"UnitShort": null,
					"UnitLong": "Number with no unit",
					"Comment": null
				}, {
					"Id": 91881493,
					"ShortName": "PWC130",
					"LongName": "PWC 130",
					"UnitShort": "W/kg",
					"UnitLong": "W/kg",
					"Comment": null
				}, {
					"Id": 91881494,
					"ShortName": "PWC150",
					"LongName": "PWC 150",
					"UnitShort": "W/kg",
					"UnitLong": "W/kg",
					"Comment": null
				}, {
					"Id": 91881495,
					"ShortName": "PWC170",
					"LongName": "PWC 170",
					"UnitShort": "W/kg",
					"UnitLong": "W/kg",
					"Comment": null
				}, {
					"Id": 91881474,
					"ShortName": "RPM",
					"LongName": "Rotations/min",
					"UnitShort": "1/min",
					"UnitLong": "1/min",
					"Comment": null
				}, {
					"Id": 91881475,
					"ShortName": "Speed",
					"LongName": "Speed",
					"UnitShort": "10mm/s",
					"UnitLong": "10mm/s",
					"Comment": null
				}, {
					"Id": 91881488,
					"ShortName": "ST m t",
					"LongName": "ST max Time",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 91881487,
					"ShortName": "ST max",
					"LongName": "ST depress max",
					"UnitShort": "10uV",
					"UnitLong": "10uV",
					"Comment": null
				}, {
					"Id": 91881485,
					"ShortName": "THR",
					"LongName": "Target Heart rate",
					"UnitShort": "BPM",
					"UnitLong": "Beats per Minute",
					"Comment": null
				}, {
					"Id": 91881497,
					"ShortName": "tPhase",
					"LongName": "tPhase",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 91881481,
					"ShortName": "tRecov",
					"LongName": "Time Recovery",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 91881479,
					"ShortName": "tRef",
					"LongName": "Time Reference",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 91881478,
					"ShortName": "tRest",
					"LongName": "Time Resting",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 91881482,
					"ShortName": "tStage",
					"LongName": "Time Stage",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 91881480,
					"ShortName": "tTest",
					"LongName": "Time Test",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 91881477,
					"ShortName": "ttotal",
					"LongName": "Test time - total",
					"UnitShort": "sec",
					"UnitLong": "sec",
					"Comment": null
				}, {
					"Id": 91881491,
					"ShortName": "W max",
					"LongName": "Load max",
					"UnitShort": "W",
					"UnitLong": "Watt",
					"Comment": null
				}
			]
		}, {
			"Id": 1403,
			"Childs": [],
			"ParentId": 14,
			"ShortName": "HiRes",
			"LongName": "HiRes",
			"Comment": null,
			"Parameters": []
		}
	]
};

export const DATA_DICTIONARY_VIEWS_DESCRIPTION = {
	DataDictionaryViews: [{
			Name: "Base_Admin_All",
			Label: "Admin_All",
			Comment: "Number and type of lung function testing performed for a specific day",
			Columns: [{
					Name: "Visit Date",
					Label: "Visit Date",
					Type: "date",
					Comment: null
				}, {
					Name: "Forced Spirometry",
					Label: "Forced Spirometry",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry"
				}, {
					Name: "Forced Spirometry Pre",
					Label: "Forced Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Pre"
				}, {
					Name: "Forced Spirometry Post",
					Label: "Forced Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Post"
				}, {
					Name: "Slow Spirometry",
					Label: "Slow Spirometry",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry"
				}, {
					Name: "Slow Spirometry Pre",
					Label: "Slow Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Pre"
				}, {
					Name: "Slow Spirometry Post",
					Label: "Slow Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Post"
				}, {
					Name: "Bodyplethysmography",
					Label: "Bodyplethysmography",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography"
				}, {
					Name: "Bodyplethysmography Pre",
					Label: "Bodyplethysmography Pre",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Pre"
				}, {
					Name: "Bodyplethysmography Post",
					Label: "Bodyplethysmography Post",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Post"
				}, {
					Name: "Diffusion",
					Label: "Diffusion",
					Type: "int",
					Comment: "Measurement count Diffusion"
				}, {
					Name: "Diffusion Pre",
					Label: "Diffusion Pre",
					Type: "int",
					Comment: "Measurement count Diffusion Pre"
				}, {
					Name: "Diffusion Post",
					Label: "Diffusion Post",
					Type: "int",
					Comment: "Measurement count Diffusion Post"
				}, {
					Name: "N2 Washout",
					Label: "N2 Washout",
					Type: "int",
					Comment: "Measurement count N2 Washout"
				}, {
					Name: "N2 Washout Pre",
					Label: "N2 Washout Pre",
					Type: "int",
					Comment: "Measurement count N2 Washout Pre"
				}, {
					Name: "N2 Washout Post",
					Label: "N2 Washout Post",
					Type: "int",
					Comment: "Measurement count N2 Washout Post"
				}, {
					Name: "MIP/MEP",
					Label: "MIP/MEP",
					Type: "int",
					Comment: "Measurement count MIP/MEP"
				}, {
					Name: "MIP/MEP Pre",
					Label: "MIP/MEP Pre",
					Type: "int",
					Comment: "Measurement count MIP/MEP Pre"
				}, {
					Name: "MIP/MEP Post",
					Label: "MIP/MEP Post",
					Type: "int",
					Comment: "Measurement count MIP/MEP Post"
				}, {
					Name: "P 0.1",
					Label: "P 0.1",
					Type: "int",
					Comment: "Measurement count P 0.1"
				}, {
					Name: "P 0.1 Pre",
					Label: "P 0.1 Pre",
					Type: "int",
					Comment: "Measurement count P 0.1 Pre"
				}, {
					Name: "P 0.1 Post",
					Label: "P 0.1 Post",
					Type: "int",
					Comment: "Measurement count P 0.1 Post"
				}, {
					Name: "IOS",
					Label: "IOS",
					Type: "int",
					Comment: "Measurement count IOS"
				}, {
					Name: "IOS Pre",
					Label: "IOS Pre",
					Type: "int",
					Comment: "Measurement count IOS Pre"
				}, {
					Name: "IOS Post",
					Label: "IOS Post",
					Type: "int",
					Comment: "Measurement count IOS Poat"
				}, {
					Name: "BxB",
					Label: "BxB",
					Type: "int",
					Comment: "Measurement count BxB"
				}, {
					Name: "Canopy",
					Label: "Canopy",
					Type: "int",
					Comment: "Measurement count Canopy"
				}, {
					Name: "ABG",
					Label: "ABG",
					Type: "int",
					Comment: "Measurement count ABG"
				}, {
					Name: "Provocation",
					Label: "Provocation",
					Type: "int",
					Comment: "Measurement count Provocation"
				}
			]
		}, {
			Name: "Base_Admin_All_Month",
			Label: "Admin_All_Month",
			Comment: "Number and type of lung function testing performed for a specific month ",
			Columns: [{
					Name: "Year",
					Label: "Year",
					Type: "int",
					Comment: "Year lungfunction testing was performed"
				}, {
					Name: "Month",
					Label: "Month",
					Type: "int",
					Comment: "Month lungfunction testing was performed"
				}, {
					Name: "Forced Spirometry",
					Label: "Forced Spirometry",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry"
				}, {
					Name: "Forced Spirometry Pre",
					Label: "Forced Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Pre"
				}, {
					Name: "Forced Spirometry Post",
					Label: "Forced Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Post"
				}, {
					Name: "Slow Spirometry",
					Label: "Slow Spirometry",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry"
				}, {
					Name: "Slow Spirometry Pre",
					Label: "Slow Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Pre"
				}, {
					Name: "Slow Spirometry Post",
					Label: "Slow Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Post"
				}, {
					Name: "Bodyplethysmography",
					Label: "Bodyplethysmography",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography"
				}, {
					Name: "Bodyplethysmography Pre",
					Label: "Bodyplethysmography Pre",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Pre"
				}, {
					Name: "Bodyplethysmography Post",
					Label: "Bodyplethysmography Post",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Post"
				}, {
					Name: "Diffusion",
					Label: "Diffusion",
					Type: "int",
					Comment: "Measurement count Diffusion"
				}, {
					Name: "Diffusion Pre",
					Label: "Diffusion Pre",
					Type: "int",
					Comment: "Measurement count Diffusion Pre"
				}, {
					Name: "Diffusion Post",
					Label: "Diffusion Post",
					Type: "int",
					Comment: "Measurement count Diffusion Post"
				}, {
					Name: "N2 Washout",
					Label: "N2 Washout",
					Type: "int",
					Comment: "Measurement count N2 Washout"
				}, {
					Name: "N2 Washout Pre",
					Label: "N2 Washout Pre",
					Type: "int",
					Comment: "Measurement count N2 Washout Pre"
				}, {
					Name: "N2 Washout Post",
					Label: "N2 Washout Post",
					Type: "int",
					Comment: "Measurement count N2 Washout Post"
				}, {
					Name: "MIP/MEP",
					Label: "MIP/MEP",
					Type: "int",
					Comment: "Measurement count MIP/MEP"
				}, {
					Name: "MIP/MEP Pre",
					Label: "MIP/MEP Pre",
					Type: "int",
					Comment: "Measurement count MIP/MEP Pre"
				}, {
					Name: "MIP/MEP Post",
					Label: "MIP/MEP Post",
					Type: "int",
					Comment: "Measurement count MIP/MEP Post"
				}, {
					Name: "P 0.1",
					Label: "P 0.1",
					Type: "int",
					Comment: "Measurement count MIP/MEP P 0.1"
				}, {
					Name: "P 0.1 Pre",
					Label: "P 0.1 Pre",
					Type: "int",
					Comment: "Measurement count MIP/MEP P 0.1 Pre"
				}, {
					Name: "P 0.1 Post",
					Label: "P 0.1 Post",
					Type: "int",
					Comment: "Measurement count MIP/MEP P 0.1 Post"
				}, {
					Name: "IOS",
					Label: "IOS",
					Type: "int",
					Comment: "Measurement count IOS"
				}, {
					Name: "IOS Pre",
					Label: "IOS Pre",
					Type: "int",
					Comment: "Measurement count IOS Pre"
				}, {
					Name: "IOS Post",
					Label: "IOS Post",
					Type: "int",
					Comment: "Measurement count IOS Post"
				}, {
					Name: "BxB",
					Label: "BxB",
					Type: "int",
					Comment: "Measurement count BxB"
				}, {
					Name: "Canopy",
					Label: "Canopy",
					Type: "int",
					Comment: "Measurement count Canopy"
				}, {
					Name: "ABG",
					Label: "ABG",
					Type: "int",
					Comment: "Measurement count ABG"
				}, {
					Name: "Provocation",
					Label: "Provocation",
					Type: "int",
					Comment: "Measurement count Provocation"
				}
			]
		}, {
			Name: "Base_Admin_All_Quarter",
			Label: "Admin_All_Quarter",
			Comment: "Number and type of lung function testing performed for a specific quarter",
			Columns: [{
					Name: "Year",
					Label: "Year",
					Type: "int",
					Comment: null
				}, {
					Name: "Quarter",
					Label: "Quarter",
					Type: "int",
					Comment: null
				}, {
					Name: "Forced Spirometry",
					Label: "Forced Spirometry",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry"
				}, {
					Name: "Forced Spirometry Pre",
					Label: "Forced Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Pre"
				}, {
					Name: "Forced Spirometry Post",
					Label: "Forced Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Post"
				}, {
					Name: "Slow Spirometry",
					Label: "Slow Spirometry",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry"
				}, {
					Name: "Slow Spirometry Pre",
					Label: "Slow Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Pre"
				}, {
					Name: "Slow Spirometry Post",
					Label: "Slow Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Post"
				}, {
					Name: "Bodyplethysmography",
					Label: "Bodyplethysmography",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography"
				}, {
					Name: "Bodyplethysmography Pre",
					Label: "Bodyplethysmography Pre",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Pre"
				}, {
					Name: "Bodyplethysmography Post",
					Label: "Bodyplethysmography Post",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Post"
				}, {
					Name: "Diffusion",
					Label: "Diffusion",
					Type: "int",
					Comment: "Measurement count Diffusion"
				}, {
					Name: "Diffusion Pre",
					Label: "Diffusion Pre",
					Type: "int",
					Comment: "Measurement count Diffusion Pre"
				}, {
					Name: "Diffusion Post",
					Label: "Diffusion Post",
					Type: "int",
					Comment: "Measurement count Diffusion Post"
				}, {
					Name: "N2 Washout",
					Label: "N2 Washout",
					Type: "int",
					Comment: "Measurement count N2 Washout"
				}, {
					Name: "N2 Washout Pre",
					Label: "N2 Washout Pre",
					Type: "int",
					Comment: "Measurement count N2 Washout Pre"
				}, {
					Name: "N2 Washout Post",
					Label: "N2 Washout Post",
					Type: "int",
					Comment: "Measurement count N2 Washout Post"
				}, {
					Name: "MIP/MEP",
					Label: "MIP/MEP",
					Type: "int",
					Comment: "Measurement count MIP/MEP"
				}, {
					Name: "MIP/MEP Pre",
					Label: "MIP/MEP Pre",
					Type: "int",
					Comment: "Measurement count MIP/MEP Pre"
				}, {
					Name: "MIP/MEP Post",
					Label: "MIP/MEP Post",
					Type: "int",
					Comment: "Measurement count MIP/MEP Post"
				}, {
					Name: "P 0.1",
					Label: "P 0.1",
					Type: "int",
					Comment: "Measurement count P 0.1"
				}, {
					Name: "P 0.1 Pre",
					Label: "P 0.1 Pre",
					Type: "int",
					Comment: "Measurement count P 0.1 Pre"
				}, {
					Name: "P 0.1 Post",
					Label: "P 0.1 Post",
					Type: "int",
					Comment: "Measurement count P 0.1 Post"
				}, {
					Name: "IOS",
					Label: "IOS",
					Type: "int",
					Comment: "Measurement count IOS"
				}, {
					Name: "IOS Pre",
					Label: "IOS Pre",
					Type: "int",
					Comment: "Measurement count IOS Pre"
				}, {
					Name: "IOS Post",
					Label: "IOS Post",
					Type: "int",
					Comment: "Measurement count IOS Post"
				}, {
					Name: "BxB",
					Label: "BxB",
					Type: "int",
					Comment: "Measurement count BxB"
				}, {
					Name: "Canopy",
					Label: "Canopy",
					Type: "int",
					Comment: "Measurement count Canopy"
				}, {
					Name: "ABG",
					Label: "ABG",
					Type: "int",
					Comment: "Measurement count ABG"
				}, {
					Name: "Provocation",
					Label: "Provocation",
					Type: "int",
					Comment: "Measurement count Provocation"
				}
			]
		}, {
			Name: "Base_Admin_All_Year",
			Label: "Admin_All_Year",
			Comment: "Number and type of lung function testing performed for a specific year",
			Columns: [{
					Name: "Year",
					Label: "Year",
					Type: "int",
					Comment: "Year lungfunction testing was performed"
				}, {
					Name: "Forced Spirometry",
					Label: "Forced Spirometry",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry"
				}, {
					Name: "Forced Spirometry Pre",
					Label: "Forced Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Pre"
				}, {
					Name: "Forced Spirometry Post",
					Label: "Forced Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Post"
				}, {
					Name: "Slow Spirometry",
					Label: "Slow Spirometry",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry"
				}, {
					Name: "Slow Spirometry Pre",
					Label: "Slow Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Pre"
				}, {
					Name: "Slow Spirometry Post",
					Label: "Slow Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Post"
				}, {
					Name: "Bodyplethysmography",
					Label: "Bodyplethysmography",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography"
				}, {
					Name: "Bodyplethysmography Pre",
					Label: "Bodyplethysmography Pre",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Pre"
				}, {
					Name: "Bodyplethysmography Post",
					Label: "Bodyplethysmography Post",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Post"
				}, {
					Name: "Diffusion",
					Label: "Diffusion",
					Type: "int",
					Comment: "Measurement count Diffusion"
				}, {
					Name: "Diffusion Pre",
					Label: "Diffusion Pre",
					Type: "int",
					Comment: "Measurement count Diffusion Pre"
				}, {
					Name: "Diffusion Post",
					Label: "Diffusion Post",
					Type: "int",
					Comment: "Measurement count Diffusion Post"
				}, {
					Name: "N2 Washout",
					Label: "N2 Washout",
					Type: "int",
					Comment: "Measurement count N2 Washout"
				}, {
					Name: "N2 Washout Pre",
					Label: "N2 Washout Pre",
					Type: "int",
					Comment: "Measurement count N2 Washout Pre"
				}, {
					Name: "N2 Washout Post",
					Label: "N2 Washout Post",
					Type: "int",
					Comment: "Measurement count N2 Washout Post"
				}, {
					Name: "MIP/MEP",
					Label: "MIP/MEP",
					Type: "int",
					Comment: "Measurement count MIP/MEP"
				}, {
					Name: "MIP/MEP Pre",
					Label: "MIP/MEP Pre",
					Type: "int",
					Comment: "Measurement count MIP/MEP Pre"
				}, {
					Name: "MIP/MEP Post",
					Label: "MIP/MEP Post",
					Type: "int",
					Comment: "Measurement count MIP/MEP Post"
				}, {
					Name: "P 0.1",
					Label: "P 0.1",
					Type: "int",
					Comment: "Measurement count P 0.1"
				}, {
					Name: "P 0.1 Pre",
					Label: "P 0.1 Pre",
					Type: "int",
					Comment: "Measurement count P 0.1 Pre"
				}, {
					Name: "P 0.1 Post",
					Label: "P 0.1 Post",
					Type: "int",
					Comment: "Measurement count P 0.1 Post"
				}, {
					Name: "IOS",
					Label: "IOS",
					Type: "int",
					Comment: "Measurement count IOS"
				}, {
					Name: "IOS Pre",
					Label: "IOS Pre",
					Type: "int",
					Comment: "Measurement count IOS Pre"
				}, {
					Name: "IOS Post",
					Label: "IOS Post",
					Type: "int",
					Comment: "Measurement count IOS Post"
				}, {
					Name: "BxB",
					Label: "BxB",
					Type: "int",
					Comment: "Measurement count BxB"
				}, {
					Name: "Canopy",
					Label: "Canopy",
					Type: "int",
					Comment: "Measurement count Canopy"
				}, {
					Name: "ABG",
					Label: "ABG",
					Type: "int",
					Comment: "Measurement count ABG"
				}, {
					Name: "Provocation",
					Label: "Provocation",
					Type: "int",
					Comment: "Measurement count Provocation"
				}
			]
		}, {
			Name: "Base_Admin_Operator",
			Label: "Admin_Operator",
			Comment: "Number and type of lung function testing performed for specific roles",
			Columns: [{
					Name: "Visit Date",
					Label: "Visit Date",
					Type: "date",
					Comment: null
				}, {
					Name: "Operator",
					Label: "Operator",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Forced Spirometry",
					Label: "Forced Spirometry",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry"
				}, {
					Name: "Forced Spirometry Pre",
					Label: "Forced Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Pre"
				}, {
					Name: "Forced Spirometry Post",
					Label: "Forced Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Post"
				}, {
					Name: "Slow Spirometry",
					Label: "Slow Spirometry",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry"
				}, {
					Name: "Slow Spirometry Pre",
					Label: "Slow Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Pre"
				}, {
					Name: "Slow Spirometry Post",
					Label: "Slow Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Post"
				}, {
					Name: "Bodyplethysmography",
					Label: "Bodyplethysmography",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography"
				}, {
					Name: "Bodyplethysmography Pre",
					Label: "Bodyplethysmography Pre",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Pre"
				}, {
					Name: "Bodyplethysmography Post",
					Label: "Bodyplethysmography Post",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Post"
				}, {
					Name: "Diffusion",
					Label: "Diffusion",
					Type: "int",
					Comment: "Measurement count Diffusion"
				}, {
					Name: "Diffusion Pre",
					Label: "Diffusion Pre",
					Type: "int",
					Comment: "Measurement count Diffusion Pre"
				}, {
					Name: "Diffusion Post",
					Label: "Diffusion Post",
					Type: "int",
					Comment: "Measurement count Diffusion Post"
				}, {
					Name: "N2 Washout",
					Label: "N2 Washout",
					Type: "int",
					Comment: "Measurement count N2 Washout"
				}, {
					Name: "N2 Washout Pre",
					Label: "N2 Washout Pre",
					Type: "int",
					Comment: "Measurement count N2 Washout Pre"
				}, {
					Name: "N2 Washout Post",
					Label: "N2 Washout Post",
					Type: "int",
					Comment: "Measurement count N2 Washout Post"
				}, {
					Name: "MIP/MEP",
					Label: "MIP/MEP",
					Type: "int",
					Comment: "Measurement count MIP/MEP"
				}, {
					Name: "MIP/MEP Pre",
					Label: "MIP/MEP Pre",
					Type: "int",
					Comment: "Measurement count MIP/MEP Pre"
				}, {
					Name: "MIP/MEP Post",
					Label: "MIP/MEP Post",
					Type: "int",
					Comment: "Measurement count MIP/MEP Post" 
				}, {
					Name: "P 0.1",
					Label: "P 0.1",
					Type: "int",
					Comment: "Measurement count P 0.1"
				}, {
					Name: "P 0.1 Pre",
					Label: "P 0.1 Pre",
					Type: "int",
					Comment: "Measurement count P 0.1 Pre"
				}, {
					Name: "P 0.1 Post",
					Label: "P 0.1 Post",
					Type: "int",
					Comment: "Measurement count P 0.1 Post"
				}, {
					Name: "IOS",
					Label: "IOS",
					Type: "int",
					Comment: "Measurement count IOS"
				}, {
					Name: "IOS Pre",
					Label: "IOS Pre",
					Type: "int",
					Comment: "Measurement count IOS Pre"
				}, {
					Name: "IOS Post",
					Label: "IOS Post",
					Type: "int",
					Comment: "Measurement count IOS Post"
				}, {
					Name: "BxB",
					Label: "BxB",
					Type: "int",
					Comment: "Measurement count BxB"
				}, {
					Name: "Canopy",
					Label: "Canopy",
					Type: "int",
					Comment: "Measurement count Canopy"
				}, {
					Name: "ABG",
					Label: "ABG",
					Type: "int",
					Comment: "Measurement count ABG"
				}, {
					Name: "Provocation",
					Label: "Provocation",
					Type: "int",
					Comment: "Measurement count Provocation"
				}
			]
		}, {
			Name: "Base_Admin_Providing_Physician",
			Label: "Admin_Providing_Physician",
			Comment: "Number and type of lung function testing performed for specific roles",
			Columns: [{
					Name: "Visit Date",
					Label: "Visit Date",
					Type: "date",
					Comment: null
				}, {
					Name: "Physician",
					Label: "Physician",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Forced Spirometry",
					Label: "Forced Spirometry",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry"
				}, {
					Name: "Forced Spirometry Pre",
					Label: "Forced Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Pre"
				}, {
					Name: "Forced Spirometry Post",
					Label: "Forced Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Post"
				}, {
					Name: "Slow Spirometry",
					Label: "Slow Spirometry",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry"
				}, {
					Name: "Slow Spirometry Pre",
					Label: "Slow Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Pre"
				}, {
					Name: "Slow Spirometry Post",
					Label: "Slow Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Post"
				}, {
					Name: "Bodyplethysmography",
					Label: "Bodyplethysmography",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography"
				}, {
					Name: "Bodyplethysmography Pre",
					Label: "Bodyplethysmography Pre",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Pre"
				}, {
					Name: "Bodyplethysmography Post",
					Label: "Bodyplethysmography Post",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Post"
				}, {
					Name: "Diffusion",
					Label: "Diffusion",
					Type: "int",
					Comment: "Measurement count Diffusion"
				}, {
					Name: "Diffusion Pre",
					Label: "Diffusion Pre",
					Type: "int",
					Comment: "Measurement count Diffusion Pre"
				}, {
					Name: "Diffusion Post",
					Label: "Diffusion Post",
					Type: "int",
					Comment: "Measurement count Diffusion Post"
				}, {
					Name: "N2 Washout",
					Label: "N2 Washout",
					Type: "int",
					Comment: "Measurement count N2 Washout"
				}, {
					Name: "N2 Washout Pre",
					Label: "N2 Washout Pre",
					Type: "int",
					Comment: "Measurement count N2 Washout Pre"
				}, {
					Name: "N2 Washout Post",
					Label: "N2 Washout Post",
					Type: "int",
					Comment: "Measurement count N2 Washout Post"
				}, {
					Name: "MIP/MEP",
					Label: "MIP/MEP",
					Type: "int",
					Comment: "Measurement count MIP/MEP"
				}, {
					Name: "MIP/MEP Pre",
					Label: "MIP/MEP Pre",
					Type: "int",
					Comment: "Measurement count MIP/MEP Pre"
				}, {
					Name: "MIP/MEP Post",
					Label: "MIP/MEP Post",
					Type: "int",
					Comment: "Measurement count MIP/MEP Post"
				}, {
					Name: "P 0.1",
					Label: "P 0.1",
					Type: "int",
					Comment: "Measurement count P 0.1"
				}, {
					Name: "P 0.1 Pre",
					Label: "P 0.1 Pre",
					Type: "int",
					Comment: "Measurement count P 0.1 Pre"
				}, {
					Name: "P 0.1 Post",
					Label: "P 0.1 Post",
					Type: "int",
					Comment: "Measurement count P 0.1 Post"
				}, {
					Name: "IOS",
					Label: "IOS",
					Type: "int",
					Comment: "Measurement count IOS"
				}, {
					Name: "IOS Pre",
					Label: "IOS Pre",
					Type: "int",
					Comment: "Measurement count IOS Pre"
				}, {
					Name: "IOS Post",
					Label: "IOS Post",
					Type: "int",
					Comment: "Measurement count IOS Post"
				}, {
					Name: "BxB",
					Label: "BxB",
					Type: "int",
					Comment: "Measurement count BxB"
				}, {
					Name: "Canopy",
					Label: "Canopy",
					Type: "int",
					Comment: "Measurement count Canopy"
				}, {
					Name: "ABG",
					Label: "ABG",
					Type: "int",
					Comment: "Measurement count ABG"
				}, {
					Name: "Provocation",
					Label: "Provocation",
					Type: "int",
					Comment: "Measurement count Provocation"
				}
			]
		}, {
			Name: "Base_Admin_Referring_Physician",
			Label: "Admin_Referring_Physician",
			Comment: "Number and type of lung function testing performed for specific roles",
			Columns: [{
					Name: "Visit Date",
					Label: "Visit Date",
					Type: "date",
					Comment: null
				}, {
					Name: "Referring Physician",
					Label: "Referring Physician",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Forced Spirometry",
					Label: "Forced Spirometry",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry"
				}, {
					Name: "Forced Spirometry Pre",
					Label: "Forced Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Pre"
				}, {
					Name: "Forced Spirometry Post",
					Label: "Forced Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Post"
				}, {
					Name: "Slow Spirometry",
					Label: "Slow Spirometry",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry"
				}, {
					Name: "Slow Spirometry Pre",
					Label: "Slow Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Pre"
				}, {
					Name: "Slow Spirometry Post",
					Label: "Slow Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Post"
				}, {
					Name: "Bodyplethysmography",
					Label: "Bodyplethysmography",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography"
				}, {
					Name: "Bodyplethysmography Pre",
					Label: "Bodyplethysmography Pre",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Pre"
				}, {
					Name: "Bodyplethysmography Post",
					Label: "Bodyplethysmography Post",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Post"
				}, {
					Name: "Diffusion",
					Label: "Diffusion",
					Type: "int",
					Comment: "Measurement count Diffusion"
				}, {
					Name: "Diffusion Pre",
					Label: "Diffusion Pre",
					Type: "int",
					Comment: "Measurement count Diffusion Pre"
				}, {
					Name: "Diffusion Post",
					Label: "Diffusion Post",
					Type: "int",
					Comment: "Measurement count Diffusion Post"
				}, {
					Name: "N2 Washout",
					Label: "N2 Washout",
					Type: "int",
					Comment: "Measurement count N2 Washout"
				}, {
					Name: "N2 Washout Pre",
					Label: "N2 Washout Pre",
					Type: "int",
					Comment: "Measurement count N2 Washout Pre"
				}, {
					Name: "N2 Washout Post",
					Label: "N2 Washout Post",
					Type: "int",
					Comment: "Measurement count N2 Washout Post"
				}, {
					Name: "MIP/MEP",
					Label: "MIP/MEP",
					Type: "int",
					Comment: "Measurement count MIP/MEP"
				}, {
					Name: "MIP/MEP Pre",
					Label: "MIP/MEP Pre",
					Type: "int",
					Comment: "Measurement count MIP/MEP Pre"
				}, {
					Name: "MIP/MEP Post",
					Label: "MIP/MEP Post",
					Type: "int",
					Comment: "Measurement count MIP/MEP Post"
				}, {
					Name: "P 0.1",
					Label: "P 0.1",
					Type: "int",
					Comment: "Measurement count P 0.1"
				}, {
					Name: "P 0.1 Pre",
					Label: "P 0.1 Pre",
					Type: "int",
					Comment: "Measurement count P 0.1 Pre"
				}, {
					Name: "P 0.1 Post",
					Label: "P 0.1 Post",
					Type: "int",
					Comment: "Measurement count P 0.1 Post"
				}, {
					Name: "IOS",
					Label: "IOS",
					Type: "int",
					Comment: "Measurement count IOS"
				}, {
					Name: "IOS Pre",
					Label: "IOS Pre",
					Type: "int",
					Comment: "Measurement count IOS Pre"
				}, {
					Name: "IOS Post",
					Label: "IOS Post",
					Type: "int",
					Comment: "Measurement count IOS Post"
				}, {
					Name: "BxB",
					Label: "BxB",
					Type: "int",
					Comment: "Measurement count BxB"
				}, {
					Name: "Canopy",
					Label: "Canopy",
					Type: "int",
					Comment: "Measurement count Canopy"
				}, {
					Name: "ABG",
					Label: "ABG",
					Type: "int",
					Comment: "Measurement count ABG"
				}, {
					Name: "Provocation",
					Label: "Provocation",
					Type: "int",
					Comment: "Measurement count Provocation"
				}
			]
		}, {
			Name: "Base_Admin_System",
			Label: "Admin_System",
			Comment: "Number and type of lung function testing performed for specific system",
			Columns: [{
					Name: "Visit Date",
					Label: "Visit Date",
					Type: "date",
					Comment: null
				}, {
					Name: "System",
					Label: "System",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Forced Spirometry",
					Label: "Forced Spirometry",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry"
				}, {
					Name: "Forced Spirometry Pre",
					Label: "Forced Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Pre"
				}, {
					Name: "Forced Spirometry Post",
					Label: "Forced Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Forced Spirometry Post"
				}, {
					Name: "Slow Spirometry",
					Label: "Slow Spirometry",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry"
				}, {
					Name: "Slow Spirometry Pre",
					Label: "Slow Spirometry Pre",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Pre"
				}, {
					Name: "Slow Spirometry Post",
					Label: "Slow Spirometry Post",
					Type: "int",
					Comment: "Measuremnt count Slow Spirometry Pos"
				}, {
					Name: "Bodyplethysmography",
					Label: "Bodyplethysmography",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography"
				}, {
					Name: "Bodyplethysmography Pre",
					Label: "Bodyplethysmography Pre",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Pre"
				}, {
					Name: "Bodyplethysmography Post",
					Label: "Bodyplethysmography Post",
					Type: "int",
					Comment: "Measurement count Bodyplethysmography Post"
				}, {
					Name: "Diffusion",
					Label: "Diffusion",
					Type: "int",
					Comment: "Measurement count Diffusion"
				}, {
					Name: "Diffusion Pre",
					Label: "Diffusion Pre",
					Type: "int",
					Comment: "Measurement count Diffusion Pre"
				}, {
					Name: "Diffusion Post",
					Label: "Diffusion Post",
					Type: "int",
					Comment: "Measurement count Diffusion Post"
				}, {
					Name: "N2 Washout",
					Label: "N2 Washout",
					Type: "int",
					Comment: "Measurement count N2 Washout"
				}, {
					Name: "N2 Washout Pre",
					Label: "N2 Washout Pre",
					Type: "int",
					Comment: "Measurement count N2 Washout Pre"
				}, {
					Name: "N2 Washout Post",
					Label: "N2 Washout Post",
					Type: "int",
					Comment: "Measurement count N2 Washout Post"
				}, {
					Name: "MIP/MEP",
					Label: "MIP/MEP",
					Type: "int",
					Comment: "Measurement count MIP/MEP"
				}, {
					Name: "MIP/MEP Pre",
					Label: "MIP/MEP Pre",
					Type: "int",
					Comment: "Measurement count MIP/MEP Pre"
				}, {
					Name: "MIP/MEP Post",
					Label: "MIP/MEP Post",
					Type: "int",
					Comment: "Measurement count MIP/MEP Post"
				}, {
					Name: "P 0.1",
					Label: "P 0.1",
					Type: "int",
					Comment: "Measurement count P 0.1"
				}, {
					Name: "P 0.1 Pre",
					Label: "P 0.1 Pre",
					Type: "int",
					Comment: "Measurement count P 0.1 Pre"
				}, {
					Name: "P 0.1 Post",
					Label: "P 0.1 Post",
					Type: "int",
					Comment: "Measurement count P 0.1 Post"
				}, {
					Name: "IOS",
					Label: "IOS",
					Type: "int",
					Comment: "Measurement count IOS"
				}, {
					Name: "IOS Pre",
					Label: "IOS Pre",
					Type: "int",
					Comment: "Measurement count IOS Pre"
				}, {
					Name: "IOS Post",
					Label: "IOS Post",
					Type: "int",
					Comment: "Measurement count IOS Post"
				}, {
					Name: "BxB",
					Label: "BxB",
					Type: "int",
					Comment: "Measurement count BxB"
				}, {
					Name: "Canopy",
					Label: "Canopy",
					Type: "int",
					Comment: "Measurement count Canopy"
				}, {
					Name: "ABG",
					Label: "ABG",
					Type: "int",
					Comment: "Measurement count ABG"
				}, {
					Name: "Provocation",
					Label: "Provocation",
					Type: "int",
					Comment: "Measurement count Provocation"
				}
			]
		}, {
			Name: "Base_All_Best",
			Label: "All_Best",
			Comment: "All best parameters per level (Reference Parameter based)",
			Columns: [{
					Name: "Visit Date",
					Label: "Visit Date",
					Type: "date",
					Comment: null
				}, {
					Name: "Patient ID",
					Label: "Patient ID",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Name",
					Label: "Name",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Middlename",
					Label: "Middlename",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Firstname",
					Label: "Firstname",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Date_of_Birth",
					Label: "Date_of_Birth",
					Type: "date",
					Comment: null
				}, {
					Name: "Gender",
					Label: "Gender",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "BiologicalGender",
					Label: "BiologicalGender",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Race",
					Label: "Race",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Date First Test",
					Label: "Date First Test",
					Type: "datetime",
					Comment: null
				}, {
					Name: "Date Last Test",
					Label: "Date Last Test",
					Type: "datetime",
					Comment: null
				}, {
					Name: "Age",
					Label: "Age",
					Type: "int",
					Comment: null
				}, {
					Name: "Height",
					Label: "Height",
					Type: "float",
					Comment: null
				}, {
					Name: "Weight",
					Label: "Weight",
					Type: "float",
					Comment: null
				}, {
					Name: "LevelType",
					Label: "LevelType",
					Type: "int",
					Comment: null
				}, {
					Name: "Sequence",
					Label: "Sequence",
					Type: "int",
					Comment: null
				}, {
					Name: "Substance",
					Label: "Substance",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Concentration",
					Label: "Concentration",
					Type: "float",
					Comment: null
				}, {
					Name: "Dose",
					Label: "Dose",
					Type: "float",
					Comment: null
				}, {
					Name: "Cummulated Dose",
					Label: "Cummulated Dose",
					Type: "float",
					Comment: null
				}, {
					Name: "6 MWD",
					Label: "6 MWD",
					Type: "float",
					Comment: "Six_Minute_Walk_Test-6MWD(4784131); "
				}, {
					Name: "AB",
					Label: "AB",
					Type: "float",
					Comment: "Blood_gases-AB(851997); "
				}, {
					Name: "Alv. Ventilation",
					Label: "Alv. Ventilation",
					Type: "float",
					Comment: "Diffusion_Rebreathing_Diff_RB-VA' rb(452263952); "
				}, {
					Name: "BB",
					Label: "BB",
					Type: "float",
					Comment: "Blood_gases-BB(852000); "
				}, {
					Name: "BE",
					Label: "BE",
					Type: "float",
					Comment: "Blood_gases-BE(851999); "
				}, {
					Name: "BF",
					Label: "BF",
					Type: "float",
					Comment: "Spirometry-BF(65542); Spirometry-BF_F(65665); Compliance-BF(393224); R_Occlusion-BF(458756); IOS-BF(1179725); Baby_Body-RR-FRC(1310725); Body_Slow_Spirometry-BF(19726342); Body_Slow_Spirometry-BF_F(19726465); CPET_BxB-BF(78708789); MIPMEP_MIP-BF_MIP(426049546); MIPMEP_MEP-BF_MEP(426115082); P01_Rest-BF_P0.1(432603137); FRC_Rebreathing_FRC_RB-BF(445710352); FRC_Rebreathing_FRC_RB-BFrb(445710368); Diffusion_Rebreathing_Diff_RB-BF(452263978); "
				}, {
					Name: "Breathing reserve (%)",
					Label: "Breathing reserve (%)",
					Type: "float",
					Comment: "CPET_BxB-BR FEV%(78708804); "
				}, {
					Name: "Breathing reserve (L)",
					Label: "Breathing reserve (L)",
					Type: "float",
					Comment: "CPET_BxB-BR FEV(78708858); "
				}, {
					Name: "C dyn / FRC",
					Label: "C dyn / FRC",
					Type: "float",
					Comment: "Compliance-Cd/FRC(393220); "
				}, {
					Name: "C dyn / TLC",
					Label: "C dyn / TLC",
					Type: "float",
					Comment: "Compliance-Cd/TLC(393219); "
				}, {
					Name: "C stat / FRC",
					Label: "C stat / FRC",
					Type: "float",
					Comment: "Compliance-Cs/FRC(393231); "
				}, {
					Name: "C stat / TLC",
					Label: "C stat / TLC",
					Type: "float",
					Comment: "Compliance-Cs/TLC(393230); "
				}, {
					Name: "CaCO2",
					Label: "CaCO2",
					Type: "float",
					Comment: null
				}, {
					Name: "CaO2",
					Label: "CaO2",
					Type: "float",
					Comment: "Blood_gases-CaO2(851993); "
				}, {
					Name: "Cardiac index",
					Label: "Cardiac index",
					Type: "float",
					Comment: "Blood_gases-CI_dF(852039); CPET_BxB-CI_c(78708884); CPET_Cardiac_Output-CI(78839831); "
				}, {
					Name: "Cardiac output",
					Label: "Cardiac output",
					Type: "float",
					Comment: "Blood_gases-Qt-Fic(852013); CPET_BxB-Qtc(78708830); CPET_Cardiac_Output-Qt(78839822); "
				}, {
					Name: "CHO %",
					Label: "CHO %",
					Type: "float",
					Comment: "CPET_BxB-CHO E%(78708872); CPET_REE-CHO %(79429648); "
				}, {
					Name: "CHO (g/day)",
					Label: "CHO (g/day)",
					Type: "float",
					Comment: "CPET_BxB-CHO g/d(78708822); CPET_REE-CHO g(79429646); "
				}, {
					Name: "Closing capacity",
					Label: "Closing capacity",
					Type: "float",
					Comment: "Body_Body-Cl.cap(19857482); "
				}, {
					Name: "Closing volume",
					Label: "Closing volume",
					Type: "float",
					Comment: "Body_Body-Cl.vol(19857470); "
				}, {
					Name: "CO05Hz",
					Label: "CO05Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO05Hz(118030377); IOS_Tidal_breathing_In_param-COin05(118095913); IOS_Tidal_breathing_Ex_param-COex05(118161449); "
				}, {
					Name: "CO10Hz",
					Label: "CO10Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO10Hz(118030382); IOS_Tidal_breathing_In_param-COin10(118095918); IOS_Tidal_breathing_Ex_param-COex10(118161454); "
				}, {
					Name: "CO15Hz",
					Label: "CO15Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO15Hz(118030383); IOS_Tidal_breathing_In_param-COin15(118095919); IOS_Tidal_breathing_Ex_param-COex15(118161455); "
				}, {
					Name: "CO1Hz",
					Label: "CO1Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO1Hz(118030378); IOS_Tidal_breathing_In_param-COin1(118095914); IOS_Tidal_breathing_Ex_param-COex1(118161450); "
				}, {
					Name: "CO20Hz",
					Label: "CO20Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO20Hz(118030384); IOS_Tidal_breathing_In_param-COin20(118095920); IOS_Tidal_breathing_Ex_param-COex20(118161456); "
				}, {
					Name: "CO25Hz",
					Label: "CO25Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO25Hz(118030385); IOS_Tidal_breathing_In_param-COin25(118095921); IOS_Tidal_breathing_Ex_param-COex25(118161457); "
				}, {
					Name: "CO2Hz",
					Label: "CO2Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO2Hz(118030379); IOS_Tidal_breathing_In_param-COin2(118095915); IOS_Tidal_breathing_Ex_param-COex2(118161451); "
				}, {
					Name: "CO35Hz",
					Label: "CO35Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO35Hz(118030386); IOS_Tidal_breathing_In_param-COin35(118095922); IOS_Tidal_breathing_Ex_param-COex35(118161458); "
				}, {
					Name: "CO3Hz",
					Label: "CO3Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO3Hz(118030380); IOS_Tidal_breathing_In_param-COin3(118095916); IOS_Tidal_breathing_Ex_param-COex3(118161452); "
				}, {
					Name: "CO5Hz",
					Label: "CO5Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO5Hz(118030381); IOS_Tidal_breathing_In_param-COin5(118095917); IOS_Tidal_breathing_Ex_param-COex5(118161453); "
				}, {
					Name: "Compliance dyn.",
					Label: "Compliance dyn.",
					Type: "float",
					Comment: "Compliance-C dyn(393217); "
				}, {
					Name: "Compliance stat.",
					Label: "Compliance stat.",
					Type: "float",
					Comment: "Compliance-C stat(393228); "
				}, {
					Name: "DLCO_IB",
					Label: "DLCO_IB",
					Type: "float",
					Comment: "Diffusion_SB_Intrabreath-DLCOIB(3866625); "
				}, {
					Name: "DLCO_IB / VA",
					Label: "DLCO_IB / VA",
					Type: "float",
					Comment: "Diffusion_SB_Intrabreath-KCO IB(3866626); "
				}, {
					Name: "DLCO_RB",
					Label: "DLCO_RB",
					Type: "float",
					Comment: "Diffusion_Rebreathing_Diff_RB-DLCO rb(452263953); "
				}, {
					Name: "DLCO_RB / VA",
					Label: "DLCO_RB / VA",
					Type: "float",
					Comment: "Diffusion_Rebreathing_Diff_RB-KCO rb(452263954); "
				}, {
					Name: "DLCO_SB",
					Label: "DLCO_SB",
					Type: "float",
					Comment: "Diffusion_SB-DLCO_SB(262148); Diffusion_SB-DLCO StdPB(262233); NO_Diffusion_Membrane-DLCOSB(4128777); "
				}, {
					Name: "DLCO_SB / BSA",
					Label: "DLCO_SB / BSA",
					Type: "float",
					Comment: "Diffusion_SB-DLCO/BSA(262157); NO_Diffusion_Membrane-DCOBSA(4128781); "
				}, {
					Name: "DLCO_SB / VA",
					Label: "DLCO_SB / VA",
					Type: "float",
					Comment: "Diffusion_SB-KCO_SB(262150); NO_Diffusion_Membrane-KCO(4128779); "
				}, {
					Name: "DLCOc_IB",
					Label: "DLCOc_IB",
					Type: "float",
					Comment: "Diffusion_SB_Intrabreath-DLCOIc(3866627); "
				}, {
					Name: "DLCOc_IB / VA",
					Label: "DLCOc_IB / VA",
					Type: "float",
					Comment: "Diffusion_SB_Intrabreath-KCOIBc(3866628); "
				}, {
					Name: "DLCOc_SB",
					Label: "DLCOc_SB",
					Type: "float",
					Comment: "Diffusion_SB-DLCOcSB(262162); NO_Diffusion_Membrane-DLCOcSB(4128778); "
				}, {
					Name: "DLCOc_SB / BSA",
					Label: "DLCOc_SB / BSA",
					Type: "float",
					Comment: "Diffusion_SB-DLCOc/BSA(262158); NO_Diffusion_Membrane-DCOcBSA(4128782); "
				}, {
					Name: "DLCOc_SB / VA",
					Label: "DLCOc_SB / VA",
					Type: "float",
					Comment: "Diffusion_SB-KCOc_SB(262163); NO_Diffusion_Membrane-KCOc(4128780); "
				}, {
					Name: "Dm (Diff mem)",
					Label: "Dm (Diff mem)",
					Type: "float",
					Comment: "NO_Diffusion_Membrane-Dm(4128769); "
				}, {
					Name: "EE (kcal/day)",
					Label: "EE (kcal/day)",
					Type: "float",
					Comment: "CPET_BxB-EE/d(78708810); CPET_REE-EE(79429644); "
				}, {
					Name: "EE / BSA",
					Label: "EE / BSA",
					Type: "float",
					Comment: "CPET_BxB-EE/BSA(78708879); CPET_REE-EE/BSA(79429659); "
				}, {
					Name: "EE / CO2 ",
					Label: "EE / CO2 ",
					Type: "float",
					Comment: "CPET_BxB-EE/CO2(78708878); CPET_REE-EE/CO2(79429658); "
				}, {
					Name: "EE / kg",
					Label: "EE / kg",
					Type: "float",
					Comment: "CPET_BxB-EE/kg(78708870); CPET_REE-EE/kg(79429645); "
				}, {
					Name: "Elastance dyn.",
					Label: "Elastance dyn.",
					Type: "float",
					Comment: "Compliance-E dyn(393218); "
				}, {
					Name: "Elastance stat.",
					Label: "Elastance stat.",
					Type: "float",
					Comment: "Compliance-E stat(393229); "
				}, {
					Name: "EQCO2 (VE/VCO2)",
					Label: "EQCO2 (VE/VCO2)",
					Type: "float",
					Comment: "CPET_BxB-EqCO2(78708796); "
				}, {
					Name: "EQO2 (VE/VO2)",
					Label: "EQO2 (VE/VO2)",
					Type: "float",
					Comment: "CPET_BxB-EqO2(78708797); "
				}, {
					Name: "ERV",
					Label: "ERV",
					Type: "float",
					Comment: "Spirometry-ERV(65539); Spirometry-ERV_F(65667); Diffusion_SB-ERV_SB(262161); IOS-ERV(1179727); Diffusion_SB_Intrabreath-ERV-IB(3866641); N2_Washout-ERV_N2(4063235); NO_Diffusion_Membrane-ERV-SB(4128803); Body_Slow_Spirometry-ERV(19726339); Body_Slow_Spirometry-ERV_F(19726467); Body_Body-ERV(19857434); MIPMEP_MIP-ERV_MIP(426049547); MIPMEP_MEP-ERV_MEP(426115083); FRC_Rebreathing_FRC_RB-ERV(445710349); Diffusion_Rebreathing_Diff_RB-ERV(452263975); "
				}, {
					Name: "Exspir. F/V area",
					Label: "Exspir. F/V area",
					Type: "float",
					Comment: "Spirometry-AEX(65585); Body_Slow_Spirometry-AEX(19726385); Body_Transmural_F_V-AEX(19791895); "
				}, {
					Name: "F resonance",
					Label: "F resonance",
					Type: "float",
					Comment: "IOS-Fres.(1179653); "
				}, {
					Name: "FAT %",
					Label: "FAT %",
					Type: "float",
					Comment: "CPET_BxB-FAT E%(78708874); CPET_REE-FAT %(79429651); "
				}, {
					Name: "FAT / g/day",
					Label: "FAT / g/day",
					Type: "float",
					Comment: "CPET_BxB-FAT g/d(78708823); CPET_REE-FAT g(79429649); "
				}, {
					Name: "FEF 200-1200",
					Label: "FEF 200-1200",
					Type: "float",
					Comment: "Spirometry-PEF*(65590); Body_Slow_Spirometry-PEF*(19726390); Body_Transmural_F_V-PEF*(19791907); "
				}, {
					Name: "FEF 25",
					Label: "FEF 25",
					Type: "float",
					Comment: "Spirometry-FEF25(65638); Body_Slow_Spirometry-FEF 25(19726438); "
				}, {
					Name: "FEF 40",
					Label: "FEF 40",
					Type: "float",
					Comment: "Spirometry-FEF40(65645); Body_Slow_Spirometry-FEF40(19726445); "
				}, {
					Name: "FEF 50",
					Label: "FEF 50",
					Type: "float",
					Comment: "Spirometry-FEF50(65637); Body_Slow_Spirometry-FEF 50(19726437); "
				}, {
					Name: "FEF 50 % FIF 50",
					Label: "FEF 50 % FIF 50",
					Type: "float",
					Comment: "Spirometry-FE%FIF(65597); Body_Slow_Spirometry-FE%FIF(19726397); Body_Transmural_F_V-FE%FIF(19791908); "
				}, {
					Name: "FEF 60",
					Label: "FEF 60",
					Type: "float",
					Comment: "Spirometry-FEF60(65646); Body_Slow_Spirometry-FEF60(19726446); "
				}, {
					Name: "FEF 75",
					Label: "FEF 75",
					Type: "float",
					Comment: "Spirometry-FEF75(65636); Body_Slow_Spirometry-FEF 75(19726436); "
				}, {
					Name: "FEF 75/85",
					Label: "FEF 75/85",
					Type: "float",
					Comment: "Spirometry-75/85(65582); Body_Slow_Spirometry-75-85(19726382); Body_Transmural_F_V-75-85(19791886); "
				}, {
					Name: "FEF 80",
					Label: "FEF 80",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 0.5",
					Label: "FEV 0.5",
					Type: "float",
					Comment: "Spirometry-FEV.5(65546); Body_Slow_Spirometry-FEV.5(19726346); Body_Transmural_F_V-FEV .5(19791873); "
				}, {
					Name: "FEV 0.75",
					Label: "FEV 0.75",
					Type: "float",
					Comment: "Spirometry-FEV.75(65624); Body_Slow_Spirometry-FEV.75(19726424); "
				}, {
					Name: "FEV 0.75 % FVC",
					Label: "FEV 0.75 % FVC",
					Type: "float",
					Comment: "Spirometry-F75FVC(65661); Body_Slow_Spirometry-F75FVC(19726461); "
				}, {
					Name: "FEV 0.75 % VC max",
					Label: "FEV 0.75 % VC max",
					Type: "float",
					Comment: "Spirometry-F75VCM(65662); Body_Slow_Spirometry-F75VCM(19726462); "
				}, {
					Name: "FEV 1",
					Label: "FEV 1",
					Type: "float",
					Comment: "Spirometry-FEV1(65547); Body_Slow_Spirometry-FEV 1(19726347); Body_Transmural_F_V-FEV 1(19791874); Body_Body-FEV1(19857439); "
				}, {
					Name: "FEV 1 % FEV 6",
					Label: "FEV 1 % FEV 6",
					Type: "float",
					Comment: "Spirometry-FEV1%6(65626); Body_Slow_Spirometry-FEV1%6(19726426); "
				}, {
					Name: "FEV 1 % FVC",
					Label: "FEV 1 % FVC",
					Type: "float",
					Comment: "Spirometry-FEV1%F(65551); Spirometry-FEV1%I(65677); Body_Slow_Spirometry-FEV1%F(19726351); Body_Slow_Spirometry-FEV1%VCin_F(19726477); Body_Transmural_F_V-FEV1%F(19791887); "
				}, {
					Name: "FEV 1 % VC",
					Label: "FEV 1 % VC",
					Type: "float",
					Comment: "Spirometry-FEV1%I(65550); Spirometry-FEV1%E(65568); Body_Slow_Spirometry-FEV1%I(19726350); Body_Slow_Spirometry-FEV1%E(19726368); Body_Transmural_F_V-FEV1%I(19791884); "
				}, {
					Name: "FEV 1 % VC max",
					Label: "FEV 1 % VC max",
					Type: "float",
					Comment: "Spirometry-FEV1%M(65552); Body_Slow_Spirometry-FEV1%M(19726352); Body_Transmural_F_V-FEV1%M(19791888); "
				}, {
					Name: "FEV 1 / VC max % pred",
					Label: "FEV 1 / VC max % pred",
					Type: "float",
					Comment: "Body_Slow_Spirometry-FEV1Mp(19726403); "
				}, {
					Name: "FEV 2",
					Label: "FEV 2",
					Type: "float",
					Comment: "Spirometry-FEV2(65548); Body_Slow_Spirometry-FEV 2(19726348); Body_Transmural_F_V-FEV 2(19791875); "
				}, {
					Name: "FEV 3",
					Label: "FEV 3",
					Type: "float",
					Comment: "Spirometry-FEV3(65549); Body_Slow_Spirometry-FEV 3(19726349); Body_Transmural_F_V-FEV 3(19791876); "
				}, {
					Name: "FEV 3 % FVC",
					Label: "FEV 3 % FVC",
					Type: "float",
					Comment: "Spirometry-FEV3%E(65589); Body_Slow_Spirometry-FEV3%E(19726389); Body_Transmural_F_V-FEV3%E(19791906); "
				}, {
					Name: "FEV 6",
					Label: "FEV 6",
					Type: "float",
					Comment: "Spirometry-FEV6(65625); Body_Slow_Spirometry-FEV6(19726425); "
				}, {
					Name: "FIF 25",
					Label: "FIF 25",
					Type: "float",
					Comment: "Spirometry-FIF25(65650); Body_Slow_Spirometry-FIF25(19726450); "
				}, {
					Name: "FIF 50",
					Label: "FIF 50",
					Type: "float",
					Comment: "Spirometry-FIF50(65593); Body_Slow_Spirometry-FIF 50(19726393); Body_Transmural_F_V-FIF 50(19791900); "
				}, {
					Name: "FIF 75",
					Label: "FIF 75",
					Type: "float",
					Comment: "Spirometry-FIF75(65651); Body_Slow_Spirometry-FIF75(19726451); "
				}, {
					Name: "FIV 1",
					Label: "FIV 1",
					Type: "float",
					Comment: "Spirometry-FIV1(65594); Body_Slow_Spirometry-FIV1(19726394); Body_Transmural_F_V-FIV 1(19791898); "
				}, {
					Name: "FIV 1 % FVC",
					Label: "FIV 1 % FVC",
					Type: "float",
					Comment: "Spirometry-FIV1%F(65595); Body_Slow_Spirometry-FIV1%F(19726395); "
				}, {
					Name: "FIV 1 % VC",
					Label: "FIV 1 % VC",
					Type: "float",
					Comment: "Spirometry-FIV1%M(65596); Body_Slow_Spirometry-FIV1%M(19726396); Body_Transmural_F_V-FIV1%I(19791901); "
				}, {
					Name: "Flow ex 150 (Rhino)",
					Label: "Flow ex 150 (Rhino)",
					Type: "float",
					Comment: "Rhinomanometry_Left-FEL150(163905543); Rhinomanometry_Right-FER150(163971079); "
				}, {
					Name: "Flow in 150 (Rhino)",
					Label: "Flow in 150 (Rhino)",
					Type: "float",
					Comment: "Rhinomanometry_Left-FIL150(163905541); Rhinomanometry_Right-FIR150(163971077); "
				}, {
					Name: "Flow SUM Ex",
					Label: "Flow SUM Ex",
					Type: "float",
					Comment: "Rhinomanometry-FSUME(1638402); "
				}, {
					Name: "Flow SUM In",
					Label: "Flow SUM In",
					Type: "float",
					Comment: "Rhinomanometry-FSUMI(1638401); "
				}, {
					Name: "FRC_Dil",
					Label: "FRC_Dil",
					Type: "float",
					Comment: "Diffusion_SB-FRC_SB(262159); Diffusion_SB_Intrabreath-FRC-IB(3866637); NO_Diffusion_Membrane-FRC-SB(4128797); FRC_Rebreathing_FRC_RB-FRC-He(445710343); Diffusion_Rebreathing_Diff_RB-FRC-He(452263969); "
				}, {
					Name: "FRC_Dil % TLC_Dil",
					Label: "FRC_Dil % TLC_Dil",
					Type: "float",
					Comment: "Diffusion_SB-FRC%TLC(262160); Diffusion_SB_Intrabreath-FRC%TLCIB(3866675); N2_Washout-FRC_N2/TLC_N2(4063238); NO_Diffusion_Membrane-FRC%TLC-SB(4128802); FRC_Rebreathing_FRC_RB-FRC%TLC(445710357); Diffusion_Rebreathing_Diff_RB-FRC %(452263983); "
				}, {
					Name: "FRC_Pleth",
					Label: "FRC_Pleth",
					Type: "float",
					Comment: "Compliance-FRC(393251); Baby_Body-FRCp(1310732); N2_Washout-FRC(4063259); Body_Body-FRCpl(19857427); Body_Body-FRCpl'(19857453); MIPMEP_MIP-FRC_MIP(426049550); MIPMEP_MEP-FRC_MEP(426115086); "
				}, {
					Name: "FRC_Pleth % TLC_Pleth",
					Label: "FRC_Pleth % TLC_Pleth",
					Type: "float",
					Comment: "Body_Body-FRCpl%(19857431); "
				}, {
					Name: "FVC",
					Label: "FVC",
					Type: "float",
					Comment: null
				}, {
					Name: "FVC in",
					Label: "FVC in",
					Type: "float",
					Comment: "Spirometry-FVC IN(65591); Body_Slow_Spirometry-FVC IN(19726391); Body_Transmural_F_V-FVC IN(19791899); "
				}, {
					Name: "G 0.5",
					Label: "G 0.5",
					Type: "float",
					Comment: "Body_Body-G0.5IN(19857443); Body_Body-G 0.5(19857502); "
				}, {
					Name: "G eff",
					Label: "G eff",
					Type: "float",
					Comment: "Baby_Body-Geff(1310742); Body_Body-G eff(19857424); "
				}, {
					Name: "G mid",
					Label: "G mid",
					Type: "float",
					Comment: "Body_Body-G mid(19857473); "
				}, {
					Name: "G peak",
					Label: "G peak",
					Type: "float",
					Comment: "Baby_Body-GPeak(1310754); Body_Body-Gpeak(19857450); "
				}, {
					Name: "G tot",
					Label: "G tot",
					Type: "float",
					Comment: "Baby_Body-Gtot(1310750); Body_Body-G tot(19857423); "
				}, {
					Name: "Gaw",
					Label: "Gaw",
					Type: "float",
					Comment: "Body_Body-Gaw(19857536); "
				}, {
					Name: "Hb",
					Label: "Hb",
					Type: "float",
					Comment: "Diffusion_SB-Hb(262165); Blood_gases-Hb(851982); Diffusion_SB_Intrabreath-Hb(3866633); NO_Diffusion_Membrane-Hb(4128795); "
				}, {
					Name: "Hc",
					Label: "Hc",
					Type: "float",
					Comment: "Blood_gases-Hct(851983); "
				}, {
					Name: "HR",
					Label: "HR",
					Type: "float",
					Comment: "Blood_gases-HR(852042); CPET_BxB-HR(78708753); "
				}, {
					Name: "HR - response (VO2)",
					Label: "HR - response (VO2)",
					Type: "float",
					Comment: "CPET_BxB-HRresp(78708826); CPET_Slope-HR/VO2s(79626254); "
				}, {
					Name: "HR Reserve (%)",
					Label: "HR Reserve (%)",
					Type: "float",
					Comment: "CPET_BxB-HRR%(78708828); "
				}, {
					Name: "HR Reserve (1/min)",
					Label: "HR Reserve (1/min)",
					Type: "float",
					Comment: "CPET_BxB-HRR (B)(78708813); "
				}, {
					Name: "HR slope (VO2/kg)",
					Label: "HR slope (VO2/kg)",
					Type: "float",
					Comment: "CPET_BxB-HRslkg(78708816); CPET_Slope-HRO2Ws(79626246); "
				}, {
					Name: "IC",
					Label: "IC",
					Type: "float",
					Comment: "Spirometry-IC(65538); Spirometry-IC_F(65668); Diffusion_SB-IC_SB(262204); Compliance-IC(393238); IOS-IC(1179726); Diffusion_SB_Intrabreath-IC-IB(3866640); N2_Washout-IC_N2(4063241); NO_Diffusion_Membrane-IC-SB(4128804); Body_Slow_Spirometry-IC(19726338); Body_Slow_Spirometry-IC_F(19726468); Body_Body-IC(19857435); MIPMEP_MIP-IC_MIP(426049549); MIPMEP_MEP-IC_MEP(426115085); FRC_Rebreathing_FRC_RB-IC(445710348); Diffusion_Rebreathing_Diff_RB-IC(452263974); "
				}, {
					Name: "Lactate",
					Label: "Lactate",
					Type: "float",
					Comment: "Blood_gases-Lac(851986); "
				}, {
					Name: "Load",
					Label: "Load",
					Type: "float",
					Comment: "Blood_gases-Load(851976); CPET_BxB-Load(78708747); CPET_BxB-Load_i(78708886); CPET_Slope-Load(79626251); "
				}, {
					Name: "Mean transit time",
					Label: "Mean transit time",
					Type: "float",
					Comment: "Spirometry-MTT(65584); Body_Slow_Spirometry-MTT(19726384); Body_Transmural_F_V-MTT(19791891); "
				}, {
					Name: "MEF 25",
					Label: "MEF 25",
					Type: "float",
					Comment: "Spirometry-MEF25(65572); Body_Slow_Spirometry-MEF 25(19726372); Body_Transmural_F_V-MEF 25(19791879); "
				}, {
					Name: "MEF 50",
					Label: "MEF 50",
					Type: "float",
					Comment: "Spirometry-MEF50(65571); Body_Slow_Spirometry-MEF 50(19726371); Body_Transmural_F_V-MEF 50(19791878); "
				}, {
					Name: "MEF 50 % FVC",
					Label: "MEF 50 % FVC",
					Type: "float",
					Comment: "Spirometry-MEF50%(65580); Body_Slow_Spirometry-MEF50%(19726380); Body_Transmural_F_V-MEF50%(19791881); "
				}, {
					Name: "MEF 75",
					Label: "MEF 75",
					Type: "float",
					Comment: "Spirometry-MEF75(65570); Body_Slow_Spirometry-MEF 75(19726370); Body_Transmural_F_V-MEF 75(19791877); "
				}, {
					Name: "MEP",
					Label: "MEP",
					Type: "float",
					Comment: "MIPMEP_MEP-MEP(426115073); "
				}, {
					Name: "MEP avg",
					Label: "MEP avg",
					Type: "float",
					Comment: "MIPMEP_MEP-MEP Ave(426115075); "
				}, {
					Name: "MEP peak",
					Label: "MEP peak",
					Type: "float",
					Comment: "MIPMEP_MEP-MEP Peak(426115074); "
				}, {
					Name: "MEP sus",
					Label: "MEP sus",
					Type: "float",
					Comment: "MIPMEP_MEP-MEP Sus(426115076); "
				}, {
					Name: "MFEF 75/25",
					Label: "MFEF 75/25",
					Type: "float",
					Comment: "Spirometry-MFEF(65640); Body_Slow_Spirometry-MFEF(19726440); "
				}, {
					Name: "MFEF 75/85",
					Label: "MFEF 75/85",
					Type: "float",
					Comment:  "Spirometry-F75/85(65641); "
				}, {
					Name: "MIF",
					Label: "MIF",
					Type: "float",
					Comment: "P01_Rest-MIF_P0.1(432603139); "
				}, {
					Name: "MIP",
					Label: "MIP",
					Type: "float",
					Comment: "Baby_Body-PImax(1310737); MIPMEP_MIP-MIP(426049537); P01_Rest-MIP(432603140); P01_Max-MIP(432668676); P01_CO2_1-MIP(432734212); P01_CO2_2-MIP(432799748); "
				}, {
					Name: "MIP avg",
					Label: "MIP avg",
					Type: "float",
					Comment: "MIPMEP_MIP-MIP Ave(426049539); "
				}, {
					Name: "MIP peak",
					Label: "MIP peak",
					Type: "float",
					Comment: "MIPMEP_MIP-MIP Peak(426049538); "
				}, {
					Name: "MIP sus",
					Label: "MIP sus",
					Type: "float",
					Comment: "MIPMEP_MIP-MIP Sus(426049540); "
				}, {
					Name: "MMEF 75/25",
					Label: "MMEF 75/25",
					Type: "float",
					Comment: "Spirometry-MMEF(65581); Body_Slow_Spirometry-MMEF(19726381); Body_Transmural_F_V-MMEF(19791885); "
				}, {
					Name: "MV, VE",
					Label: "MV, VE",
					Type: "float",
					Comment: "Spirometry-MV(65541); Spirometry-MV_F(65666); Diffusion_SB-MV_SB(262202); R_Occlusion-MV(458758); Baby_Body-MV-FRC(1310726); Diffusion_SB_Intrabreath-MV-IB(3866671); Body_Slow_Spirometry-MV(19726341); Body_Slow_Spirometry-MV_F(19726466); CPET_BxB-V'E(78708790); MIPMEP_MIP-MV_MIP(426049545); MIPMEP_MEP-MV_MEP(426115081); P01_Rest-MV_P0.1(432603141); FRC_Rebreathing_FRC_RB-MV(445710351); FRC_Rebreathing_FRC_RB-MVrb(445710369); Diffusion_Rebreathing_Diff_RB-MV(452263977); "
				}, {
					Name: "MVV",
					Label: "MVV",
					Type: "float",
					Comment: "Spirometry-MVV(65553); Spirometry-FEV*30(65557); Body_Slow_Spirometry-MVV(19726353); Body_Slow_Spirometry-FEV*30(19726357); Body_Slow_Spirometry-MVV_6(19726452); Body_Transmural_F_V-MVV-Th(19791889); "
				}, {
					Name: "O2 pulse",
					Label: "O2 pulse",
					Type: "float",
					Comment: "CPET_BxB-O2pulse(78708801); "
				}, {
					Name: "OUES-Slope",
					Label: "OUES-Slope",
					Type: "float",
					Comment: "CPET_Slope-OUESs(79626250); "
				}, {
					Name: "P 0.1",
					Label: "P 0.1",
					Type: "float",
					Comment: "Baby_Body-P100(1310736); P01_Rest-P0.1(432603145); "
				}, {
					Name: "P 0.1 / MIF",
					Label: "P 0.1 / MIF",
					Type: "float",
					Comment: "P01_Rest-P0.1/MIF(432603142); "
				}, {
					Name: "P 0.1 / MIP",
					Label: "P 0.1 / MIP",
					Type: "float",
					Comment: "P01_Rest-P0.1/MIP(432603144); "
				}, {
					Name: "P 0.1 / MV",
					Label: "P 0.1 / MV",
					Type: "float",
					Comment: "P01_Rest-P0.1/MV(432603143); "
				}, {
					Name: "P 0.1 / P 0.1 max",
					Label: "P 0.1 / P 0.1 max",
					Type: "float",
					Comment: "P01_Max-P0.1/P0.1Max(432668679); "
				}, {
					Name: "P 0.1 max",
					Label: "P 0.1 max",
					Type: "float",
					Comment: "P01_Max-P0.1Max(432668678); "
				}, {
					Name: "P 0.1 max / MV",
					Label: "P 0.1 max / MV",
					Type: "float",
					Comment: "P01_Max-P0.1Max/MV(432668681); "
				}, {
					Name: "P diastolic",
					Label: "P diastolic",
					Type: "float",
					Comment: "CPET_BxB-Pdia(78708763); "
				}, {
					Name: "P el 100 % TLC",
					Label: "P el 100 % TLC",
					Type: "float",
					Comment: "Compliance-Pel100(393282); "
				}, {
					Name: "P el 50 % / TLC",
					Label: "P el 50 % / TLC",
					Type: "float",
					Comment: "Compliance-P el50(393236); "
				}, {
					Name: "P el 60 % / TLC",
					Label: "P el 60 % / TLC",
					Type: "float",
					Comment: "Compliance-P el60(393240); "
				}, {
					Name: "P el 70 % / TLC",
					Label: "P el 70 % / TLC",
					Type: "float",
					Comment: "Compliance-P el70(393241); "
				}, {
					Name: "P el 80 % / TLC",
					Label: "P el 80 % / TLC",
					Type: "float",
					Comment: "Compliance-P el80(393242); "
				}, {
					Name: "P el 90 % TLC",
					Label: "P el 90 % TLC",
					Type: "float",
					Comment: "Compliance-P el90(393243); "
				}, {
					Name: "P el TLC",
					Label: "P el TLC",
					Type: "float",
					Comment: "Compliance-PeTLC(393233); "
				}, {
					Name: "P mean",
					Label: "P mean",
					Type: "float",
					Comment: "CPET_BxB-Pmean(78708868); "
				}, {
					Name: "P systolic",
					Label: "P systolic",
					Type: "float",
					Comment: "CPET_BxB-Psys(78708762); "
				}, {
					Name: "P(A-a)O2",
					Label: "P(A-a)O2",
					Type: "float",
					Comment: "Blood_gases-AaDO2(851991); "
				}, {
					Name: "P(a-ET)CO2",
					Label: "P(a-ET)CO2",
					Type: "float",
					Comment: "Blood_gases-PaETCO2(852014); "
				}, {
					Name: "PaCO2",
					Label: "PaCO2",
					Type: "float",
					Comment: "Blood_gases-PaCO2(851989); "
				}, {
					Name: "PAlvO2",
					Label: "PAlvO2",
					Type: "float",
					Comment: "Blood_gases-PAO2(851990); "
				}, {
					Name: "PaO2",
					Label: "PaO2",
					Type: "float",
					Comment: "Blood_gases-PaO2(851987); Blood_gases-PaO2st(851992); "
				}, {
					Name: "PEF",
					Label: "PEF",
					Type: "float",
					Comment: "Spirometry-PEF(65573); Body_Slow_Spirometry-PEF(19726373); Body_Transmural_F_V-PEF(19791880); Body_Body-PEF(19857438); "
				}, {
					Name: "PETCO2",
					Label: "PETCO2",
					Type: "float",
					Comment: "Blood_gases-PETCO2(852033); CPET_BxB-PETCO2(78708793); "
				}, {
					Name: "PETO2",
					Label: "PETO2",
					Type: "float",
					Comment: "Blood_gases-PETO2(852034); CPET_BxB-PETO2(78708795); "
				}, {
					Name: "pH a",
					Label: "pH a",
					Type: "float",
					Comment: "Blood_gases-pHa(851981); "
				}, {
					Name: "PIF",
					Label: "PIF",
					Type: "float",
					Comment: "Spirometry-PIF(65592); Body_Slow_Spirometry-PIF(19726392); Body_Transmural_F_V-PIF(19791897); "
				}, {
					Name: "PRO %",
					Label: "PRO %",
					Type: "float",
					Comment: "CPET_BxB-PRO E%(78708876); CPET_REE-PRO %(79429654); "
				}, {
					Name: "PRO / g/day",
					Label: "PRO / g/day",
					Type: "float",
					Comment: "CPET_BxB-PRO g/d(78708824); CPET_REE-PRO g(79429652); "
				}, {
					Name: "Pulmonary blood flow",
					Label: "Pulmonary blood flow",
					Type: "float",
					Comment: "Diffusion_SB_Intrabreath-Qt(3866634); "
				}, {
					Name: "R 0.5",
					Label: "R 0.5",
					Type: "float",
					Comment: "Body_Body-R0.5IN(19857409); Body_Body-R 0.5(19857501); Body_Body-R0.5EX(19857505); "
				}, {
					Name: "R at 10Hz",
					Label: "R at 10Hz",
					Type: "float",
					Comment: "IOS-R10Hz(1179661); IOS_Tidal_breathing_mean_v-R10Hz(118030342); IOS_Tidal_breathing_In_param-Rin10(118095878); IOS_Tidal_breathing_Ex_param-Rex10(118161414); "
				}, {
					Name: "R at 15Hz",
					Label: "R at 15Hz",
					Type: "float",
					Comment: "IOS-R15Hz(1179663); IOS_Tidal_breathing_mean_v-R15Hz(118030343); IOS_Tidal_breathing_In_param-Rin15(118095879); IOS_Tidal_breathing_Ex_param-Rex15(118161415); "
				}, {
					Name: "R at 20Hz",
					Label: "R at 20Hz",
					Type: "float",
					Comment: "IOS-R20Hz(1179665); IOS_Tidal_breathing_mean_v-R20Hz(118030344); IOS_Tidal_breathing_In_param-Rin20(118095880); IOS_Tidal_breathing_Ex_param-Rex20(118161416); "
				}, {
					Name: "R at 25Hz",
					Label: "R at 25Hz",
					Type: "float",
					Comment: "IOS-R25Hz(1179667); IOS_Tidal_breathing_mean_v-R25Hz(118030345); IOS_Tidal_breathing_In_param-Rin25(118095881); IOS_Tidal_breathing_Ex_param-Rex25(118161417); "
				}, {
					Name: "R at 35Hz",
					Label: "R at 35Hz",
					Type: "float",
					Comment: "IOS-R35Hz(1179669); IOS_Tidal_breathing_mean_v-R35Hz(118030346); IOS_Tidal_breathing_In_param-Rin35(118095882); IOS_Tidal_breathing_Ex_param-Rex35(118161418); "
				}, {
					Name: "R at 5Hz",
					Label: "R at 5Hz",
					Type: "float",
					Comment: "IOS-R5Hz(1179659); IOS_Tidal_breathing_mean_v-R5Hz(118030341); IOS_Tidal_breathing_In_param-Rin5(118095877); IOS_Tidal_breathing_Ex_param-Rex5(118161413); "
				}, {
					Name: "R eff",
					Label: "R eff",
					Type: "float",
					Comment: "Baby_Body-Reff(1310741); Baby_Body-Rineff(1310744); Baby_Body-Rexeff(1310746); Body_Body-R eff(19857411); Body_Body-RI eff(19857414); Body_Body-RE eff(19857415); "
				}, {
					Name: "R ex 150 (Rhino)",
					Label: "R ex 150 (Rhino)",
					Type: "float",
					Comment: "Rhinomanometry_Left-REL150(163905544); Rhinomanometry_Right-RER150(163971080); "
				}, {
					Name: "R in 150 (Rhino)",
					Label: "R in 150 (Rhino)",
					Type: "float",
					Comment: "Rhinomanometry_Left-RIL150(163905542); Rhinomanometry_Right-RIR150(163971078); "
				}, {
					Name: "R IOS_central",
					Label: "R IOS_central",
					Type: "float",
					Comment: "IOS-Rc(1179651); "
				}, {
					Name: "R IOS_peripheral",
					Label: "R IOS_peripheral",
					Type: "float",
					Comment: "IOS-Rp(1179652); "
				}, {
					Name: "R mid",
					Label: "R mid",
					Type: "float",
					Comment: "Body_Body-R mid(19857471); Body_Body-RmidIN(19857476); Body_Body-RmidEX(19857478); "
				}, {
					Name: "R peak",
					Label: "R peak",
					Type: "float",
					Comment: "Baby_Body-RPeak(1310753); Body_Body-R PEAK(19857416); "
				}, {
					Name: "R tot",
					Label: "R tot",
					Type: "float",
					Comment: "Baby_Body-Rtot(1310749); Baby_Body-Rin(1310756); Baby_Body-Rex(1310758); Body_Body-R tot(19857410); Body_Body-R IN(19857412); Body_Body-R EX(19857413); "
				}, {
					Name: "Raw",
					Label: "Raw",
					Type: "float",
					Comment: "Body_Body-Raw(19857534); "
				}, {
					Name: "Raw Compliance",
					Label: "Raw Compliance",
					Type: "float",
					Comment: "Compliance-R eff(393247); Compliance-RINeff(393248); Compliance-REXeff(393249); "
				}, {
					Name: "RER",
					Label: "RER",
					Type: "float",
					Comment: "Blood_gases-RER(851980); CPET_BxB-RER(78708791); "
				}, {
					Name: "Reserved (IF) Baby-Body",
					Label: "Reserved (IF) Baby-Body",
					Type: "float",
					Comment:  "Baby_Body-Res(1310727); "
				}, {
					Name: "Reserved_01",
					Label: "Reserved_01",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_02",
					Label: "Reserved_02",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_03",
					Label: "Reserved_03",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_04",
					Label: "Reserved_04",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_05",
					Label: "Reserved_05",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_06",
					Label: "Reserved_06",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_07",
					Label: "Reserved_07",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_08",
					Label: "Reserved_08",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_09",
					Label: "Reserved_09",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_10",
					Label: "Reserved_10",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_11",
					Label: "Reserved_11",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_12",
					Label: "Reserved_12",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_13",
					Label: "Reserved_13",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_14",
					Label: "Reserved_14",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_15",
					Label: "Reserved_15",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_16",
					Label: "Reserved_16",
					Type: "float",
					Comment: null
				}, {
					Name: "Rocc",
					Label: "Rocc",
					Type: "float",
					Comment: "R_Occlusion-R Occ(458753); "
				}, {
					Name: "Rrs DO (Baby R/C)",
					Label: "Rrs DO (Baby R/C)",
					Type: "float",
					Comment: "Baby_R_C-RrsDO(1376257); Baby_R_C-Rrs2*(1376260); Baby_R_C-RrsAv*(1376263); "
				}, {
					Name: "RV_Dil",
					Label: "RV_Dil",
					Type: "float",
					Comment: "Diffusion_SB-RV_SB(262145); Diffusion_SB_Intrabreath-RV-IB(3866636); NO_Diffusion_Membrane-RV-SB(4128798); FRC_Rebreathing_FRC_RB-RV(445710354); Diffusion_Rebreathing_Diff_RB-RV(452263980); "
				}, {
					Name: "RV_Dil % TLC_Dil",
					Label: "RV_Dil % TLC_Dil",
					Type: "float",
					Comment: "Diffusion_SB-RV%TLC_SB(262146); Diffusion_SB_Intrabreath-RV%TLCIB(3866674); N2_Washout-RV_N2/TLC_N2(4063247); NO_Diffusion_Membrane-RV%TLC-SB(4128801); FRC_Rebreathing_FRC_RB-RV%TLC(445710356); Diffusion_Rebreathing_Diff_RB-RV%TLC(452263982); "
				}, {
					Name: "RV_Pleth",
					Label: "RV_Pleth",
					Type: "float",
					Comment: "Diffusion_SB-RV_PL(262187); Diffusion_SB-RV_EX_SB(262226); Diffusion_SB_Intrabreath-RV-BY(3866677); NO_Diffusion_Membrane-RV Pl(4128848); Body_Body-RV(19857428); "
				}, {
					Name: "RV_Pleth % TLC_Pleth",
					Label: "RV_Pleth % TLC_Pleth",
					Type: "float",
					Comment: "Body_Body-RV%TLC(19857430); "
				}, {
					Name: "SaO2",
					Label: "SaO2",
					Type: "float",
					Comment: "Blood_gases-SaO2(851985); "
				}, {
					Name: "sG 0.5",
					Label: "sG 0.5",
					Type: "float",
					Comment: "Body_Body-sG05IN(19857444); Body_Body-sG 0.5(19857503); "
				}, {
					Name: "sG eff",
					Label: "sG eff",
					Type: "float",
					Comment: "Baby_Body-sGeff(1310740); Body_Body-sG eff(19857426); "
				}, {
					Name: "sG mid",
					Label: "sG mid",
					Type: "float",
					Comment: "Body_Body-sG mid(19857474); "
				}, {
					Name: "sG peak",
					Label: "sG peak",
					Type: "float",
					Comment: "Baby_Body-sGPeak(1310752); Body_Body-sGpeak(19857451); "
				}, {
					Name: "sG tot",
					Label: "sG tot",
					Type: "float",
					Comment: "Baby_Body-sGtot(1310748); Body_Body-sG tot(19857425); "
				}, {
					Name: "sGaw",
					Label: "sGaw",
					Type: "float",
					Comment: "Body_Body-sGaw(19857537); "
				}, {
					Name: "SNIP",
					Label: "SNIP",
					Type: "float",
					Comment: "SNIP_Sniff-SNIP(439156737); "
				}, {
					Name: "SNIP avg",
					Label: "SNIP avg",
					Type: "float",
					Comment: "SNIP_Sniff-SNIPAve(439156739); "
				}, {
					Name: "SNIP peak",
					Label: "SNIP peak",
					Type: "float",
					Comment: "SNIP_Sniff-SNIPPk(439156738); "
				}, {
					Name: "SNIP sus",
					Label: "SNIP sus",
					Type: "float",
					Comment: "SNIP_Sniff-SNIPSus(439156740); "
				}, {
					Name: "spec. W el",
					Label: "spec. W el",
					Type: "float",
					Comment: "Compliance-SW-el(393253); "
				}, {
					Name: "spec. W vis",
					Label: "spec. W vis",
					Type: "float",
					Comment: "Compliance-SW-vis(393252); "
				}, {
					Name: "sR 0.5",
					Label: "sR 0.5",
					Type: "float",
					Comment: "Body_Body-sR05IN(19857442); Body_Body-sR 0.5(19857500); Body_Body-sR05EX(19857504); "
				}, {
					Name: "sR eff",
					Label: "sR eff",
					Type: "float",
					Comment: "Baby_Body-sReff(1310739); Baby_Body-sRinef(1310743); Baby_Body-sRexef(1310745); Body_Body-sR eff(19857418); Body_Body-sRIeff(19857446); Body_Body-sREeff(19857447); "
				}, {
					Name: "sR mid",
					Label: "sR mid",
					Type: "float",
					Comment: "Body_Body-sR mid(19857472); Body_Body-sRmdIN(19857477); Body_Body-sRmdEX(19857479); "
				}, {
					Name: "sR peak",
					Label: "sR peak",
					Type: "float",
					Comment: "Baby_Body-sRPeak(1310751); Body_Body-sRpeak(19857449); "
				}, {
					Name: "sR tot",
					Label: "sR tot",
					Type: "float",
					Comment: "Baby_Body-sRtot(1310747); Baby_Body-sRin(1310755); Baby_Body-sRex(1310757); Body_Body-sR tot(19857417); Body_Body-sRI t(19857440); Body_Body-sRE t(19857441); "
				}, {
					Name: "sRaw",
					Label: "sRaw",
					Type: "float",
					Comment: "Body_Body-sRaw(19857535); "
				}, {
					Name: "STB",
					Label: "STB",
					Type: "float",
					Comment: "Blood_gases-STB(851998); "
				}, {
					Name: "Stroke volume",
					Label: "Stroke volume",
					Type: "float",
					Comment: "Blood_gases-SV_dF(852040); CPET_BxB-SVc(78708831); CPET_Cardiac_Output-SV(78839824); "
				}, {
					Name: "Stroke volume index",
					Label: "Stroke volume index",
					Type: "float",
					Comment: "Blood_gases-SVI_dF(852041); CPET_BxB-SVI_c(78708885); CPET_Cardiac_Output-SVI(78839832); "
				}, {
					Name: "T Body",
					Label: "T Body",
					Type: "float",
					Comment: "Blood_gases-TBody(851975); CPET_BxB-TBody(78708751); "
				}, {
					Name: "TCO2",
					Label: "TCO2",
					Type: "float",
					Comment: "Blood_gases-CPCO2(851996); "
				}, {
					Name: "Time constant (Tau)",
					Label: "Time constant (Tau)",
					Type: "float",
					Comment: "CPET_BxB-MRT(78708891); CPET_BxB-Tau(78708894); CPET_Slope-Tau(79626253); "
				}, {
					Name: "Tin/Ttot",
					Label: "Tin/Ttot",
					Type: "float",
					Comment: "Baby_Body-tI/T-F(1310729); P01_Rest-TIN/TTOT(432603152); "
				}, {
					Name: "TLC_Dil",
					Label: "TLC_Dil",
					Type: "float",
					Comment: "Diffusion_SB-TLC_SB(262147); Diffusion_SB_Intrabreath-TLC-IB(3866638); NO_Diffusion_Membrane-TLC-SB(4128799); FRC_Rebreathing_FRC_RB-TLC(445710355); Diffusion_Rebreathing_Diff_RB-TLC(452263981); "
				}, {
					Name: "TLC_Pleth",
					Label: "TLC_Pleth",
					Type: "float",
					Comment: "Diffusion_SB-TLC_PL(262188); Diffusion_SB-TLC_EX_SB(262227); Compliance-TLC(393250); Diffusion_SB_Intrabreath-TLC-BY(3866678); NO_Diffusion_Membrane-TLC Pl(4128849); Body_Body-TLC(19857429); MIPMEP_MIP-TLC_MIP(426049548); MIPMEP_MEP-TLC_MEP(426115084); "
				}, {
					Name: "TOT / Kcal /day",
					Label: "TOT / Kcal /day",
					Type: "float",
					Comment: null
				}, {
					Name: "UN",
					Label: "UN",
					Type: "float",
					Comment: "Blood_gases-UN(851984); "
				}, {
					Name: "VA",
					Label: "VA",
					Type: "float",
					Comment: "Diffusion_SB-VA_SB(262151); Diffusion_SB-VA CO_SB(262176); Diffusion_SB-VA_SQM(262181); Diffusion_SB-VA_EX_SB(262221); Diffusion_SB_Intrabreath-VA max(3866652); NO_Diffusion_Membrane-VA-SB(4128800); NO_Diffusion_Membrane-VA SQM(4128844); NO_Diffusion_Membrane-VA SQM CO(4128845); Diffusion_Rebreathing_Diff_RB-VA rb(452263947); "
				}, {
					Name: "VC",
					Label: "VC",
					Type: "float",
					Comment: "Spirometry-VC IN(65537); Spirometry-VC EX(65544); Spirometry-VCmod(65635); Diffusion_SB-VIN_SB(262152); Diffusion_SB-VC Trg(262166); Diffusion_SB-VEX_CH4(262225); Compliance-VC(393237); IOS-VC IN(1179730); IOS-VC EX(1179731); Diffusion_SB_Intrabreath-VIN-IB(3866639); N2_Washout-VCIN_N2(4063254); N2_Washout-VCEX_N2(4063255); NO_Diffusion_Membrane-VIN SB(4128826); Single_Breath_O2-VC_SBO2(4653057); Body_Slow_Spirometry-VC IN(19726337); Body_Slow_Spirometry-VC EX(19726344); Body_Slow_Spirometry-VCmod(19726435); Body_Transmural_F_V-VC IN(19791882); Body_Body-VC(19857433); FRC_Rebreathing_FRC_RB-VC_IN(445710345); FRC_Rebreathing_FRC_RB-VC_EX(445710346); Diffusion_Rebreathing_Diff_RB-VC IN(452263971); Diffusion_Rebreathing_Diff_RB-VC EX(452263972); "
				}, {
					Name: "Vc (capilar blood volume)",
					Label: "Vc (capilar blood volume)",
					Type: "float",
					Comment: "NO_Diffusion_Membrane-Vc  (QC)(4128770); "
				}, {
					Name: "VC max",
					Label: "VC max",
					Type: "float",
					Comment: "Spirometry-VC MAX(65545); Diffusion_SB-VCmax(262195); IOS-VC MAX(1179729); Diffusion_SB_Intrabreath-VCmaxIB(3866673); Diffusion_SB_Intrabreath-VCmaxSP(3866676); N2_Washout-VCMAX_N2(4063256); NO_Diffusion_Membrane-VC Trg(4128828); NO_Diffusion_Membrane-VCmax(4128829); Body_Slow_Spirometry-VC MAX(19726345); Body_Transmural_F_V-VC Max(19791896); FRC_Rebreathing_FRC_RB-VC_max(445710347); Diffusion_Rebreathing_Diff_RB-VC max(452263973); "
				}, {
					Name: "VCO2",
					Label: "VCO2",
					Type: "float",
					Comment: "CPET_BxB-V'CO2(78708787); CPET_Cardiac_Output-V'CO2(78839823); "
				}, {
					Name: "VD / VT",
					Label: "VD / VT",
					Type: "float",
					Comment: "CPET_BxB-VDc/VT(78708805); CPET_BxB-VDf/VT(78708806); CPET_BxB-VDe/VT(78708807); "
				}, {
					Name: "VE slope (VCO2)",
					Label: "VE slope (VCO2)",
					Type: "float",
					Comment: "CPET_BxB-V'Eslo(78708881); CPET_Slope-VECO2s(79626242); "
				}, {
					Name: "VE slope (VO2)",
					Label: "VE slope (VO2)",
					Type: "float",
					Comment: "CPET_Slope-VEO2s(79626248); "
				}, {
					Name: "VO2",
					Label: "VO2",
					Type: "float",
					Comment: "Blood_gases-V'O2(851979); CPET_BxB-V'O2(78708788); "
				}, {
					Name: "VO2 / kg",
					Label: "VO2 / kg",
					Type: "float",
					Comment: "CPET_BxB-V'O2/kg(78708798); "
				}, {
					Name: "VO2 slope (watt)",
					Label: "VO2 slope (watt)",
					Type: "float",
					Comment: "CPET_BxB-V'O2slo(78708818); CPET_Slope-VO2Ws(79626244); "
				}, {
					Name: "VT",
					Label: "VT",
					Type: "float",
					Comment: "Spirometry-VT(65540); Spirometry-VT_F(65664); Compliance-VT(393223); R_Occlusion-VT(458757); IOS-VT(1179649); Capnography-VT(1245186); Capnography-VTin(1245195); Capnography-VTex(1245196); Baby_Body-VT-FRC(1310721); Body_Slow_Spirometry-VT(19726340); Body_Slow_Spirometry-VT_F(19726464); Body_Body-VT(19857419); CPET_BxB-VTex(78708740); CPET_BxB-VTin(78708741); Capnography_Multibreath-VT(124583938); Capnography_Multibreath-VTin(124583947); Capnography_Multibreath-VTex(124583948); Capnography_Single_Breath-VT(124649474); Capnography_Single_Breath-VTin(124649483); Capnography_Single_Breath-VTex(124649484); MIPMEP_MIP-VT_MIP(426049544); MIPMEP_MEP-VT_MEP(426115080); P01_Rest-VT_P0.1(432603153); FRC_Rebreathing_FRC_RB-VT(445710350); FRC_Rebreathing_FRC_RB-VTrb(445710370); Diffusion_Rebreathing_Diff_RB-VT(452263976); "
				}, {
					Name: "W el",
					Label: "W el",
					Type: "float",
					Comment: "Compliance-W el(393222); "
				}, {
					Name: "W vis",
					Label: "W vis",
					Type: "float",
					Comment: "Compliance-W vis(393221); "
				}, {
					Name: "X at 10Hz",
					Label: "X at 10Hz",
					Type: "float",
					Comment: "IOS-X10Hz(1179662); IOS_Tidal_breathing_mean_v-X10Hz(118030362); IOS_Tidal_breathing_In_param-Xin10(118095898); IOS_Tidal_breathing_Ex_param-Xex10(118161434); "
				}, {
					Name: "X at 15Hz",
					Label: "X at 15Hz",
					Type: "float",
					Comment: "IOS-X15Hz(1179664); IOS_Tidal_breathing_mean_v-X15Hz(118030363); IOS_Tidal_breathing_In_param-Xin15(118095899); IOS_Tidal_breathing_Ex_param-Xex15(118161435); "
				}, {
					Name: "X at 20Hz",
					Label: "X at 20Hz",
					Type: "float",
					Comment: "IOS-X20Hz(1179666); IOS_Tidal_breathing_mean_v-X20Hz(118030364); IOS_Tidal_breathing_In_param-Xin20(118095900); IOS_Tidal_breathing_Ex_param-Xex20(118161436); "
				}, {
					Name: "X at 25Hz",
					Label: "X at 25Hz",
					Type: "float",
					Comment: "IOS-X25Hz(1179668); IOS_Tidal_breathing_mean_v-X25Hz(118030365); IOS_Tidal_breathing_In_param-Xin25(118095901); IOS_Tidal_breathing_Ex_param-Xex25(118161437); "
				}, {
					Name: "X at 35Hz",
					Label: "X at 35Hz",
					Type: "float",
					Comment: "IOS-X35Hz(1179670); IOS_Tidal_breathing_mean_v-X35Hz(118030366); IOS_Tidal_breathing_In_param-Xin35(118095902); IOS_Tidal_breathing_Ex_param-Xex35(118161438); "
				}, {
					Name: "X at 5Hz",
					Label: "X at 5Hz",
					Type: "float",
					Comment: "IOS-X5Hz(1179660); IOS-X5Hz c(1179749); IOS_Tidal_breathing_mean_v-X5Hz(118030361); IOS_Tidal_breathing_In_param-Xin5(118095897); IOS_Tidal_breathing_Ex_param-Xex5(118161433); "
				}, {
					Name: "Z at 5Hz",
					Label: "Z at 5Hz",
					Type: "float",
					Comment: "IOS-Z5Hz(1179650); "
				}, {
					Name: "Property 1",
					Label: "Property 1",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 2",
					Label: "Property 2",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 3",
					Label: "Property 3",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 4",
					Label: "Property 4",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 5",
					Label: "Property 5",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 6",
					Label: "Property 6",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 7",
					Label: "Property 7",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 8",
					Label: "Property 8",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 9",
					Label: "Property 9",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 10",
					Label: "Property 10",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 11",
					Label: "Property 11",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 12",
					Label: "Property 12",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 13",
					Label: "Property 13",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 14",
					Label: "Property 14",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 15",
					Label: "Property 15",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Diagnosis",
					Label: "Diagnosis",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Anamnesis",
					Label: "Anamnesis",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Medication",
					Label: "Medication",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Occupational Exposure",
					Label: "Occupational Exposure",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Location",
					Label: "Location",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Room",
					Label: "Room",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Referring Physician Id",
					Label: "Referring Physician Id",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Referring Physician Name",
					Label: "Referring Physician Name",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Operator Id",
					Label: "Operator Id",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Operator Name",
					Label: "Operator Name",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Physician Id",
					Label: "Physician Id",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Physician Name",
					Label: "Physician Name",
					Type: "nvarchar",
					Comment: null
				}
			]
		}, {
			Name: "Base_All_Trials",
			Label: "All_Trials",
			Comment: "All parameters per level2, trial3 and measurement typ (Reference Parameter based)",
			Columns: [{
					Name: "Visit Date",
					Label: "Visit Date",
					Type: "date",
					Comment: null
				}, {
					Name: "Patient ID",
					Label: "Patient ID",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Name",
					Label: "Name",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Middlename",
					Label: "Middlename",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Firstname",
					Label: "Firstname",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Date_of_Birth",
					Label: "Date_of_Birth",
					Type: "date",
					Comment: null
				}, {
					Name: "Gender",
					Label: "Gender",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "BiologicalGender",
					Label: "BiologicalGender",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Race",
					Label: "Race",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Test Date",
					Label: "Test Date",
					Type: "datetime",
					Comment: null
				}, {
					Name: "Test Duration",
					Label: "Test Duration",
					Type: "int",
					Comment: null
				}, {
					Name: "Age",
					Label: "Age",
					Type: "int",
					Comment: null
				}, {
					Name: "Height",
					Label: "Height",
					Type: "float",
					Comment: null
				}, {
					Name: "Weight",
					Label: "Weight",
					Type: "float",
					Comment: null
				}, {
					Name: "Measurement",
					Label: "Measurement",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "LevelType",
					Label: "LevelType",
					Type: "int",
					Comment: null
				}, {
					Name: "Sequence",
					Label: "Sequence",
					Type: "int",
					Comment: null
				}, {
					Name: "Trial",
					Label: "Trial",
					Type: "int",
					Comment: null
				}, {
					Name: "Substance",
					Label: "Substance",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Concentration",
					Label: "Concentration",
					Type: "float",
					Comment: null
				}, {
					Name: "Dose",
					Label: "Dose",
					Type: "float",
					Comment: null
				}, {
					Name: "Cummulated Dose",
					Label: "Cummulated Dose",
					Type: "float",
					Comment: null
				}, {
					Name: "6 MWD",
					Label: "6 MWD",
					Type: "float",
					Comment: "Six_Minute_Walk_Test-6MWD(4784131); "
				}, {
					Name: "AB",
					Label: "AB",
					Type: "float",
					Comment: "Blood_gases-AB(851997); "
				}, {
					Name: "Alv. Ventilation",
					Label: "Alv. Ventilation",
					Type: "float",
					Comment: "Diffusion_Rebreathing_Diff_RB-VA' rb(452263952); "
				}, {
					Name: "BB",
					Label: "BB",
					Type: "float",
					Comment: "Blood_gases-BB(852000); "
				}, {
					Name: "BE",
					Label: "BE",
					Type: "float",
					Comment: "Blood_gases-BE(851999); "
				}, {
					Name: "BF",
					Label: "BF",
					Type: "float",
					Comment: "Spirometry-BF(65542); Spirometry-BF_F(65665); Compliance-BF(393224); R_Occlusion-BF(458756); IOS-BF(1179725); Baby_Body-RR-FRC(1310725); Body_Slow_Spirometry-BF(19726342); Body_Slow_Spirometry-BF_F(19726465); CPET_BxB-BF(78708789); MIPMEP_MIP-BF_MIP(426049546); MIPMEP_MEP-BF_MEP(426115082); P01_Rest-BF_P0.1(432603137); FRC_Rebreathing_FRC_RB-BF(445710352); FRC_Rebreathing_FRC_RB-BFrb(445710368); Diffusion_Rebreathing_Diff_RB-BF(452263978); "
				}, {
					Name: "Breathing reserve (%)",
					Label: "Breathing reserve (%)",
					Type: "float",
					Comment: "CPET_BxB-BR FEV%(78708804); "
				}, {
					Name: "Breathing reserve (L)",
					Label: "Breathing reserve (L)",
					Type: "float",
					Comment: "CPET_BxB-BR FEV(78708858); "
				}, {
					Name: "C dyn / FRC",
					Label: "C dyn / FRC",
					Type: "float",
					Comment: "Compliance-Cd/FRC(393220); "
				}, {
					Name: "C dyn / TLC",
					Label: "C dyn / TLC",
					Type: "float",
					Comment: "Compliance-Cd/TLC(393219); "
				}, {
					Name: "C stat / FRC",
					Label: "C stat / FRC",
					Type: "float",
					Comment: "Compliance-Cs/FRC(393231); "
				}, {
					Name: "C stat / TLC",
					Label: "C stat / TLC",
					Type: "float",
					Comment: "Compliance-Cs/TLC(393230); "
				}, {
					Name: "CaCO2",
					Label: "CaCO2",
					Type: "float",
					Comment: null
				}, {
					Name: "CaO2",
					Label: "CaO2",
					Type: "float",
					Comment: "Blood_gases-CaO2(851993); "
				}, {
					Name: "Cardiac index",
					Label: "Cardiac index",
					Type: "float",
					Comment: "Blood_gases-CI_dF(852039); CPET_BxB-CI_c(78708884); CPET_Cardiac_Output-CI(78839831); "
				}, {
					Name: "Cardiac output",
					Label: "Cardiac output",
					Type: "float",
					Comment: "Blood_gases-Qt-Fic(852013); CPET_BxB-Qtc(78708830); CPET_Cardiac_Output-Qt(78839822); "
				}, {
					Name: "CHO %",
					Label: "CHO %",
					Type: "float",
					Comment: "CPET_BxB-CHO E%(78708872); CPET_REE-CHO %(79429648); "
				}, {
					Name: "CHO (g/day)",
					Label: "CHO (g/day)",
					Type: "float",
					Comment: "CPET_BxB-CHO g/d(78708822); CPET_REE-CHO g(79429646); "
				}, {
					Name: "Closing capacity",
					Label: "Closing capacity",
					Type: "float",
					Comment: "Body_Body-Cl.cap(19857482); "
				}, {
					Name: "Closing volume",
					Label: "Closing volume",
					Type: "float",
					Comment: "Body_Body-Cl.vol(19857470); "
				}, {
					Name: "CO05Hz",
					Label: "CO05Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO05Hz(118030377); IOS_Tidal_breathing_In_param-COin05(118095913); IOS_Tidal_breathing_Ex_param-COex05(118161449); "
				}, {
					Name: "CO10Hz",
					Label: "CO10Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO10Hz(118030382); IOS_Tidal_breathing_In_param-COin10(118095918); IOS_Tidal_breathing_Ex_param-COex10(118161454); "
				}, {
					Name: "CO15Hz",
					Label: "CO15Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO15Hz(118030383); IOS_Tidal_breathing_In_param-COin15(118095919); IOS_Tidal_breathing_Ex_param-COex15(118161455); "
				}, {
					Name: "CO1Hz",
					Label: "CO1Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO1Hz(118030378); IOS_Tidal_breathing_In_param-COin1(118095914); IOS_Tidal_breathing_Ex_param-COex1(118161450); "
				}, {
					Name: "CO20Hz",
					Label: "CO20Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO20Hz(118030384); IOS_Tidal_breathing_In_param-COin20(118095920); IOS_Tidal_breathing_Ex_param-COex20(118161456); "
				}, {
					Name: "CO25Hz",
					Label: "CO25Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO25Hz(118030385); IOS_Tidal_breathing_In_param-COin25(118095921); IOS_Tidal_breathing_Ex_param-COex25(118161457); "
				}, {
					Name: "CO2Hz",
					Label: "CO2Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO2Hz(118030379); IOS_Tidal_breathing_In_param-COin2(118095915); IOS_Tidal_breathing_Ex_param-COex2(118161451); "
				}, {
					Name: "CO35Hz",
					Label: "CO35Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO35Hz(118030386); IOS_Tidal_breathing_In_param-COin35(118095922); IOS_Tidal_breathing_Ex_param-COex35(118161458); "
				}, {
					Name: "CO3Hz",
					Label: "CO3Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO3Hz(118030380); IOS_Tidal_breathing_In_param-COin3(118095916); IOS_Tidal_breathing_Ex_param-COex3(118161452); "
				}, {
					Name: "CO5Hz",
					Label: "CO5Hz",
					Type: "float",
					Comment: "IOS_Tidal_breathing_mean_v-CO5Hz(118030381); IOS_Tidal_breathing_In_param-COin5(118095917); IOS_Tidal_breathing_Ex_param-COex5(118161453); "
				}, {
					Name: "Compliance dyn.",
					Label: "Compliance dyn.",
					Type: "float",
					Comment: "Compliance-C dyn(393217); "
				}, {
					Name: "Compliance stat.",
					Label: "Compliance stat.",
					Type: "float",
					Comment: "Compliance-C stat(393228); "
				}, {
					Name: "DLCO_IB",
					Label: "DLCO_IB",
					Type: "float",
					Comment: "Diffusion_SB_Intrabreath-DLCOIB(3866625); "
				}, {
					Name: "DLCO_IB / VA",
					Label: "DLCO_IB / VA",
					Type: "float",
					Comment: "Diffusion_SB_Intrabreath-KCO IB(3866626); "
				}, {
					Name: "DLCO_RB",
					Label: "DLCO_RB",
					Type: "float",
					Comment: "Diffusion_Rebreathing_Diff_RB-DLCO rb(452263953); "
				}, {
					Name: "DLCO_RB / VA",
					Label: "DLCO_RB / VA",
					Type: "float",
					Comment: "Diffusion_Rebreathing_Diff_RB-KCO rb(452263954); "
				}, {
					Name: "DLCO_SB",
					Label: "DLCO_SB",
					Type: "float",
					Comment: "Diffusion_SB-DLCO_SB(262148); Diffusion_SB-DLCO StdPB(262233); NO_Diffusion_Membrane-DLCOSB(4128777); "
				}, {
					Name: "DLCO_SB / BSA",
					Label: "DLCO_SB / BSA",
					Type: "float",
					Comment: "Diffusion_SB-DLCO/BSA(262157); NO_Diffusion_Membrane-DCOBSA(4128781); "
				}, {
					Name: "DLCO_SB / VA",
					Label: "DLCO_SB / VA",
					Type: "float",
					Comment: "Diffusion_SB-KCO_SB(262150); NO_Diffusion_Membrane-KCO(4128779); "
				}, {
					Name: "DLCOc_IB",
					Label: "DLCOc_IB",
					Type: "float",
					Comment: "Diffusion_SB_Intrabreath-DLCOIc(3866627); "
				}, {
					Name: "DLCOc_IB / VA",
					Label: "DLCOc_IB / VA",
					Type: "float",
					Comment: "Diffusion_SB_Intrabreath-KCOIBc(3866628); "
				}, {
					Name: "DLCOc_SB",
					Label: "DLCOc_SB",
					Type: "float",
					Comment: "Diffusion_SB-DLCOcSB(262162); NO_Diffusion_Membrane-DLCOcSB(4128778); "
				}, {
					Name: "DLCOc_SB / BSA",
					Label: "DLCOc_SB / BSA",
					Type: "float",
					Comment: "Diffusion_SB-DLCOc/BSA(262158); NO_Diffusion_Membrane-DCOcBSA(4128782); "
				}, {
					Name: "DLCOc_SB / VA",
					Label: "DLCOc_SB / VA",
					Type: "float",
					Comment: "Diffusion_SB-KCOc_SB(262163); NO_Diffusion_Membrane-KCOc(4128780); "
				}, {
					Name: "Dm (Diff mem)",
					Label: "Dm (Diff mem)",
					Type: "float",
					Comment: "NO_Diffusion_Membrane-Dm(4128769); "
				}, {
					Name: "EE (kcal/day)",
					Label: "EE (kcal/day)",
					Type: "float",
					Comment: "CPET_BxB-EE/d(78708810); CPET_REE-EE(79429644); "
				}, {
					Name: "EE / BSA",
					Label: "EE / BSA",
					Type: "float",
					Comment: "CPET_BxB-EE/BSA(78708879); CPET_REE-EE/BSA(79429659); "
				}, {
					Name: "EE / CO2 ",
					Label: "EE / CO2 ",
					Type: "float",
					Comment: "CPET_BxB-EE/CO2(78708878); CPET_REE-EE/CO2(79429658); "
				}, {
					Name: "EE / kg",
					Label: "EE / kg",
					Type: "float",
					Comment: "CPET_BxB-EE/kg(78708870); CPET_REE-EE/kg(79429645); "
				}, {
					Name: "Elastance dyn.",
					Label: "Elastance dyn.",
					Type: "float",
					Comment: "Compliance-E dyn(393218); "
				}, {
					Name: "Elastance stat.",
					Label: "Elastance stat.",
					Type: "float",
					Comment: "Compliance-E stat(393229); "
				}, {
					Name: "EQCO2 (VE/VCO2)",
					Label: "EQCO2 (VE/VCO2)",
					Type: "float",
					Comment: "CPET_BxB-EqCO2(78708796); "
				}, {
					Name: "EQO2 (VE/VO2)",
					Label: "EQO2 (VE/VO2)",
					Type: "float",
					Comment: "CPET_BxB-EqO2(78708797); "
				}, {
					Name: "ERV",
					Label: "ERV",
					Type: "float",
					Comment: "Spirometry-ERV(65539); Spirometry-ERV_F(65667); Diffusion_SB-ERV_SB(262161); IOS-ERV(1179727); Diffusion_SB_Intrabreath-ERV-IB(3866641); N2_Washout-ERV_N2(4063235); NO_Diffusion_Membrane-ERV-SB(4128803); Body_Slow_Spirometry-ERV(19726339); Body_Slow_Spirometry-ERV_F(19726467); Body_Body-ERV(19857434); MIPMEP_MIP-ERV_MIP(426049547); MIPMEP_MEP-ERV_MEP(426115083); FRC_Rebreathing_FRC_RB-ERV(445710349); Diffusion_Rebreathing_Diff_RB-ERV(452263975); "
				}, {
					Name: "Exspir. F/V area",
					Label: "Exspir. F/V area",
					Type: "float",
					Comment: "Spirometry-AEX(65585); Body_Slow_Spirometry-AEX(19726385); Body_Transmural_F_V-AEX(19791895); "
				}, {
					Name: "F resonance",
					Label: "F resonance",
					Type: "float",
					Comment: "IOS-Fres.(1179653); "
				}, {
					Name: "FAT %",
					Label: "FAT %",
					Type: "float",
					Comment: "CPET_BxB-FAT E%(78708874); CPET_REE-FAT %(79429651); "
				}, {
					Name: "FAT / g/day",
					Label: "FAT / g/day",
					Type: "float",
					Comment: "CPET_BxB-FAT g/d(78708823); CPET_REE-FAT g(79429649); "
				}, {
					Name: "FEF 200-1200",
					Label: "FEF 200-1200",
					Type: "float",
					Comment: "Spirometry-PEF*(65590); Body_Slow_Spirometry-PEF*(19726390); Body_Transmural_F_V-PEF*(19791907); "
				}, {
					Name: "FEF 25",
					Label: "FEF 25",
					Type: "float",
					Comment: "Spirometry-FEF25(65638); Body_Slow_Spirometry-FEF 25(19726438); "
				}, {
					Name: "FEF 40",
					Label: "FEF 40",
					Type: "float",
					Comment: "Spirometry-FEF40(65645); Body_Slow_Spirometry-FEF40(19726445); "
				}, {
					Name: "FEF 50",
					Label: "FEF 50",
					Type: "float",
					Comment: "Spirometry-FEF50(65637); Body_Slow_Spirometry-FEF 50(19726437); "
				}, {
					Name: "FEF 50 % FIF 50",
					Label: "FEF 50 % FIF 50",
					Type: "float",
					Comment: "Spirometry-FE%FIF(65597); Body_Slow_Spirometry-FE%FIF(19726397); Body_Transmural_F_V-FE%FIF(19791908); "
				}, {
					Name: "FEF 60",
					Label: "FEF 60",
					Type: "float",
					Comment: "Spirometry-FEF60(65646); Body_Slow_Spirometry-FEF60(19726446); "
				}, {
					Name: "FEF 75",
					Label: "FEF 75",
					Type: "float",
					Comment: "Spirometry-FEF75(65636); Body_Slow_Spirometry-FEF 75(19726436); "
				}, {
					Name: "FEF 75/85",
					Label: "FEF 75/85",
					Type: "float",
					Comment: "Spirometry-75/85(65582); Body_Slow_Spirometry-75-85(19726382); Body_Transmural_F_V-75-85(19791886); "
				}, {
					Name: "FEF 80",
					Label: "FEF 80",
					Type: "float",
					Comment: "Spirometry-FEF80(65647); Body_Slow_Spirometry-FEF80(19726447); "
				}, {
					Name: "FEV 0.5",
					Label: "FEV 0.5",
					Type: "float",
					Comment: "Spirometry-FEV.5(65546); Body_Slow_Spirometry-FEV.5(19726346); Body_Transmural_F_V-FEV .5(19791873); "
				}, {
					Name: "FEV 0.75",
					Label: "FEV 0.75",
					Type: "float",
					Comment: "Spirometry-FEV.75(65624); Body_Slow_Spirometry-FEV.75(19726424); "
				}, {
					Name: "FEV 0.75 % FVC",
					Label: "FEV 0.75 % FVC",
					Type: "float",
					Comment: "Spirometry-F75FVC(65661); Body_Slow_Spirometry-F75FVC(19726461); "
				}, {
					Name: "FEV 0.75 % VC max",
					Label: "FEV 0.75 % VC max",
					Type: "float",
					Comment: "Spirometry-F75VCM(65662); Body_Slow_Spirometry-F75VCM(19726462); "
				}, {
					Name: "FEV 1",
					Label: "FEV 1",
					Type: "float",
					Comment: "Spirometry-FEV1(65547); Body_Slow_Spirometry-FEV 1(19726347); Body_Transmural_F_V-FEV 1(19791874); Body_Body-FEV1(19857439); "
				}, {
					Name: "FEV 1 % FEV 6",
					Label: "FEV 1 % FEV 6",
					Type: "float",
					Comment: "Spirometry-FEV1%6(65626); Body_Slow_Spirometry-FEV1%6(19726426); "
				}, {
					Name: "FEV 1 % FVC",
					Label: "FEV 1 % FVC",
					Type: "float",
					Comment: "Spirometry-FEV1%F(65551); Spirometry-FEV1%I(65677); Body_Slow_Spirometry-FEV1%F(19726351); Body_Slow_Spirometry-FEV1%VCin_F(19726477); Body_Transmural_F_V-FEV1%F(19791887); "
				}, {
					Name: "FEV 1 % VC",
					Label: "FEV 1 % VC",
					Type: "float",
					Comment: "Spirometry-FEV1%I(65550); Spirometry-FEV1%E(65568); Body_Slow_Spirometry-FEV1%I(19726350); Body_Slow_Spirometry-FEV1%E(19726368); Body_Transmural_F_V-FEV1%I(19791884); "
				}, {
					Name: "FEV 1 % VC max",
					Label: "FEV 1 % VC max",
					Type: "float",
					Comment: "Spirometry-FEV1%M(65552); Body_Slow_Spirometry-FEV1%M(19726352); Body_Transmural_F_V-FEV1%M(19791888); "
				}, {
					Name: "FEV 1 / VC max % pred",
					Label: "FEV 1 / VC max % pred",
					Type: "float",
					Comment: "Body_Slow_Spirometry-FEV1Mp(19726403); "
				}, {
					Name: "FEV 2",
					Label: "FEV 2",
					Type: "float",
					Comment: "Spirometry-FEV2(65548); Body_Slow_Spirometry-FEV 2(19726348); Body_Transmural_F_V-FEV 2(19791875); "
				}, {
					Name: "FEV 3",
					Label: "FEV 3",
					Type: "float",
					Comment: "Spirometry-FEV3(65549); Body_Slow_Spirometry-FEV 3(19726349); Body_Transmural_F_V-FEV 3(19791876); "
				}, {
					Name: "FEV 3 % FVC",
					Label: "FEV 3 % FVC",
					Type: "float",
					Comment: "Spirometry-FEV3%E(65589); Body_Slow_Spirometry-FEV3%E(19726389); Body_Transmural_F_V-FEV3%E(19791906); "
				}, {
					Name: "FEV 6",
					Label: "FEV 6",
					Type: "float",
					Comment: "Spirometry-FEV6(65625); Body_Slow_Spirometry-FEV6(19726425); "
				}, {
					Name: "FIF 25",
					Label: "FIF 25",
					Type: "float",
					Comment: "Spirometry-FIF25(65650); Body_Slow_Spirometry-FIF25(19726450); "
				}, {
					Name: "FIF 50",
					Label: "FIF 50",
					Type: "float",
					Comment: "Spirometry-FIF50(65593); Body_Slow_Spirometry-FIF 50(19726393); Body_Transmural_F_V-FIF 50(19791900); "
				}, {
					Name: "FIF 75",
					Label: "FIF 75",
					Type: "float",
					Comment: "Spirometry-FIF75(65651); Body_Slow_Spirometry-FIF75(19726451); "
				}, {
					Name: "FIV 1",
					Label: "FIV 1",
					Type: "float",
					Comment: "Spirometry-FIV1(65594); Body_Slow_Spirometry-FIV1(19726394); Body_Transmural_F_V-FIV 1(19791898); "
				}, {
					Name: "FIV 1 % FVC",
					Label: "FIV 1 % FVC",
					Type: "float",
					Comment: "Spirometry-FIV1%F(65595); Body_Slow_Spirometry-FIV1%F(19726395); "
				}, {
					Name: "FIV 1 % VC",
					Label: "FIV 1 % VC",
					Type: "float",
					Comment: "Spirometry-FIV1%M(65596); Body_Slow_Spirometry-FIV1%M(19726396); Body_Transmural_F_V-FIV1%I(19791901); "
				}, {
					Name: "Flow ex 150 (Rhino)",
					Label: "Flow ex 150 (Rhino)",
					Type: "float",
					Comment: "Rhinomanometry_Left-FEL150(163905543); Rhinomanometry_Right-FER150(163971079); "
				}, {
					Name: "Flow in 150 (Rhino)",
					Label: "Flow in 150 (Rhino)",
					Type: "float",
					Comment: "Rhinomanometry_Left-FIL150(163905541); Rhinomanometry_Right-FIR150(163971077); "
				}, {
					Name: "Flow SUM Ex",
					Label: "Flow SUM Ex",
					Type: "float",
					Comment: "Rhinomanometry-FSUME(1638402); "
				}, {
					Name: "Flow SUM In",
					Label: "Flow SUM In",
					Type: "float",
					Comment: "Rhinomanometry-FSUMI(1638401); "
				}, {
					Name: "FRC_Dil",
					Label: "FRC_Dil",
					Type: "float",
					Comment: "Diffusion_SB-FRC_SB(262159); Diffusion_SB_Intrabreath-FRC-IB(3866637); NO_Diffusion_Membrane-FRC-SB(4128797); FRC_Rebreathing_FRC_RB-FRC-He(445710343); Diffusion_Rebreathing_Diff_RB-FRC-He(452263969); "
				}, {
					Name: "FRC_Dil % TLC_Dil",
					Label: "FRC_Dil % TLC_Dil",
					Type: "float",
					Comment: "Diffusion_SB-FRC%TLC(262160); Diffusion_SB_Intrabreath-FRC%TLCIB(3866675); N2_Washout-FRC_N2/TLC_N2(4063238); NO_Diffusion_Membrane-FRC%TLC-SB(4128802); FRC_Rebreathing_FRC_RB-FRC%TLC(445710357); Diffusion_Rebreathing_Diff_RB-FRC %(452263983); "
				}, {
					Name: "FRC_Pleth",
					Label: "FRC_Pleth",
					Type: "float",
					Comment: "Compliance-FRC(393251); Baby_Body-FRCp(1310732); N2_Washout-FRC(4063259); Body_Body-FRCpl(19857427); Body_Body-FRCpl'(19857453); MIPMEP_MIP-FRC_MIP(426049550); MIPMEP_MEP-FRC_MEP(426115086); "
				}, {
					Name: "FRC_Pleth % TLC_Pleth",
					Label: "FRC_Pleth % TLC_Pleth",
					Type: "float",
					Comment: "Body_Body-FRCpl%(19857431); "
				}, {
					Name: "FVC",
					Label: "FVC",
					Type: "float",
					Comment: "Spirometry-FVC(65567); Spirometry-VCIN_F(65674); Spirometry-VCEX_F(65675); Body_Slow_Spirometry-FVC(19726367); Body_Slow_Spirometry-VCin_F(19726474); Body_Slow_Spirometry-VCex_F(19726475); Body_Transmural_F_V-FVC(19791883); "
				}, {
					Name: "FVC in",
					Label: "FVC in",
					Type: "float",
					Comment: "Spirometry-FVC IN(65591); Body_Slow_Spirometry-FVC IN(19726391); Body_Transmural_F_V-FVC IN(19791899); "
				}, {
					Name: "G 0.5",
					Label: "G 0.5",
					Type: "float",
					Comment: "Body_Body-G0.5IN(19857443); Body_Body-G 0.5(19857502); "
				}, {
					Name: "G eff",
					Label: "G eff",
					Type: "float",
					Comment: "Baby_Body-Geff(1310742); Body_Body-G eff(19857424); "
				}, {
					Name: "G mid",
					Label: "G mid",
					Type: "float",
					Comment: "Body_Body-G mid(19857473); "
				}, {
					Name: "G peak",
					Label: "G peak",
					Type: "float",
					Comment: "Baby_Body-GPeak(1310754); Body_Body-Gpeak(19857450); "
				}, {
					Name: "G tot",
					Label: "G tot",
					Type: "float",
					Comment: "Baby_Body-Gtot(1310750); Body_Body-G tot(19857423); "
				}, {
					Name: "Gaw",
					Label: "Gaw",
					Type: "float",
					Comment: "Body_Body-Gaw(19857536); "
				}, {
					Name: "Hb",
					Label: "Hb",
					Type: "float",
					Comment: "Diffusion_SB-Hb(262165); Blood_gases-Hb(851982); Diffusion_SB_Intrabreath-Hb(3866633); NO_Diffusion_Membrane-Hb(4128795); "
				}, {
					Name: "Hc",
					Label: "Hc",
					Type: "float",
					Comment: "Blood_gases-Hct(851983); "
				}, {
					Name: "HR",
					Label: "HR",
					Type: "float",
					Comment: "Blood_gases-HR(852042); CPET_BxB-HR(78708753); "
				}, {
					Name: "HR - response (VO2)",
					Label: "HR - response (VO2)",
					Type: "float",
					Comment: "CPET_BxB-HRresp(78708826); CPET_Slope-HR/VO2s(79626254); "
				}, {
					Name: "HR Reserve (%)",
					Label: "HR Reserve (%)",
					Type: "float",
					Comment: "CPET_BxB-HRR%(78708828); "
				}, {
					Name: "HR Reserve (1/min)",
					Label: "HR Reserve (1/min)",
					Type: "float",
					Comment: "CPET_BxB-HRR (B)(78708813); "
				}, {
					Name: "HR slope (VO2/kg)",
					Label: "HR slope (VO2/kg)",
					Type: "float",
					Comment: "CPET_BxB-HRslkg(78708816); CPET_Slope-HRO2Ws(79626246); "
				}, {
					Name: "IC",
					Label: "IC",
					Type: "float",
					Comment: "Spirometry-IC(65538); Spirometry-IC_F(65668); Diffusion_SB-IC_SB(262204); Compliance-IC(393238); IOS-IC(1179726); Diffusion_SB_Intrabreath-IC-IB(3866640); N2_Washout-IC_N2(4063241); NO_Diffusion_Membrane-IC-SB(4128804); Body_Slow_Spirometry-IC(19726338); Body_Slow_Spirometry-IC_F(19726468); Body_Body-IC(19857435); MIPMEP_MIP-IC_MIP(426049549); MIPMEP_MEP-IC_MEP(426115085); FRC_Rebreathing_FRC_RB-IC(445710348); Diffusion_Rebreathing_Diff_RB-IC(452263974); "
				}, {
					Name: "Lactate",
					Label: "Lactate",
					Type: "float",
					Comment: "Blood_gases-Lac(851986); "
				}, {
					Name: "Load",
					Label: "Load",
					Type: "float",
					Comment: "Blood_gases-Load(851976); CPET_BxB-Load(78708747); CPET_BxB-Load_i(78708886); CPET_Slope-Load(79626251); "
				}, {
					Name: "Mean transit time",
					Label: "Mean transit time",
					Type: "float",
					Comment: "Spirometry-MTT(65584); Body_Slow_Spirometry-MTT(19726384); Body_Transmural_F_V-MTT(19791891); "
				}, {
					Name: "MEF 25",
					Label: "MEF 25",
					Type: "float",
					Comment: "Spirometry-MEF25(65572); Body_Slow_Spirometry-MEF 25(19726372); Body_Transmural_F_V-MEF 25(19791879); "
				}, {
					Name: "MEF 50",
					Label: "MEF 50",
					Type: "float",
					Comment: "Spirometry-MEF50(65571); Body_Slow_Spirometry-MEF 50(19726371); Body_Transmural_F_V-MEF 50(19791878); "
				}, {
					Name: "MEF 50 % FVC",
					Label: "MEF 50 % FVC",
					Type: "float",
					Comment: "Spirometry-MEF50%(65580); Body_Slow_Spirometry-MEF50%(19726380); Body_Transmural_F_V-MEF50%(19791881); "
				}, {
					Name: "MEF 75",
					Label: "MEF 75",
					Type: "float",
					Comment: "Spirometry-MEF75(65570); Body_Slow_Spirometry-MEF 75(19726370); Body_Transmural_F_V-MEF 75(19791877); "
				}, {
					Name: "MEP",
					Label: "MEP",
					Type: "float",
					Comment: "MIPMEP_MEP-MEP(426115073); "
				}, {
					Name: "MEP avg",
					Label: "MEP avg",
					Type: "float",
					Comment: "MIPMEP_MEP-MEP Ave(426115075); "
				}, {
					Name: "MEP peak",
					Label: "MEP peak",
					Type: "float",
					Comment: "MIPMEP_MEP-MEP Peak(426115074); "
				}, {
					Name: "MEP sus",
					Label: "MEP sus",
					Type: "float",
					Comment: "MIPMEP_MEP-MEP Sus(426115076); "
				}, {
					Name: "MFEF 75/25",
					Label: "MFEF 75/25",
					Type: "float",
					Comment: "Spirometry-MFEF(65640); Body_Slow_Spirometry-MFEF(19726440); "
				}, {
					Name: "MFEF 75/85",
					Label: "MFEF 75/85",
					Type: "float",
					Comment:  "Spirometry-F75/85(65641); "
				}, {
					Name: "MIF",
					Label: "MIF",
					Type: "float",
					Comment: "P01_Rest-MIF_P0.1(432603139); "
				}, {
					Name: "MIP",
					Label: "MIP",
					Type: "float",
					Comment: "Baby_Body-PImax(1310737); MIPMEP_MIP-MIP(426049537); P01_Rest-MIP(432603140); P01_Max-MIP(432668676); P01_CO2_1-MIP(432734212); P01_CO2_2-MIP(432799748); "
				}, {
					Name: "MIP avg",
					Label: "MIP avg",
					Type: "float",
					Comment: "MIPMEP_MIP-MIP Ave(426049539); "
				}, {
					Name: "MIP peak",
					Label: "MIP peak",
					Type: "float",
					Comment: "MIPMEP_MIP-MIP Peak(426049538); "
				}, {
					Name: "MIP sus",
					Label: "MIP sus",
					Type: "float",
					Comment: "MIPMEP_MIP-MIP Sus(426049540); "
				}, {
					Name: "MMEF 75/25",
					Label: "MMEF 75/25",
					Type: "float",
					Comment: "Spirometry-MMEF(65581); Body_Slow_Spirometry-MMEF(19726381); Body_Transmural_F_V-MMEF(19791885); "
				}, {
					Name: "MV, VE",
					Label: "MV, VE",
					Type: "float",
					Comment: "Spirometry-MV(65541); Spirometry-MV_F(65666); Diffusion_SB-MV_SB(262202); R_Occlusion-MV(458758); Baby_Body-MV-FRC(1310726); Diffusion_SB_Intrabreath-MV-IB(3866671); Body_Slow_Spirometry-MV(19726341); Body_Slow_Spirometry-MV_F(19726466); CPET_BxB-V'E(78708790); MIPMEP_MIP-MV_MIP(426049545); MIPMEP_MEP-MV_MEP(426115081); P01_Rest-MV_P0.1(432603141); FRC_Rebreathing_FRC_RB-MV(445710351); FRC_Rebreathing_FRC_RB-MVrb(445710369); Diffusion_Rebreathing_Diff_RB-MV(452263977); "
				}, {
					Name: "MVV",
					Label: "MVV",
					Type: "float",
					Comment: "Spirometry-MVV(65553); Spirometry-FEV*30(65557); Body_Slow_Spirometry-MVV(19726353); Body_Slow_Spirometry-FEV*30(19726357); Body_Slow_Spirometry-MVV_6(19726452); Body_Transmural_F_V-MVV-Th(19791889); "
				}, {
					Name: "O2 pulse",
					Label: "O2 pulse",
					Type: "float",
					Comment: "CPET_BxB-O2pulse(78708801); "
				}, {
					Name: "OUES-Slope",
					Label: "OUES-Slope",
					Type: "float",
					Comment: "CPET_Slope-OUESs(79626250); "
				}, {
					Name: "P 0.1",
					Label: "P 0.1",
					Type: "float",
					Comment: "Baby_Body-P100(1310736); P01_Rest-P0.1(432603145); "
				}, {
					Name: "P 0.1 / MIF",
					Label: "P 0.1 / MIF",
					Type: "float",
					Comment: "P01_Rest-P0.1/MIF(432603142); "
				}, {
					Name: "P 0.1 / MIP",
					Label: "P 0.1 / MIP",
					Type: "float",
					Comment: "P01_Rest-P0.1/MIP(432603144); "
				}, {
					Name: "P 0.1 / MV",
					Label: "P 0.1 / MV",
					Type: "float",
					Comment: "P01_Rest-P0.1/MV(432603143); "
				}, {
					Name: "P 0.1 / P 0.1 max",
					Label: "P 0.1 / P 0.1 max",
					Type: "float",
					Comment: "P01_Max-P0.1/P0.1Max(432668679); "
				}, {
					Name: "P 0.1 max",
					Label: "P 0.1 max",
					Type: "float",
					Comment: "P01_Max-P0.1Max(432668678); "
				}, {
					Name: "P 0.1 max / MV",
					Label: "P 0.1 max / MV",
					Type: "float",
					Comment: "P01_Max-P0.1Max/MV(432668681); "
				}, {
					Name: "P diastolic",
					Label: "P diastolic",
					Type: "float",
					Comment: "CPET_BxB-Pdia(78708763); "
				}, {
					Name: "P el 100 % TLC",
					Label: "P el 100 % TLC",
					Type: "float",
					Comment: "Compliance-Pel100(393282); "
				}, {
					Name: "P el 50 % / TLC",
					Label: "P el 50 % / TLC",
					Type: "float",
					Comment: "Compliance-P el50(393236); "
				}, {
					Name: "P el 60 % / TLC",
					Label: "P el 60 % / TLC",
					Type: "float",
					Comment: "Compliance-P el60(393240); "
				}, {
					Name: "P el 70 % / TLC",
					Label: "P el 70 % / TLC",
					Type: "float",
					Comment: "Compliance-P el70(393241); "
				}, {
					Name: "P el 80 % / TLC",
					Label: "P el 80 % / TLC",
					Type: "float",
					Comment: "Compliance-P el80(393242); "
				}, {
					Name: "P el 90 % TLC",
					Label: "P el 90 % TLC",
					Type: "float",
					Comment: "Compliance-P el90(393243); "
				}, {
					Name: "P el TLC",
					Label: "P el TLC",
					Type: "float",
					Comment: "Compliance-PeTLC(393233); "
				}, {
					Name: "P mean",
					Label: "P mean",
					Type: "float",
					Comment: "CPET_BxB-Pmean(78708868); "
				}, {
					Name: "P systolic",
					Label: "P systolic",
					Type: "float",
					Comment: "CPET_BxB-Psys(78708762); "
				}, {
					Name: "P(A-a)O2",
					Label: "P(A-a)O2",
					Type: "float",
					Comment: "Blood_gases-AaDO2(851991); "
				}, {
					Name: "P(a-ET)CO2",
					Label: "P(a-ET)CO2",
					Type: "float",
					Comment: "Blood_gases-PaETCO2(852014); "
				}, {
					Name: "PaCO2",
					Label: "PaCO2",
					Type: "float",
					Comment: "Blood_gases-PaCO2(851989); "
				}, {
					Name: "PAlvO2",
					Label: "PAlvO2",
					Type: "float",
					Comment: "Blood_gases-PAO2(851990); "
				}, {
					Name: "PaO2",
					Label: "PaO2",
					Type: "float",
					Comment: "Blood_gases-PaO2(851987); Blood_gases-PaO2st(851992); "
				}, {
					Name: "PEF",
					Label: "PEF",
					Type: "float",
					Comment: "Spirometry-PEF(65573); Body_Slow_Spirometry-PEF(19726373); Body_Transmural_F_V-PEF(19791880); Body_Body-PEF(19857438); "
				}, {
					Name: "PETCO2",
					Label: "PETCO2",
					Type: "float",
					Comment: "Blood_gases-PETCO2(852033); CPET_BxB-PETCO2(78708793); "
				}, {
					Name: "PETO2",
					Label: "PETO2",
					Type: "float",
					Comment: "Blood_gases-PETO2(852034); CPET_BxB-PETO2(78708795); "
				}, {
					Name: "pH a",
					Label: "pH a",
					Type: "float",
					Comment: "Blood_gases-pHa(851981); "
				}, {
					Name: "PIF",
					Label: "PIF",
					Type: "float",
					Comment: "Spirometry-PIF(65592); Body_Slow_Spirometry-PIF(19726392); Body_Transmural_F_V-PIF(19791897); "
				}, {
					Name: "PRO %",
					Label: "PRO %",
					Type: "float",
					Comment: "CPET_BxB-PRO E%(78708876); CPET_REE-PRO %(79429654); "
				}, {
					Name: "PRO / g/day",
					Label: "PRO / g/day",
					Type: "float",
					Comment: "CPET_BxB-PRO g/d(78708824); CPET_REE-PRO g(79429652); "
				}, {
					Name: "Pulmonary blood flow",
					Label: "Pulmonary blood flow",
					Type: "float",
					Comment: "Diffusion_SB_Intrabreath-Qt(3866634); "
				}, {
					Name: "R 0.5",
					Label: "R 0.5",
					Type: "float",
					Comment: "Body_Body-R0.5IN(19857409); Body_Body-R 0.5(19857501); Body_Body-R0.5EX(19857505); "
				}, {
					Name: "R at 10Hz",
					Label: "R at 10Hz",
					Type: "float",
					Comment: "IOS-R10Hz(1179661); IOS_Tidal_breathing_mean_v-R10Hz(118030342); IOS_Tidal_breathing_In_param-Rin10(118095878); IOS_Tidal_breathing_Ex_param-Rex10(118161414); "
				}, {
					Name: "R at 15Hz",
					Label: "R at 15Hz",
					Type: "float",
					Comment: "IOS-R15Hz(1179663); IOS_Tidal_breathing_mean_v-R15Hz(118030343); IOS_Tidal_breathing_In_param-Rin15(118095879); IOS_Tidal_breathing_Ex_param-Rex15(118161415); "
				}, {
					Name: "R at 20Hz",
					Label: "R at 20Hz",
					Type: "float",
					Comment: "IOS-R20Hz(1179665); IOS_Tidal_breathing_mean_v-R20Hz(118030344); IOS_Tidal_breathing_In_param-Rin20(118095880); IOS_Tidal_breathing_Ex_param-Rex20(118161416); "
				}, {
					Name: "R at 25Hz",
					Label: "R at 25Hz",
					Type: "float",
					Comment: "IOS-R25Hz(1179667); IOS_Tidal_breathing_mean_v-R25Hz(118030345); IOS_Tidal_breathing_In_param-Rin25(118095881); IOS_Tidal_breathing_Ex_param-Rex25(118161417); "
				}, {
					Name: "R at 35Hz",
					Label: "R at 35Hz",
					Type: "float",
					Comment: "IOS-R35Hz(1179669); IOS_Tidal_breathing_mean_v-R35Hz(118030346); IOS_Tidal_breathing_In_param-Rin35(118095882); IOS_Tidal_breathing_Ex_param-Rex35(118161418); "
				}, {
					Name: "R at 5Hz",
					Label: "R at 5Hz",
					Type: "float",
					Comment: "IOS-R5Hz(1179659); IOS_Tidal_breathing_mean_v-R5Hz(118030341); IOS_Tidal_breathing_In_param-Rin5(118095877); IOS_Tidal_breathing_Ex_param-Rex5(118161413); "
				}, {
					Name: "R eff",
					Label: "R eff",
					Type: "float",
					Comment: "Baby_Body-Reff(1310741); Baby_Body-Rineff(1310744); Baby_Body-Rexeff(1310746); Body_Body-R eff(19857411); Body_Body-RI eff(19857414); Body_Body-RE eff(19857415); "
				}, {
					Name: "R ex 150 (Rhino)",
					Label: "R ex 150 (Rhino)",
					Type: "float",
					Comment: "Rhinomanometry_Left-REL150(163905544); Rhinomanometry_Right-RER150(163971080); "
				}, {
					Name: "R in 150 (Rhino)",
					Label: "R in 150 (Rhino)",
					Type: "float",
					Comment: "Rhinomanometry_Left-RIL150(163905542); Rhinomanometry_Right-RIR150(163971078); "
				}, {
					Name: "R IOS_central",
					Label: "R IOS_central",
					Type: "float",
					Comment: "IOS-Rc(1179651); "
				}, {
					Name: "R IOS_peripheral",
					Label: "R IOS_peripheral",
					Type: "float",
					Comment: "IOS-Rp(1179652); "
				}, {
					Name: "R mid",
					Label: "R mid",
					Type: "float",
					Comment: "Body_Body-R mid(19857471); Body_Body-RmidIN(19857476); Body_Body-RmidEX(19857478); "
				}, {
					Name: "R peak",
					Label: "R peak",
					Type: "float",
					Comment: "Baby_Body-RPeak(1310753); Body_Body-R PEAK(19857416); "
				}, {
					Name: "R tot",
					Label: "R tot",
					Type: "float",
					Comment: "Baby_Body-Rtot(1310749); Baby_Body-Rin(1310756); Baby_Body-Rex(1310758); Body_Body-R tot(19857410); Body_Body-R IN(19857412); Body_Body-R EX(19857413); "
				}, {
					Name: "Raw",
					Label: "Raw",
					Type: "float",
					Comment: "Body_Body-Raw(19857534); "
				}, {
					Name: "Raw Compliance",
					Label: "Raw Compliance",
					Type: "float",
					Comment: "Compliance-R eff(393247); Compliance-RINeff(393248); Compliance-REXeff(393249); "
				}, {
					Name: "RER",
					Label: "RER",
					Type: "float",
					Comment: "Blood_gases-RER(851980); CPET_BxB-RER(78708791); "
				}, {
					Name: "Reserved (IF) Baby-Body",
					Label: "Reserved (IF) Baby-Body",
					Type: "float",
					Comment:  "Baby_Body-Res(1310727); "
				}, {
					Name: "Reserved_01",
					Label: "Reserved_01",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_02",
					Label: "Reserved_02",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_03",
					Label: "Reserved_03",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_04",
					Label: "Reserved_04",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_05",
					Label: "Reserved_05",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_06",
					Label: "Reserved_06",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_07",
					Label: "Reserved_07",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_08",
					Label: "Reserved_08",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_09",
					Label: "Reserved_09",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_10",
					Label: "Reserved_10",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_11",
					Label: "Reserved_11",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_12",
					Label: "Reserved_12",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_13",
					Label: "Reserved_13",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_14",
					Label: "Reserved_14",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_15",
					Label: "Reserved_15",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_16",
					Label: "Reserved_16",
					Type: "float",
					Comment: null
				}, {
					Name: "Rocc",
					Label: "Rocc",
					Type: "float",
					Comment: "R_Occlusion-R Occ(458753); "
				}, {
					Name: "Rrs DO (Baby R/C)",
					Label: "Rrs DO (Baby R/C)",
					Type: "float",
					Comment: "Baby_R_C-RrsDO(1376257); Baby_R_C-Rrs2*(1376260); Baby_R_C-RrsAv*(1376263); "
				}, {
					Name: "RV_Dil",
					Label: "RV_Dil",
					Type: "float",
					Comment: "Diffusion_SB-RV_SB(262145); Diffusion_SB_Intrabreath-RV-IB(3866636); NO_Diffusion_Membrane-RV-SB(4128798); FRC_Rebreathing_FRC_RB-RV(445710354); Diffusion_Rebreathing_Diff_RB-RV(452263980); "
				}, {
					Name: "RV_Dil % TLC_Dil",
					Label: "RV_Dil % TLC_Dil",
					Type: "float",
					Comment: "Diffusion_SB-RV%TLC_SB(262146); Diffusion_SB_Intrabreath-RV%TLCIB(3866674); N2_Washout-RV_N2/TLC_N2(4063247); NO_Diffusion_Membrane-RV%TLC-SB(4128801); FRC_Rebreathing_FRC_RB-RV%TLC(445710356); Diffusion_Rebreathing_Diff_RB-RV%TLC(452263982); "
				}, {
					Name: "RV_Pleth",
					Label: "RV_Pleth",
					Type: "float",
					Comment: "Diffusion_SB-RV_PL(262187); Diffusion_SB-RV_EX_SB(262226); Diffusion_SB_Intrabreath-RV-BY(3866677); NO_Diffusion_Membrane-RV Pl(4128848); Body_Body-RV(19857428); "
				}, {
					Name: "RV_Pleth % TLC_Pleth",
					Label: "RV_Pleth % TLC_Pleth",
					Type: "float",
					Comment: "Body_Body-RV%TLC(19857430); "
				}, {
					Name: "SaO2",
					Label: "SaO2",
					Type: "float",
					Comment: "Blood_gases-SaO2(851985); "
				}, {
					Name: "sG 0.5",
					Label: "sG 0.5",
					Type: "float",
					Comment: "Body_Body-sG05IN(19857444); Body_Body-sG 0.5(19857503); "
				}, {
					Name: "sG eff",
					Label: "sG eff",
					Type: "float",
					Comment: "Baby_Body-sGeff(1310740); Body_Body-sG eff(19857426); "
				}, {
					Name: "sG mid",
					Label: "sG mid",
					Type: "float",
					Comment: "Body_Body-sG mid(19857474); "
				}, {
					Name: "sG peak",
					Label: "sG peak",
					Type: "float",
					Comment: "Baby_Body-sGPeak(1310752); Body_Body-sGpeak(19857451); "
				}, {
					Name: "sG tot",
					Label: "sG tot",
					Type: "float",
					Comment: "Baby_Body-sGtot(1310748); Body_Body-sG tot(19857425); "
				}, {
					Name: "sGaw",
					Label: "sGaw",
					Type: "float",
					Comment: "Body_Body-sGaw(19857537); "
				}, {
					Name: "SNIP",
					Label: "SNIP",
					Type: "float",
					Comment: "SNIP_Sniff-SNIP(439156737); "
				}, {
					Name: "SNIP avg",
					Label: "SNIP avg",
					Type: "float",
					Comment: "SNIP_Sniff-SNIPAve(439156739); "
				}, {
					Name: "SNIP peak",
					Label: "SNIP peak",
					Type: "float",
					Comment: "SNIP_Sniff-SNIPPk(439156738); "
				}, {
					Name: "SNIP sus",
					Label: "SNIP sus",
					Type: "float",
					Comment: "SNIP_Sniff-SNIPSus(439156740); "
				}, {
					Name: "spec. W el",
					Label: "spec. W el",
					Type: "float",
					Comment: "Compliance-SW-el(393253); "
				}, {
					Name: "spec. W vis",
					Label: "spec. W vis",
					Type: "float",
					Comment: "Compliance-SW-vis(393252); "
				}, {
					Name: "sR 0.5",
					Label: "sR 0.5",
					Type: "float",
					Comment: "Body_Body-sR05IN(19857442); Body_Body-sR 0.5(19857500); Body_Body-sR05EX(19857504); "
				}, {
					Name: "sR eff",
					Label: "sR eff",
					Type: "float",
					Comment: "Baby_Body-sReff(1310739); Baby_Body-sRinef(1310743); Baby_Body-sRexef(1310745); Body_Body-sR eff(19857418); Body_Body-sRIeff(19857446); Body_Body-sREeff(19857447); "
				}, {
					Name: "sR mid",
					Label: "sR mid",
					Type: "float",
					Comment: "Body_Body-sR mid(19857472); Body_Body-sRmdIN(19857477); Body_Body-sRmdEX(19857479); "
				}, {
					Name: "sR peak",
					Label: "sR peak",
					Type: "float",
					Comment: "Baby_Body-sRPeak(1310751); Body_Body-sRpeak(19857449); "
				}, {
					Name: "sR tot",
					Label: "sR tot",
					Type: "float",
					Comment: "Baby_Body-sRtot(1310747); Baby_Body-sRin(1310755); Baby_Body-sRex(1310757); Body_Body-sR tot(19857417); Body_Body-sRI t(19857440); Body_Body-sRE t(19857441); "
				}, {
					Name: "sRaw",
					Label: "sRaw",
					Type: "float",
					Comment: "Body_Body-sRaw(19857535); "
				}, {
					Name: "STB",
					Label: "STB",
					Type: "float",
					Comment: "Blood_gases-STB(851998); "
				}, {
					Name: "Stroke volume",
					Label: "Stroke volume",
					Type: "float",
					Comment: "Blood_gases-SV_dF(852040); CPET_BxB-SVc(78708831); CPET_Cardiac_Output-SV(78839824); "
				}, {
					Name: "Stroke volume index",
					Label: "Stroke volume index",
					Type: "float",
					Comment: "Blood_gases-SVI_dF(852041); CPET_BxB-SVI_c(78708885); CPET_Cardiac_Output-SVI(78839832); "
				}, {
					Name: "T Body",
					Label: "T Body",
					Type: "float",
					Comment: "Blood_gases-TBody(851975); CPET_BxB-TBody(78708751); "
				}, {
					Name: "TCO2",
					Label: "TCO2",
					Type: "float",
					Comment: "Blood_gases-CPCO2(851996); "
				}, {
					Name: "Time constant (Tau)",
					Label: "Time constant (Tau)",
					Type: "float",
					Comment: "CPET_BxB-MRT(78708891); CPET_BxB-Tau(78708894); CPET_Slope-Tau(79626253); "
				}, {
					Name: "Tin/Ttot",
					Label: "Tin/Ttot",
					Type: "float",
					Comment: "Baby_Body-tI/T-F(1310729); P01_Rest-TIN/TTOT(432603152); "
				}, {
					Name: "TLC_Dil",
					Label: "TLC_Dil",
					Type: "float",
					Comment: "Diffusion_SB-TLC_SB(262147); Diffusion_SB_Intrabreath-TLC-IB(3866638); NO_Diffusion_Membrane-TLC-SB(4128799); FRC_Rebreathing_FRC_RB-TLC(445710355); Diffusion_Rebreathing_Diff_RB-TLC(452263981); "
				}, {
					Name: "TLC_Pleth",
					Label: "TLC_Pleth",
					Type: "float",
					Comment: "Diffusion_SB-TLC_PL(262188); Diffusion_SB-TLC_EX_SB(262227); Compliance-TLC(393250); Diffusion_SB_Intrabreath-TLC-BY(3866678); NO_Diffusion_Membrane-TLC Pl(4128849); Body_Body-TLC(19857429); MIPMEP_MIP-TLC_MIP(426049548); MIPMEP_MEP-TLC_MEP(426115084); "
				}, {
					Name: "TOT / Kcal /day",
					Label: "TOT / Kcal /day",
					Type: "float",
					Comment: null
				}, {
					Name: "UN",
					Label: "UN",
					Type: "float",
					Comment: "Blood_gases-UN(851984); "
				}, {
					Name: "VA",
					Label: "VA",
					Type: "float",
					Comment: "Diffusion_SB-VA_SB(262151); Diffusion_SB-VA CO_SB(262176); Diffusion_SB-VA_SQM(262181); Diffusion_SB-VA_EX_SB(262221); Diffusion_SB_Intrabreath-VA max(3866652); NO_Diffusion_Membrane-VA-SB(4128800); NO_Diffusion_Membrane-VA SQM(4128844); NO_Diffusion_Membrane-VA SQM CO(4128845); Diffusion_Rebreathing_Diff_RB-VA rb(452263947); "
				}, {
					Name: "VC",
					Label: "VC",
					Type: "float",
					Comment: "Spirometry-VC IN(65537); Spirometry-VC EX(65544); Spirometry-VCmod(65635); Diffusion_SB-VIN_SB(262152); Diffusion_SB-VC Trg(262166); Diffusion_SB-VEX_CH4(262225); Compliance-VC(393237); IOS-VC IN(1179730); IOS-VC EX(1179731); Diffusion_SB_Intrabreath-VIN-IB(3866639); N2_Washout-VCIN_N2(4063254); N2_Washout-VCEX_N2(4063255); NO_Diffusion_Membrane-VIN SB(4128826); Single_Breath_O2-VC_SBO2(4653057); Body_Slow_Spirometry-VC IN(19726337); Body_Slow_Spirometry-VC EX(19726344); Body_Slow_Spirometry-VCmod(19726435); Body_Transmural_F_V-VC IN(19791882); Body_Body-VC(19857433); FRC_Rebreathing_FRC_RB-VC_IN(445710345); FRC_Rebreathing_FRC_RB-VC_EX(445710346); Diffusion_Rebreathing_Diff_RB-VC IN(452263971); Diffusion_Rebreathing_Diff_RB-VC EX(452263972); "
				}, {
					Name: "Vc (capilar blood volume)",
					Label: "Vc (capilar blood volume)",
					Type: "float",
					Comment: "NO_Diffusion_Membrane-Vc  (QC)(4128770); "
				}, {
					Name: "VC max",
					Label: "VC max",
					Type: "float",
					Comment: "Spirometry-VC MAX(65545); Diffusion_SB-VCmax(262195); IOS-VC MAX(1179729); Diffusion_SB_Intrabreath-VCmaxIB(3866673); Diffusion_SB_Intrabreath-VCmaxSP(3866676); N2_Washout-VCMAX_N2(4063256); NO_Diffusion_Membrane-VC Trg(4128828); NO_Diffusion_Membrane-VCmax(4128829); Body_Slow_Spirometry-VC MAX(19726345); Body_Transmural_F_V-VC Max(19791896); FRC_Rebreathing_FRC_RB-VC_max(445710347); Diffusion_Rebreathing_Diff_RB-VC max(452263973); "
				}, {
					Name: "VCO2",
					Label: "VCO2",
					Type: "float",
					Comment: "CPET_BxB-V'CO2(78708787); CPET_Cardiac_Output-V'CO2(78839823); "
				}, {
					Name: "VD / VT",
					Label: "VD / VT",
					Type: "float",
					Comment: "CPET_BxB-VDc/VT(78708805); CPET_BxB-VDf/VT(78708806); CPET_BxB-VDe/VT(78708807); "
				}, {
					Name: "VE slope (VCO2)",
					Label: "VE slope (VCO2)",
					Type: "float",
					Comment: "CPET_BxB-V'Eslo(78708881); CPET_Slope-VECO2s(79626242); "
				}, {
					Name: "VE slope (VO2)",
					Label: "VE slope (VO2)",
					Type: "float",
					Comment: "CPET_Slope-VEO2s(79626248); "
				}, {
					Name: "VO2",
					Label: "VO2",
					Type: "float",
					Comment: "Blood_gases-V'O2(851979); CPET_BxB-V'O2(78708788); "
				}, {
					Name: "VO2 / kg",
					Label: "VO2 / kg",
					Type: "float",
					Comment: "CPET_BxB-V'O2/kg(78708798); "
				}, {
					Name: "VO2 slope (watt)",
					Label: "VO2 slope (watt)",
					Type: "float",
					Comment: "CPET_BxB-V'O2slo(78708818); CPET_Slope-VO2Ws(79626244); "
				}, {
					Name: "VT",
					Label: "VT",
					Type: "float",
					Comment: "Spirometry-VT(65540); Spirometry-VT_F(65664); Compliance-VT(393223); R_Occlusion-VT(458757); IOS-VT(1179649); Capnography-VT(1245186); Capnography-VTin(1245195); Capnography-VTex(1245196); Baby_Body-VT-FRC(1310721); Body_Slow_Spirometry-VT(19726340); Body_Slow_Spirometry-VT_F(19726464); Body_Body-VT(19857419); CPET_BxB-VTex(78708740); CPET_BxB-VTin(78708741); Capnography_Multibreath-VT(124583938); Capnography_Multibreath-VTin(124583947); Capnography_Multibreath-VTex(124583948); Capnography_Single_Breath-VT(124649474); Capnography_Single_Breath-VTin(124649483); Capnography_Single_Breath-VTex(124649484); MIPMEP_MIP-VT_MIP(426049544); MIPMEP_MEP-VT_MEP(426115080); P01_Rest-VT_P0.1(432603153); FRC_Rebreathing_FRC_RB-VT(445710350); FRC_Rebreathing_FRC_RB-VTrb(445710370); Diffusion_Rebreathing_Diff_RB-VT(452263976); "
				}, {
					Name: "W el",
					Label: "W el",
					Type: "float",
					Comment: "Compliance-W el(393222); "
				}, {
					Name: "W vis",
					Label: "W vis",
					Type: "float",
					Comment: "Compliance-W vis(393221); "
				}, {
					Name: "X at 10Hz",
					Label: "X at 10Hz",
					Type: "float",
					Comment: "IOS-X10Hz(1179662); IOS_Tidal_breathing_mean_v-X10Hz(118030362); IOS_Tidal_breathing_In_param-Xin10(118095898); IOS_Tidal_breathing_Ex_param-Xex10(118161434); "
				}, {
					Name: "X at 15Hz",
					Label: "X at 15Hz",
					Type: "float",
					Comment: "IOS-X15Hz(1179664); IOS_Tidal_breathing_mean_v-X15Hz(118030363); IOS_Tidal_breathing_In_param-Xin15(118095899); IOS_Tidal_breathing_Ex_param-Xex15(118161435); "
				}, {
					Name: "X at 20Hz",
					Label: "X at 20Hz",
					Type: "float",
					Comment: "IOS-X20Hz(1179666); IOS_Tidal_breathing_mean_v-X20Hz(118030364); IOS_Tidal_breathing_In_param-Xin20(118095900); IOS_Tidal_breathing_Ex_param-Xex20(118161436); "
				}, {
					Name: "X at 25Hz",
					Label: "X at 25Hz",
					Type: "float",
					Comment: "IOS-X25Hz(1179668); IOS_Tidal_breathing_mean_v-X25Hz(118030365); IOS_Tidal_breathing_In_param-Xin25(118095901); IOS_Tidal_breathing_Ex_param-Xex25(118161437); "
				}, {
					Name: "X at 35Hz",
					Label: "X at 35Hz",
					Type: "float",
					Comment: "IOS-X35Hz(1179670); IOS_Tidal_breathing_mean_v-X35Hz(118030366); IOS_Tidal_breathing_In_param-Xin35(118095902); IOS_Tidal_breathing_Ex_param-Xex35(118161438); "
				}, {
					Name: "X at 5Hz",
					Label: "X at 5Hz",
					Type: "float",
					Comment: "IOS-X5Hz(1179660); IOS-X5Hz c(1179749); IOS_Tidal_breathing_mean_v-X5Hz(118030361); IOS_Tidal_breathing_In_param-Xin5(118095897); IOS_Tidal_breathing_Ex_param-Xex5(118161433); "
				}, {
					Name: "Z at 5Hz",
					Label: "Z at 5Hz",
					Type: "float",
					Comment: "IOS-Z5Hz(1179650); "
				}
			]
		}, {
			Name: "Base_User_Defined_All_Best",
			Label: "User_Defined_All_Best",
			Comment: "All best parameters per level user defined template parameter based depends on user configuration",
			Columns: [{
					Name: "Visit Date",
					Label: "Visit Date",
					Type: "date",
					Comment: null
				}, {
					Name: "Patient ID",
					Label: "Patient ID",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Name",
					Label: "Name",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Middlename",
					Label: "Middlename",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Firstname",
					Label: "Firstname",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Date_of_Birth",
					Label: "Date_of_Birth",
					Type: "date",
					Comment: null
				}, {
					Name: "Gender",
					Label: "Gender",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "BiologicalGender",
					Label: "BiologicalGender",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Race",
					Label: "Race",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Date First Test",
					Label: "Date First Test",
					Type: "datetime",
					Comment: null
				}, {
					Name: "Date Last Test",
					Label: "Date Last Test",
					Type: "datetime",
					Comment: null
				}, {
					Name: "Age",
					Label: "Age",
					Type: "int",
					Comment: null
				}, {
					Name: "Height",
					Label: "Height",
					Type: "float",
					Comment: null
				}, {
					Name: "Weight",
					Label: "Weight",
					Type: "float",
					Comment: null
				}, {
					Name: "LevelType",
					Label: "LevelType",
					Type: "int",
					Comment: null
				}, {
					Name: "Sequence",
					Label: "Sequence",
					Type: "int",
					Comment: null
				}, {
					Name: "Substance",
					Label: "Substance",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Concentration",
					Label: "Concentration",
					Type: "float",
					Comment: null
				}, {
					Name: "Dose",
					Label: "Dose",
					Type: "float",
					Comment: null
				}, {
					Name: "Cummulated Dose",
					Label: "Cummulated Dose",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV1",
					Label: "FEV1",
					Type: "float",
					Comment: null
				}, {
					Name: "ECode",
					Label: "ECode",
					Type: "float",
					Comment: null
				}, {
					Name: "Property 1",
					Label: "Property 1",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 2",
					Label: "Property 2",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 3",
					Label: "Property 3",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 4",
					Label: "Property 4",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 5",
					Label: "Property 5",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 6",
					Label: "Property 6",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 7",
					Label: "Property 7",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 8",
					Label: "Property 8",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 9",
					Label: "Property 9",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 10",
					Label: "Property 10",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 11",
					Label: "Property 11",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 12",
					Label: "Property 12",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 13",
					Label: "Property 13",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 14",
					Label: "Property 14",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 15",
					Label: "Property 15",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Diagnosis",
					Label: "Diagnosis",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Anamnesis",
					Label: "Anamnesis",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Medication",
					Label: "Medication",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Occupational Exposure",
					Label: "Occupational Exposure",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Location",
					Label: "Location",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Room",
					Label: "Room",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Referring Physician Id",
					Label: "Referring Physician Id",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Referring Physician Name",
					Label: "Referring Physician Name",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Operator Id",
					Label: "Operator Id",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Operator Name",
					Label: "Operator Name",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Physician Id",
					Label: "Physician Id",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Physician Name",
					Label: "Physician Name",
					Type: "nvarchar",
					Comment: null
				}
			]
		}, {
			Name: "Base_User_Defined_All_Trials",
			Label: "User_Defined_All_Trials",
			Comment: "All parameters per level, trial and measurement user defined template parameter based depends on user configuration",
			Columns: [{
					Name: "Visit Date",
					Label: "Visit Date",
					Type: "date",
					Comment: null
				}, {
					Name: "Patient ID",
					Label: "Patient ID",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Name",
					Label: "Name",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Middlename",
					Label: "Middlename",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Firstname",
					Label: "Firstname",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Date_of_Birth",
					Label: "Date_of_Birth",
					Type: "date",
					Comment: null
				}, {
					Name: "Gender",
					Label: "Gender",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "BiologicalGender",
					Label: "BiologicalGender",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Race",
					Label: "Race",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Test Date",
					Label: "Test Date",
					Type: "datetime",
					Comment: null
				}, {
					Name: "Test Duration",
					Label: "Test Duration",
					Type: "int",
					Comment: null
				}, {
					Name: "Age",
					Label: "Age",
					Type: "int",
					Comment: null
				}, {
					Name: "Height",
					Label: "Height",
					Type: "float",
					Comment: null
				}, {
					Name: "Weight",
					Label: "Weight",
					Type: "float",
					Comment: null
				}, {
					Name: "Measurement",
					Label: "Measurement",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "LevelType",
					Label: "LevelType",
					Type: "int",
					Comment: null
				}, {
					Name: "Sequence",
					Label: "Sequence",
					Type: "int",
					Comment: null
				}, {
					Name: "Trial",
					Label: "Trial",
					Type: "int",
					Comment: null
				}, {
					Name: "Substance",
					Label: "Substance",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Concentration",
					Label: "Concentration",
					Type: "float",
					Comment: null
				}, {
					Name: "Dose",
					Label: "Dose",
					Type: "float",
					Comment: null
				}, {
					Name: "Cummulated Dose",
					Label: "Cummulated Dose",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV1",
					Label: "FEV1",
					Type: "float",
					Comment: null
				}, {
					Name: "ECode",
					Label: "ECode",
					Type: "float",
					Comment: null
				}
			]
		}, {
			Name: "Base_Pre_Post",
			Label: "Pre_Post",
			Comment: "A view providing Pre, Post and %Change for spirometry records",
			Columns: [{
					Name: "Visit Date",
					Label: "Visit Date",
					Type: "date",
					Comment: null
				}, {
					Name: "Patient ID",
					Label: "Patient ID",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Name",
					Label: "Name",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Middlename",
					Label: "Middlename",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Firstname",
					Label: "Firstname",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Date of Birth",
					Label: "Date of Birth",
					Type: "datetime",
					Comment: null
				}, {
					Name: "Gender",
					Label: "Gender",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "BiologicalGender",
					Label: "BiologicalGender",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Race",
					Label: "Race",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Test Date",
					Label: "Test Date",
					Type: "datetime",
					Comment: null
				}, {
					Name: "Age",
					Label: "Age",
					Type: "int",
					Comment: null
				}, {
					Name: "Height",
					Label: "Height",
					Type: "float",
					Comment: null
				}, {
					Name: "Weight",
					Label: "Weight",
					Type: "float",
					Comment: null
				}, {
					Name: "Pre FEV 1",
					Label: "Pre FEV 1",
					Type: "float",
					Comment: null
				}, {
					Name: "Pre FVC",
					Label: "Pre FVC",
					Type: "float",
					Comment: null
				}, {
					Name: "Pre PEF",
					Label: "Pre PEF",
					Type: "float",
					Comment: null
				}, {
					Name: "Pre MMEF 75/25",
					Label: "Pre MMEF 75/25",
					Type: "float",
					Comment: null
				}, {
					Name: "Pre MEF 25",
					Label: "Pre MEF 25",
					Type: "float",
					Comment: null
				}, {
					Name: "Pre MEF 50",
					Label: "Pre MEF 50",
					Type: "float",
					Comment: null
				}, {
					Name: "Pre MEF 75",
					Label: "Pre MEF 75",
					Type: "float",
					Comment: null
				}, {
					Name: "Post FEV 1",
					Label: "Post FEV 1",
					Type: "float",
					Comment: null
				}, {
					Name: "Post FVC",
					Label: "Post FVC",
					Type: "float",
					Comment: null
				}, {
					Name: "Post PEF",
					Label: "Post PEF",
					Type: "float",
					Comment: null
				}, {
					Name: "Post MMEF 75/25",
					Label: "Post MMEF 75/25",
					Type: "float",
					Comment: null
				}, {
					Name: "Post MEF 25",
					Label: "Post MEF 25",
					Type: "float",
					Comment: null
				}, {
					Name: "Post MEF 50",
					Label: "Post MEF 50",
					Type: "float",
					Comment: null
				}, {
					Name: "Post MEF 75",
					Label: "Post MEF 75",
					Type: "float",
					Comment: null
				}, {
					Name: "Pre FEV1/FVC%",
					Label: "Pre FEV1/FVC%",
					Type: "float",
					Comment: null
				}, {
					Name: "Post FEV1/FVC%",
					Label: "Post FEV1/FVC%",
					Type: "float",
					Comment: null
				}, {
					Name: "%Chg FEV 1",
					Label: "%Chg FEV 1",
					Type: "float",
					Comment: null
				}, {
					Name: "%Chg FVC",
					Label: "%Chg FVC",
					Type: "float",
					Comment: null
				}
			]
		}, {
			Name: "Base_Quality_Spirometry",
			Label: "Quality_Spirometry",
			Comment: "Quality parameters for forced spirometry per level and trial",
			Columns: [{
					Name: "Visit Date",
					Label: "Visit Date",
					Type: "date",
					Comment: null
				}, {
					Name: "Patient ID",
					Label: "Patient ID",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Name",
					Label: "Name",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Middlename",
					Label: "Middlename",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Firstname",
					Label: "Firstname",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Date of Birth",
					Label: "Date of Birth",
					Type: "datetime",
					Comment: null
				}, {
					Name: "Gender",
					Label: "Gender",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "BiologicalGender",
					Label: "BiologicalGender",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Race",
					Label: "Race",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Test Date",
					Label: "Test Date",
					Type: "datetime",
					Comment: null
				}, {
					Name: "Age",
					Label: "Age",
					Type: "int",
					Comment: null
				}, {
					Name: "Height",
					Label: "Height",
					Type: "float",
					Comment: null
				}, {
					Name: "Weight",
					Label: "Weight",
					Type: "float",
					Comment: null
				}, {
					Name: "LevelType",
					Label: "LevelType",
					Type: "int",
					Comment: null
				}, {
					Name: "Sequence",
					Label: "Sequence",
					Type: "int",
					Comment: null
				}, {
					Name: "Trial",
					Label: "Trial",
					Type: "int",
					Comment: null
				}, {
					Name: "FVC",
					Label: "FVC",
					Type: "float",
					Comment: "Spirometry-FVC(65567); Spirometry-VCIN_F(65674); Spirometry-VCEX_F(65675); Body_Slow_Spirometry-FVC(19726367); Body_Slow_Spirometry-VCin_F(19726474); Body_Slow_Spirometry-VCex_F(19726475); Body_Transmural_F_V-FVC(19791883); "
				}, {
					Name: "FET",
					Label: "FET",
					Type: "float",
					Comment: null
				}, {
					Name: "FETPEF",
					Label: "FETPEF",
					Type: "float",
					Comment: null
				}, {
					Name: "VBEex",
					Label: "VBEex",
					Type: "float",
					Comment: null
				}
			]
		}, {
			Name: "Base_Selected_Best_References",
			Label: "Selected_Best_References",
			Comment: "Selected best parameters per level including Predicted, %Predicted and Z-Score",
			Columns: [{
					Name: "Visit Date",
					Label: "Visit Date",
					Type: "date",
					Comment: null
				}, {
					Name: "Patient ID",
					Label: "Patient ID",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Name",
					Label: "Name",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Middlename",
					Label: "Middlename",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Firstname",
					Label: "Firstname",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Date_Of_Birth",
					Label: "Date_Of_Birth",
					Type: "date",
					Comment: null
				}, {
					Name: "Gender",
					Label: "Gender",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "BiologicalGender",
					Label: "BiologicalGender",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Race",
					Label: "Race",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Test Date",
					Label: "Test Date",
					Type: "datetime",
					Comment: null
				}, {
					Name: "Age",
					Label: "Age",
					Type: "int",
					Comment: null
				}, {
					Name: "Height",
					Label: "Height",
					Type: "float",
					Comment: null
				}, {
					Name: "Weight",
					Label: "Weight",
					Type: "float",
					Comment: null
				}, {
					Name: "LevelType",
					Label: "LevelType",
					Type: "int",
					Comment: null
				}, {
					Name: "Sequence",
					Label: "Sequence",
					Type: "int",
					Comment: null
				}, {
					Name: "Substance",
					Label: "Substance",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Concentration",
					Label: "Concentration",
					Type: "float",
					Comment: null
				}, {
					Name: "Dose",
					Label: "Dose",
					Type: "float",
					Comment: null
				}, {
					Name: "Cummulated Dose",
					Label: "Cummulated Dose",
					Type: "float",
					Comment: null
				}, {
					Name: "6 MWD PRED",
					Label: "6 MWD PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "6 MWD %PRED",
					Label: "6 MWD %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "6 MWD ZScore",
					Label: "6 MWD ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "AB PRED",
					Label: "AB PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "AB %PRED",
					Label: "AB %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "AB ZScore",
					Label: "AB ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Alv. Ventilation PRED",
					Label: "Alv. Ventilation PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Alv. Ventilation %PRED",
					Label: "Alv. Ventilation %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Alv. Ventilation ZScore",
					Label: "Alv. Ventilation ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "BB PRED",
					Label: "BB PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "BB %PRED",
					Label: "BB %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "BB ZScore",
					Label: "BB ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "BE PRED",
					Label: "BE PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "BE %PRED",
					Label: "BE %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "BE ZScore",
					Label: "BE ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "BF PRED",
					Label: "BF PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "BF %PRED",
					Label: "BF %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "BF ZScore",
					Label: "BF ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Breathing reserve (%) PRED",
					Label: "Breathing reserve (%) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Breathing reserve (%) %PRED",
					Label: "Breathing reserve (%) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Breathing reserve (%) ZScore",
					Label: "Breathing reserve (%) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Breathing reserve (L) PRED",
					Label: "Breathing reserve (L) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Breathing reserve (L) %PRED",
					Label: "Breathing reserve (L) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Breathing reserve (L) ZScore",
					Label: "Breathing reserve (L) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "C dyn / FRC PRED",
					Label: "C dyn / FRC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "C dyn / FRC %PRED",
					Label: "C dyn / FRC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "C dyn / FRC ZScore",
					Label: "C dyn / FRC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "C dyn / TLC PRED",
					Label: "C dyn / TLC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "C dyn / TLC %PRED",
					Label: "C dyn / TLC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "C dyn / TLC ZScore",
					Label: "C dyn / TLC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "C stat / FRC PRED",
					Label: "C stat / FRC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "C stat / FRC %PRED",
					Label: "C stat / FRC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "C stat / FRC ZScore",
					Label: "C stat / FRC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "C stat / TLC PRED",
					Label: "C stat / TLC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "C stat / TLC %PRED",
					Label: "C stat / TLC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "C stat / TLC ZScore",
					Label: "C stat / TLC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "CaCO2 PRED",
					Label: "CaCO2 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CaCO2 %PRED",
					Label: "CaCO2 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CaCO2 ZScore",
					Label: "CaCO2 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "CaO2 PRED",
					Label: "CaO2 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CaO2 %PRED",
					Label: "CaO2 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CaO2 ZScore",
					Label: "CaO2 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Cardiac index PRED",
					Label: "Cardiac index PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Cardiac index %PRED",
					Label: "Cardiac index %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Cardiac index ZScore",
					Label: "Cardiac index ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Cardiac output PRED",
					Label: "Cardiac output PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Cardiac output %PRED",
					Label: "Cardiac output %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Cardiac output ZScore",
					Label: "Cardiac output ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "CHO % PRED",
					Label: "CHO % PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CHO % %PRED",
					Label: "CHO % %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CHO % ZScore",
					Label: "CHO % ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "CHO (g/day) PRED",
					Label: "CHO (g/day) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CHO (g/day) %PRED",
					Label: "CHO (g/day) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CHO (g/day) ZScore",
					Label: "CHO (g/day) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Closing capacity PRED",
					Label: "Closing capacity PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Closing capacity %PRED",
					Label: "Closing capacity %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Closing capacity ZScore",
					Label: "Closing capacity ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Closing volume PRED",
					Label: "Closing volume PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Closing volume %PRED",
					Label: "Closing volume %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Closing volume ZScore",
					Label: "Closing volume ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "CO05Hz PRED",
					Label: "CO05Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO05Hz %PRED",
					Label: "CO05Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO05Hz ZScore",
					Label: "CO05Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "CO10Hz PRED",
					Label: "CO10Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO10Hz %PRED",
					Label: "CO10Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO10Hz ZScore",
					Label: "CO10Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "CO15Hz PRED",
					Label: "CO15Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO15Hz %PRED",
					Label: "CO15Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO15Hz ZScore",
					Label: "CO15Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "CO1Hz PRED",
					Label: "CO1Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO1Hz %PRED",
					Label: "CO1Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO1Hz ZScore",
					Label: "CO1Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "CO20Hz PRED",
					Label: "CO20Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO20Hz %PRED",
					Label: "CO20Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO20Hz ZScore",
					Label: "CO20Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "CO25Hz PRED",
					Label: "CO25Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO25Hz %PRED",
					Label: "CO25Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO25Hz ZScore",
					Label: "CO25Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "CO2Hz PRED",
					Label: "CO2Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO2Hz %PRED",
					Label: "CO2Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO2Hz ZScore",
					Label: "CO2Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "CO35Hz PRED",
					Label: "CO35Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO35Hz %PRED",
					Label: "CO35Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO35Hz ZScore",
					Label: "CO35Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "CO3Hz PRED",
					Label: "CO3Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO3Hz %PRED",
					Label: "CO3Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO3Hz ZScore",
					Label: "CO3Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "CO5Hz PRED",
					Label: "CO5Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO5Hz %PRED",
					Label: "CO5Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "CO5Hz ZScore",
					Label: "CO5Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Compliance dyn. PRED",
					Label: "Compliance dyn. PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Compliance dyn. %PRED",
					Label: "Compliance dyn. %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Compliance dyn. ZScore",
					Label: "Compliance dyn. ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Compliance stat. PRED",
					Label: "Compliance stat. PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Compliance stat. %PRED",
					Label: "Compliance stat. %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Compliance stat. ZScore",
					Label: "Compliance stat. ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_IB PRED",
					Label: "DLCO_IB PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_IB %PRED",
					Label: "DLCO_IB %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_IB ZScore",
					Label: "DLCO_IB ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_IB / VA PRED",
					Label: "DLCO_IB / VA PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_IB / VA %PRED",
					Label: "DLCO_IB / VA %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_IB / VA ZScore",
					Label: "DLCO_IB / VA ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_RB PRED",
					Label: "DLCO_RB PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_RB %PRED",
					Label: "DLCO_RB %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_RB ZScore",
					Label: "DLCO_RB ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_RB / VA PRED",
					Label: "DLCO_RB / VA PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_RB / VA %PRED",
					Label: "DLCO_RB / VA %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_RB / VA ZScore",
					Label: "DLCO_RB / VA ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_SB PRED",
					Label: "DLCO_SB PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_SB %PRED",
					Label: "DLCO_SB %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_SB ZScore",
					Label: "DLCO_SB ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_SB / BSA PRED",
					Label: "DLCO_SB / BSA PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_SB / BSA %PRED",
					Label: "DLCO_SB / BSA %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_SB / BSA ZScore",
					Label: "DLCO_SB / BSA ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_SB / VA PRED",
					Label: "DLCO_SB / VA PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_SB / VA %PRED",
					Label: "DLCO_SB / VA %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCO_SB / VA ZScore",
					Label: "DLCO_SB / VA ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCOc_IB PRED",
					Label: "DLCOc_IB PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCOc_IB %PRED",
					Label: "DLCOc_IB %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCOc_IB ZScore",
					Label: "DLCOc_IB ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCOc_IB / VA PRED",
					Label: "DLCOc_IB / VA PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCOc_IB / VA %PRED",
					Label: "DLCOc_IB / VA %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCOc_IB / VA ZScore",
					Label: "DLCOc_IB / VA ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCOc_SB PRED",
					Label: "DLCOc_SB PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCOc_SB %PRED",
					Label: "DLCOc_SB %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCOc_SB ZScore",
					Label: "DLCOc_SB ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCOc_SB / BSA PRED",
					Label: "DLCOc_SB / BSA PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCOc_SB / BSA %PRED",
					Label: "DLCOc_SB / BSA %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCOc_SB / BSA ZScore",
					Label: "DLCOc_SB / BSA ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCOc_SB / VA PRED",
					Label: "DLCOc_SB / VA PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCOc_SB / VA %PRED",
					Label: "DLCOc_SB / VA %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "DLCOc_SB / VA ZScore",
					Label: "DLCOc_SB / VA ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Dm (Diff mem) PRED",
					Label: "Dm (Diff mem) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Dm (Diff mem) %PRED",
					Label: "Dm (Diff mem) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Dm (Diff mem) ZScore",
					Label: "Dm (Diff mem) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "EE (kcal/day) PRED",
					Label: "EE (kcal/day) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "EE (kcal/day) %PRED",
					Label: "EE (kcal/day) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "EE (kcal/day) ZScore",
					Label: "EE (kcal/day) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "EE / BSA PRED",
					Label: "EE / BSA PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "EE / BSA %PRED",
					Label: "EE / BSA %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "EE / BSA ZScore",
					Label: "EE / BSA ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "EE / CO2  PRED",
					Label: "EE / CO2  PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "EE / CO2  %PRED",
					Label: "EE / CO2  %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "EE / CO2  ZScore",
					Label: "EE / CO2  ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "EE / kg PRED",
					Label: "EE / kg PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "EE / kg %PRED",
					Label: "EE / kg %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "EE / kg ZScore",
					Label: "EE / kg ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Elastance dyn. PRED",
					Label: "Elastance dyn. PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Elastance dyn. %PRED",
					Label: "Elastance dyn. %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Elastance dyn. ZScore",
					Label: "Elastance dyn. ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Elastance stat. PRED",
					Label: "Elastance stat. PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Elastance stat. %PRED",
					Label: "Elastance stat. %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Elastance stat. ZScore",
					Label: "Elastance stat. ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "EQCO2 (VE/VCO2) PRED",
					Label: "EQCO2 (VE/VCO2) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "EQCO2 (VE/VCO2) %PRED",
					Label: "EQCO2 (VE/VCO2) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "EQCO2 (VE/VCO2) ZScore",
					Label: "EQCO2 (VE/VCO2) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "EQO2 (VE/VO2) PRED",
					Label: "EQO2 (VE/VO2) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "EQO2 (VE/VO2) %PRED",
					Label: "EQO2 (VE/VO2) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "EQO2 (VE/VO2) ZScore",
					Label: "EQO2 (VE/VO2) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "ERV PRED",
					Label: "ERV PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "ERV %PRED",
					Label: "ERV %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "ERV ZScore",
					Label: "ERV ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Exspir. F/V area PRED",
					Label: "Exspir. F/V area PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Exspir. F/V area %PRED",
					Label: "Exspir. F/V area %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Exspir. F/V area ZScore",
					Label: "Exspir. F/V area ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "F resonance PRED",
					Label: "F resonance PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "F resonance %PRED",
					Label: "F resonance %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "F resonance ZScore",
					Label: "F resonance ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FAT % PRED",
					Label: "FAT % PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FAT % %PRED",
					Label: "FAT % %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FAT % ZScore",
					Label: "FAT % ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FAT / g/day PRED",
					Label: "FAT / g/day PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FAT / g/day %PRED",
					Label: "FAT / g/day %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FAT / g/day ZScore",
					Label: "FAT / g/day ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 200-1200 PRED",
					Label: "FEF 200-1200 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 200-1200 %PRED",
					Label: "FEF 200-1200 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 200-1200 ZScore",
					Label: "FEF 200-1200 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 25 PRED",
					Label: "FEF 25 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 25 %PRED",
					Label: "FEF 25 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 25 ZScore",
					Label: "FEF 25 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 40 PRED",
					Label: "FEF 40 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 40 %PRED",
					Label: "FEF 40 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 40 ZScore",
					Label: "FEF 40 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 50 PRED",
					Label: "FEF 50 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 50 %PRED",
					Label: "FEF 50 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 50 ZScore",
					Label: "FEF 50 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 50 % FIF 50 PRED",
					Label: "FEF 50 % FIF 50 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 50 % FIF 50 %PRED",
					Label: "FEF 50 % FIF 50 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 50 % FIF 50 ZScore",
					Label: "FEF 50 % FIF 50 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 60 PRED",
					Label: "FEF 60 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 60 %PRED",
					Label: "FEF 60 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 60 ZScore",
					Label: "FEF 60 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 75 PRED",
					Label: "FEF 75 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 75 %PRED",
					Label: "FEF 75 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 75 ZScore",
					Label: "FEF 75 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 75/85 PRED",
					Label: "FEF 75/85 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 75/85 %PRED",
					Label: "FEF 75/85 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 75/85 ZScore",
					Label: "FEF 75/85 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 80 PRED",
					Label: "FEF 80 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 80 %PRED",
					Label: "FEF 80 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEF 80 ZScore",
					Label: "FEF 80 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 0.5 PRED",
					Label: "FEV 0.5 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 0.5 %PRED",
					Label: "FEV 0.5 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 0.5 ZScore",
					Label: "FEV 0.5 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 0.75 PRED",
					Label: "FEV 0.75 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 0.75 %PRED",
					Label: "FEV 0.75 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 0.75 ZScore",
					Label: "FEV 0.75 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 0.75 % FVC PRED",
					Label: "FEV 0.75 % FVC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 0.75 % FVC %PRED",
					Label: "FEV 0.75 % FVC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 0.75 % FVC ZScore",
					Label: "FEV 0.75 % FVC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 0.75 % VC max PRED",
					Label: "FEV 0.75 % VC max PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 0.75 % VC max %PRED",
					Label: "FEV 0.75 % VC max %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 0.75 % VC max ZScore",
					Label: "FEV 0.75 % VC max ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 PRED",
					Label: "FEV 1 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 %PRED",
					Label: "FEV 1 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 ZScore",
					Label: "FEV 1 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 % FEV 6 PRED",
					Label: "FEV 1 % FEV 6 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 % FEV 6 %PRED",
					Label: "FEV 1 % FEV 6 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 % FEV 6 ZScore",
					Label: "FEV 1 % FEV 6 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 % FVC PRED",
					Label: "FEV 1 % FVC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 % FVC %PRED",
					Label: "FEV 1 % FVC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 % FVC ZScore",
					Label: "FEV 1 % FVC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 % VC PRED",
					Label: "FEV 1 % VC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 % VC %PRED",
					Label: "FEV 1 % VC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 % VC ZScore",
					Label: "FEV 1 % VC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 % VC max PRED",
					Label: "FEV 1 % VC max PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 % VC max %PRED",
					Label: "FEV 1 % VC max %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 % VC max ZScore",
					Label: "FEV 1 % VC max ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 / VC max % PRED PRED",
					Label: "FEV 1 / VC max % PRED PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 / VC max % PRED %PRED",
					Label: "FEV 1 / VC max % PRED %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 1 / VC max % PRED ZScore",
					Label: "FEV 1 / VC max % PRED ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 2 PRED",
					Label: "FEV 2 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 2 %PRED",
					Label: "FEV 2 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 2 ZScore",
					Label: "FEV 2 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 3 PRED",
					Label: "FEV 3 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 3 %PRED",
					Label: "FEV 3 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 3 ZScore",
					Label: "FEV 3 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 3 % FVC PRED",
					Label: "FEV 3 % FVC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 3 % FVC %PRED",
					Label: "FEV 3 % FVC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 3 % FVC ZScore",
					Label: "FEV 3 % FVC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 6 PRED",
					Label: "FEV 6 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 6 %PRED",
					Label: "FEV 6 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FEV 6 ZScore",
					Label: "FEV 6 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FIF 25 PRED",
					Label: "FIF 25 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FIF 25 %PRED",
					Label: "FIF 25 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FIF 25 ZScore",
					Label: "FIF 25 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FIF 50 PRED",
					Label: "FIF 50 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FIF 50 %PRED",
					Label: "FIF 50 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FIF 50 ZScore",
					Label: "FIF 50 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FIF 75 PRED",
					Label: "FIF 75 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FIF 75 %PRED",
					Label: "FIF 75 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FIF 75 ZScore",
					Label: "FIF 75 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FIV 1 PRED",
					Label: "FIV 1 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FIV 1 %PRED",
					Label: "FIV 1 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FIV 1 ZScore",
					Label: "FIV 1 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FIV 1 % FVC PRED",
					Label: "FIV 1 % FVC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FIV 1 % FVC %PRED",
					Label: "FIV 1 % FVC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FIV 1 % FVC ZScore",
					Label: "FIV 1 % FVC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FIV 1 % VC PRED",
					Label: "FIV 1 % VC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FIV 1 % VC %PRED",
					Label: "FIV 1 % VC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FIV 1 % VC ZScore",
					Label: "FIV 1 % VC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Flow ex 150 (Rhino) PRED",
					Label: "Flow ex 150 (Rhino) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Flow ex 150 (Rhino) %PRED",
					Label: "Flow ex 150 (Rhino) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Flow ex 150 (Rhino) ZScore",
					Label: "Flow ex 150 (Rhino) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Flow in 150 (Rhino) PRED",
					Label: "Flow in 150 (Rhino) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Flow in 150 (Rhino) %PRED",
					Label: "Flow in 150 (Rhino) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Flow in 150 (Rhino) ZScore",
					Label: "Flow in 150 (Rhino) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Flow SUM Ex PRED",
					Label: "Flow SUM Ex PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Flow SUM Ex %PRED",
					Label: "Flow SUM Ex %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Flow SUM Ex ZScore",
					Label: "Flow SUM Ex ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Flow SUM In PRED",
					Label: "Flow SUM In PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Flow SUM In %PRED",
					Label: "Flow SUM In %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Flow SUM In ZScore",
					Label: "Flow SUM In ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FRC_Dil PRED",
					Label: "FRC_Dil PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FRC_Dil %PRED",
					Label: "FRC_Dil %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FRC_Dil ZScore",
					Label: "FRC_Dil ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FRC_Dil % TLC_Dil PRED",
					Label: "FRC_Dil % TLC_Dil PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FRC_Dil % TLC_Dil %PRED",
					Label: "FRC_Dil % TLC_Dil %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FRC_Dil % TLC_Dil ZScore",
					Label: "FRC_Dil % TLC_Dil ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FRC_Pleth PRED",
					Label: "FRC_Pleth PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FRC_Pleth %PRED",
					Label: "FRC_Pleth %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FRC_Pleth ZScore",
					Label: "FRC_Pleth ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FRC_Pleth % TLC_Pleth PRED",
					Label: "FRC_Pleth % TLC_Pleth PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FRC_Pleth % TLC_Pleth %PRED",
					Label: "FRC_Pleth % TLC_Pleth %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FRC_Pleth % TLC_Pleth ZScore",
					Label: "FRC_Pleth % TLC_Pleth ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FVC PRED",
					Label: "FVC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FVC %PRED",
					Label: "FVC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FVC ZScore",
					Label: "FVC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "FVC in PRED",
					Label: "FVC in PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FVC in %PRED",
					Label: "FVC in %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "FVC in ZScore",
					Label: "FVC in ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "G 0.5 PRED",
					Label: "G 0.5 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "G 0.5 %PRED",
					Label: "G 0.5 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "G 0.5 ZScore",
					Label: "G 0.5 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "G eff PRED",
					Label: "G eff PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "G eff %PRED",
					Label: "G eff %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "G eff ZScore",
					Label: "G eff ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "G mid PRED",
					Label: "G mid PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "G mid %PRED",
					Label: "G mid %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "G mid ZScore",
					Label: "G mid ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "G peak PRED",
					Label: "G peak PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "G peak %PRED",
					Label: "G peak %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "G peak ZScore",
					Label: "G peak ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "G tot PRED",
					Label: "G tot PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "G tot %PRED",
					Label: "G tot %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "G tot ZScore",
					Label: "G tot ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Gaw PRED",
					Label: "Gaw PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Gaw %PRED",
					Label: "Gaw %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Gaw ZScore",
					Label: "Gaw ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Hb PRED",
					Label: "Hb PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Hb %PRED",
					Label: "Hb %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Hb ZScore",
					Label: "Hb ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Hc PRED",
					Label: "Hc PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Hc %PRED",
					Label: "Hc %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Hc ZScore",
					Label: "Hc ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "HR PRED",
					Label: "HR PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "HR %PRED",
					Label: "HR %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "HR ZScore",
					Label: "HR ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "HR - response (VO2) PRED",
					Label: "HR - response (VO2) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "HR - response (VO2) %PRED",
					Label: "HR - response (VO2) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "HR - response (VO2) ZScore",
					Label: "HR - response (VO2) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "HR Reserve (%) PRED",
					Label: "HR Reserve (%) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "HR Reserve (%) %PRED",
					Label: "HR Reserve (%) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "HR Reserve (%) ZScore",
					Label: "HR Reserve (%) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "HR Reserve (1/min) PRED",
					Label: "HR Reserve (1/min) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "HR Reserve (1/min) %PRED",
					Label: "HR Reserve (1/min) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "HR Reserve (1/min) ZScore",
					Label: "HR Reserve (1/min) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "HR slope (VO2/kg) PRED",
					Label: "HR slope (VO2/kg) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "HR slope (VO2/kg) %PRED",
					Label: "HR slope (VO2/kg) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "HR slope (VO2/kg) ZScore",
					Label: "HR slope (VO2/kg) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "IC PRED",
					Label: "IC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "IC %PRED",
					Label: "IC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "IC ZScore",
					Label: "IC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Lactate PRED",
					Label: "Lactate PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Lactate %PRED",
					Label: "Lactate %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Lactate ZScore",
					Label: "Lactate ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Load PRED",
					Label: "Load PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Load %PRED",
					Label: "Load %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Load ZScore",
					Label: "Load ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Mean transit time PRED",
					Label: "Mean transit time PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Mean transit time %PRED",
					Label: "Mean transit time %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Mean transit time ZScore",
					Label: "Mean transit time ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MEF 25 PRED",
					Label: "MEF 25 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEF 25 %PRED",
					Label: "MEF 25 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEF 25 ZScore",
					Label: "MEF 25 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MEF 50 PRED",
					Label: "MEF 50 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEF 50 %PRED",
					Label: "MEF 50 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEF 50 ZScore",
					Label: "MEF 50 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MEF 50 % FVC PRED",
					Label: "MEF 50 % FVC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEF 50 % FVC %PRED",
					Label: "MEF 50 % FVC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEF 50 % FVC ZScore",
					Label: "MEF 50 % FVC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MEF 75 PRED",
					Label: "MEF 75 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEF 75 %PRED",
					Label: "MEF 75 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEF 75 ZScore",
					Label: "MEF 75 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MEP PRED",
					Label: "MEP PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEP %PRED",
					Label: "MEP %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEP ZScore",
					Label: "MEP ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MEP avg PRED",
					Label: "MEP avg PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEP avg %PRED",
					Label: "MEP avg %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEP avg ZScore",
					Label: "MEP avg ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MEP peak PRED",
					Label: "MEP peak PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEP peak %PRED",
					Label: "MEP peak %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEP peak ZScore",
					Label: "MEP peak ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MEP sus PRED",
					Label: "MEP sus PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEP sus %PRED",
					Label: "MEP sus %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MEP sus ZScore",
					Label: "MEP sus ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MFEF 75/25 PRED",
					Label: "MFEF 75/25 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MFEF 75/25 %PRED",
					Label: "MFEF 75/25 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MFEF 75/25 ZScore",
					Label: "MFEF 75/25 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MFEF 75/85 PRED",
					Label: "MFEF 75/85 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MFEF 75/85 %PRED",
					Label: "MFEF 75/85 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MFEF 75/85 ZScore",
					Label: "MFEF 75/85 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MIF PRED",
					Label: "MIF PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MIF %PRED",
					Label: "MIF %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MIF ZScore",
					Label: "MIF ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MIP PRED",
					Label: "MIP PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MIP %PRED",
					Label: "MIP %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MIP ZScore",
					Label: "MIP ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MIP avg PRED",
					Label: "MIP avg PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MIP avg %PRED",
					Label: "MIP avg %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MIP avg ZScore",
					Label: "MIP avg ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MIP peak PRED",
					Label: "MIP peak PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MIP peak %PRED",
					Label: "MIP peak %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MIP peak ZScore",
					Label: "MIP peak ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MIP sus PRED",
					Label: "MIP sus PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MIP sus %PRED",
					Label: "MIP sus %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MIP sus ZScore",
					Label: "MIP sus ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MMEF 75/25 PRED",
					Label: "MMEF 75/25 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MMEF 75/25 %PRED",
					Label: "MMEF 75/25 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MMEF 75/25 ZScore",
					Label: "MMEF 75/25 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MV, VE PRED",
					Label: "MV, VE PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MV, VE %PRED",
					Label: "MV, VE %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MV, VE ZScore",
					Label: "MV, VE ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "MVV PRED",
					Label: "MVV PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MVV %PRED",
					Label: "MVV %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "MVV ZScore",
					Label: "MVV ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "O2 pulse PRED",
					Label: "O2 pulse PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "O2 pulse %PRED",
					Label: "O2 pulse %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "O2 pulse ZScore",
					Label: "O2 pulse ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "OUES-Slope PRED",
					Label: "OUES-Slope PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "OUES-Slope %PRED",
					Label: "OUES-Slope %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "OUES-Slope ZScore",
					Label: "OUES-Slope ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 PRED",
					Label: "P 0.1 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 %PRED",
					Label: "P 0.1 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 ZScore",
					Label: "P 0.1 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 / MIF PRED",
					Label: "P 0.1 / MIF PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 / MIF %PRED",
					Label: "P 0.1 / MIF %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 / MIF ZScore",
					Label: "P 0.1 / MIF ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 / MIP PRED",
					Label: "P 0.1 / MIP PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 / MIP %PRED",
					Label: "P 0.1 / MIP %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 / MIP ZScore",
					Label: "P 0.1 / MIP ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 / MV PRED",
					Label: "P 0.1 / MV PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 / MV %PRED",
					Label: "P 0.1 / MV %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 / MV ZScore",
					Label: "P 0.1 / MV ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 / P 0.1 max PRED",
					Label: "P 0.1 / P 0.1 max PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 / P 0.1 max %PRED",
					Label: "P 0.1 / P 0.1 max %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 / P 0.1 max ZScore",
					Label: "P 0.1 / P 0.1 max ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 max PRED",
					Label: "P 0.1 max PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 max %PRED",
					Label: "P 0.1 max %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 max ZScore",
					Label: "P 0.1 max ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 max / MV PRED",
					Label: "P 0.1 max / MV PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 max / MV %PRED",
					Label: "P 0.1 max / MV %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P 0.1 max / MV ZScore",
					Label: "P 0.1 max / MV ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P diastolic PRED",
					Label: "P diastolic PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P diastolic %PRED",
					Label: "P diastolic %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P diastolic ZScore",
					Label: "P diastolic ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 100 % TLC PRED",
					Label: "P el 100 % TLC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 100 % TLC %PRED",
					Label: "P el 100 % TLC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 100 % TLC ZScore",
					Label: "P el 100 % TLC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 50 % / TLC PRED",
					Label: "P el 50 % / TLC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 50 % / TLC %PRED",
					Label: "P el 50 % / TLC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 50 % / TLC ZScore",
					Label: "P el 50 % / TLC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 60 % / TLC PRED",
					Label: "P el 60 % / TLC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 60 % / TLC %PRED",
					Label: "P el 60 % / TLC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 60 % / TLC ZScore",
					Label: "P el 60 % / TLC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 70 % / TLC PRED",
					Label: "P el 70 % / TLC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 70 % / TLC %PRED",
					Label: "P el 70 % / TLC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 70 % / TLC ZScore",
					Label: "P el 70 % / TLC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 80 % / TLC PRED",
					Label: "P el 80 % / TLC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 80 % / TLC %PRED",
					Label: "P el 80 % / TLC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 80 % / TLC ZScore",
					Label: "P el 80 % / TLC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 90 % TLC PRED",
					Label: "P el 90 % TLC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 90 % TLC %PRED",
					Label: "P el 90 % TLC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P el 90 % TLC ZScore",
					Label: "P el 90 % TLC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P el TLC PRED",
					Label: "P el TLC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P el TLC %PRED",
					Label: "P el TLC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P el TLC ZScore",
					Label: "P el TLC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P mean PRED",
					Label: "P mean PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P mean %PRED",
					Label: "P mean %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P mean ZScore",
					Label: "P mean ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P systolic PRED",
					Label: "P systolic PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P systolic %PRED",
					Label: "P systolic %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P systolic ZScore",
					Label: "P systolic ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P(A-a)O2 PRED",
					Label: "P(A-a)O2 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P(A-a)O2 %PRED",
					Label: "P(A-a)O2 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P(A-a)O2 ZScore",
					Label: "P(A-a)O2 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "P(a-ET)CO2 PRED",
					Label: "P(a-ET)CO2 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P(a-ET)CO2 %PRED",
					Label: "P(a-ET)CO2 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "P(a-ET)CO2 ZScore",
					Label: "P(a-ET)CO2 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "PaCO2 PRED",
					Label: "PaCO2 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PaCO2 %PRED",
					Label: "PaCO2 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PaCO2 ZScore",
					Label: "PaCO2 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "PAlvO2 PRED",
					Label: "PAlvO2 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PAlvO2 %PRED",
					Label: "PAlvO2 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PAlvO2 ZScore",
					Label: "PAlvO2 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "PaO2 PRED",
					Label: "PaO2 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PaO2 %PRED",
					Label: "PaO2 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PaO2 ZScore",
					Label: "PaO2 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "PEF PRED",
					Label: "PEF PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PEF %PRED",
					Label: "PEF %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PEF ZScore",
					Label: "PEF ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "PETCO2 PRED",
					Label: "PETCO2 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PETCO2 %PRED",
					Label: "PETCO2 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PETCO2 ZScore",
					Label: "PETCO2 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "PETO2 PRED",
					Label: "PETO2 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PETO2 %PRED",
					Label: "PETO2 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PETO2 ZScore",
					Label: "PETO2 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "pH a PRED",
					Label: "pH a PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "pH a %PRED",
					Label: "pH a %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "pH a ZScore",
					Label: "pH a ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "PIF PRED",
					Label: "PIF PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PIF %PRED",
					Label: "PIF %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PIF ZScore",
					Label: "PIF ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "PRO % PRED",
					Label: "PRO % PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PRO % %PRED",
					Label: "PRO % %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PRO % ZScore",
					Label: "PRO % ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "PRO / g/day PRED",
					Label: "PRO / g/day PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PRO / g/day %PRED",
					Label: "PRO / g/day %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "PRO / g/day ZScore",
					Label: "PRO / g/day ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Pulmonary blood flow PRED",
					Label: "Pulmonary blood flow PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Pulmonary blood flow %PRED",
					Label: "Pulmonary blood flow %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Pulmonary blood flow ZScore",
					Label: "Pulmonary blood flow ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "R 0.5 PRED",
					Label: "R 0.5 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R 0.5 %PRED",
					Label: "R 0.5 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R 0.5 ZScore",
					Label: "R 0.5 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 10Hz PRED",
					Label: "R at 10Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 10Hz %PRED",
					Label: "R at 10Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 10Hz ZScore",
					Label: "R at 10Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 15Hz PRED",
					Label: "R at 15Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 15Hz %PRED",
					Label: "R at 15Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 15Hz ZScore",
					Label: "R at 15Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 20Hz PRED",
					Label: "R at 20Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 20Hz %PRED",
					Label: "R at 20Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 20Hz ZScore",
					Label: "R at 20Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 25Hz PRED",
					Label: "R at 25Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 25Hz %PRED",
					Label: "R at 25Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 25Hz ZScore",
					Label: "R at 25Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 35Hz PRED",
					Label: "R at 35Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 35Hz %PRED",
					Label: "R at 35Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 35Hz ZScore",
					Label: "R at 35Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 5Hz PRED",
					Label: "R at 5Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 5Hz %PRED",
					Label: "R at 5Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R at 5Hz ZScore",
					Label: "R at 5Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "R eff PRED",
					Label: "R eff PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R eff %PRED",
					Label: "R eff %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R eff ZScore",
					Label: "R eff ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "R ex 150 (Rhino) PRED",
					Label: "R ex 150 (Rhino) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R ex 150 (Rhino) %PRED",
					Label: "R ex 150 (Rhino) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R ex 150 (Rhino) ZScore",
					Label: "R ex 150 (Rhino) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "R in 150 (Rhino) PRED",
					Label: "R in 150 (Rhino) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R in 150 (Rhino) %PRED",
					Label: "R in 150 (Rhino) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R in 150 (Rhino) ZScore",
					Label: "R in 150 (Rhino) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "R IOS_central PRED",
					Label: "R IOS_central PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R IOS_central %PRED",
					Label: "R IOS_central %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R IOS_central ZScore",
					Label: "R IOS_central ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "R IOS_peripheral PRED",
					Label: "R IOS_peripheral PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R IOS_peripheral %PRED",
					Label: "R IOS_peripheral %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R IOS_peripheral ZScore",
					Label: "R IOS_peripheral ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "R mid PRED",
					Label: "R mid PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R mid %PRED",
					Label: "R mid %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R mid ZScore",
					Label: "R mid ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "R peak PRED",
					Label: "R peak PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R peak %PRED",
					Label: "R peak %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R peak ZScore",
					Label: "R peak ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "R tot PRED",
					Label: "R tot PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R tot %PRED",
					Label: "R tot %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "R tot ZScore",
					Label: "R tot ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Raw PRED",
					Label: "Raw PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Raw %PRED",
					Label: "Raw %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Raw ZScore",
					Label: "Raw ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Raw Compliance PRED",
					Label: "Raw Compliance PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Raw Compliance %PRED",
					Label: "Raw Compliance %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Raw Compliance ZScore",
					Label: "Raw Compliance ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "RER PRED",
					Label: "RER PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "RER %PRED",
					Label: "RER %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "RER ZScore",
					Label: "RER ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved (IF) Baby-Body PRED",
					Label: "Reserved (IF) Baby-Body PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved (IF) Baby-Body %PRED",
					Label: "Reserved (IF) Baby-Body %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved (IF) Baby-Body ZScore",
					Label: "Reserved (IF) Baby-Body ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_01 PRED",
					Label: "Reserved_01 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_01 %PRED",
					Label: "Reserved_01 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_01 ZScore",
					Label: "Reserved_01 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_02 PRED",
					Label: "Reserved_02 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_02 %PRED",
					Label: "Reserved_02 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_02 ZScore",
					Label: "Reserved_02 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_03 PRED",
					Label: "Reserved_03 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_03 %PRED",
					Label: "Reserved_03 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_03 ZScore",
					Label: "Reserved_03 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_04 PRED",
					Label: "Reserved_04 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_04 %PRED",
					Label: "Reserved_04 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_04 ZScore",
					Label: "Reserved_04 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_05 PRED",
					Label: "Reserved_05 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_05 %PRED",
					Label: "Reserved_05 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_05 ZScore",
					Label: "Reserved_05 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_06 PRED",
					Label: "Reserved_06 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_06 %PRED",
					Label: "Reserved_06 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_06 ZScore",
					Label: "Reserved_06 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_07 PRED",
					Label: "Reserved_07 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_07 %PRED",
					Label: "Reserved_07 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_07 ZScore",
					Label: "Reserved_07 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_08 PRED",
					Label: "Reserved_08 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_08 %PRED",
					Label: "Reserved_08 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_08 ZScore",
					Label: "Reserved_08 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_09 PRED",
					Label: "Reserved_09 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_09 %PRED",
					Label: "Reserved_09 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_09 ZScore",
					Label: "Reserved_09 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_10 PRED",
					Label: "Reserved_10 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_10 %PRED",
					Label: "Reserved_10 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_10 ZScore",
					Label: "Reserved_10 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_11 PRED",
					Label: "Reserved_11 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_11 %PRED",
					Label: "Reserved_11 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_11 ZScore",
					Label: "Reserved_11 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_12 PRED",
					Label: "Reserved_12 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_12 %PRED",
					Label: "Reserved_12 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_12 ZScore",
					Label: "Reserved_12 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_13 PRED",
					Label: "Reserved_13 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_13 %PRED",
					Label: "Reserved_13 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_13 ZScore",
					Label: "Reserved_13 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_14 PRED",
					Label: "Reserved_14 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_14 %PRED",
					Label: "Reserved_14 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_14 ZScore",
					Label: "Reserved_14 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_15 PRED",
					Label: "Reserved_15 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_15 %PRED",
					Label: "Reserved_15 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_15 ZScore",
					Label: "Reserved_15 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_16 PRED",
					Label: "Reserved_16 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_16 %PRED",
					Label: "Reserved_16 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Reserved_16 ZScore",
					Label: "Reserved_16 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Rocc PRED",
					Label: "Rocc PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Rocc %PRED",
					Label: "Rocc %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Rocc ZScore",
					Label: "Rocc ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Rrs DO (Baby R/C) PRED",
					Label: "Rrs DO (Baby R/C) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Rrs DO (Baby R/C) %PRED",
					Label: "Rrs DO (Baby R/C) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Rrs DO (Baby R/C) ZScore",
					Label: "Rrs DO (Baby R/C) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "RV_Dil PRED",
					Label: "RV_Dil PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "RV_Dil %PRED",
					Label: "RV_Dil %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "RV_Dil ZScore",
					Label: "RV_Dil ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "RV_Dil % TLC_Dil PRED",
					Label: "RV_Dil % TLC_Dil PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "RV_Dil % TLC_Dil %PRED",
					Label: "RV_Dil % TLC_Dil %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "RV_Dil % TLC_Dil ZScore",
					Label: "RV_Dil % TLC_Dil ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "RV_Pleth PRED",
					Label: "RV_Pleth PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "RV_Pleth %PRED",
					Label: "RV_Pleth %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "RV_Pleth ZScore",
					Label: "RV_Pleth ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "RV_Pleth % TLC_Pleth PRED",
					Label: "RV_Pleth % TLC_Pleth PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "RV_Pleth % TLC_Pleth %PRED",
					Label: "RV_Pleth % TLC_Pleth %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "RV_Pleth % TLC_Pleth ZScore",
					Label: "RV_Pleth % TLC_Pleth ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "SaO2 PRED",
					Label: "SaO2 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "SaO2 %PRED",
					Label: "SaO2 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "SaO2 ZScore",
					Label: "SaO2 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "sG 0.5 PRED",
					Label: "sG 0.5 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sG 0.5 %PRED",
					Label: "sG 0.5 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sG 0.5 ZScore",
					Label: "sG 0.5 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "sG eff PRED",
					Label: "sG eff PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sG eff %PRED",
					Label: "sG eff %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sG eff ZScore",
					Label: "sG eff ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "sG mid PRED",
					Label: "sG mid PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sG mid %PRED",
					Label: "sG mid %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sG mid ZScore",
					Label: "sG mid ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "sG peak PRED",
					Label: "sG peak PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sG peak %PRED",
					Label: "sG peak %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sG peak ZScore",
					Label: "sG peak ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "sG tot PRED",
					Label: "sG tot PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sG tot %PRED",
					Label: "sG tot %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sG tot ZScore",
					Label: "sG tot ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "sGaw PRED",
					Label: "sGaw PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sGaw %PRED",
					Label: "sGaw %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sGaw ZScore",
					Label: "sGaw ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "SNIP PRED",
					Label: "SNIP PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "SNIP %PRED",
					Label: "SNIP %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "SNIP ZScore",
					Label: "SNIP ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "SNIP avg PRED",
					Label: "SNIP avg PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "SNIP avg %PRED",
					Label: "SNIP avg %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "SNIP avg ZScore",
					Label: "SNIP avg ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "SNIP peak PRED",
					Label: "SNIP peak PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "SNIP peak %PRED",
					Label: "SNIP peak %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "SNIP peak ZScore",
					Label: "SNIP peak ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "SNIP sus PRED",
					Label: "SNIP sus PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "SNIP sus %PRED",
					Label: "SNIP sus %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "SNIP sus ZScore",
					Label: "SNIP sus ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "spec. W el PRED",
					Label: "spec. W el PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "spec. W el %PRED",
					Label: "spec. W el %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "spec. W el ZScore",
					Label: "spec. W el ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "spec. W vis PRED",
					Label: "spec. W vis PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "spec. W vis %PRED",
					Label: "spec. W vis %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "spec. W vis ZScore",
					Label: "spec. W vis ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "sR 0.5 PRED",
					Label: "sR 0.5 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sR 0.5 %PRED",
					Label: "sR 0.5 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sR 0.5 ZScore",
					Label: "sR 0.5 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "sR eff PRED",
					Label: "sR eff PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sR eff %PRED",
					Label: "sR eff %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sR eff ZScore",
					Label: "sR eff ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "sR mid PRED",
					Label: "sR mid PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sR mid %PRED",
					Label: "sR mid %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sR mid ZScore",
					Label: "sR mid ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "sR peak PRED",
					Label: "sR peak PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sR peak %PRED",
					Label: "sR peak %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sR peak ZScore",
					Label: "sR peak ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "sR tot PRED",
					Label: "sR tot PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sR tot %PRED",
					Label: "sR tot %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sR tot ZScore",
					Label: "sR tot ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "sRaw PRED",
					Label: "sRaw PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sRaw %PRED",
					Label: "sRaw %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "sRaw ZScore",
					Label: "sRaw ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "STB PRED",
					Label: "STB PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "STB %PRED",
					Label: "STB %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "STB ZScore",
					Label: "STB ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Stroke volume PRED",
					Label: "Stroke volume PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Stroke volume %PRED",
					Label: "Stroke volume %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Stroke volume ZScore",
					Label: "Stroke volume ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Stroke volume index PRED",
					Label: "Stroke volume index PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Stroke volume index %PRED",
					Label: "Stroke volume index %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Stroke volume index ZScore",
					Label: "Stroke volume index ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "T Body PRED",
					Label: "T Body PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "T Body %PRED",
					Label: "T Body %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "T Body ZScore",
					Label: "T Body ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "TCO2 PRED",
					Label: "TCO2 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "TCO2 %PRED",
					Label: "TCO2 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "TCO2 ZScore",
					Label: "TCO2 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Time constant (Tau) PRED",
					Label: "Time constant (Tau) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Time constant (Tau) %PRED",
					Label: "Time constant (Tau) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Time constant (Tau) ZScore",
					Label: "Time constant (Tau) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Tin/Ttot PRED",
					Label: "Tin/Ttot PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Tin/Ttot %PRED",
					Label: "Tin/Ttot %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Tin/Ttot ZScore",
					Label: "Tin/Ttot ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "TLC_Dil PRED",
					Label: "TLC_Dil PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "TLC_Dil %PRED",
					Label: "TLC_Dil %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "TLC_Dil ZScore",
					Label: "TLC_Dil ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "TLC_Pleth PRED",
					Label: "TLC_Pleth PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "TLC_Pleth %PRED",
					Label: "TLC_Pleth %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "TLC_Pleth ZScore",
					Label: "TLC_Pleth ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "TOT / Kcal /day PRED",
					Label: "TOT / Kcal /day PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "TOT / Kcal /day %PRED",
					Label: "TOT / Kcal /day %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "TOT / Kcal /day ZScore",
					Label: "TOT / Kcal /day ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "UN PRED",
					Label: "UN PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "UN %PRED",
					Label: "UN %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "UN ZScore",
					Label: "UN ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "VA PRED",
					Label: "VA PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VA %PRED",
					Label: "VA %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VA ZScore",
					Label: "VA ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "VC PRED",
					Label: "VC PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VC %PRED",
					Label: "VC %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VC ZScore",
					Label: "VC ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Vc (capilar blood volume) PRED",
					Label: "Vc (capilar blood volume) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Vc (capilar blood volume) %PRED",
					Label: "Vc (capilar blood volume) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Vc (capilar blood volume) ZScore",
					Label: "Vc (capilar blood volume) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "VC max PRED",
					Label: "VC max PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VC max %PRED",
					Label: "VC max %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VC max ZScore",
					Label: "VC max ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "VCO2 PRED",
					Label: "VCO2 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VCO2 %PRED",
					Label: "VCO2 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VCO2 ZScore",
					Label: "VCO2 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "VD / VT PRED",
					Label: "VD / VT PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VD / VT %PRED",
					Label: "VD / VT %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VD / VT ZScore",
					Label: "VD / VT ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "VE slope (VCO2) PRED",
					Label: "VE slope (VCO2) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VE slope (VCO2) %PRED",
					Label: "VE slope (VCO2) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VE slope (VCO2) ZScore",
					Label: "VE slope (VCO2) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "VE slope (VO2) PRED",
					Label: "VE slope (VO2) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VE slope (VO2) %PRED",
					Label: "VE slope (VO2) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VE slope (VO2) ZScore",
					Label: "VE slope (VO2) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "VO2 PRED",
					Label: "VO2 PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VO2 %PRED",
					Label: "VO2 %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VO2 ZScore",
					Label: "VO2 ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "VO2 / kg PRED",
					Label: "VO2 / kg PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VO2 / kg %PRED",
					Label: "VO2 / kg %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VO2 / kg ZScore",
					Label: "VO2 / kg ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "VO2 slope (watt) PRED",
					Label: "VO2 slope (watt) PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VO2 slope (watt) %PRED",
					Label: "VO2 slope (watt) %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VO2 slope (watt) ZScore",
					Label: "VO2 slope (watt) ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "VT PRED",
					Label: "VT PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VT %PRED",
					Label: "VT %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "VT ZScore",
					Label: "VT ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "W el PRED",
					Label: "W el PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "W el %PRED",
					Label: "W el %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "W el ZScore",
					Label: "W el ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "W vis PRED",
					Label: "W vis PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "W vis %PRED",
					Label: "W vis %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "W vis ZScore",
					Label: "W vis ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 10Hz PRED",
					Label: "X at 10Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 10Hz %PRED",
					Label: "X at 10Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 10Hz ZScore",
					Label: "X at 10Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 15Hz PRED",
					Label: "X at 15Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 15Hz %PRED",
					Label: "X at 15Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 15Hz ZScore",
					Label: "X at 15Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 20Hz PRED",
					Label: "X at 20Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 20Hz %PRED",
					Label: "X at 20Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 20Hz ZScore",
					Label: "X at 20Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 25Hz PRED",
					Label: "X at 25Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 25Hz %PRED",
					Label: "X at 25Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 25Hz ZScore",
					Label: "X at 25Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 35Hz PRED",
					Label: "X at 35Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 35Hz %PRED",
					Label: "X at 35Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 35Hz ZScore",
					Label: "X at 35Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 5Hz PRED",
					Label: "X at 5Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 5Hz %PRED",
					Label: "X at 5Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "X at 5Hz ZScore",
					Label: "X at 5Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Z at 5Hz PRED",
					Label: "Z at 5Hz PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Z at 5Hz %PRED",
					Label: "Z at 5Hz %PRED",
					Type: "float",
					Comment: null
				}, {
					Name: "Z at 5Hz ZScore",
					Label: "Z at 5Hz ZScore",
					Type: "float",
					Comment: null
				}, {
					Name: "Property 1",
					Label: "Property 1",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 2",
					Label: "Property 2",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 3",
					Label: "Property 3",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 4",
					Label: "Property 4",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 5",
					Label: "Property 5",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 6",
					Label: "Property 6",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 7",
					Label: "Property 7",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 8",
					Label: "Property 8",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 9",
					Label: "Property 9",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 10",
					Label: "Property 10",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 11",
					Label: "Property 11",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 12",
					Label: "Property 12",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 13",
					Label: "Property 13",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 14",
					Label: "Property 14",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Property 15",
					Label: "Property 15",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Diagnosis",
					Label: "Diagnosis",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Anamnesis",
					Label: "Anamnesis",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Medication",
					Label: "Medication",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Occupational Exposure",
					Label: "Occupational Exposure",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Location",
					Label: "Location",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Room",
					Label: "Room",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Referring Physician Id",
					Label: "Referring Physician Id",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Referring Physician Name",
					Label: "Referring Physician Name",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Operator Id",
					Label: "Operator Id",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Operator Name",
					Label: "Operator Name",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Physician Id",
					Label: "Physician Id",
					Type: "nvarchar",
					Comment: null
				}, {
					Name: "Physician Name",
					Label: "Physician Name",
					Type: "nvarchar",
					Comment: null
				}
			]
		}
	]
};
