var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rounded border shadow-sm", class: { group: !_vm.isRoot } },
    [
      _c(
        "div",
        { staticClass: "pt-2 pl-4 pb-2", class: { "pr-2": _vm.isRoot } },
        [
          _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex w-100 position-relative justify-content-end pr-2"
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        left: "-15px",
                        "z-index": "15"
                      }
                    },
                    [
                      _c("div", { staticClass: "btn-group btn-group-sm" }, [
                        _c(
                          "button",
                          {
                            ref: "operatorButtonAll",
                            staticClass: "btn btn-light",
                            class: {
                              active: _vm.segment.logicalOperator === "all"
                            },
                            on: {
                              click: function($event) {
                                return _vm.setLogicalOperator("all")
                              }
                            }
                          },
                          [_vm._v("\n              AND\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            ref: "operatorButtonAny",
                            staticClass: "btn btn-light",
                            class: {
                              active: _vm.segment.logicalOperator === "any"
                            },
                            on: {
                              click: function($event) {
                                return _vm.setLogicalOperator("any")
                              }
                            }
                          },
                          [_vm._v("\n              OR\n            ")]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "button",
                      {
                        ref: "addRuleButton",
                        staticClass: "btn btn-sm btn-light",
                        on: { click: _vm.addRule }
                      },
                      [
                        _c("i", { staticClass: "fas fa-plus" }),
                        _vm._v(" add rule\n          ")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        ref: "addGroupButton",
                        staticClass: "btn btn-sm btn-light",
                        on: { click: _vm.addGroup }
                      },
                      [
                        _c("i", { staticClass: "fas fa-plus" }),
                        _vm._v(" add group\n          ")
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.isRoot
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-light ml-2",
                            on: { click: _vm.removeGroup }
                          },
                          [_c("i", { staticClass: "fas fa-trash" })]
                        )
                      : _vm._e()
                  ])
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.segment.childIds, function(id) {
                return _c(
                  "div",
                  { key: "segment-" + id, staticClass: "w-100 segment" },
                  [
                    _vm.segmentTypes[id] === "group"
                      ? _c("QueryBuilderGroup", { attrs: { id: id } })
                      : _c("QueryBuilderRule", { attrs: { id: id } })
                  ],
                  1
                )
              })
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }