<template>
  <div class="container-fluid border-bottom pb-3 mb-3">
    <div class="row">
      <div class="col-4">
        <span class="text-muted">{{measName}}:</span>
        <b
          :title="param.LongName"
          style="cursor: help; border-bottom: 1px dotted #000;"
        >{{param.ShortName}} ({{param.Id}})</b>
        ({{pIndex}})
      </div>
      <div class="col-4">
        <span class="text-muted">Unit (short):</span>
        <b>{{param.UnitShort}}</b>
      </div>
      <div class="col-4">
        <span class="text-muted">Unit (long):</span>
        <b>{{param.UnitLong}}</b>
      </div>
    </div>
    <div class="row">
      <div class="col-12">{{param.Comment}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Parameter",
  props: {
    measName: { type: String },
    param: {
      type: Object
    }
  },
  computed: {
    pIndex() {
      return (this.param.Id & 0xffff) - 1;
    }
  }
};
</script>