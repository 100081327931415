import Vue from "vue";
import Vuex from "vuex";

import templates from "@/store/templates";
import query from "@/store/query";
import auth from "@/store/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    auth,
    templates,
    query
  }
});
