var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.edit
    ? _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.save,
              expression: "save"
            }
          ],
          staticClass: "bg-light rounded"
        },
        [
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.activeMeasMethodId,
                    expression: "activeMeasMethodId"
                  }
                ],
                ref: "input",
                staticClass: "form-control form-control-sm",
                attrs: { id: "measMethodId" },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape"
                      ])
                    ) {
                      return null
                    }
                    return _vm.cancel($event)
                  },
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.activeMeasMethodId = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(_vm.mdOptions, function(o) {
                return _c("option", { domProps: { value: o.id } }, [
                  _vm._v(_vm._s(o.shortName))
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.activeParameterId,
                    expression: "activeParameterId"
                  }
                ],
                staticClass: "form-control form-control-sm",
                attrs: { id: "parameterId" },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape"
                      ])
                    ) {
                      return null
                    }
                    return _vm.cancel($event)
                  },
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.activeParameterId = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(_vm.paramOptions, function(o) {
                return _c("option", { domProps: { value: o.id } }, [
                  _vm._v(_vm._s(o.shortName))
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm bg-light",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.remove($event)
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-trash" })]
            )
          ])
        ]
      )
    : _c(
        "div",
        {
          staticClass: "d-flex rounded hoverable",
          on: { click: _vm.startEditing }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-100 form-control-sm d-flex align-items-center justify-content-between"
            },
            [
              _vm.parameter
                ? _c("div", [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.measurementDescription.shortName) +
                        " (" +
                        _vm._s(_vm.measMethodId) +
                        "):\n      " +
                        _vm._s(_vm.parameter.shortName) +
                        " (" +
                        _vm._s(_vm.parameterId) +
                        ")\n    "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("i", { staticClass: "edit-indicator fas fa-pen text-muted" })
            ]
          )
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }