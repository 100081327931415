var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100" },
    [
      _c(
        "div",
        {
          staticClass:
            "h-100 overflow-auto d-flex flex-column position-relative"
        },
        [
          _c("div", { staticClass: "flex-grow-1 p-2 overflow-auto" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters" },
              [
                _vm._l(_vm.templates, function(t) {
                  return _c(
                    "div",
                    { key: t.id, staticClass: "col-4 p-2" },
                    [
                      _c("TemplateCard", {
                        attrs: { id: t.id },
                        nativeOn: {
                          click: function($event) {
                            return (function() {
                              t._selected
                                ? _vm.uncheckTemplate(t.id)
                                : _vm.checkTemplate(t.id)
                            })($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "col-4 p-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-100 p-2 add-template-placeholder cursor-pointer rounded border d-flex flex-column justify-content-center align-items-center",
                      on: { click: _vm.newTemplate }
                    },
                    [
                      _c("i", { staticClass: "fas fa-2x fa-plus" }),
                      _vm._v("\n            Create new template\n          ")
                    ]
                  )
                ])
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "p-2 bg-light border-top button-bar d-flex align-items-center justify-content-between"
            },
            [
              _c("div", [
                _c("input", {
                  ref: "fileInput",
                  staticStyle: { display: "none" },
                  attrs: { type: "file", name: "filter" },
                  on: { change: _vm.importFromFile }
                }),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-light",
                      on: {
                        click: function($event) {
                          return _vm.$refs.fileInput.click()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-upload" }),
                      _vm._v(" Import\n          ")
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-light",
                      on: { click: _vm.exportToFile }
                    },
                    [
                      _c("i", { staticClass: "fas fa-download" }),
                      _vm._v(" Export\n          ")
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary",
                    attrs: { disabled: _vm.isDeployDisabled },
                    on: {
                      click: function($event) {
                        return _vm.$bvModal.show("deploy-cube-modal")
                      }
                    }
                  },
                  [_vm._v("Deploy Cube")]
                )
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("EditTemplateModal"),
      _vm._v(" "),
      _c("ShowTemplateModal"),
      _vm._v(" "),
      _c("DeployCubeModal")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h6" }, [
      _vm._v("\n        Templates\n        "),
      _c("div", { staticClass: "small font-weight-normal text-muted" }, [
        _vm._v("Select/Edit the templates you want to deploy")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }