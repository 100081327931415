var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { id: "edit-template-modal", title: "Edit Template", size: "lg" },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function(ref) {
            var ok = ref.ok
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  attrs: { disabled: _vm.isTemplateInvalid },
                  on: {
                    click: function($event) {
                      return ok()
                    }
                  }
                },
                [_vm._v("OK")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("div", { staticClass: "p-2" }, [
        _c("div", { staticClass: "form-label" }, [_vm._v("Name")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-2" },
          [
            _c("TextInline", {
              attrs: { validate: _vm.validateName },
              model: {
                value: _vm.name,
                callback: function($$v) {
                  _vm.name = $$v
                },
                expression: "name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-label" }, [_vm._v("Label")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-2" },
          [
            _c("TextInline", {
              attrs: { validate: _vm.validateLabel },
              model: {
                value: _vm.label,
                callback: function($$v) {
                  _vm.label = $$v
                },
                expression: "label"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-label" }, [_vm._v("Description")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-2" },
          [
            _c("TextareaInline", {
              model: {
                value: _vm.description,
                callback: function($$v) {
                  _vm.description = $$v
                },
                expression: "description"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-label" }, [
          _vm._v("\n      Slots\n      "),
          _c(
            "button",
            { staticClass: "btn btn-sm btn-light", on: { click: _vm.addSlot } },
            [_c("i", { staticClass: "fas fa-plus" })]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "draggable",
              {
                staticClass: "drag-area",
                attrs: { "ghost-class": "shadow" },
                model: {
                  value: _vm.slotOrder,
                  callback: function($$v) {
                    _vm.slotOrder = $$v
                  },
                  expression: "slotOrder"
                }
              },
              _vm._l(_vm.slotOrder, function(id) {
                return _c("TemplateSlot", {
                  key: "slot-" + id,
                  staticClass: "cursor-move",
                  attrs: { id: id }
                })
              }),
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }