var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column", staticStyle: { height: "100vh" } },
    [
      _c(
        "div",
        {
          staticClass: "pl-2 bg-white border-bottom d-flex",
          staticStyle: {
            "min-height": "50px",
            height: "50px",
            "border-bottom-width": "2px !important"
          }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "router-link",
                {
                  staticClass: "link pl-2 pr-2 d-inline-block",
                  class: {
                    "router-link-active": _vm.$route.name === "connection"
                  },
                  attrs: { to: { name: "home" } }
                },
                [_vm._v("Home")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "link pl-2 pr-2 d-inline-block",
                  class: {
                    "router-link-active": _vm.$route.name === "login"
                  },
                  attrs: { to: { name: "configuration" } }
                },
                [_vm._v("Configuration")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "link pl-2 pr-2 d-inline-block",
                  attrs: { to: { name: "about" } }
                },
                [_vm._v("About")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.isLoggedIn
            ? _c(
                "div",
                {
                  staticClass:
                    "ml-auto p-2 d-flex align-items-center justify-content-end"
                },
                [
                  _c("div", { staticClass: "mr-2" }, [
                    _vm._v("\n        logged in as\n        "),
                    _c("strong", [_vm._v(_vm._s(_vm.username))]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-light",
                        on: { click: _vm.logout }
                      },
                      [_c("i", { staticClass: "fas fa-sign-out-alt" })]
                    )
                  ])
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "bg-white position-relative flex-grow-1 overflow-auto",
          attrs: { id: "AbContScroll" }
        },
        [_c("router-view")],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center mr-2" }, [
      _c("img", {
        attrs: { id: "review-logo", src: require("./assets/img/logo.png") }
      }),
      _vm._v("\n      DataCube (v 3.10)\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }