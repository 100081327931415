var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid border-bottom pb-3 mb-3" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c("span", { staticClass: "text-muted" }, [
          _vm._v(_vm._s(_vm.measName) + ":")
        ]),
        _vm._v(" "),
        _c(
          "b",
          {
            staticStyle: { cursor: "help", "border-bottom": "1px dotted #000" },
            attrs: { title: _vm.param.LongName }
          },
          [
            _vm._v(
              _vm._s(_vm.param.ShortName) + " (" + _vm._s(_vm.param.Id) + ")"
            )
          ]
        ),
        _vm._v("\n      (" + _vm._s(_vm.pIndex) + ")\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c("span", { staticClass: "text-muted" }, [_vm._v("Unit (short):")]),
        _vm._v(" "),
        _c("b", [_vm._v(_vm._s(_vm.param.UnitShort))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c("span", { staticClass: "text-muted" }, [_vm._v("Unit (long):")]),
        _vm._v(" "),
        _c("b", [_vm._v(_vm._s(_vm.param.UnitLong))])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [_vm._v(_vm._s(_vm.param.Comment))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }