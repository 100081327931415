var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        id: "show-template-modal",
        title: "Template Details",
        size: "lg"
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function(ref) {
            var ok = ref.ok
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary",
                  on: {
                    click: function($event) {
                      return ok()
                    }
                  }
                },
                [_vm._v("\n      OK\n    ")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("div", { staticClass: "p-2" }, [
        _c("div", { staticClass: "form-label" }, [
          _vm._v("\n      Name\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-2 small " }, [
          _vm._v("\n      " + _vm._s(_vm.name) + "\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-label" }, [
          _vm._v("\n      Label\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-2 small " }, [
          _vm._v("\n      " + _vm._s(_vm.label) + "\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-label" }, [
          _vm._v("\n      Description\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-2 small" }, [
          _vm._v("\n      " + _vm._s(_vm.description) + "\n    ")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }