var render = function(_h, _vm) {
  var _c = _vm._c
  return _c("div", { staticClass: "container-fluid border-bottom pb-3 mb-3" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c("span", { staticClass: "text-muted" }, [
          _vm._v(_vm._s(_vm.props.viewName) + ":")
        ]),
        _vm._v(" "),
        _c(
          "b",
          {
            staticStyle: { cursor: "help", "border-bottom": "1px dotted #000" },
            attrs: { title: _vm.props.col.Type }
          },
          [
            _vm._v(
              _vm._s(_vm.props.col.Name) +
                " (" +
                _vm._s(_vm.props.col.Type) +
                ")"
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-8" }, [
        _vm._v(_vm._s(_vm.props.col.Comment))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }