var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100 bg-white overflow-auto d-flex flex-column" },
    [
      _c("div", { staticClass: "flex-grow-1 p-2 overflow-auto" }, [
        _c(
          "div",
          {
            staticClass: "h6 d-flex align-items-center justify-content-between"
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "small pr-2 d-flex align-items-center justify-content-between"
              },
              [
                _c("div", [
                  _vm._v("\n          Connected as user\n          "),
                  _c("b", [_vm._v(_vm._s(_vm.connection.databaseUser))]),
                  _vm._v(" to database\n          "),
                  _c("b", [_vm._v(_vm._s(_vm.connection.databaseName))]),
                  _vm._v(" on\n          "),
                  _c("b", [_vm._v(_vm._s(_vm.connection.serverName))]),
                  _vm._v(
                    "\n          - Version: " +
                      _vm._s(_vm.version) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pl-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-primary",
                      on: { click: _vm.disconnect }
                    },
                    [_vm._v("Disconnect")]
                  )
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex mt-2 p-2" }, [
          _c("div", { staticStyle: { width: "300px" } }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name,
                    expression: "name"
                  }
                ],
                ref: "viewInput",
                staticClass: "form-control form-control-sm mb-2",
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.name = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function($event) {
                      return _vm.$refs.viewInput.blur()
                    }
                  ]
                }
              },
              _vm._l(_vm.views, function(view, idx) {
                return _c(
                  "option",
                  { key: idx, domProps: { value: view.name } },
                  [_vm._v(_vm._s(view.label))]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.selectedView
              ? _c("div", { staticClass: "small font-italic pl-2" }, [
                  _vm._v(_vm._s(_vm.selectedView.columns.length) + " columns")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-grow-1 pl-2" },
            [_vm.name !== "" ? _c("QueryBuilder") : _vm._e()],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "p-2 bg-light border-top button-bar d-flex align-items-center justify-content-between"
        },
        [
          _c("div", [
            _c("input", {
              ref: "fileInput",
              staticStyle: { display: "none" },
              attrs: { type: "file", name: "filter" },
              on: { change: _vm.importFromFile }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-light",
                on: {
                  click: function($event) {
                    return _vm.$refs.fileInput.click()
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-upload" }),
                _vm._v(" Import\n      ")
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-light",
                on: { click: _vm.exportToFile }
              },
              [
                _c("i", { staticClass: "fas fa-download" }),
                _vm._v(" Export\n      ")
              ]
            )
          ]),
          _vm._v(" "),
          _vm.count > -1
            ? _c("div", [_vm._v(_vm._s(_vm.count) + " results")])
            : _vm._e(),
          _vm._v(" "),
          _c("div", [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-primary",
                attrs: { disabled: _vm.isCountDisabled },
                on: { click: _vm.queryCount }
              },
              [_vm._v("Count")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-primary",
                attrs: { disabled: _vm.isDownloadDisabled },
                on: { click: _vm.download }
              },
              [_vm._v("Download")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n        Query\n        "),
      _c("div", { staticClass: "small font-weight-normal text-muted" }, [
        _vm._v("Select the view and create a query.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }