<template>
  <b-modal ref="modal" id="deploy-cube-modal" size="lg" scrollable>
    <div class="p-2">
      <div class="d-flex pt-2">
        <div class="w-50 pr-1">
          <div class="form-label">Source DB Name</div>
          <input type="text" class="form-control form-control-sm" v-model="sourceDBName" />
        </div>
        <div class="w-50 pl-1">
          <div class="form-label">Cube DB Name</div>
          <input type="text" class="form-control form-control-sm" v-model="cubeDBName" />
        </div>
      </div>
      <div class="d-flex pt-2">
        <div class="w-50 pr-1">
          <div class="form-label">Servername</div>
          <input type="text" class="form-control form-control-sm" v-model="serverName" />
        </div>
        <div class="w-50 pl-1">
          <div class="form-label">Instance name / port number</div>
          <input type="text" class="form-control form-control-sm" v-model="instanceName" />
        </div>
      </div>
      <div class="d-flex pt-2">
        <div class="w-50 pr-1">
          <div class="form-label">Login name</div>
          <input type="text" class="form-control form-control-sm" v-model="loginName" />
        </div>
        <div class="w-50 pl-1">
          <div class="form-label">Password</div>
          <input type="password" class="form-control form-control-sm" v-model="password" />
        </div>
      </div>
      <div class="d-flex pt-2">
        <div class="w-50 pr-1">
          <div class="form-label">Cube Login name</div>
          <input type="text" class="form-control form-control-sm" v-model="cubeLoginName" />
        </div>
        <div class="w-50 pl-1">
          <div class="form-label">Cube Password</div>
          <input type="password" class="form-control form-control-sm" v-model="cubePassword" />
        </div>
      </div>
      <div class="mt-2 d-flex align-items-center" v-if="running">
        Loading...
        <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div>
      </div>
      <div class="mt-2">
        <div class="alert alert-danger" v-if="error">{{ error }}</div>
        <template v-if="script">
          <div class="form-label mt-2">Script</div>
          <textarea class="form-control" style="min-height: 250px;">
        {{ script }}</textarea>
        </template>
      </div>
    </div>

    <template slot="modal-footer" slot-scope="{ cancel }">
      <button class="btn btn-sm btn-light" @click="exportScript()" :disabled="running">Export Script</button>
      <button class="btn btn-sm btn-primary" @click="deploy()" :disabled="running">Deploy</button>
      <button class="btn btn-sm btn-light" @click="cancel()">Cancel</button>
    </template>
  </b-modal>
</template>

<script>
import {
  UPDATE_DEPLOYMENT_PASSWORD,
  UPDATE_DEPLOYMENT_LOGIN_NAME,
  UPDATE_DEPLOYMENT_CUBE_PASSWORD,
  UPDATE_DEPLOYMENT_CUBE_LOGIN_NAME,
  UPDATE_DEPLOYMENT_INSTANCE_NAME,
  UPDATE_DEPLOYMENT_SERVER_NAME,
  UPDATE_DEPLOYMENT_CUBE_DB_NAME,
  UPDATE_DEPLOYMENT_SOURCE_DB_NAME
} from "@/store/templates";
import Api, { convertConfigurationToBackend } from "@/common/api";

import { TEMPLATE_TYPE_USER } from "@/domain";

export default {
  name: "DeployCubeModal",
  props: {
    id: String
  },
  data() {
    return {
      script: null,
      error: null,
      running: false
    };
  },
  computed: {
    sourceDBName: {
      get() {
        return this.$store.getters.deployment.sourceDBName;
      },
      set(val) {
        this.$store.dispatch(UPDATE_DEPLOYMENT_SOURCE_DB_NAME, val);
      }
    },
    cubeDBName: {
      get() {
        return this.$store.getters.deployment.cubeDBName;
      },
      set(val) {
        this.$store.dispatch(UPDATE_DEPLOYMENT_CUBE_DB_NAME, val);
      }
    },
    serverName: {
      get() {
        return this.$store.getters.deployment.serverName;
      },
      set(val) {
        this.$store.dispatch(UPDATE_DEPLOYMENT_SERVER_NAME, val);
      }
    },
    instanceName: {
      get() {
        return this.$store.getters.deployment.instanceName;
      },
      set(val) {
        this.$store.dispatch(UPDATE_DEPLOYMENT_INSTANCE_NAME, val);
      }
    },
    loginName: {
      get() {
        return this.$store.getters.deployment.loginName;
      },
      set(val) {
        this.$store.dispatch(UPDATE_DEPLOYMENT_LOGIN_NAME, val);
      }
    },
    password: {
      get() {
        return this.$store.getters.deployment.password;
      },
      set(val) {
        this.$store.dispatch(UPDATE_DEPLOYMENT_PASSWORD, val);
      }
    },
    cubeLoginName: {
      get() {
        return this.$store.getters.deployment.cubeLoginName;
      },
      set(val) {
        this.$store.dispatch(UPDATE_DEPLOYMENT_CUBE_LOGIN_NAME, val);
      }
    },
    cubePassword: {
      get() {
        return this.$store.getters.deployment.cubePassword;
      },
      set(val) {
        this.$store.dispatch(UPDATE_DEPLOYMENT_CUBE_PASSWORD, val);
      }
    }
  },
  methods: {
    reset() {
      this.script = null;
      this.error = null;
      this.running = false;
    },
    deploy() {
      this.reset();
      // convert templates to backend format
      const ut = {};
      const bt = {};
      const templates = this.$store.getters.templates;
      const token = this.$store.getters.token;
      const slots = this.$store.getters.slots;
      Object.keys(templates).forEach(k => {
        const t = templates[k];
        if (!t._selected) {
          return;
        }
        if (t.type === TEMPLATE_TYPE_USER) {
          ut[k] = templates[k];
        } else {
          bt[k] = templates[k];
        }
      });
      const userTemplates = convertConfigurationToBackend(ut, slots);
      const baseTemplates = convertConfigurationToBackend(bt, slots);
      this.running = true;
      return Api.CreateCubeDB(
        token,
        this.serverName,
        this.instanceName,
        this.cubeDBName,
        this.sourceDBName,
        this.loginName,
        this.password,
        this.cubeLoginName,
        this.cubePassword,
        baseTemplates,
        userTemplates
      )
        .then(d => {
          this.script = atob(d.Data);
          this.running = false;
        })
        .catch(err => {
          this.error = err;
          this.running = false;
        });
    },
    exportScript() {
      this.reset();
      // convert templates to backend format
      const ut = {};
      const bt = {};
      const templates = this.$store.getters.templates;
      const token = this.$store.getters.token;
      const slots = this.$store.getters.slots;
      this.running = true;
      Object.keys(templates).forEach(k => {
        const t = templates[k];
        if (!t._selected) {
          return;
        }
        if (t.type === TEMPLATE_TYPE_USER) {
          ut[k] = templates[k];
        } else {
          bt[k] = templates[k];
        }
      });
      const userTemplates = convertConfigurationToBackend(ut, slots);
      const baseTemplates = convertConfigurationToBackend(bt, slots);
      return Api.CreateCubeScript(
        token,
        this.cubeDBName,
        this.sourceDBName,
        baseTemplates,
        userTemplates
      )
        .then(d => {
          this.script = atob(d.Data);
          this.running = false;
        })
        .catch(err => {
          this.error = err;
          this.running = false;
        });
    }
  }
};
</script>

<style scoped></style>
