var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "p-2",
      class: {
        "border border-danger": _vm.paramCount === 0,
        border: _vm.paramCount !== 0
      }
    },
    [
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          {
            staticStyle: {
              width: "35px",
              "min-width": "35px",
              "max-width": "35px"
            },
            on: {
              click: function() {
                _vm.collapsed ? _vm.expand() : _vm.hide()
              }
            }
          },
          [
            _c("i", {
              staticClass: "fas",
              class: {
                "fa-chevron-down": _vm.collapsed,
                "fa-chevron-up": !_vm.collapsed
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-100" },
          [
            _c("div", { staticClass: "form-label" }, [_vm._v("Name")]),
            _vm._v(" "),
            _c("TextInline", {
              attrs: { type: "text", validate: _vm.validateName },
              model: {
                value: _vm.name,
                callback: function($$v) {
                  _vm.name = $$v
                },
                expression: "name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-100" },
          [
            _c("div", { staticClass: "form-label" }, [_vm._v("Function")]),
            _vm._v(" "),
            _c("SelectInline", {
              attrs: { options: _vm.operations },
              model: {
                value: _vm.operation,
                callback: function($$v) {
                  _vm.operation = $$v
                },
                expression: "operation"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ml-3 mr-3" }, [
          _c(
            "div",
            {
              staticClass: "badge",
              class: {
                "badge-danger": _vm.paramCount === 0,
                "badge-light": _vm.paramCount > 0
              }
            },
            [_vm._v(_vm._s(_vm.paramCount) + " parameter(s)")]
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            { staticClass: "btn btn-sm btn-light", on: { click: _vm.remove } },
            [_c("i", { staticClass: "fas fa-trash" })]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ml-3 mr-3" }, [
        _vm.referenceValueDisplay
          ? _c("div", { staticClass: "badge badge-light" }, [
              _vm._v(
                "Selected parameter for reference value output: " +
                  _vm._s(_vm.referenceValueDisplay)
              )
            ])
          : _c("div", { staticClass: "badge" }, [
              _vm._v("No parameter selected for reference value output")
            ])
      ]),
      _vm._v(" "),
      !_vm.collapsed
        ? _c("div", { staticStyle: { "margin-left": "35px" } }, [
            _c("div", { staticClass: "form-label" }, [_vm._v("Parameter(s)")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row" },
              [
                _vm._l(_vm.slot.parameters, function(p, idx) {
                  return _c("div", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", [
                        _c("input", {
                          attrs: {
                            type: "checkbox",
                            disabled:
                              !p.isRefValue && _vm.isDisabledRefValue(p.id)
                          },
                          domProps: { checked: p.isRefValue },
                          on: {
                            click: function($event) {
                              return _vm.setRefValue(p.id)
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("ParameterInline", {
                            key: "parameter-" + p.id,
                            attrs: {
                              idx: idx,
                              slotId: _vm.slot.id,
                              parameterId: p.id,
                              measMethodId: p.measMethodId,
                              measMethodOptions: _vm.measMethodOptions
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                }),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "d-flex bg-light rounded" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newMeasMethodId,
                            expression: "newMeasMethodId"
                          }
                        ],
                        ref: "input",
                        staticClass: "form-control form-control-sm",
                        attrs: { id: "measMethodId" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.newMeasMethodId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._l(_vm.measMethodOptions, function(o) {
                        return _c("option", { domProps: { value: o.id } }, [
                          _vm._v(_vm._s(o.shortName))
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newParameterId,
                            expression: "newParameterId"
                          }
                        ],
                        staticClass: "form-control form-control-sm",
                        attrs: { id: "parameterId" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.newParameterId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._l(_vm.paramOptions, function(o) {
                        return _c("option", { domProps: { value: o.id } }, [
                          _vm._v(_vm._s(o.shortName))
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-light",
                        on: { click: _vm.addParameter }
                      },
                      [_c("i", { staticClass: "fas fa-plus" })]
                    )
                  ])
                ])
              ],
              2
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "text-right" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }