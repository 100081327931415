<template>
  <div class="h-100 d-flex justify-content-center align-items-center">
    <div class="w-25">
      <form v-on:submit.prevent="connect">
        <div class="alert alert-danger" v-if="error">{{ error }}</div>
        <div class="pt-2 form-label">Servername</div>
        <input type="text" class="form-control form-control-sm" v-model="serverName" />
        <div class="pt-2 form-label">Instance name / port number</div>
        <input type="text" class="form-control form-control-sm" v-model="instanceName" />
        <div class="pt-2 form-label">Database</div>
        <input type="text" class="form-control form-control-sm" v-model="databaseName" />
        <div class="pt-2 form-label">User</div>
        <input type="text" class="form-control form-control-sm" v-model="databaseUser" />
        <div class="pt-2 form-label">Password</div>
        <input type="password" class="form-control form-control-sm" v-model="databasePassword" />
        <div class="mt-2 text-right">
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            @click="connect"
            :disabled="isConnectDisabled"
          >Connect</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { CONNECT } from "@/store/query";

export default {
  name: "Connection",
  data() {
    return {
      serverName: "localhost",
      instanceName: "SENTRYSUITE",
      databaseName: null,
      databaseUser: null,
      databasePassword: null,
      error: null
    };
  },
  computed: {
    isConnectDisabled() {
      return (
        !this.databaseUser ||
        !this.databasePassword ||
        !this.serverName ||
        !this.databaseName
      );
    }
  },
  methods: {
    connect() {
      this.error = null;
      this.$store
        .dispatch(CONNECT, {
          serverName: this.serverName,
          instanceName: this.instanceName,
          databaseName: this.databaseName,
          databaseUser: this.databaseUser,
          databasePassword: this.databasePassword
        })
        .then(() => {
          this.$router.push({ name: "home" }).catch(err => {
            console.log(err);
          });
          this.databasePassword = null;
        })
        .catch(err => {
          this.error = err;
          this.databasePassword = null;
        });
    }
  }
};
</script>

<style scoped></style>
