<template>
  <div class="h-100">
    <!-- left -->
    <div class="h-100 overflow-auto d-flex flex-column position-relative">
      <div class="flex-grow-1 p-2 overflow-auto">
        <div class="h6">
          Templates
          <div
            class="small font-weight-normal text-muted"
          >Select/Edit the templates you want to deploy</div>
        </div>
        <div class="row no-gutters">
          <div v-for="t in templates" :key="t.id" class="col-4 p-2">
            <TemplateCard
              :id="t.id"
              @click.native="
                () => {
                  t._selected ? uncheckTemplate(t.id) : checkTemplate(t.id);
                }
              "
            ></TemplateCard>
          </div>
          <div class="col-4 p-2">
            <div
              class="h-100 p-2 add-template-placeholder cursor-pointer rounded border d-flex flex-column justify-content-center align-items-center"
              @click="newTemplate"
            >
              <i class="fas fa-2x fa-plus"></i>
              Create new template
            </div>
          </div>
        </div>
      </div>
      <!-- bottom -->
      <div
        class="p-2 bg-light border-top button-bar d-flex align-items-center justify-content-between"
      >
        <div>
          <input
            type="file"
            name="filter"
            ref="fileInput"
            @change="importFromFile"
            style="display: none"
          />
          <div>
            <button class="btn btn-sm btn-light" @click="$refs.fileInput.click()">
              <i class="fas fa-upload"></i> Import
            </button>
            <button class="btn btn-sm btn-light" @click="exportToFile">
              <i class="fas fa-download"></i> Export
            </button>
          </div>
        </div>
        <div>
          <button
            class="btn btn-sm btn-primary"
            :disabled="isDeployDisabled"
            @click="$bvModal.show('deploy-cube-modal')"
          >Deploy Cube</button>
        </div>
      </div>
    </div>

    <EditTemplateModal></EditTemplateModal>
    <ShowTemplateModal></ShowTemplateModal>
    <DeployCubeModal></DeployCubeModal>
  </div>
</template>

<script>
import {
  ADD_TEMPLATE,
  FETCH_BASE_TEMPLATES,
  FETCH_MEASUREMENT_DESCRIPTIONS,
  IMPORT_TEMPLATES,
  CLOSE_TEMPLATE_EDITOR,
  REMOVE_TEMPLATE_FROM_DEPLOYMENT,
  SELECT_TEMPLATE_FOR_DEPLOYMENT,
  RESET_MODIFIED,
  exportTemplates
} from "@/store/templates";

import DeployCubeModal from "@/components/configuration/DeployCubeModal";
import TemplateCard from "@/components/configuration/TemplateCard";
import EditTemplateModal from "@/components/configuration/EditTemplateModal";
import ShowTemplateModal from "@/components/configuration/ShowTemplateModal";

export default {
  name: "Configuration",
  components: {
    ShowTemplateModal,
    EditTemplateModal,
    TemplateCard,
    DeployCubeModal
  },
  computed: {
    templates() {
      return this.$store.getters.templates;
    },
    isDeployDisabled() {
      return this.$store.getters.selectedTemplateIds.length === 0;
    }
  },
  mounted() {
    this.$store.dispatch(FETCH_BASE_TEMPLATES);
    this.$store.dispatch(FETCH_MEASUREMENT_DESCRIPTIONS);
  },
  methods: {
    checkTemplate(id) {
      this.$store.dispatch(SELECT_TEMPLATE_FOR_DEPLOYMENT, id);
    },
    uncheckTemplate(id) {
      this.$store.dispatch(REMOVE_TEMPLATE_FROM_DEPLOYMENT, id);
    },
    newTemplate() {
      this.$store.dispatch(ADD_TEMPLATE);
    },
    importFromFile() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = evt => {
          try {
            const d = JSON.parse(evt.target.result);
            this.$store.dispatch(IMPORT_TEMPLATES, d);
          } catch (e) {
            alert(e);
          }
        };
        reader.onerror = evt => {
          alert(evt);
        };
      }
    },
    exportToFile() {
      const content = JSON.stringify(
        exportTemplates(
          this.$store.state.templates.templates,
          this.$store.state.templates.slots
        )
      );
      this.$store.dispatch(RESET_MODIFIED);
      const blob = new Blob([content]);
      const fileName = "ses-query-builder-templates.json";
      //for microsoft IE
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
    closeTemplateEditor() {
      this.$store.dispatch(CLOSE_TEMPLATE_EDITOR);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../custom";

.button-bar {
  min-height: 50px;
  height: 50px;
}
.hoverable {
  &:hover {
    background-color: map_get($theme-colors, "hover") !important;
  }
}
.template-list:nth-child(n + 1) {
  border-bottom: none !important;
}
.template-list:last-child {
  border-bottom: inherit !important;
}
.add-template-placeholder {
  opacity: 0.5;
  border-width: 2px !important;
  border-style: dashed !important;

  &:hover {
    opacity: 1;
  }
}
</style>
