<template>
  <textarea
    ref="input"
    class="form-control form-control-sm"
    id="description"
    rows="5"
    v-model="initialValue"
    @keyup.enter="save"
    @keyup.esc="cancel"
    v-click-outside="save"
    v-if="edit"
  ></textarea>
  <div
    class="w-100 hoverable form-control-sm d-flex align-items-center justify-content-between"
    style="height: auto !important;"
    v-else
    @click="startEditing"
  >
    <span>{{ initialValue }}</span>
    <span v-if="initialValue === ''">&nbsp;</span>
    <i class="edit-indicator fas fa-pen text-muted"></i>
  </div>
</template>

<script>
export default {
  name: "TextareaInline",
  props: ["value"],
  data() {
    return {
      edit: false,
      initialValue: this.value
    };
  },
  watch: {
    value(val) {
      this.initialValue = val;
    }
  },
  methods: {
    startEditing() {
      this.edit = true;
      this.$nextTick().then(() => {
        this.$refs.input.focus();
      });
    },
    save($event) {
      // allow multiline input when holding shift
      if ($event.shiftKey) {
        return;
      }
      console.log($event);
      this.$emit("input", this.initialValue);
      this.edit = false;
    },
    cancel() {
      this.initialValue = this.value;
      this.edit = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../_custom.scss";

.edit-indicator {
  display: none;
}
.hoverable {
  &:hover {
    background-color: map-get($theme-colors, "light") !important;
    cursor: text;

    .edit-indicator {
      display: inline-block;
    }
  }
}
</style>
