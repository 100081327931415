var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm._v("\n      Jump To:\n      "),
        _c(
          "button",
          {
            staticClass: "btn btn-link btn-sm",
            on: {
              click: function($event) {
                return _vm.ScrollTo("pd")
              }
            }
          },
          [_vm._v("Parameter Descriptions")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-link btn-sm",
            on: {
              click: function($event) {
                return _vm.ScrollTo("vd")
              }
            }
          },
          [_vm._v("View Descriptions")]
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("h2", { attrs: { id: "pd" } }, [
            _vm._v("\n        Parameter Descriptions\n        "),
            _c(
              "button",
              {
                staticClass: "btn btn-link btn-sm",
                on: {
                  click: function($event) {
                    return _vm.ScrollTo("AbContScroll")
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-arrow-alt-circle-up" })]
            )
          ]),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "The following section lists all parameters including identifiers, units and a short description of the parameter content:"
            )
          ]),
          _vm._v(" "),
          _vm._l(_vm.mds, function(m) {
            return _c(
              "div",
              { key: m.Id },
              [
                _c("h3", { staticClass: "bg-light p-2" }, [
                  _vm._v("\n          " + _vm._s(m.ShortName) + "\n          "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-link btn-sm",
                      on: {
                        click: function($event) {
                          return _vm.ScrollTo("AbContScroll")
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-arrow-alt-circle-up" })]
                  )
                ]),
                _vm._v(" "),
                _vm._l(m.Parameters, function(p) {
                  return _c("Parameter", {
                    key: p.Id,
                    attrs: {
                      measName: m.ShortName + " (" + m.Id + ")",
                      param: p
                    }
                  })
                }),
                _vm._v(" "),
                _vm._l(m.Childs, function(c) {
                  return _c(
                    "div",
                    [
                      _c("h3", { staticClass: "p-2" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(m.ShortName) +
                            " -> " +
                            _vm._s(c.ShortName) +
                            "\n            "
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link btn-sm",
                            on: {
                              click: function($event) {
                                return _vm.ScrollTo("AbContScroll")
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-arrow-alt-circle-up"
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(c.Parameters, function(cp) {
                        return _c("Parameter", {
                          key: cp.Id,
                          attrs: {
                            measName:
                              m.ShortName +
                              " -> " +
                              c.ShortName +
                              " (" +
                              c.Id +
                              ")",
                            param: cp
                          }
                        })
                      })
                    ],
                    2
                  )
                })
              ],
              2
            )
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("h2", { attrs: { id: "vd" } }, [
            _vm._v("\n        View Descriptions\n        "),
            _c(
              "button",
              {
                staticClass: "btn btn-link btn-sm",
                on: {
                  click: function($event) {
                    return _vm.ScrollTo("AbContScroll")
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-arrow-alt-circle-up" })]
            )
          ]),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "The following section provides an overview about all preconfigured views and describes content and general usage of the views:"
            )
          ]),
          _vm._v(" "),
          _vm._l(_vm.vds, function(v) {
            return _c("div", { key: v.Name }, [
              _c("h3", { staticClass: "bg-light p-2" }, [
                _vm._v("\n          " + _vm._s(v.Label) + "\n          "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-link btn-sm",
                    on: {
                      click: function($event) {
                        return _vm.ScrollTo("AbContScroll")
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-arrow-alt-circle-up" })]
                )
              ]),
              _vm._v(" "),
              _c("h5", [_vm._v(_vm._s(v.Comment))]),
              _vm._v(" "),
              _c(
                "p",
                _vm._l(v.Columns, function(c) {
                  return _c("Column", {
                    key: c.Name,
                    attrs: { viewName: v.Label, col: c }
                  })
                }),
                1
              )
            ])
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h2", [_vm._v("Overview")]),
        _vm._v(" "),
        _c("h5", [
          _vm._v(
            "\n        The Sentry CIS DataCube is a Data warehouse solution which provides the option to conduct big data analysis for SentrySuite database. Based on Sentry CIS Datacube templates a\n        "
          ),
          _c("b", [_vm._v("customized snapshot of the central repository")]),
          _vm._v(
            " is transferred into a Datacube database, hosted on central repository server. The DataCube engine is implemented in the new Sentry\n        "
          ),
          _c("b", [_vm._v("CIS Data Analytics service.")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(
            "The following diagram gives an overview about Sentry CIS DataCube installation, configuration and deployment and the principle QueryBuilder workflows:\n      "
          )
        ]),
        _vm._v(" "),
        _c("img", {
          attrs: {
            id: "about-datacube",
            src: require("../../assets/img/aboutdatacube.jpg")
          }
        }),
        _vm._v(" "),
        _c("h2", [_vm._v("Calculation of Z Score")]),
        _vm._v(" "),
        _c("h5", [
          _vm._v(
            "The following diagram gives an overview how the Z Score can be recalculated:"
          )
        ]),
        _vm._v(" "),
        _c("img", {
          attrs: {
            id: "about-datacube",
            src: require("../../assets/img/CalculateZScore.jpg")
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }