import Api from "@/common/api";

const state = {
  token: null,
  username: ""
};

export const LOGIN = "login";
export const LOGOUT = "logout";
const actions = {
  [LOGIN]({ commit }, { username, password }) {
    return Api.Login(username, password).then(d => {
      commit(SET_USERNAME, username);
      commit(SET_TOKEN, d);
    });
  },
  [LOGOUT]({ commit }) {
    commit(SET_TOKEN, null);
  }
};

const SET_USERNAME = "setUsername";
const SET_TOKEN = "setToken";
const mutations = {
  [SET_TOKEN](state, token) {
    state.token = token;
  },
  [SET_USERNAME](state, username) {
    state.username = username;
  }
};
const getters = {
  isLoggedIn: state => !!state.token,
  username: state => state.username,
  token: state => state.token
};

export default {
  state,
  actions,
  mutations,
  getters
};
