<template functional>
  <div class="container-fluid border-bottom pb-3 mb-3">
    <div class="row">
      <div class="col-4">
        <span class="text-muted">{{props.viewName}}:</span>
        <b
          :title="props.col.Type"
          style="cursor: help; border-bottom: 1px dotted #000;"
        >{{props.col.Name}} ({{props.col.Type}})</b>
      </div>
      <div class="col-8">{{props.col.Comment}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Column",
  props: {
    viewName: { type: String },
    col: {
      type: Object
    }
  }
};
</script>