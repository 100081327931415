<template>
  <div class="border rounded bg-white" style="margin-left: 5px">
    <div class="d-flex p-2 justify-content-between">
      <div class="d-flex">
        <div>
          <select
            ref="input"
            class="form-control form-control-sm"
            v-model="name"
            @keyup.esc="cancel"
          >
            <option v-for="c in columns" :value="c.name">{{ c.label }}</option>
          </select>
        </div>
        <div>
          <select
            class="form-control form-control-sm"
            id="parameterId"
            v-model="operator"
            @keyup.esc="cancel"
          >
            <option v-for="o in operators">{{ o }}</option>
          </select>
        </div>
        <div>
          <v-date-picker
            v-if="type==='date'"
            class="form-control form-control-sm"
            v-model="value"
            :max-date="new Date()"
          >
            <input
              type="text"
              slot-scope="{ inputProps, inputEvents }"
              class="w-100 h-100"
              style="background-color: transparent; color: inherit;"
              v-bind="inputProps"
              v-on="inputEvents"
            />
          </v-date-picker>
          <input v-else class="form-control form-control-sm" :type="type" v-model="value" />
        </div>
      </div>
      <div>
        <button class="btn btn-sm bg-light" @click.prevent.stop="remove">
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { OPERATORS_BY_COLUMN_TYPE } from "@/domain";
import {
  REMOVE_RULE,
  UPDATE_RULE_VALUE,
  UPDATE_RULE_NAME,
  UPDATE_RULE_OPERATOR
} from "@/store/query";

export default {
  name: "QueryBuilderRule",
  props: ["id"],
  data() {
    return {
      edit: false
    };
  },
  computed: {
    columns() {
      return this.$store.getters.columns;
    },
    segment() {
      return this.$store.getters.segmentById(this.id);
    },
    column() {
      return this.columns.find(c => c.name === this.segment.name);
    },
    operators() {
      return OPERATORS_BY_COLUMN_TYPE[this.column.type];
    },
    type() {
      switch (this.column.type) {
        case "nvarchar":
          return "text";
        case "int":
        case "float":
          return "number";
        case "date":
        case "datetime":
          return "date";
      }
      return "text";
    },
    operator: {
      get() {
        return this.segment.operator;
      },
      set(val) {
        this.$store.dispatch(UPDATE_RULE_OPERATOR, {
          id: this.id,
          operator: val
        });
      }
    },
    name: {
      get() {
        return this.segment.name;
      },
      set(val) {
        this.$store.dispatch(UPDATE_RULE_NAME, { id: this.id, name: val });
      }
    },
    value: {
      get() {
        if (!!this.segment.value && this.type === "date") {
          return moment(this.segment.value, "YYYY-MM-DD").toDate();
        }
        return this.segment.value;
      },
      set(val) {
        let v = val;
        if (!!v && this.type === "date") {
          v = moment(v).format("YYYY-MM-DD");
        }
        this.$store.dispatch(UPDATE_RULE_VALUE, { id: this.id, value: v });
      }
    }
  },
  methods: {
    startEditing() {
      this.edit = true;
      this.$nextTick().then(() => {
        this.$refs.input.focus();
      });
    },
    remove() {
      this.$store.dispatch(REMOVE_RULE, { id: this.id });
    },
    cancel() {},
    save() {
      this.edit = false;
    }
  }
};
</script>

<style lang="scss">
@import "../../_custom.scss";

.edit-indicator {
  display: none;
}
.hoverable {
  &:hover {
    background-color: map-get($theme-colors, "light") !important;
    cursor: pointer;

    .edit-indicator {
      display: inline-block;
    }
  }
}

/* This is just some basic styling to have it working, more needs to be done */
.vc-container {
  .vc-grid-container {
    width: 100%;
    display: -ms-grid;
    &.vc-weeks {
      padding: 5px 6px 7px 6px;
    }
    .vc-day-content {
      min-height: 1.8rem;
      width: 1.8rem;
      height: 1.8rem;
      margin: 0.1rem auto;
    }
  }
}
</style>
