import Vue from "vue";

import Api, { convertQueryToBackend, convertViewToBackend } from "@/common/api";
import { OPERATORS_BY_COLUMN_TYPE, DATA_VERSION } from "@/domain";

let segmentId = 2;

export const SEGMENT_TYPE_GROUP = "group";
export const SEGMENT_TYPE_RULE = "rule";

// file export / import functions
export const exportQuery = (selectedView, segments) => {
  return {
    version: DATA_VERSION,
    data: {
      selectedView,
      segments
    }
  };
};
const importQuery = ({ version, data }) => {
  if (version !== DATA_VERSION) {
    throw new Error("unsupported file version");
  }
  return {
    selectedView: data.selectedView,
    segments: data.segments
  };
};

const state = {
  segments: {
    1: {
      id: 1,
      logicalOperator: "all",
      type: SEGMENT_TYPE_GROUP,
      childIds: []
    }
  },
  version: null,
  connection: {
    serverName: null,
    instanceName: null,
    databaseName: null,
    databaseUser: null,
    databasePassword: null
  },
  count: -1, // -1 indicates that no count request was send
  views: {},
  selectedView: null
};
export const CONNECT = "connect";
export const DISCONNECT = "disconnect";
export const QUERY_COUNT = "queryCount";
export const FETCH_VIEWS = "fetchViews";
export const SELECT_VIEW = "selectView";
export const IMPORT_QUERY = "importQuery";
export const ADD_RULE = "addRule";
export const ADD_GROUP = "addGroup";
export const REMOVE_GROUP = "removeGroup";
export const REMOVE_RULE = "removeRule";
export const UPDATE_LOGICAL_OPERATOR = "updateLogicalOperator";
export const UPDATE_RULE_VALUE = "updateRuleValue";
export const UPDATE_RULE_OPERATOR = "updateRuleOperator";
export const UPDATE_RULE_NAME = "updateRuleName";
const actions = {
  [DISCONNECT]({ commit }) {
    commit(SET_CONNECTION, {});
    commit(RESET);
    commit(SET_COUNT, -1);
    commit(SET_SELECTED_VIEW, null);
  },
  [CONNECT](
    { commit },
    { serverName, instanceName, databaseName, databaseUser, databasePassword }
  ) {
    return Api.GetCubeVersion(
      serverName,
      instanceName,
      databaseName,
      databaseUser,
      databasePassword
    ).then(d => {
      commit(SET_CONNECTION, {
        serverName,
        instanceName,
        databaseName,
        databaseUser,
        databasePassword
      });
      commit(SET_CUBE_VERSION, d.Version);
    });
  },
  [QUERY_COUNT]({ commit, state, getters }) {
    // convert to backend format
    return Api.GetQueryBuilderCount(
      state.connection.serverName,
      state.connection.instanceName,
      state.connection.databaseName,
      state.connection.databaseUser,
      state.connection.databasePassword,
      convertViewToBackend(getters.selectedView),
      convertQueryToBackend(getters.segments)
    ).then(d => {
      commit(SET_COUNT, d.Count);
    });
  },
  [FETCH_VIEWS]({ commit, state }) {
    return Api.GetQueryBuilderViews(
      state.connection.serverName,
      state.connection.instanceName,
      state.connection.databaseName,
      state.connection.databaseUser,
      state.connection.databasePassword
    ).then(d => {
      const views = {};
      d.Views.forEach(v => {
        const view = {
          name: v.Name,
          label: v.Label,
          columns: []
        };
        v.Columns.forEach(c => {
          view.columns.push({
            name: c.Name,
            label: c.Label,
            type: c.Type
          });
        });
        views[view.name] = view;
      });

      commit(SET_VIEWS, views);
    });
  },
  [IMPORT_QUERY]({ commit, state }, data) {
    const { segments, selectedView } = importQuery(data);
    // make sure the view exists
    if (!state.views[selectedView]) {
      return;
    }
    commit(RESET);
    commit(SET_COUNT, -1);
    commit(SET_SELECTED_VIEW, selectedView);
    commit(SET_SEGMENTS, segments);
  },
  [SELECT_VIEW]({ commit }, name) {
    commit(RESET);
    commit(SET_COUNT, -1);
    commit(SET_SELECTED_VIEW, name);
  },
  [UPDATE_LOGICAL_OPERATOR]({ commit }, { id, operator }) {
    commit(SET_COUNT, -1);
    commit(SET_LOGICAL_OPERATOR, { id, operator });
  },
  [ADD_GROUP]({ commit, dispatch }, { id }) {
    commit(SET_COUNT, -1);
    const group = {
      id: segmentId++,
      logicalOperator: "all",
      type: SEGMENT_TYPE_GROUP,
      childIds: []
    };
    commit(SET_SEGMENT, { parentId: id, segment: group });
    return dispatch(ADD_RULE, { id: group.id });
  },
  [REMOVE_GROUP]({ commit }, { id }) {
    commit(SET_COUNT, -1);
    commit(UNSET_SEGMENT, { id });
  },
  [ADD_RULE]({ commit, getters }, { id }) {
    commit(SET_COUNT, -1);
    const rule = {
      id: segmentId++,
      type: SEGMENT_TYPE_RULE,
      name: getters.selectedView.columns[0].name,
      operator:
        OPERATORS_BY_COLUMN_TYPE[getters.selectedView.columns[0].type][0],
      value: ""
    };
    commit(SET_SEGMENT, { parentId: id, segment: rule });
  },
  [REMOVE_RULE]({ commit }, { id }) {
    commit(SET_COUNT, -1);
    commit(UNSET_SEGMENT, { id });
  },
  [UPDATE_RULE_NAME]({ commit, getters }, { id, name }) {
    commit(SET_COUNT, -1);
    const c = getters.columns.find(c => c.name === name);
    const operator = OPERATORS_BY_COLUMN_TYPE[c.type][0];
    commit(SET_SEGMENT_NAME, { id, name, operator });
  },
  [UPDATE_RULE_VALUE]({ commit }, { id, value }) {
    commit(SET_COUNT, -1);
    commit(SET_SEGMENT_VALUE, { id, value });
  },
  [UPDATE_RULE_OPERATOR]({ commit }, { id, operator }) {
    commit(SET_COUNT, -1);
    commit(SET_SEGMENT_OPERATOR, { id, operator });
  }
};

const SET_CONNECTION = "setConnection";
const SET_CUBE_VERSION = "setCubeVersion";
const SET_COUNT = "setCount";
const RESET = "reset";
const SET_SEGMENTS = "setSegments";
const SET_VIEWS = "setViews";
const SET_SELECTED_VIEW = "setSelectedView";
const SET_LOGICAL_OPERATOR = "setLogicalOperator";
const UNSET_SEGMENT = "unsetSegment";
const SET_SEGMENT = "setSegment";
const SET_SEGMENT_OPERATOR = "setSegmentOperator";
const SET_SEGMENT_VALUE = "setSegmentValue";
const SET_SEGMENT_NAME = "setSegmentName";
const mutations = {
  [SET_CONNECTION](
    state,
    { serverName, instanceName, databaseName, databaseUser, databasePassword }
  ) {
    state.connection = {
      serverName,
      instanceName,
      databaseName,
      databaseUser,
      databasePassword
    };
  },
  [SET_CUBE_VERSION](state, version) {
    state.version = version;
  },
  [RESET](state) {
    state.segments = {
      1: {
        id: 1,
        logicalOperator: "all",
        type: SEGMENT_TYPE_GROUP,
        childIds: []
      }
    };
  },
  [SET_COUNT](state, count) {
    state.count = count;
  },
  [SET_VIEWS](state, views) {
    state.views = views;
  },
  [SET_SELECTED_VIEW](state, name) {
    state.selectedView = name;
  },
  [SET_LOGICAL_OPERATOR](state, { id, operator }) {
    const segment = state.segments[id];
    if (segment.type === SEGMENT_TYPE_RULE) {
      return;
    }
    state.segments[id].logicalOperator = operator;
  },
  [UNSET_SEGMENT](state, { id }) {
    const cleanup = parentId => {
      const segment = state.segments[parentId];
      if (segment.type === SEGMENT_TYPE_GROUP) {
        segment.childIds.forEach(childId => {
          cleanup(childId);
        });
      }
      Vue.delete(state.segments, parentId);
    };
    // remove from parent
    Object.keys(state.segments).forEach(segmentId => {
      const segment = state.segments[segmentId];
      if (segment.type === SEGMENT_TYPE_RULE) {
        return;
      }
      const idx = segment.childIds.findIndex(s => s === id);
      if (idx === -1) {
        return;
      }
      segment.childIds.splice(idx, 1);
    });
    cleanup(id);
  },
  [SET_SEGMENTS](state, segments) {
    state.segments = segments;
  },
  [SET_SEGMENT](state, { parentId, segment }) {
    Vue.set(state.segments, segment.id, segment);
    state.segments[parentId].childIds.push(segment.id);
  },
  [SET_SEGMENT_NAME](state, { id, name, operator }) {
    state.segments[id].name = name;
    // reset value and operator
    state.segments[id].value = "";
    state.segments[id].operator = operator;
  },
  [SET_SEGMENT_OPERATOR](state, { id, operator }) {
    state.segments[id].operator = operator;
  },
  [SET_SEGMENT_VALUE](state, { id, value }) {
    state.segments[id].value = value;
  }
};

const getters = {
  version: state => state.version,
  count: state => state.count,
  isConnected: state => !!state.connection.serverName,
  connection: state => state.connection,
  segments: state => state.segments,
  segmentById: state => id => state.segments[id],
  selectedView: state => {
    if (!state.selectedView) {
      return null;
    }
    return state.views[state.selectedView];
  },
  views: state => state.views,
  columns: state => {
    if (!state.selectedView) {
      return null;
    }
    return state.views[state.selectedView].columns;
  },
  segmentTypes: state => {
    const res = {};
    Object.keys(state.segments).forEach(id => {
      res[id] = state.segments[id].type;
    });
    return res;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
