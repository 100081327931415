<template>
  <div
    class="h-100 cursor-pointer rounded border position-relative shadow-sm"
    :class="{ 'alert-success border-success': t._selected }"
    style="min-height: 98px;"
  >
    <div class="h-100 p-2 d-flex flex-column">
      <div class="flex-grow-1">
        <div>
          {{ t.label }}
          <span class="badge badge-warning" v-if="t.type === 'base'">
            <i class="fas fa-lock"></i> Base Template
          </span>
        </div>
        <div class="small">{{ t.description | truncate(120) }}</div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="small text-muted">Name: {{ t.name }}</div>
        <div>
          <button
            class="btn btn-sm btn-light"
            @click.stop="editTemplate(t.id)"
            v-if="!isBaseTemplate"
          >
            <i class="fas fa-pen"></i>
          </button>
          <button
            class="btn btn-sm btn-light"
            @click.stop="deleteTemplate(t.id)"
            v-if="!isBaseTemplate"
          >
            <i class="fas fa-trash"></i>
          </button>
          <button
            class="btn btn-sm btn-light"
            @click.stop="showTemplate(t.id)"
            v-if="isBaseTemplate"
          >
            <i class="fas fa-info"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TEMPLATE_TYPE_BASE } from "@/domain";
import {
  SELECT_TEMPLATE_FOR_DEPLOYMENT,
  REMOVE_TEMPLATE_FROM_DEPLOYMENT,
  REMOVE_TEMPLATE,
  OPEN_TEMPLATE_EDITOR
} from "@/store/templates";

export default {
  name: "TemplateCard",
  props: {
    id: String
  },
  computed: {
    selectedTemplateId() {
      return this.$store.getters.selectedTemplateId;
    },
    isBaseTemplate() {
      return this.t.type === TEMPLATE_TYPE_BASE;
    },
    t() {
      return this.$store.getters.templateById(this.id);
    }
  },
  methods: {
    deleteTemplate(id) {
      this.$store.dispatch(REMOVE_TEMPLATE, { id });
    },
    editTemplate(id) {
      this.$store.dispatch(OPEN_TEMPLATE_EDITOR, { id });
      this.$bvModal.show("edit-template-modal");
    },
    showTemplate(id) {
      this.$store.dispatch(OPEN_TEMPLATE_EDITOR, { id });
      this.$bvModal.show("show-template-modal");
    }
  }
};
</script>

<style scoped></style>
