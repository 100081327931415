<template>
  <QueryBuilderGroup id="1"></QueryBuilderGroup>
</template>

<script>
import QueryBuilderGroup from "@/components/home/QueryBuilderGroup";

export default {
  name: "QueryBuilder",
  components: { QueryBuilderGroup }
  /*
  data() {
    return {
      Query: {
        LogicalOperator: "all",
        Rules: [
          {
            Rule: "Visit Date",
            SelectedOperator: "<=",
            SelectedOperand: "Visit Date",
            Value: "2019-05-05"
          }
        ],
        Groups: [
          {
            LogicalOperator: "any",
            Rules: [
              {
                Rule: "Name",
                SelectedOperator: "Equals",
                SelectedOperand: "Name",
                Value: "zzzz"
              }
            ],
            Groups: [
              {
                LogicalOperator: "any",
                Rules: [
                  {
                    Rule: "Weight",
                    SelectedOperator: ">",
                    SelectedOperand: "Weight",
                    Value: "10"
                  },
                  {
                    Rule: "Weight",
                    SelectedOperator: "<",
                    SelectedOperand: "Weight",
                    Value: "100"
                  }
                ],
                Groups: []
              }
            ]
          }
        ]
      }
    };
  }
  */
};
</script>

<style scoped></style>
