<template>
  <div class="rounded border shadow-sm" :class="{ group: !isRoot }">
    <div class="pt-2 pl-4 pb-2" :class="{ 'pr-2': isRoot }">
      <div>
        <div class="d-flex w-100 position-relative justify-content-end pr-2">
          <div style="position:absolute; left: -15px; z-index: 15;">
            <div class="btn-group btn-group-sm">
              <button
                ref="operatorButtonAll"
                class="btn btn-light"
                :class="{ active: segment.logicalOperator === 'all' }"
                @click="setLogicalOperator('all')"
              >
                AND
              </button>
              <button
                ref="operatorButtonAny"
                class="btn btn-light"
                :class="{ active: segment.logicalOperator === 'any' }"
                @click="setLogicalOperator('any')"
              >
                OR
              </button>
            </div>
          </div>
          <div class="d-flex">
            <button
              ref="addRuleButton"
              class="btn btn-sm btn-light"
              @click="addRule"
            >
              <i class="fas fa-plus"></i> add rule
            </button>
            <button
              ref="addGroupButton"
              class="btn btn-sm btn-light"
              @click="addGroup"
            >
              <i class="fas fa-plus"></i> add group
            </button>
            <button
              class="btn btn-sm btn-light ml-2"
              @click="removeGroup"
              v-if="!isRoot"
            >
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </div>
        <div
          class="w-100 segment"
          :key="`segment-${id}`"
          v-for="id in segment.childIds"
        >
          <QueryBuilderGroup
            :id="id"
            v-if="segmentTypes[id] === 'group'"
          ></QueryBuilderGroup>
          <QueryBuilderRule :id="id" v-else></QueryBuilderRule>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ADD_GROUP,
  REMOVE_GROUP,
  ADD_RULE,
  UPDATE_LOGICAL_OPERATOR
} from "@/store/query";

import QueryBuilderRule from "@/components/home/QueryBuilderRule";

export default {
  name: "QueryBuilderGroup",
  components: { QueryBuilderRule },
  props: ["id"],
  computed: {
    segment() {
      return this.$store.getters.segmentById(this.id);
    },
    segmentTypes() {
      return this.$store.getters.segmentTypes;
    },
    isRoot() {
      return this.id === "1";
    }
  },
  methods: {
    addGroup() {
      this.$refs.addGroupButton.blur();
      this.$store.dispatch(ADD_GROUP, { id: this.id });
    },
    removeGroup() {
      this.$store.dispatch(REMOVE_GROUP, { id: this.id });
    },
    addRule() {
      this.$refs.addRuleButton.blur();
      this.$store.dispatch(ADD_RULE, { id: this.id });
    },
    setLogicalOperator(operator) {
      this.$store.dispatch(UPDATE_LOGICAL_OPERATOR, { id: this.id, operator });
      this.$refs.operatorButtonAny.blur();
      this.$refs.operatorButtonAll.blur();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../custom";

$border-color: map_get($theme-colors, "secondary");
$border-color: map_get($theme-colors, "primary");

/*

$input-border-color: #5c7098 !default;
$input-bg:           #c3d7ff !default;
 */

.group {
  margin-left: 5px;
  // background-color: #cce5ff;
  border-color: #5c7098;
  background-color: rgba(195, 215, 255, 0.3);
}

.segment {
  position: relative;
  margin: 4px 0;

  &:before {
    content: "";
    position: absolute;
    top: -15px;
    left: -5px;
    width: 10px;
    height: calc(50% + 15px);
    border-color: $border-color;
    border-style: solid;
    border-width: 0 0 2px 2px;
  }

  &:after {
    content: "";
    position: absolute;
    left: -5px;
    width: 10px;
    top: 50%;
    height: 50%;
    border-color: $border-color;
    border-style: solid;
    border-width: 0 0 0 2px;
  }

  &:last-child:after {
    display: none;
  }
}
</style>
