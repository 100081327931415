<template>
  <select
    ref="input"
    class="form-control form-control-sm"
    v-model="initialValue"
    @keyup.esc="cancel"
    v-click-outside="save"
    v-if="edit"
  >
    <option v-for="o in options" :value="o.id">
      {{ o.label }}
    </option>
  </select>
  <div
    class="w-100 hoverable form-control-sm d-flex align-items-center justify-content-between"
    v-else
    @click="startEditing"
  >
    <span>{{ label }}</span>
    <i class="edit-indicator fas fa-pen text-muted"></i>
  </div>
</template>

<script>
export default {
  name: "SelectInline",
  props: ["value", "options"],
  data() {
    return {
      edit: false,
      initialValue: this.value
    };
  },
  computed: {
    label() {
      const o = this.options.find(o => o.id === this.value);
      if (!o) {
        return "";
      }
      return o.label;
    }
  },
  methods: {
    startEditing() {
      this.edit = true;
      this.$nextTick().then(() => {
        this.$refs.input.focus();
      });
    },
    save() {
      this.$emit("input", this.initialValue);
      this.edit = false;
    },
    cancel() {
      this.initialValue = this.value;
      this.edit = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../_custom.scss";

.edit-indicator {
  display: none;
}
.hoverable {
  &:hover {
    background-color: map-get($theme-colors, "light") !important;
    cursor: pointer;

    .edit-indicator {
      display: inline-block;
    }
  }
}
</style>
